import React from 'react';
interface inputTypes {
  label: string;
  type: string;
  value: string;
  onChange: () => void,
  name: string
}
function InputAnimated({ label, type, value, onChange, name }: inputTypes) {


  return (
    <div className="inputEffect ">
      <div className="form__group">
        <input name={name} onChange={onChange} style={{ borderBottom: value !== '' ? '1px solid #ff6900' : '1px solid rgba(255, 255, 255, 0.5)' }} value={value} type={type} className="form__field" placeholder={label} />
        <label className="form__label graphik-regular">{label}</label>
      </div>
    </div>
  );
}

export default InputAnimated;
