import React from 'react';
import AllClassesIntro from '../../components/classes/AllClassesIntro';
import Layout from '../../components/layout/Layout';

function ClassDetail() {
  return (
    <Layout>
      <div className='max-w-[1440px] mx-auto'>
        <AllClassesIntro />
      </div>
    </Layout>
  );
}

export default ClassDetail;
