import React, { useContext, useState } from "react";
import { RegisterContext } from "../../../context/RegisterContext";
import Selected from "../../../common/buttons/Selected";
import { yourMemberships } from "../../../../mock/memberships";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import useFetch from "../../../hooks/useFetch";
import ArrowButton from "../../../common/buttons/ArrowButton";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import InputBase from "../../../common/inputs/InputBaseDashBoard";
import CheckboxActive from "../../../common/buttons/CheckboxActive";
import { questionsData } from "../../../../mock/question";
import Question from "../../questions/Question";
import ScrollToTop from "../../../common/scrollEffect/ScrollToTop";
function ConfirmDetail(props) {
  const [startDate, setStartDate] = useState<string>("");
  const { setStep } = props;
  const [toggle, setToggle] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);

  // Context
  const yourClub = useContext(RegisterContext).yourClub;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;
  const upgrades = useContext(RegisterContext).upgrades;
  const setUpgrades = useContext(RegisterContext).setUpgrades;
  const plan = useContext(RegisterContext).plan;

  // Get Upgrades
  const { data } = useFetch({
    params: "membership-upgrades",
    populate: "logo,image,features,price",
  });

  // Check value upgrades exists
  const handleCheck = (club: object) => {
    setUpgrades((prev) => {
      const isChecked = upgrades.some(
        (item) => item?.attributes?.title === club?.attributes?.title
      );
      return isChecked
        ? upgrades?.filter(
            (item) => item?.attributes?.title !== club?.attributes?.title
          )
        : [...prev, club];
    });
  };

  // Total Price
  let total = 0;
  upgrades.forEach((e) => {
    total += e?.attributes?.price?.[1]?.value;
  });

  return (
    <>
      <div className=" flex gap-[8px] items-center mt-[34px]">
        <div
          onClick={() => setStep(4)}
          className="w-[41px] h-[48px] p-[7px] pl-0"
        >
          <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
            <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
          </div>
        </div>
        <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
          step 4
        </span>
      </div>
      <div className="mt-[35px]">
        <h6 className="uppercase text-white text-sm leading-[140%] tracking-widest gotham">
          step 5
        </h6>
        <p className="mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white">
          confirm your details
        </p>
        <div className="flex w-full justify-between">
          <div className="">
            <div className="w-full mt-[31px]">
              <div>
                <h2 className="text-white text-[14px] gotham tracking-widest font-normal uppercase border-b border-b-white pb-2">
                  contact detail
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 mt-[21px] gap-y-[21px]">
                  <InputBase
                    isDashboard={true}
                    holder="First name*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    isDashboard={true}
                    holder="Last name*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    isDashboard={true}
                    holder="Email address*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    isDashboard={true}
                    holder="Phone number*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    isDashboard={true}
                    holder="Gender*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <div
                    className={`w-full border-b isDashboard flex h-[53px] flex-col pb-[2px]  relative ${
                      startDate !== "" ? "border-b-gray" : "border-b-gray"
                    } `}
                  >
                    <div className="absolute  bottom-[6px] w-full">
                      <Flatpickr
                        onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                        options={{
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                        }}
                      />
                    </div>
                    <label
                      className={`gotham text-[12px] absolute left-0 ${
                        startDate !== ""
                          ? "text-white top-[2px]"
                          : "text-gray bottom-[14px]"
                      }`}
                    >
                      Start date*
                    </label>
                    <div className="absolute  z-10 right-3 bottom-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_1209)">
                          <path
                            d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                            fill="#82FA00"
                          />
                          <path
                            d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1209">
                            <rect width="20" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
                <InputBase
                  isDashboard={true}
                  style="lg:w-full"
                  isRequired={true}
                />
              </div>
              {/* Actions */}
              <div className="mt-[70px]">
                <h2 className="text-white uppercase gotham text-[14px]  font-normal  border-b border-b-white pb-2">
                  health check
                </h2>
              </div>
              <div className="mt-[21px] flex lg:flex-row flex-col lg:items-center   items-start lg:w-auto  w-full justify-between">
                <h2 className="gotham font-normal text-sm lg:max-w-[448px] text-white lg:w-auto lg:w-[331px]">
                  Do you have any injuries, disabilities or current conditions?*
                </h2>
                <div className="flex lg:mt-0 mt-[18px] items-center lg:w-auto w-full  gap-[20px]">
                  <button
                    onClick={() => setAccepted(true)}
                    className={`text-white lg:w-[87px] lg:w-[50vw] w-1/2 border h-[40px] rounded ${
                      !accepted
                        ? "bg-transparent text-white border-white"
                        : "bg-white text-black"
                    } uppercase font-bold gotham text-[11px]`}
                  >
                    yes
                  </button>
                  <button
                    onClick={() => setAccepted(false)}
                    className={`text-white lg:w-[87px] lg:w-[50vw] w-1/2 border h-[40px] rounded ${
                      accepted
                        ? "bg-transparent text-white border-white"
                        : "bg-white text-black"
                    } uppercase font-bold gotham text-[11px]`}
                  >
                    no
                  </button>
                </div>
              </div>
              {/* Tick all that apply */}
              {accepted && (
                <div className="text-white mt-[60px]">
                  <p className="text-gray leading-[160%] mt-3 gotham pb-2 border-b border-b-gray font-normal text-sm">
                    Tick all to apply:*
                  </p>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal max-w-[80%] text-sm">
                      High or low blood pressure?
                    </h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">Pregnant?</h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">
                      Tightness in chest?
                    </h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">
                      Undergoing cancer treatment?
                    </h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">
                      High or low blood pressure?
                    </h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">Lorem ipsum</h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">
                      High or low blood pressure?
                    </h5>
                    <CheckboxActive e isDashboard={true} />
                  </div>
                  <div className="py-[17px] border-b border-b-gray flex items-center justify-between">
                    <h5 className="gotham font-normal text-sm">
                      Other (please specify)
                    </h5>
                    <CheckboxActive isDashboard={true} />
                  </div>
                </div>
              )}
              {/* Emergency contact */}
              <div className="mt-[50px]">
                <h2 className="text-white gotham text-[14px] font-normal uppercase border-b border-b-white pb-2">
                  Emergency contact
                </h2>
              </div>
              <div className="mt-[34px]">
                <div className="grid mb-[21px] lg:grid-cols-2 grid-cols-1 gap-y-4 text-white gap-x-[32px]">
                  <InputBase
                    isDashboard={true}
                    holder="Emergency contact*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    isDashboard={true}
                    holder="Relation to you*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                </div>
                <InputBase
                  isDashboard={true}
                  holder="Emergency phone*"
                  style="lg:w-full"
                  isRequired={true}
                />
              </div>

              {/* Privacy policy */}
              <div className="flex items-start justify-between gap-[15px] mt-[37px]">
                <div className="text-white">
                  <CheckboxActive isDashboard={true} />
                </div>
                <p className="font-normal text-[13px] -mt-1 leading-[160%] text-white gotham lg:w-[549px]">
                  I agree to the terms & conditions and the privacy policy
                  attached to my membership. I understand that I can cancel at
                  anytime with 14 days notice as outlined in the contract.
                </p>
              </div>
            </div>
            <div className="flex items-center w-full justify-between mt-[53px] mb-[90px]">
              <ArrowButton
                text="BACK"
                isRight={false}
                onClick={() => {
                  setStep(4);
                }}
              />
              <ArrowButton
                text="NEXT"
                isRight={true}
                onClick={() => {
                  setStep(6);
                }}
              />
            </div>
          </div>
          <div className="text-white mt-[-60px]">
            <div className="lg:w-[339px] lg:block hidden">
              <h2 className="text-white gotham text-[14px] font-normal  border-b border-b-white leading-[90%] uppercase pb-2">
                Your new membership
              </h2>
              <div className="w-full justify-between flex items-center h-4 mt-4 pr-[40px]">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                  Home Club
                </h2>
                <span
                  onClick={() => setStep(2)}
                  className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer "
                >
                  Edit
                </span>
              </div>
              <div
                style={{ color: yourClub?.attributes?.color }}
                className="font-normal  text-[12px] mt-3 uppercase gotham leading-[140%] pb-4 border-b border-b-gray"
              >
                {yourClub?.attributes?.title}
              </div>
              {/* Home Club */}
              <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white gotham">
                  Membership Plan
                </h2>
                <span
                  onClick={() => setStep(3)}
                  className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer"
                >
                  Edit
                </span>
              </div>
              {/* Membership Plan */}
              <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                <div className="flex items-center justify-between">
                  <h2 className="gotham uppercase text-[11px] text-white leading-[140%] ">
                    {plan?.attributes?.title} membership
                  </h2>
                  <span className="text-white gotham text-[11px] leading-[140%] font-normal uppercase">
                    ${plan?.attributes?.price}.00
                  </span>
                </div>
                <div className="flex mt-[10px] gap-[10px] flex-col">
                  {plan?.attributes?.features?.data?.map((item) => (
                    <h2 className="gotham  uppercase text-[11px] text-white leading-[140%] ">
                      {item?.attributes?.title}
                    </h2>
                  ))}
                </div>
              </div>
              {/* Upgrades */}
              <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                  Upgrades/Add-ons
                </h2>
                <span
                  onClick={() => setStep(4)}
                  className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer"
                >
                  Edit
                </span>
              </div>
              <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                <div className="flex mt-[10px] gap-[10px] flex-col">
                  {upgrades?.map((item) => (
                    <div className="flex items-center justify-between">
                      <h2
                        style={{ color: item?.attributes?.color }}
                        className="gotham  uppercase text-[11px] text-white leading-[140%] "
                      >
                        {item?.attributes?.title}
                      </h2>
                      <span className="gotham  uppercase text-[11px] text-white leading-[140%]">
                        ${item?.attributes?.price[1]?.value}.00
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                <h5 className="font-normal gotham text-sm text-white ">
                  {yourMemberships.costPerForNightText}
                </h5>
                <div className="text-white text-sm font-normal gotham text-[12px] leading-[100%]">
                  ${total + plan?.attributes?.price}.00
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                <div className="flex items-center relative justify-center">
                  <h5 className="font-normal gotham text-[12px] text-white ">
                    {yourMemberships.totalText}
                  </h5>
                </div>
                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                  $44.80
                </div>
              </div>
              {/* Promo code */}
              <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                <h5 className="font-normal text-sm text-white gotham">
                  {yourMemberships.promoCodeText}
                </h5>
                <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                  <span className="w-[20px] text-white gotham text-[12px] uppercase">
                    cl-
                  </span>
                  <input
                    // onChange={(e) => setCode(e.target.value)}
                    type="text"
                    className="w-full h-full ml-1 border-0 text-white font-normal gotham text-[12px] outline-0 bg-transparent"
                  />
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                <div className="flex items-center relative justify-center">
                  <h5 className="font-normal gotham text-[12px] text-white ">
                    Direct debit starts
                  </h5>
                </div>
                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                  01/09/2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDetail;
