import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useFetch from '../../hooks/useFetch';
import LoadingScreen from '../../common/loading/LoadingScreen';

function ExploreViva({ setExploreModal }) {
  const sliderRef = useRef<any>();
  const [currentBrand, setCurrentBrand] = useState<number>();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    swipe: false,
    swipeToSlide: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };


  const { data, loading } = useFetch({ params: 'explore-brand', populate: 'exploreBrands.images,exploreBrands.background,exploreInformation.image' })

  const vivaBrands = data?.data?.attributes?.exploreBrands

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (

        <div className="fixed z-[99999999] bg-black transition-transform h-[100vh] top-0 overflow-hidden left-0 w-full mx-auto">
          <div className="relative w-[1440px] flex justify-center max-x-auto left-[50%] transform translate-x-[-50%] h-full">
            <div className="absolute w-full h-full flex justify-between ">
              <div className="w-[116px] viva-left  h-full  hover:w-[294px] cursor-pointer transition-all bg-graySecondary">
                <div
                  onClick={() => setExploreModal(false)}
                  className="text-white w-[116px] z-[99999] absolute top-[50%] text-[20px] font-bold cursor-pointer transform  translate-y-[-50%] left-1 origin-left -rotate-90 text-xl ml-5"
                >
                  <span className="mr-[10px]">x</span> close
                </div>
                <img
                  src={data?.data?.attributes?.exploreInformation?.[0]?.image?.data?.attributes?.url}
                  alt="logo"
                  className="w-[82px] h-[37px] absolute  bottom-[40px] transition-all left-[15px]"
                />
                <p className="graphik-regular font-normal text-[12px] mt-[17px] absolute bottom-[40px] max-w-[255px] transition-all left-[15px] opacity-0 leading-[160%] text-white">
                  {data?.data?.attributes?.exploreInformation?.[0]?.subtitle}
                </p>
              </div>
              <div className="flex-1 overflow-hidden h-full">
                <Slider ref={sliderRef} {...settings}>
                  {vivaBrands?.map((item: object, index: number) => (
                    <div
                      key={index}
                      onMouseEnter={() => setCurrentBrand(index)}
                      className="w-[439px] h-[100vh] cursor-pointer transition-all bg-graySecondary  relative  border-l-[0.5px] border-l-gray"
                    >
                      {/* Background */}
                      {currentBrand === index && (
                        <div
                          style={{
                            backgroundImage: `url(${item?.background?.data?.attributes?.url})`,
                          }}
                          className="absolute top-0 left-0 w-full  transition-opacity  delay-75 h-full"
                        ></div>
                      )}
                      <img
                        style={{ marginTop: item?.images?.data?.length > 1 ? '-35px' : '0' }}
                        src={item?.images?.data?.[0]?.attributes?.url}
                        className={`w-[${item?.attributes?.height}px] h-[${item?.attributes?.width
                          }px]  absolute top-1/2 -translate-y-1/2  object-cover left-[50%] transform z-10 translate-x-[-50%]
                      }] `}
                      />
                      <img
                        src={item?.images?.data?.[1]?.attributes?.url}
                        className={`w-[${item?.attributes?.height}px] h-[${item?.attributes?.width
                          }px]  absolute top-1/2 -translate-y-1/2  object-cover left-[50%] mb-3 transform z-10 translate-x-[-50%] mt-[65px]
                      }] `}
                      />
                      <>
                        <div
                          className="w-full h-full relative left-[50%] transform translate-x-[-50%] bottom-[45%] "
                        >
                        </div>
                        {/* Border top */}
                        <span
                          style={{ backgroundColor: currentBrand === index ? item?.customMetadata?.color : 'transparent' }}
                          className={`w-full h-[36px] transition-all  top-0 left-0 text-white z-[999] absolute`}
                        ></span>
                        {/* Dot Cusor pointer */}
                        <div
                          style={{
                            backgroundColor: currentBrand === index ? item?.customMetadata?.color : 'transparent',
                            left: item?.customMetadata?.cursorPosition?.left + 'px',
                            top: item?.customMetadata?.cursorPosition?.top + 'px',
                          }}
                          className={`w-[13px] h-[13px] rounded-[50%] absolute`}
                        ></div>
                      </>

                      <p className="uppercase tracking-widest max-w-[171px] absolute bottom-[40px] left-[39px] font-medium text-sm leading-[140%] text-white graphik-regular">
                        {item?.title}
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* Prev, Next Slides */}
          <div className="flex gap-1 items-center  top-[40px]  right-0 w-full  fixed">
            <div className="absolute cursor-pointer flex gap-2 right-[33px] top-[10px] justify-end h-full left-0 w-[1440px] mx-auto">
              <div
                onClick={() => sliderRef.current.slickPrev()}
                className="w-[30px] h-[30px] border border-white rounded-[50%] flex items-center justify-center"
              >
                <svg
                  width="15"
                  height="15"
                  className="rotate-180"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767" stroke="white" stroke-width="1.3261" />
                  <path d="M15.9743 11.1309H0.998047" stroke="white" stroke-width="1.3261" />
                </svg>
              </div>
              <div
                onClick={() => sliderRef.current.slickNext()}
                className="w-[30px] h-[30px] border border-white rounded-[50%] flex items-center justify-center"
              >
                <svg width="15" height="15" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767" stroke="white" stroke-width="1.3261" />
                  <path d="M15.9743 11.1309H0.998047" stroke="white" stroke-width="1.3261" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
}

export default ExploreViva;
