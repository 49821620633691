import React from "react";
export function arrowRight() {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767"
        stroke="currentColor"
        stroke-width="1.3261"
      />
      <path
        d="M15.9743 11.1309H0.998047"
        stroke="currentColor"
        stroke-width="1.3261"
      />
    </svg>
  );
}
