import React from 'react';
import ButtonActive from '../../common/buttons/Active';

import circle from '../../assets/images/memberships/circle.png';
import LoadingScreen from '../../common/loading/LoadingScreen';
import { Link } from 'gatsby';

interface Iprops {
  loading: boolean,
  data: object
}
function WelcomeHiit(props: Iprops) {
  const { loading, data } = props

  const images = data?.data?.attributes?.midBanner?.images


  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <div className="pl-[24px] md:pl-[99px] max-w-[1440px] mx-auto pr-[24px] w-full mt-[19px] md:mt-[150px] relative h-[500px] md:h-[700px] ">
          <div className="text-white mt-[50px] md:mt-[100px] font-bold text-[40px] md:text-[62px] leading-[97.5%] tracking-tighter">
            <h2 className="first-line:text-white outline-text-1 max-w-[480px] ">{data?.data?.attributes?.midBanner?.title}</h2>
          </div>
          <p className="text-white graphik-regular text-justify font-normal text-[14px] md:text-[16px] text-base my-[40px] max-w-[350px]">
            {data?.data?.attributes?.midBanner?.description}
          </p>
          <Link to='/about'>
            <ButtonActive height={56} text="JOIN NOW" />
          </Link>
          <div className="absolute w-[446px] h-[379px] right-[136px] top-[-93px]">
            <img className="w-full h-full object-cover hidden lg:block" src={images?.data?.[2]?.attributes?.url} alt="coach" />
            <img src={images?.data?.[3]?.attributes?.url} className="text-white w-[83.7px] hidden lg:block h-[205px] absolute left-[163px] bottom-[-130px]" />
            <div className="absolute z-0 w-[272px] h-[273px] left-[309px] top-[294px]">
              <img className="w-full h-full object-contain hidden lg:block" src={images?.data?.[4]?.attributes?.url} alt="coach" />
            </div>
          </div>
          <div className="absolute z-[-1] w-[269px] md:w-[361px] h-[304px] right-[24px] left-auto md:left-[300px] top-[190px]">
            <img className="w-full h-full object-cover " src={images?.data[0]?.attributes?.url} alt="coach" />
            <div className="absolute z-[-2] w-[346px] h-[493px] hidden lg:block right-[-320px] top-[-20px]">
              <img className="w-full h-full object-cover " src={images?.data[1]?.attributes?.url} alt="coach" />
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default WelcomeHiit;
