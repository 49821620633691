import React, { useState } from "react";
import moment from "moment";
import logo from "../assets/images/hiit-logo.png";
function IpadCheckin() {
  const club = [
    { name: "Braddon", id: 40 },
    { name: "Campbell", id: 41 },
    { name: "Campbelltown", id: 95 },
    { name: "Canberra City", id: 98 },
    { name: "CISAC", id: 67 },
    { name: "Coburg", id: 132 },
    { name: "Corrimal", id: 104 },
    { name: "Erindale", id: 42 },
    { name: "Goulburn", id: 76 },
    { name: "Gungahlin", id: 97 },
    { name: "Kingston", id: 39 },
    { name: "Mitchell", id: 52 },
    { name: "Queanbeyan", id: 43 },
    { name: "Redcliffe", id: 117 },
    { name: "Shellharbour", id: 112 },
    { name: "Tuggeranong", id: 44 },
    { name: "Weston", id: 102 },
    { name: "Woden", id: 109 },
    { name: "Wollongong", id: 57 },
    { name: "Yamanto", id: 111 },
  ];
  const [clubSelect, setClub] = useState();
  const [classList, setClassList] = useState([]);
  const handleSelectClub = async (i) => {
    console.log("PRETRY" + classList);
    try {
      setLoading(true);
      setClub(i);
      const link = `${process.env.GROUNDUP_API_URL}/classes/timetable/?locationid=`;
      const data = await fetch(link + i.id).then((res) => res.json());
      setClassList(data);
      setLoading(false);
    } catch (e) {}
  };
  const combineDateTime = (n, r) => {
    var s = new Date(n),
      i = new Date(r),
      o = new Date();
    return (
      o.setFullYear(s.getFullYear(), s.getMonth(), s.getDate()),
      o.setHours(i.getHours(), i.getMinutes()),
      o
    );
  };
  const clearCurrent = () => {
    setLoading(true);
    setClub();
    setClassList([]);
    setClass();
    setUserList([]);
    setLoading(false);
  };
  const [classSelect, setClass] = useState();
  const [userList, setUserList] = useState([]);
  const handleSelectClass = async (i) => {
    try {
      setClass(i);
      const link = `${process.env.GROUNDUP_API_URL}/classes/classlist/?tid=`;
      let data = await fetch(link + i.T_ID).then((res) => res.json());
      data = data.filter((i) => i.status == "BOOKED" || i.status == "ATTENDED");
      setUserList(data);
    } catch (e) {}
  };
  const setBooked = async (item, idx) => {
    try {
      const url = `${process.env.GROUNDUP_API_URL}/classes/classlist/?tid=`;
      const response = await fetch(url + classSelect?.T_ID, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          bid: parseInt(item?.bid),
          memberno: item.memberno,
          status: item.status == "BOOKED" ? "ATTENDED" : "CLEAR",
        }), // body data type must match "Content-Type" header
      })
        .then()
        .catch((e) => {
          throw new Error(e);
        });

      const data = userList.map((i, index) => {
        let temp = i;
        if (index === idx) {
          temp = {
            ...i,
            status: i.status == "ATTENDED" ? "BOOKED" : "ATTENDED",
          };
        }
        return temp;
      });
      setUserList(data);
    } catch (e) {
      // console.log(e);
    }
  };
  const [loading, setLoading] = useState(false);
  return (
    <div className="min-h-[100vh] bg-[#000000] relative">
      <div className="h-24 bg-[#000000] flex justify-between items-center text-white">
        <div className="flex items-center justify-start pl-5">
          {userList.length > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="h-16 w-12 -mr-4"
              onClick={() => clearCurrent()}
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          )}
          <img
            src={logo}
            className="w-[140px] sm:w-[230px] ml-\[6px\] "
            alt="logo"
          />
          {/* <svg
            className="ml-8 w-64 pt-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 222 41"
          >
            <path
              fill="#fff"
              d="M19.815 31.657c-1.546 1.026-3.357 1.437-5.836 1.437C2.208 33.094 0 25.494 0 18.341 0 11.188 2.306 3.589 14.763 3.589c10.692 0 14.42 4.828 14.42 9.97h-9.466c-.294-1.924-1.472-3.264-4.954-3.264-4.855 0-5.247 3.8-5.247 8.046 0 4.246.343 8.092 5.199 8.092 2.6 0 4.66-1.788 5.002-4.47h-5.541v-6.17h15.057v24.412h-7.484c-2.006 0-1.934-2.37-1.934-2.37v-6.178zM125.481 4.037v15.826L116.21 4.037H105.372v16.047c0 4.56-1.03 5.902-4.661 5.902-3.58 0-4.658-1.34-4.658-5.902V4.037h-9.465v10.672c-.88-5.911-4.375-11.12-15.002-11.12-9.93 0-13.614 4.582-14.76 10.007-.098-4.077-1.56-9.56-13.335-9.56H31.229v28.61h9.417V22.052l6.818 10.596h11.723l-9.073-10.46c3.486-.597 5.492-2.637 6.298-5.217a28.32 28.32 0 00-.032 1.373c0 7.197 2.403 14.751 15.205 14.751 10.831 0 14.253-5.407 15.051-11.403.477 7.786 4.604 11.403 14.077 11.403 10.153 0 14.176-4.111 14.176-13.008v-2.973l.049.065 9.564 15.467h10.496V4.037h-9.517zM43.147 17.224h-2.5v-6.348h2.5c3.336 0 4.17 1.116 4.17 3.218 0 1.923-.784 3.13-4.17 3.13zm28.438 8.985c-5.345 0-5.69-3.622-5.69-7.868 0-4.247.345-7.824 5.69-7.824 5.397 0 5.69 3.577 5.69 7.868 0 4.202-.295 7.824-5.69 7.824zM136.898 4.037h12.606c10.104 0 14.861 5.498 14.861 14.127 0 8.582-5.1 14.483-15.352 14.483h-12.115V4.037zm10.939 21.77c4.562 0 7.26-1.073 7.26-7.69 0-6.482-2.453-7.241-7.063-7.241h-1.717v14.93h1.52zM196.398 4.037h11.919C220.971 4.037 222 9.892 222 14.274c0 4.335-.686 10.104-13.291 10.104h-2.893v8.269h-9.418V4.037zm12.164 13.68c3.138 0 3.924-1.653 3.924-3.443 0-1.832-.834-3.398-3.924-3.398h-2.746v6.84h2.746z"
            />
            <path
              fill="#fff"
              d="M185.084 4.037v16.047c0 4.56-1.03 5.902-4.661 5.902-3.58 0-4.658-1.34-4.658-5.902V4.037h-.008L169.561.018 160.523 0l5.722 4.037h.051v16.047c0 8.897 4.021 13.008 14.125 13.008 10.153 0 14.176-4.111 14.176-13.008V4.037h-9.513z"
            />
          </svg> */}
        </div>
        <div className="text-3xl uppercase text-center">
          <div>Choose a club</div>
          <div className="font-normal text-xl"></div>
        </div>

        <div
          className="text-right mr-6 w-64 "
          onClick={() => {
            clearCurrent();
          }}
        >
          <div className="text-3xl">{classSelect?.C_Name}</div>
          {classSelect && (
            <div className="text-xl whitespace-nowrap">
              {moment(classSelect?.T_Time).format("h:mm a")} -{" "}
              {classSelect?.I_DisplayName}
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="snippet w-min mx-auto my-10 " data-title="dot-pulse">
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      )}
      {!loading && (
        <div
          className={`my-[20px] mx-auto flex flex-col gap-[20px] ${
            userList.length !== 0 ? "" : "max-w-[600px]"
          }`}
        >
          {!clubSelect && (
            <>
              {club.map((i, idx) => (
                <div
                  key={idx}
                  onClick={() => handleSelectClub(i)}
                  className="p-[20px] bg-[#000000] text-[26px] cursor-pointer text-white border-white border "
                >
                  {i.name}
                </div>
              ))}
            </>
          )}
          {clubSelect &&
            classList &&
            userList.length == 0 &&
            classList.length > 0 && (
              <>
                {classList
                  .filter((i) => {
                    const n = new Date();
                    const s = combineDateTime(i.T_Date, i.T_Time);
                    i = new Date(s.getTime() + 60 * i.T_Duration * 1e3);
                    n.setHours(0, 0, 0, 0);
                    return !((s.getTime() - n.getTime()) / 1296e5 > 1);
                  })
                  .map((i, idx) => {
                    return (
                      <div
                        key={idx}
                        onClick={() => handleSelectClass(i)}
                        className="p-[20px] bg-[#000000] text-[26px] cursor-pointer border border-white text-white"
                      >
                        {i.I_DisplayName} - {i.C_Name}
                        <br />
                        {moment(i.T_Time).format("h:mm a")} -{" "}
                        {i.S_Name?.split("-")[1]}
                      </div>
                    );
                  })}
              </>
            )}
          <div className="grid grid-cols-3 p-8 overflow-y-auto gap-[30px] w-full max-w-[1300px] my-0 mx-auto">
            {userList &&
              userList.length > 0 &&
              userList.map((i, idx) => (
                <div
                  key={idx}
                  className={`p-[20px]  text-[26px] cursor-pointer uppercase ${
                    i.status === "BOOKED"
                      ? i.iscasual === 1
                        ? "bg-[#FFA500] text-white border border-white"
                        : "border-white border-[2px] text-white"
                      : "bg-[#ffffff]"
                  }`}
                  onClick={() => setBooked(i, idx)}
                >
                  {i.name}
                  {i.iscasual === 1 ? " - CASUAL" : ""}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default IpadCheckin;
