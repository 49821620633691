import React, { useState, useEffect } from "react";
import Footer from "../DashboardFooter";
import Sidebar from "../DashboardSideBar";
import Drawer from "react-drag-drawer";
import { globalHistory } from "@reach/router";
import { dashboardUserBasicInfo, sidebarMenu } from "../../../mock/dashboard";
import logoutIcon from "../../assets/images/dashboard/logoutIcon.png";
import { Link } from "gatsby";

function DashBoardLayout(props: any) {
  const [isOpen, setOpen] = useState(true);
  const [expand, setExpand] = useState<boolean>(false);
  const path = globalHistory.location.pathname;
  useEffect(() => {
    path.split("/").includes("account") ? setExpand(true) : false;
  }, [path]);
  const toggle = () => {
    // let toggle = isOpen;
    setOpen(!isOpen);
  };

  return (
    <div className=" mx-auto ">
      <div id="Layout" className=" h-[calc(100vh-51px)] flex w-full relative">
        <Sidebar
          sideBarOpen={true}
          setSideBarOpen={() => {}}
          location={props}
        />
        <div
          className={`h-[calc(100vh-75px)] md:h-[calc(100vh-51px)] overflow-auto ${
            isOpen ? " md:w-[calc(100%-339px)]" : "w-full"
          }`}
        >
          {props.children}
        </div>
      </div>
      <div
        onClick={toggle}
        className=" md:hidden block bg-black fixed bottom-[0px] h-[75px] w-full flex flex-col text-white pt-[16px] border-y border-gray gap-[16px] items-center"
      >
        <svg
          width="58"
          height="4"
          viewBox="0 0 58 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52734 2H56.4713"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
        <div>MENU</div>
      </div>
      <Drawer open={isOpen} onRequestClose={toggle}>
        <div className=" bg-black w-[100vw] md:hidden block text-white">
          <div
            onClick={toggle}
            className="bg-black  h-[75px] w-full flex flex-col text-white pt-[16px] border-y border-gray gap-[16px] items-center"
          >
            <svg
              width="58"
              height="4"
              viewBox="0 0 58 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.52734 2H56.4713"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <div>MENU</div>
          </div>
          <div className="">
            {sidebarMenu.map((item: any, index: number) => (
              <>
                {item.name == "account" ? (
                  <div>
                    <div
                      onClick={() => setExpand(!expand)}
                      key={index}
                      className={`gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase ${
                        index === 0 ? "border-y" : ""
                      }`}
                    >
                      {item.name}
                    </div>
                    <div
                      // className={`overflow-hidden ${
                      //   expand == true
                      //     ? "h-auto opacity-100 transition-all"
                      //     : "h-0 opacity-0 transition-all"
                      // }`}
                    >
                      {item.tabs.map((item, index) => (
                        <Link to={`/${item.link}`}>
                          <p
                            className={`h-[36px] flex items-center ${
                              window.location.pathname == "/" + item.link
                                ? "border-r-[5px] border-r-white bg-[#292929]"
                                : ""
                            } pl-[24px] gotham text-white capitalize text-sm font-normal flex items-center" key={index} `}
                          >
                            {item.name}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link to={`/dashboard${index != 0 ? "/" : ""}${item.id}`}>
                    <div
                      key={index}
                      className={` gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase ${
                        index === 0 ? "border-y" : ""
                      }          ${
                        window.location.pathname.split("/")[
                          window.location.pathname.split("/").length - 1
                        ] === item.link
                          ? "border-r-[5px] border-r-white bg-[#292929]"
                          : index === 0
                      }`}
                    >
                      {item.name}
                    </div>
                  </Link>
                )}
              </>
            ))}
            <div
              onClick={() => setOpen(true)}
              className={`flex justify-between items-center gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase                   `}
            >
              LOG OUT <img className="w-6 h-6 mr-[17px]" src={logoutIcon} />
            </div>
          </div>
        </div>
      </Drawer>
      <Footer />
    </div>
  );
}

export default DashBoardLayout;
