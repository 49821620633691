import React, { useEffect } from "react";
// import TableData from "./TableData";
function TableBase(props) {
  const color = {
    "club lime": "#B3E833",
    "hiit republic": "#FF6900",
    "ground up": "#A4ACD7",
  };
  const TableData = [
    { id: 1, fullName: "Noor Khan", age: 25, city: "Patna", place: "location" },
    { id: 2, fullName: "Rapsan Jani", age: 26, city: "Noida" },
    { id: 3, fullName: "Monika Singh", age: 18, city: "New Delhi" },
    { id: 4, fullName: "Sunil Kumar", age: 22, city: "Jaipur" },
    { id: 5, fullName: "Kajol Kumari", age: 21, city: "Chennai" },
  ];
  // get table column
  const column = props?.headerCells;

  // get table heading data
  const ThData = () => {
    return column.map((data, idx) => {
      if (idx === 0)
        return (
          <th
            className="text-left py-[15px] border-b sticky top-0 bg-black z-10 border-white h-[52px] text-sm gotham font-normal text-start   uppercase w-[14%] pl-[22px] "
            key={data}
          >
            {data}
          </th>
        );
      else
        return (
          <th
            className="text-left py-[15px] border-b sticky top-0 bg-black z-10 border-white h-[52px] text-sm gotham font-normal text-start uppercase w-[14%]"
            key={data}
          >
            {Object.keys(data) && data.elm ? data.label : data}
          </th>
        );
    });
  };

  // get table row data
  const tdData = () => {
    return props.bodyCells.map((data) => {
      return (
        <tr className="text-left relative ">
          {column.map((v, idx) => {
            if (idx === 0)
              return (
                <td className="relative py-[1px] border-b border-gray text-start h-[52px] gotham uppercase z-0 w-[14%] pl-[22px] font-medium truncate">
                  {data[v] && Object.keys(data[v]).length > 0 && data[v]?.custom
                    ? data?.[v]?.custom(data)
                    : data[v]}
                </td>
              );
            else
              return (
                <td className=" py-[1px] border-b  border-gray text-start h-[52px] gotham  uppercase z-0 w-[14%] font-normal text-clip ">
                  {column[idx] &&
                  Object.keys(column[idx]).length &&
                  column[idx].elm
                    ? column[idx]?.elm(data)
                    : data[v]}
                  {color[data[v]] ? (
                    <div
                      style={{ background: color[data[v]] }}
                      className="w-[5px] h-full absolute left-0 top-0 "
                    ></div>
                  ) : (
                    ""
                  )}
                </td>
              );
          })}
        </tr>
      );
    });
  };
  const responsiveLayout = () => (
    <div className="">
      {props.bodyCells.map((data, idx) => (
        <div
          className={`relative pt-[13px] pl-[18px] pr-6 pb-[16px] -mx-6 border-white ${
            idx == 0 ? "border-y" : "border-b"
          }`}
        >
          <div className="flex justify-between">
            <p className="font-[500] text-xs tracking-[0.01em] leading-[20px]">
              {data.date}
            </p>
            <p className="font-[400] text-xs tracking-[0.01em] leading-[20px]">
              {data.instructor}
            </p>
          </div>
          <div className="font-[500] text-xs tracking-[0.01em] leading-[20px]">
            {data.time}
          </div>
          <div className="flex justify-between">
            <div>
              <p className="font-[400] tracking-[0.01em] leading-[20px]">
                {data.class}
              </p>
              <p className="font-[400] text-xs tracking-[0.01em] leading-[20px]">
                {data.brand} {data.place}
              </p>
            </div>
            <div>
              {props.headerCells[props.headerCells.length - 1]?.elm(data)}
            </div>
          </div>
          <div
            style={{ background: color[data.brand] }}
            className="w-[5px] h-full absolute left-0 top-0 "
          ></div>
        </div>
      ))}
    </div>
  );
  return (
    <>
      {props && props.headerCells && props.bodyCells && (
        <>
          {/* if(responsive true then) */}
          <table
            className={`table w-full ${
              props.responsiveChange ? "xl:table hidden" : ""
            }`}
          >
            <thead>
              <tr>{ThData()}</tr>
            </thead>
            <tbody>{tdData()}</tbody>
          </table>
          {/* else */}
          {props.responsiveChange && (
            <div
              className={`text-white text-lg ${
                props.responsiveChange ? "xl:hidden block " : "hidden"
              }`}
            >
              {responsiveLayout()}
            </div>
          )}
        </>
      )}
    </>
  );
}
export default TableBase;
