import React, { useEffect, useState } from "react";
import Menu from "../../assets/images/icons/MenuIcon.svg";
import Map from "../../assets/images/icons/MapIcon.svg";
import MapActive from "../../assets/images/icons/map.svg";
import MenuActive from "../../assets/images/icons/menu.svg";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import Dropdown from "../../common/dropdowns/Dropdown.tsx";
import { dropdown } from "../../../mock/dropdown";
import positionFilterIcon from "../../assets/images/icons/positionFilterIcon.svg";
import filterIconMobile from "../../assets/images/icons/filterIconMobile.svg";

function StudioSearch(props: any) {
  const { isMap, setIsMap } = props;
  const [isSelected, setSelected] = useState({
    location: [],
    "sort by": [],
    position: false,
  });
  const onChange = (e: boolean, name: any, type: string) => {
    // let nameArray = "";
    if (name?.text === "closest to me") {
      // props.sortPosition(name);
      setSelected({
        ...isSelected,
        position: e,
      });
    } else {
      if (e || type == "sort by") {
        if (type == "sort by")
          setSelected({
            ...isSelected,
            [type]: name,
          });
        else
          setSelected({
            ...isSelected,
            [type]: (isSelected as any)[type].concat(name),
          });
      } else {
        if (type !== "sort by") {
          setSelected({
            ...isSelected,
            [type]: (isSelected as any)[type].filter((i: string) => i != name),
          });
        }
      }
    }
  };
  const [locationAllow, setLocationAllow] = useState(null);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setLocationAllow(true);
        },
        () => {
          setLocationAllow(false);
        }
      );
    } else {
      setLocationAllow(false);
    }
  }, []);
  const [mobileFilter, setMobileFilter] = useState(false);



  return (
    <>
      <div className="text-white mx-[9px]  gap-[9px] z-10 relative lg:flex hidden">
        <div className="h-[59px] w-[37.014%] bg-[#292929] flex justify-start items-center text-[18px] font-bold gap-[22px] pl-[59px]">
          <img className="bg-[#292929] " src={SearchIcon} />
          <input
            id="studioSearch"
            className="focus:outline-none placeholder:text-white w-full bg-[#292929] text-lg font-bold text-white"
            placeholder="city, suburb or postcode"
            onKeyUp={props.set}
          />
          {/* City, suburb or postcode</input> */}
        </div>
        <Dropdown
          style="h-[59px] w-[23.75%] bg-[#292929]  font-[18px] font-bold px-[24px]"
          data={dropdown.location}
          onChange={onChange}
          onSelect={() => props.filterLocation(isSelected.location)}
          selectedData={isSelected.location}
        ></Dropdown>
        {locationAllow != null && (
          <Dropdown
            style="h-[59px] w-[23.75%] bg-[#292929]  font-[18px] font-bold px-[24px]"
            justOne={true}
            noEachTitle={true}
            data={dropdown.sort}
            onChange={onChange}
            isLocation={locationAllow}
            onSelect={() => {
              // props.sortPosition();
              props.sortData(isSelected["sort by"], isSelected["position"]);
            }}
            selectedData={isSelected["sort by"]}
            outSideGroup="closest to me"
          // outSideGroupSelected={() => {}}
          // outSideGroupSelectedData={isSelected["position"]}
          ></Dropdown>
        )}
        <div className="h-[59px] w-[12.7083334%] bg-[#292929] flex">
          <div
            onClick={() => setIsMap(false)}
            className={`bg-[#292929] text-brand-orange w-1/2 h-full ${!isMap ? "border-b-brand-orange border-b-[5px]" : ""
              }  flex justify-center items-center`}
          >
            <img className="bg-[#292929]" src={!isMap ? Menu : MenuActive} />
          </div>
          <div
            onClick={() => setIsMap(true)}
            className={`bg-[#292929] text-brand-orange w-1/2 h-full ${isMap ? "border-b-[5px] border-b-brand-orange" : ""
              } flex justify-center items-center`}
          >
            <img className="bg-[#292929]" src={isMap ? MapActive : Map} />
          </div>
        </div>
      </div>
      <div className="lg:hidden flex ">
        <div className="h-[59px] relative w-full mx-3 my-3 bg-[#292929] flex justify-start items-center text-[18px] font-bold gap-[22px] px-[14px] border-[1px] border-brand-orange">
          <img className="bg-[#292929] " src={SearchIcon} />
          <input
            id="studioSearch"
            className="focus:outline-none w-full bg-[#292929] text-white"
            placeholder="City, suburb or postcode"
            onKeyUp={props.set}
          />
          {/* City, suburb or postcode</input> */}
          <img src={positionFilterIcon} />
          <label>
            <img
              src={filterIconMobile}
              onClick={() => {
                setMobileFilter(!mobileFilter);
              }}
            />
            <div
              className={`absolute w-full  left-0 top-full border-[1px] border-brand-orange bg-[#292929] text-white ${mobileFilter ? "" : "hidden"
                }`}
            >
              <Dropdown
                style="h-[59px] w-full bg-[#292929] z-[9999]  font-[18px] font-bold px-[24px]"
                data={dropdown.location}
                onChange={onChange}
                onSelect={() => props.filterLocation(isSelected.location)}
                selectedData={isSelected.location}
              ></Dropdown>
              <Dropdown
                style="h-[59px] w-full bg-[#292929]  font-[18px] font-bold px-[24px]"
                justOne={true}
                data={dropdown.sort}
                onChange={onChange}
                onSelect={() => props.sortData(isSelected["sort by"])}
                selectedData={isSelected["sort by"]}
              ></Dropdown>
            </div>
          </label>
        </div>
      </div>{" "}
    </>
  );
}
export default StudioSearch;
