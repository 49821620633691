import React from 'react';
import { congrats } from '../../../mock/memberships';
import ButtonActive from '../../common/buttons/Active';
import store from '../../assets/images/memberships/store.png';
import chPlay from '../../assets/images/memberships/ggPlay.png';
import textEffect from '../../assets/images/memberships/textEffect2.png';
import textEffect2 from '../../assets/images/memberships/textEffect.png';
import coach from '../../assets/images/memberships/coach3.png';
import coach2 from '../../assets/images/memberships/coach2.png';
import icon from '../../assets/images/memberships/h-icon.png';
import welcome from '../../assets/images/memberships/welcome.png';
import { Link } from 'gatsby';

function Congrats() {
  return (
    <div className="flex justify-between h-full w-full  ">
      {/* Info */}

      <div className="lg:w-[796px] w-full mt-[70px] pb-[60px] mx-6">
        <h2 className="text-white text-[20px] font-bold lowercase border-b border-b-white pb-2">
          {congrats.signUpText}
        </h2>
        {/* Congrats info */}
        <div className="flex items-start mt-[23px] justify-between lg:flex-row flex-col">
          <div>
            <p className="outline-text-1 text-[60px] font-bold leading-[110%] tracking-tight">{congrats.title}</p>
            <span className="text-[60px] tracking-tight lowercase text-brand-orange  leading-[110%] font-bold">
              amy
            </span>
          </div>
          <div className="grid grid-cols-2 gap-[78px]">
            <div>
              <h6 className="graphik-regular font-normal text-[12px] leading-[160%] text-white">
                {congrats.info.memText}
              </h6>
              <span className="mt-[17px] text-brand-orange font-bold text-sm">846 363</span>
              <h6 className="mt-[25px] graphik-regular font-normal text-[12px] leading-[160%] text-white">
                {congrats.info.portalText}
              </h6>
              <span className="mt-[17px] text-brand-orange font-bold text-sm">019 373 266</span>
              <Link to="/login">
                <div className="mt-[40px]  lg:w-[143px]">
                  <ButtonActive width="143px" text="login" height={52} />
                </div>
              </Link>
            </div>
            <div>
              <h6 className="graphik-regular font-normal text-[12px] leading-[160%] text-white">
                {congrats.info.startDateText}
              </h6>
              <span className="mt-[17px] text-brand-orange font-bold text-sm">01/05/22</span>
              <h6 className="mt-[25px] graphik-regular font-normal text-[12px] leading-[160%] max-w-[160px] text-white">
                {congrats.info.attention}
              </h6>
            </div>
          </div>
        </div>
        {/* Next steps */}

        <div className="mt-[26px]">
          <h2 className="text-white text-[20px] font-bold lowercase border-b border-b-white pb-2">
            {congrats.nextSteps.title}
          </h2>
          {congrats.nextSteps.steps.map((item: any, index: number) => (
            <div className="py-[18px] flex items-center justify-between border-b border-b-gray">
              <div className="flex items-center">
                <span className="text-[35px] w-[66px] outline-text-1 font-bold leading-[110%] lowercase">
                  0{index + 1}
                </span>
                <p className="text-[20px] lowercase text-brand-orange  font-bold leading-[100%]">{item.name}</p>
              </div>
              <div className=" graphik-regular text-[14px] font-normal max-w-[367px] leading-[160%] text-white">
                {item.desc}
                {index === 1 && (
                  <div className="mt-[8px] gap-[8px] flex lg:flex-row flex-col items-center">
                    <img className="w-[115px] h-[34px]" src={store} />
                    <img className="w-[134px] h-[52px]" src={chPlay} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Intro image */}
      <div className="relative lg:w-[418px] w-0">
        {/* Close icon */}
        {/* <div className="absolute right-0 top-5">
          <Link to="/">
            <div className="cursor-pointer">
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                  stroke="#FF6900"
                  stroke-width="1.32496"
                />
                <path d="M24.7275 15.1323L15.3977 24.4622" stroke="#FF6900" stroke-width="1.32496" />
                <path d="M24.8311 24.5654L15.5598 15.2942" stroke="#FF6900" stroke-width="1.32496" />
              </svg>
            </div>
          </Link>
        </div>
        <img className="absolute left-0 top-[7px] w-[159px] h-[221px]" src={textEffect} />
        <img className="absolute left-0 top-[351px] w-[217px] h-[206px] z-10" src={coach} />
        <img className="absolute left-[137px] top-[165px] w-[231px] h-[195px]" src={coach2} />
        <img className="absolute left-[18px] z-10 top-[498px] w-[63px] h-[118px]" src={icon} />
        <img className="absolute right-0 z-10 top-[127px] w-[33px] h-[257px]" src={welcome} />
        <img className="absolute right-0 z-10 top-[462px] w-[201px] h-[284px]" src={textEffect2} /> */}
      </div>
    </div>
  );
}

export default Congrats;
