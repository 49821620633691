import React from 'react';
import BookAClassToolBar from '../../components/classes/BookAClassToolBar';
import Layout from '../../components/layout/Layout';

function BookAClass() {
  return (
    <Layout>
      <div className='max-w-[1440px] mx-auto'>
        <BookAClassToolBar />
      </div>
    </Layout>
  );
}

export default BookAClass;
