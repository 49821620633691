import React, { useState, useEffect, useRef } from "react";
import { studios } from "../../../mock/studios";
import CheckedMark from "../../assets/images/icons/CheckedMark.svg";
import ArrowRight45 from "../../assets/images/icons/ArrowRight45.svg";
import { Link } from "gatsby";
import { Map } from "../map/Map";
import DropDownIcon from "../../assets/images/icons/DropDownIcon.svg";

function listOfStudio(props) {
  const { isMap } = props;

  const [clubSelected, setClubSelected] = useState();
  const viewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: true }
              : { ...i, isShow: false }
        )
      );
  };

  return (
    <>
      <div className="w-full  lg:flex hidden">
        <div
          className={`${isMap
              ? "w-[651px] transition-transform h-[570px] overflow-y-scroll scrollbar-map"
              : "w-full transition-all"
            }`}
        >
          {props.data && props.data.length ? (
            props.data.map((i: any, index: number) => {
              return (
                <div
                  onClick={() => setClubSelected(i)}
                  className={`${isMap && "hover:bg-[#1C1C1C] transition-all"
                    } 'text-white h-[280px] border-b-[1px] border-[rgba(255,255,255,0.4)]'+ ${i?.isShow && !isMap ? "bg-[#1C1C1C]" : ""
                    }`}
                  key={index}
                  onMouseEnter={() => viewClub(index)}
                >
                  <div className="p-[22px] flex">
                    <img
                      src={i.image}
                      className="w-[311px] h-[235px] object-cover"
                    />
                    <div
                      className={`px-[26px] text-brand-orange flex flex-col ${isMap ? "min-w-full" : "w-1/4"
                        } `}
                    >
                      <div
                        className={`flex h-1/2 gap-[4px] ${isMap ? "justify-between w-full" : "flex-col"
                          }`}
                      >
                        <span className="text-brand-orange font-bold text-2xl graphik-regular leading-6">
                          {i.title}
                        </span>
                        <span className="text-white text-sm font-normal graphik-regular">
                          {i.distance}km
                        </span>
                      </div>
                      <div className="flex flex-col h-1/2 gap-[11px] justify-between">
                        <span className="text-white max-w-[218px] text-sm font-normal graphik-regular">
                          {i.address}
                        </span>
                        <p className="text-brand-orange max-w-[218px] text-sm font-normal graphik-regular">
                          {i.phoneNumber}
                        </p>
                      </div>
                    </div>
                    {!isMap && (
                      <div className="w-1/4 px-[26px] flex flex-col justify-end mb-[17px] gap-[23px]">
                        {i.access.map((i: any, index: number) => (
                          <div key={index} className="flex gap-[17px]">
                            <div className="w-[30px] h-[30px] flex justify-center items-center border-[1px] border-brand-orange rounded-full">
                              <img src={CheckedMark} />
                            </div>
                            <span className="text-white font-bold ">{i}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="w-1/4 flex justify-between items-end flex-col">
                      {!isMap && (
                        <div className="flex flex-col justify-end">
                          <div className="text-end text-white graphik-regular">
                            Memberships from
                          </div>
                          <span className="text-brand-orange font-medium text-base text-end graphik-regular">
                            ${i.price}/week
                          </span>
                        </div>
                      )}

                      {!isMap && (
                        <>
                          {i.isShow ? (
                            <Link
                              to={"/studio/" + i.id}
                              className="w-[151px] h-[60px] border-[1px] border-[#777777] text-[#777777] hover:text-white focus:border-b-[5px] focus:border-b-brand-orange hover:border-b-[1px] hover:border-white cursor-pointer"
                            >
                              <div className="flex h-[55px] justify-center items-center ">
                                <p className="">view club</p>
                              </div>
                            </Link>
                          ) : (
                            <div className="w-[60px] h-[60px] border-[1px] border-white hover:border-b-[5px] hover:border-b-brand-orange cursor-pointer">
                              <div className="flex h-[55px] justify-center items-center ">
                                <img src={ArrowRight45} />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        {isMap && (
          <div className="flex-1">
            <Map clubSelected={clubSelected} />
          </div>
        )}
      </div>
    </>
  );
}

const StudioList = (props) => {
  const { isMap } = props;
  // const { data, loading } = useFetch({ params: "studios", populate: "" });
  return (
    <>
      <div className="mt-[42px] mx-[69px] lg:block hidden">
        <div
          className={`h-[38px] border-b-brand-orange border-b-[1.5px] text-brand-orange font-bold text-xl ${isMap ? "max-w-[650px]" : "w-full"
            }  flex justify-between`}
        >
          <div
            className={`text-brand-orange font-bold text-xl ${isMap && "hidden"
              }`}
          >
            {props.searching.length ? props.searching.join(", ") : "All"}
          </div>
          <div className="text-brand-orange font-bold text-xl">
            {props?.data?.length} {!isMap ? "Clubs" : "clubs nearby"}
          </div>
        </div>
        <div className="flex flex-col">{listOfStudio(props)}</div>
      </div>
      <div className=" w-full lg:hidden block">{listOfStudioMobile(props)}</div>
    </>
  );
};
function Distance(pos: number) {
  const distance = pos?.km;
  return <span className="graphik-regular">{distance?.toFixed(1)}</span>;
}
function listOfStudioMobile(props) {
  const { isMap } = props;
  const viewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: true }
              : { ...i, isShow: false }
        )
      );
  };
  const unViewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: false }
              : { ...i, isShow: false }
        )
      );
  };
  return (
    <div className=" w-full bg-[#292929]">
      <div className="text-white w-full flex justify-center items-center">
        <div className="w-[55px] h-1 bg-brand-orange my-[16px] rounded"></div>
      </div>
      <div className="flex justify-center items-center">
        <div className="text-brand-orange font-bold text-xl">
          {props?.data?.length} {!isMap ? "clubs" : "clubs nearby"}
        </div>
      </div>
      <div className=" h-1 bg-brand-orange  rounded mx-3 mt-[28px]"></div>
      {props.data && props.data.length ? (
        props.data.map((i: any, index: number) => {
          return (
            <div
              className={`${isMap && "hover:bg-[#1C1C1C] transition-all"
                } 'text-white  border-b-[1px] border-[rgba(255,255,255,0.4)] border-b-[rgba(255,255,255,0.4)] border-b-[1px]'+ ${i?.isShow ? "]" : ""
                }`}
              key={index}
            >
              <div className="pt-[25px] flex flex-col">
                <img
                  src={i.image}
                  className="max-w-[350px] w-full  h-[235px] mx-auto object-cover"
                />
                <div
                  className={`px-[26px] py-[20px] text-brand-orange flex flex-col gap-[14px] ${isMap ? "min-w-full" : ""
                    } `}
                >
                  <div
                    className={`flex  items-center justify-between ${isMap ? "justify-between w-full" : ""
                      }`}
                  >
                    <span className="text-brand-orange text-[18px] font-bold">
                      {i.title}
                    </span>
                    <span className="text-brand-orange font-medium text-[18px] text-end graphik-regular">
                      ${i.price}/week
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className=" text-white graphik-regular text-[18px] ">
                      <Distance km={i.distance} /> km
                    </span>
                    <img
                      src={DropDownIcon}
                      onClick={() =>
                        i.isShow ? unViewClub(index) : viewClub(index)
                      }
                    />
                  </div>
                  {i.isShow && (
                    <div className="text-white flex ">
                      <div className="w-1/2">
                        <span className="text-white max-w-[218px] text-sm font-normal graphik-regular">
                          {i.address}
                        </span>
                        <p className="text-brand-orange max-w-[218px] text-sm font-normal graphik-regular">
                          {i.phoneNumber}
                        </p>
                      </div>
                      <div className="w-1/2 grid  grid-flow-row grid-cols-3 items-center">
                        {i.access.map((i: any, index: number) => (
                          <>
                            <div className="w-[18px] h-[18px] flex justify-center items-center border-[1px] border-brand-orange rounded-full">
                              <img src={CheckedMark} />
                            </div>
                            <span className="text-white text-sm col-span-2">
                              {i}
                            </span>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
}
export default StudioList;
