import ButtonActive from "../../common/buttons/Active";
import React, { useContext, useEffect, useState } from "react";
import tick from "../../assets/images/icons/tick.svg";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import { RegisterContext } from "../../context/RegisterContext";

function PricesForBraddon() {
  const [expand, setExpand] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>();
  const [options, setOptions] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const { loading, data } = useFetch({
    params: "membership-plans",
    populate: "features",
  });
  const [price, setPrice] = useState();

  const sortData = ({ data }) => {
    let type = {};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (type[data[i].attributes.type])
          type[data[i].attributes.type].push(data[i]);
        else type[data[i].attributes.type] = [data[i]];
      }
      setPrice(type);
      setCurrentPlan(Object.keys(type)[0]);
      setOptions(Object.keys(type));
    }
  };

  useEffect(() => {
    if (data) sortData(data);
  }, [data]);
  const setOpen = useContext(RegisterContext).setOpen;
  const yourClub = useContext(RegisterContext).yourClub;

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && price && (
        <div className="mt-[20px] md:mt-[80px] px-[24px] lg:w-[983px] w-full mx-auto">
          <div className="flex items-center flex-col lg:flex-row justify-between">
            <div className="flex text-[16px] md:text-2xl">
              <h6 className="text-white font-bold">
                membership prices for
                {yourClub ? (
                  <span className="text-brand-orange mx-2 lowercase">
                    {yourClub?.attributes?.title}
                  </span>
                ) : (
                  <span className="text-brand-orange mx-1 ">
                  </span>
                )}
                <div
                  className="text-[#A4A4A4] inline graphik-regular underline text-base"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {yourClub ? 'change' : 'select'}
                </div>
              </h6>
            </div>
            <div className="text-white flex mt-[18px]  lg:mt-0 items-center lg:w-auto w-full">
              {Object.keys(price).map((item: string, index: number) => (
                <button
                  key={index}
                  onClick={() => setCurrentPlan(item)}
                  style={{
                    borderBottom:
                      currentPlan === item
                        ? "5px solid #FF6900"
                        : "1px solid white",
                    paddingTop: currentPlan === item ? "5px" : "",
                  }}
                  className={`text-white font-bold  lg:px-[35px] px-[24px] lg:w-auto w-[33.33%] h-[56px] border-t border-l bg-[black] border-r border-white`}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          {/* Plans */}
          <div className="mt-[32px] border-[0.5px] border-gray ">
            <div className="grid lg:grid-flow-col grid-flow-row gap-0">
              {data.data
                .filter((item: object) => item.attributes.type === currentPlan)
                .map((item: object, index: number) => (
                  <div className="px-[16px] lg:px-10 pt-[43px] pb-[24px] border-gray  lg:pb-[36px] border-[0.5px]">
                    <div className="flex items-center justify-start lg:justify-between">
                      <h2 className="text-[16px] lg:text-[24px] leading-[100%] font-bold text-white">
                        {item.attributes.title}
                      </h2>
                      {item.attributes.popular == true && (
                        <span className="h-[22px] w-[76px] border ml-4 lg:ml-0 border-brand-orange text-sm font-bold rounded-[100px] flex text-brand-orange justify-center items-center pb-1">
                          popular
                        </span>
                      )}
                      <div
                        onClick={() => {
                          setExpand(!expand);
                          setSelected(index);
                        }}
                        className="text-white flex lg:hidden float-right w-full justify-end"
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L8.25114 8.25114L15.5023 1"
                            stroke={
                              selected === index && expand ? "#ff6900" : "gray"
                            }
                            stroke-width="2.5"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-[18px] text-white  font-bold flex justify-between items-center">
                      <div className="flex items-center">
                        <p className="text-[25px] lg:text-[40px]">
                          ${item.attributes.price}
                        </p>
                        <span className="text-[16px] lg:text-[20px] ml-2 mt-2">
                          / week
                        </span>
                      </div>
                      <div className="block lg:hidden">
                        <a href="/register">
                          <ButtonActive
                            height={50}
                            width={"132px"}
                            text="select plan"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="w-full h-[1px] mt-6 mb-[0px] lg:mb-[22px] lg:bg-[#c6c6c6] bg-transparent"></div>
                    <div
                      className={`flex-col lg:min-h-[140px] min-h-fit ${expand && selected === index
                        ? "h-auto  opacity-100 transition-all"
                        : "transition-opacity lg:h-auto h-0 overflow-hidden lg:opacity-100 opacity-0"
                        }  flex  mb-[0px] lg:mb-[37px] `}
                    >
                      {item.attributes.features.data.map((item) => (
                        <div className="flex items-center h-[35px]">
                          <img
                            className="w-[18px]"
                            src={tick}
                            alt="tick icon"
                          />{" "}
                          <span className="ml-2 text-sm text-white w-[200px]  graphik-regular">
                            {item.attributes.title}
                          </span>
                        </div>
                      ))}
                    </div>

                    {/* Select plan */}
                    <div className="hidden lg:block">
                      <a href="/register">
                        <ButtonActive
                          height={56}
                          width={"100%"}
                          text="select plan"
                        />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PricesForBraddon;
