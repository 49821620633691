import React, { useState } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";
interface InputType {
  holder: string;
  // label: string;
  type: string;
  nameInput: string;
  onchange: any;
  // isActiveData: boolean;
  img: any;
  isRequired: boolean;
  isShowRequired: boolean;
  errorText: string;
  width: string;
  style: string;
  isDashboard: boolean
}
function InputBase({
  holder,
  // label,
  type,
  onchange,
  nameInput,
  // isActiveData = false,
  img = null,
  isRequired = false,
  isDashboard,
  isShowRequired = false,
  errorText = "",
  width = "",
  style = "",
}: InputType) {
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    return e.target.value === ""
      ? (setActive(false),
        (e.target.style.borderColor = ""),
        (e.target.style.color = ""),
        (e.target.style.caretColor = "#ffffff"))
      : (setActive(true),
        (e.target.style.borderColor = "#B3E833"),
        (e.target.style.caretColor = "#B3E833"),
        (e.target.style.color = isDashboard ? 'white' : "#B3E833"));
  };
  return (
    <div
      style={{
        width: `${width !== "" ? `${width}` : style == "" ? "373px" : ""}`,
      }}
      className={` relative ${style}`}
    >
      <label>
        <span className="after:content-[''] after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white">
          {active ? "" : ""}
        </span>
        <span
          className={`absolute  block text-[12px] gotham font-medium  transition-all duration-300 ${active ? isDashboard ? 'text-gray top-0 left-0' : "top-0 left-0 text-white" : "top-[26px] left-[1px] text-gray"
            }`}
        >
          {holder}
        </span>
        {isRequired ? (
          <div
            style={{ borderColor: active ? "" : "" }}
            className={`flex inputBorder items-center  border-b  ${active && !isDashboard ? 'border-brand-clime' : 'border-gray'}`}
          >
            <input
              onChange={onchange}
              type={type}
              onInput={(e) => {
                activeValue(e);
              }}
              name={nameInput}
              className="mt-0  w-[55%] text-white placeholder:text-white py-[7px] bg-transparent gotham  placeholder-slate-400 block sm:text-sm focus:outline-none"
              placeholder={""}
            />

            <div className="gotham absolute -z-10 right-1 w-[50%] flex items-center float-right justify-end  font-normal text-xs text-brand-orange">
              {(isShowRequired && !active) || errorText !== "" ? (
                <>
                  {errorText !== "" ? errorText : "This Field is required"}
                  <img className="w-4 ml-[12px] h-4 flex" src={warnCircle} />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <input
            onChange={onchange}
            type={type}
            onInput={(e) => {
              activeValue(e);
            }}
            name={nameInput}
            className="mt-1 px-6 placeholder:text-white py-4 bg-black border gotham border-[#FFFFFF] placeholder-slate-400  focus:outline-none  block w-full sm:text-sm focus:ring-1"
            placeholder={""}
          />
        )}

        {img}
      </label>
    </div>
  );
}

export default InputBase;
