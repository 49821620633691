import React, { useContext } from 'react'
import DashBoardLayout from '../../../components/layout/DashboardLayout'
import { RegisterContext } from '../../../context/RegisterContext'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link } from 'gatsby'
import { arrowRight } from "../../../assets/images/icons/arrowRight.tsx";
function Congrats() {

    // Context
    const upgrades = useContext(RegisterContext).upgrades

    return (
        <DashBoardLayout>
            <div className='ml-[60px] pb-[24px] '>
                <Link to='/dashboard'>
                    <div className=" flex gap-[8px] items-center mt-[34px]">
                        <div className="w-[41px] h-[48px] p-[7px] pl-0">
                            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                                <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                            </div>
                        </div>
                        <span className="gotham text-sm leading-[19px] text-white tracking-widest">
                            DASHBOARD
                        </span>
                    </div>
                </Link>
                {/* Layout */}
                <div className='flex items-start gap-[131px]'>
                    <div>
                        <p className="bebas font-normal text-[54px] text-white leading-[90%] my-[32px]">Congrats Amy</p>
                        <span className='text-[14px] leading-[160%] gotham text-white'>Your memberships includes:</span>
                        <div className='mt-[10px] flex  flex-wrap items-center gap-[16px]'>
                            {upgrades?.map((item, index) => (
                                <button key={index} style={{ backgroundColor: item?.attributes?.color }}
                                    className='px-[14px] h-[32px] leading-[215%] font-medium rounded-[4px] bg-brand-clime uppercase flex items-center justify-center text-[12.1px] gotham text-black'>
                                    {item?.attributes?.title}
                                </button>
                            ))}
                        </div>
                        <div className='mt-[66px]'>
                            <h3 className='gotham text-sm font-normal text-white leading-[140%]'>We’ve updated your account</h3>
                            <p className='gotham text-white mt-[20px] w-[533px] text-sm leading-[160%]'>You can access the new facilities commencing today. We’ve emailed a receipt to hello@banditdesigngroup.com.au, detailing your upgrade.</p>
                            <div className="mt-[35px]">
                                <div className="w-max">
                                    <div className="flex group">
                                        <button
                                            className={`border-white text-[11px] uppercase px-[15px] border-b text-white gotham text-medium  py-[11px] font-bold flex
                    border-t border-l  
                    rounded-bl-[5px] rounded-tl-[5px]  border-r group-hover:border-brand-clime group-hover:text-brand-clime`}
                                        >
                                            BOOK A CLASS
                                        </button>{" "}

                                        <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-white text-white group-hover:border-brand-clime group-hover:text-brand-clime ">
                                            {arrowRight()}
                                        </div>

                                    </div></div>
                            </div>
                        </div>
                    </div>

                    <div className='text-white flex flex-col gap-[22px]'>
                        {upgrades?.map((item, index) => (
                            <div className='w-[377px] h-[181px] relative' key={index}>
                                <img className='w-full h-full object-cover' src={item?.attributes?.image?.data?.attributes?.url} alt="background" />
                                <img className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2' src={item?.attributes?.logo?.data?.attributes?.url} alt="logo" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DashBoardLayout>
    )
}

export default Congrats