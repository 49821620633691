import studio1 from '../src/assets/images/studios/studio1.png';
import studio2 from '../src/assets/images/studios/studio2.png';
import studio3 from '../src/assets/images/studios/wall.png';
import fitnest from '../src/assets/images/memberships/fitnest.png';
import wall from '../src/assets/images/memberships/wall.png';
import wall2 from '../src/assets/images/memberships/wall2.png';
import wall3 from '../src/assets/images/memberships/wall3.png';
import coach from '../src/assets/images/memberships/coach4.png';
import coach2 from '../src/assets/images/memberships/coach5.png';
import coach3 from '../src/assets/images/memberships/coach6.png';
import class1 from '../src/assets/images/classes/class1.png';
import class2 from '../src/assets/images/classes/class2.png';
import class3 from '../src/assets/images/classes/class3.png';
import class4 from '../src/assets/images/classes/class4.png';

export const menuNavbar = ['studios', 'classes', 'book a class', 'memberships', 'the experience'];

export const menuMobile = [
  {
    name: 'studios',
    subName: [
      {
        name: 'All studios',
        path: '/studio',
      },
      {
        name: 'Closed to me',
        path: '/studio',
      },
      {
        name: 'A-Z',
        path: '/studio',
      },
    ],
  },
  {
    name: 'classes',
    subName: [
      {
        name: 'All Classes',
        path: '/class',
      },
      {
        name: 'Timetable',
        path: '/class',
      },
    ],
  },
  {
    name: 'book a class',
  },
  {
    name: 'memberships',
    subName: [
      {
        name: 'Memberships',
        path: '/memberships',
      },
      {
        name: 'Build a membership',
        path: '/register',
      },
      {
        name: 'Special Offers',
        path: '/',
      },
      {
        name: 'FAQ',
        path: '/',
      },
    ],
  },
  {
    name: 'the experience',
    subName: [
      {
        name: 'The Experience',
        path: '/about',
      },
      {
        name: 'Blog',
        path: '/blog',
      },
      {
        name: 'Own a hiit',
        path: '/own-a-hiit',
      },
      {
        name: 'Club Lime',
        path: '/',
      },
      {
        name: 'Ground Up',
        path: '/',
      },
    ],
  },
  {
    name: 'support',
    subName: [
      {
        name: 'Talk with us',
        path: '/',
      },
      {
        name: 'Help Centre',
        path: '/',
      },
    ],
  },
  {
    name: 'member id app',
    subName: [],
  },
];
export const featureStudio = {
  findStudio: [
    { text: 'all studios', link: '/studio' },
    { text: 'closest to me', link: '/studio' },
    { text: 'a-z', link: '/studio' },
  ],
  studiosNearby: [
    {
      name: 'braddon',
      distance: 0.1,
      image: studio1,
    },
    {
      name: 'fyshwick',
      distance: 0.8,
      image: studio2,
    },
    {
      name: 'anu',
      distance: 1.3,
      image: studio3,
    },
  ],
};

export const featureClasses = {
  classes: [{ text: 'all classes', link: '/class' }],
  upComingClasses: [{ text: 'book a class', link: '/book-a-class' }],
  image: fitnest,
  featured: [
    'republic amrap',
    'republic heat',
    'republic hiit',
    'republic peak',
    'republic shred',
    'republic strength',
    'republic throwdown',
    'republic yoga',
  ],
};

export const featureMemberships = {
  memberships: [
    { text: 'memberships', link: '/memberships' },
    { text: 'build a membership', link: '/register' },
    { text: 'speacial offers', link: '/studio' },
    { text: 'faq', link: '/faq' },
  ],
  hiitTheSpot: [
    {
      name: '24/7 access',
      image: wall,
    },
    {
      name: '100+ clubs',
      image: wall2,
    },
    {
      name: 'get a free pass',
      image: coach,
    },
  ],
};

export const featureTheExperience = {
  aboutUs: [
    { text: 'the experience', link: '/about' },
    { text: 'about hiit republic', link: '/about' },
  ],
  onTheBlog: [
    { text: 'blog', link: '/blog' },
    { text: 'meal plans', link: '/blog' },
    { text: 'hiit coaching at home', link: '/blog' },
    { text: 'health & wellbeing', link: '/blog' },
  ],
  featuredBlogs: [
    {
      name: 'hiit at home with sam k',
      image: coach2,
    },
    {
      name: 'why no weight loss?',
      image: wall3,
    },
    {
      name: 'how to build your quads',
      image: coach3,
    },
    {
      name: 'hiit at home with sam k',
      image: coach2,
    },
    {
      name: 'why no weight loss?',
      image: wall3,
    },
    {
      name: 'how to build your quads',
      image: coach3,
    },
  ],
};

export const intro = {
  coachOne: require('../src/assets/images/studios/studio2.png'),
  coachTwo: require('../src/assets/images/studios/studio2.png'),
  textEffectOne: require('../src/assets/images/studios/studio2.png'),
  textEffectTwo: require('../src/assets/images/studios/studio2.png'),
  title: 'more than just a workout, experience hiit our way',
  textPinned: 'experience',
  textButton: 'get started',
  description:
    'A workout like no other. HIIT Republic a boutique functional fitness class based environment with a full gym upgrade membership option, open 24/7, all at an affordable price.',
};

export const studiosNearYou = {
  count: 12,
  textButton: 'find a studio',
  studios: [
    {
      id: 1,
      image: coach2,
      title: 'fyshwick ',
      distance: 0.3,
      operatingTime: '24/7',
      classPerWeek: 60,
      desc: 'High tech equipment',
      access: ['24/7', 'swimming pool', 'health club'],
      address: 'Hiit Republic Fyshwick 84 Newcastle St, Fyshwick ACT 2609',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
    {
      id: 2,
      image: require('../src/assets/images/studios/studio2.png'),
      title: 'kingston',
      distance: 1,
      operatingTime: '24/7',
      classPerWeek: 70,
      desc: 'Creche',
      access: ['24/7', 'swimming pool', 'health club'],
      address: 'Hiit Republic Fyshwick 84 Newcastle St, Fyshwick ACT 2609',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
    {
      id: 3,
      image: require('../src/assets/images/studios/studio2.png'),
      title: 'braddon',
      distance: 3.5,
      operatingTime: '24/7',
      classPerWeek: 40,
      desc: 'Leading trainers',
      access: ['24/7', 'creche', 'sled'],
      address:
        'Hiit Republic Braddon Building 156 Gym and Pool Building Health and Wellbeing Centre, Joplin Ln, Acton ACT 2601',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
    {
      id: 4,
      image: require('../src/assets/images/studios/studio2.png'),
      title: 'fyshwick',
      distance: 0.3,
      operatingTime: '24/7',
      classPerWeek: 60,
      desc: 'High tech equipment',
      access: ['24/7', 'swimming pool', 'health club'],
      address: 'Hiit Republic Fyshwick 84 Newcastle St, Fyshwick ACT 2609',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
    {
      id: 5,
      image: require('../src/assets/images/studios/studio2.png'),
      title: 'kingston',
      distance: 1,
      operatingTime: '24/7',
      classPerWeek: 70,
      desc: 'Creche',
      access: ['24/7', 'swimming pool', 'health club'],
      address: 'Hiit Republic Fyshwick 84 Newcastle St, Fyshwick ACT 2609',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
    {
      id: 6,
      image: require('../src/assets/images/studios/studio2.png'),
      title: 'braddon',
      distance: 3.5,
      operatingTime: '24/7',
      classPerWeek: 40,
      desc: 'Leading trainers',
      access: ['24/7', 'creche', 'sled'],
      address:
        'Hiit Republic Braddon Building 156 Gym and Pool Building Health and Wellbeing Centre, Joplin Ln, Acton ACT 2601',
      price: '16.9',
      phoneNumber: '(02) 13 12 44',
    },
  ],
};

export const membershipsThatSuitYou = {
  image: require('../src/assets/images/studios/studio2.png'),
  title: 'memberships that suit you.',
  textButton: 'build a memberships',
  info: [
    {
      title: '24/7 access',
      image: require('../src/assets/images/studios/studio2.png'),
      description: 'Insert extra information about the industry-leading coaches.',
    },
    {
      title: 'Industry-leading coaches',
      image: require('../src/assets/images/studios/studio2.png'),
      description: 'Insert extra information about the industry-leading coaches.',
    },
    {
      title: 'keyleses entry',
      image: require('../src/assets/images/studios/studio2.png'),
      description: 'Insert extra information about the industry-leading coaches.',
    },
    {
      title: 'no lock in contracts',
      image: require('../src/assets/images/studios/studio2.png'),
      description: 'Insert extra information about the industry-leading coaches.',
    },
    {
      title: 'unlimited classes',
      image: require('../src/assets/images/studios/studio2.png'),
      description: 'Insert extra information about the industry-leading coaches.',
    },
  ],
};

export const ourClasses = {
  textButton: 'all classes',
  description:
    'At Hiit Republic, we give you the freedom to mix and match different fitness methods and discover the best formula for your body, lifestyle and goals.',
  classes: [
    {
      id: 0,
      title: 'republic peak',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 3,

      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',
      image: class1,
      width: 354,
      height: 295,
    },
    {
      id: 1,
      title: 'republic strength',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 2,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',
      image: class2,
      width: 292,
      height: 295,
    },
    {
      id: 2,
      title: 'republic throw down',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 1,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',

      image: class3,
      width: 342,
      height: 405,
    },
    {
      id: 3,
      title: 'republic amrap',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 3,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',

      image: class4,
      width: 235,
      height: 295,
    },
    {
      id: 4,
      title: 'republic strength',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 3,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',

      image: class2,
      width: 292,
      height: 295,
    },
    {
      id: 5,
      title: 'republic throw down',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 3,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',

      image: class3,
      width: 342,
      height: 405,
    },
    {
      id: 6,
      title: 'republic amrap',
      subTitle: 'about the class',
      rateTitle: 'sweat rating',
      rating: 3,
      description:
        'The Throw Down is the ideal class for a calori burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.',

      image: class4,
      width: 235,
      height: 295,
    },
  ],
};
