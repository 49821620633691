import React, { useEffect, useState } from "react";
import plus from "../../assets/images/memberships/plus.png";
function Selected(props: any) {
    const {
        onChecked,
        isPlus,
        style,
        isBorderBottomColor,
        isChecked,
        controlled,
    } = props;
    const [isAccepted, setIsAccepted] = useState(false);
    useEffect(() => {
        if (typeof onChecked == "function") {
            onChecked(isAccepted);
        }
    }, [isAccepted]);

    return (
        <button
            onClick={() => setIsAccepted(!isAccepted)}
            className={
                `text-white font-bold ${isAccepted ? 'bg-white border-white' : 'bg-transparent border-white'} h-[24px] w-[24px] bg-none border  rounded-md flex justify-center items-center ` +
                style
            }
        >
            {isChecked ? (
                <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 4.69231L5.2 9L13 1"
                        stroke='black'
                        strokeWidth="1.02"
                    />
                </svg>
            ) : !controlled && isAccepted ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                    <path d="M1 4.69231L5.2 9L13 1" stroke="black" strokeWidth="1.5" />
                </svg>
            ) : isPlus ? (
                <img src={plus} />
            ) : (
                ""
            )}
        </button>
    );
}

export default Selected;
