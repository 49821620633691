import React from 'react';
import ExploreOtherVivaBrands from '../components/home/ExploreOtherVivaBrands';
import GetYourFreePass from '../components/home/GetYourFreePass';
import HomeSlider from '../components/home/HomeSlider';
import Intro from '../components/home/Intro';
import Memberships from '../components/home/Memberships';
import OurClass from '../components/home/OurClass';
import StudiosNearYou from '../components/home/StudiosNearYou';
import YourCoach from '../components/home/YourCoach';
import Layout from '../components/layout/Layout';

function HomePage() {
  return (
    <Layout>
      <HomeSlider />
      <Intro />
      <StudiosNearYou />
      <Memberships />
      <div className='max-w-[1440px] mx-auto'>
        <OurClass />
      </div>
      <YourCoach />
      <GetYourFreePass />
      {/* <ExploreOtherVivaBrands /> */}
    </Layout>
  );
}

export default HomePage;
