import React, { useState } from 'react'
interface IProps {
    initial: boolean
}
function Switch(props: IProps) {
    const { initial } = props
    const [mode, setMode] = useState(initial)
    return (
        <div className='flex items-center'>
            <button
                onClick={() => setMode('on')}
                className={`w-[51px] h-[40px] border-white rounded-l gotham font-medium text-[11.2px] uppercase border 
                ${mode == 'on' ? 'bg-white text-black' : 'bg-black text-white'}   
                flex items-center justify-center`}
            >
                on
            </button>
            <button
                onClick={() => setMode('off')}
                className={`w-[51px] h-[40px] border-white rounded-r  gotham font-medium text-[11.2px] uppercase border 
                ${mode == 'off' ? 'bg-white text-black' : 'bg-black  text-white'}   
                flex items-center justify-center`}
            >
                off
            </button>


        </div>
    )
}

export default Switch