import React, { useContext } from 'react'
import DashBoardLayout from '../../../components/layout/DashboardLayout'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link } from 'gatsby'
import InputBase from '../../../common/inputs/InputBaseDashBoard';
function Password() {


    return (
        <DashBoardLayout>
            <div className=' pb-[24px] ml-6 mr-6 xl:ml-[60px]'>
                <Link to='/dashboard'>
                    <div className=" flex gap-[8px] items-center mt-[34px]">
                        <div className="w-[41px] h-[48px] p-[7px] pl-0">
                            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                                <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                            </div>
                        </div>
                        <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                            dashboard
                        </span>
                    </div>
                </Link>
                {/* Layout */}
                <p className="bebas font-normal text-[55px] text-white leading-[90%] my-[32px]">
                    PASSWORD
                </p>

                <div className='mt-[39px] lg:w-[453px] lg:h-[438px] border border-gray rounded-[4px] bg-[#0F0F0F] pb-6'>

                    <div className='h-[57px] flex items-center pl-[27px] uppercase text-white tracking-widest border-b border-b-gray'>reset password</div>
                    <div className='mt-[37px] px-[27px]'>
                        <div>
                            <InputBase isDashboard={true} holder="Existing password" style="lg:w-full" isRequired={true} />
                            <span className='text-gray font-normal gotham text-[12px] mt-1 text-end w-full float-right'>Forgot your email/password?</span>
                        </div>
                        <div className='mt-[50px] gap-[25pxx]'>
                            <InputBase isDashboard={true} holder="New password" style="lg:w-full" isRequired={true} />
                            <div className='mt-[21px]'>
                                <InputBase isDashboard={true} holder="Confirm new password" style="lg:w-full" isRequired={true} />
                            </div>
                            <button className='w-[146px] h-[37px] border-b-gray border text-gray hover:border-white text-[12px] gotham font-normal hover:text-white rounded mt-[32px]'>SAVE</button>
                        </div>

                    </div>

                </div>
            </div>
        </DashBoardLayout>
    )
}

export default Password