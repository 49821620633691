import React from "react";
import Logo from "../assets/images/viva/logo.png";

function DashboardFooter() {
    return (
        <div className="text-white h-[51px] pl-6 py-[16px] flex justify-between items-center border-t border-[rgba(255,255,255,0.4)]">
            <p className="text-[rgba(255,255,255,0.6)]">Part of Viva Leisure Group</p>
            <img className="w-[56px] h-[26px] object-contain mr-[34px]" src={Logo} />
        </div>
    );
}

export default DashboardFooter;
