import ButtonActive from "../../common/buttons/Active";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import { RegisterContext } from "../../context/RegisterContext";
import ButtonSelected from "../../common/buttons/ButtonSelected";
import { Link } from "gatsby";

function MembershipPlan(props) {
  const { isLabel, color, size, plan, setPlan, isDashboard, setStep } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>();
  const [currentPlan, setCurrentPlan] = useState();
  const { loading, data } = useFetch({
    params: "membership-plans",
    populate: "features",
  });
  const [price, setPrice] = useState();

  const sortData = ({ data }) => {
    let type = {};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (type[data[i].attributes.type])
          type[data[i].attributes.type].push(data[i]);
        else type[data[i].attributes.type] = [data[i]];
      }
      setPrice(type);
      setCurrentPlan(Object.keys(type)[0]);
      // setOptions(Object.keys(type));
    }
  };

  useEffect(() => {
    if (data) sortData(data);
  }, [data]);
  const setOpen = useContext(RegisterContext).setOpen;
  const [isChecked, setChecked] = useState(false);

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && price && (
        <div className={`lg:w-[945px] w-full relative`}>
          {isLabel !== false && (
            <p className="uppercase memberText absolute  text-effect text-transparent text-[65px] bebas top-1/2 -translate-y-1/2 -left-[98px] -rotate-90">
              BUILD a membership
            </p>
          )}
          <div className="mt-[40px] lg:mt-[80px] overflow-hidden lg:px-[24px] px-0 lg:w-[983px]  w-full mx-auto">
            <div className="flex items-center flex-col lg:flex-row justify-between">
              <div className="flex text-[16px] md:text-2xl">
                <p className="text-white text-[16px] font-medium uppercase tracking-widest">
                  membership prices for
                  <span className="text-brand-clime tracking-widest text-[16px] font-medium uppercase mx-2">
                    braddon
                  </span>
                  <span
                    className="text-[#A4A4A4] gotham lowercase text-base"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Change
                  </span>
                </p>
              </div>
              <div className="text-white flex mt-[18px] lg:w-auto w-full lg:mt-0 items-center">
                {Object.keys(price).map((item: string, index: number) => (
                  <button
                    key={index}
                    onClick={() => setCurrentPlan(item)}
                    className={`text-white font-bold  text-[11px] uppercase lg:w-[113px] w-[33.33%] border-[0.8px] h-[40px] 
                    ${index === 0 ? "rounded-tl-[4px] rounded-bl-[4px]" : ""}
                    ${index === 1 ? "" : ""}
                    ${index === 2 ? "rounded-tr-[4px] rounded-br-[4px]" : ""}

                     ${
                       currentPlan == item
                         ? isDashboard
                           ? "bg-white border-white text-black"
                           : "bg-brand-clime text-black border-brand-clime"
                         : "bg-[black] border-white"
                     }`}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
            {/* Plans */}
            <div className={`mt-[32px]  bg-[${color}] rounded-[5px] `}>
              <div className="grid lg:grid-flow-col grid-flow-row gap-0">
                {data.data
                  .filter(
                    (item: object) => item.attributes.type === currentPlan
                  )
                  .map((item: object, index: number) => (
                    <div
                      key={index}
                      className={`px-[16px] lg:px-10 lg:pt-[43px] pt-[18px] pb-[21px] border-gray lg:pb-[36px] border-[0.5px] ${
                        index == 0
                          ? "md:rounded-tl-[5px] md:rounded-tr-[5px]"
                          : ""
                      } ${
                        index == 2
                          ? "md:rounded-bl-[5px] md:rounded-br-[5px]"
                          : ""
                      }`}
                    >
                      <div className="flex items-center justify-between lg:justify-between">
                        <div className="flex items-center lg:justify-between justify-start w-full">
                          <h2 className="text-[16px] leading-[140%] uppercase gotham font-medium tracking-widest text-white mr-4 lg:mr-0">
                            {item.attributes.title}
                          </h2>
                          {item.attributes.popular == true && (
                            <span
                              className={`h-[22px] w-[54px] md:w-[76px] gotham border  border-brand-clime leading-[0.1em] font-medium  ${
                                isDashboard ? " bg-white" : "bg-brand-clime"
                              } text-[7.1px] md:text-[10px] rounded-[100px] uppercase flex text-black justify-center items-center`}
                            >
                              popular
                            </span>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setExpand(!expand);
                            setSelected(index);
                          }}
                          className={`text-white flex lg:hidden ${
                            selected == index && expand
                              ? "rotate-180 transition-all"
                              : "rotate-0 transition-transform"
                          } float-right  justify-end`}
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L8.25114 8.25114L15.5023 1"
                              stroke={
                                selected === index && expand
                                  ? "#B3E833"
                                  : "gray"
                              }
                              strokeWidth="2.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="mt-[18px] text-white font-bold flex justify-between items-center">
                        <div className="flex items-center">
                          <p className="text-[58px] leading-[90%] bebas font-normal lg:text-[40px]">
                            ${item.attributes.price}
                          </p>
                          <span className="text-[16px] lg:text-[20px] ml-2 mt-2">
                            / week
                          </span>
                        </div>
                        <div className="block lg:hidden">
                          <div
                            className="w-[100px]"
                            onClick={() => {
                              setStep(4);
                              setPlan(item);
                            }}
                          >
                            <button
                              className={`w-full ${
                                plan?.id == item?.id
                                  ? "bg-brand-clime  text-black hover:text-black border-brand-clime"
                                  : "bg-transparent hover:text-brand-clime border-white text-white"
                              } h-[40px] rounded-[4px] border-[0.8px] uppercase flex items-center justify-center font-medium transition-colors text-[11.2px] gotham hover:border-brand-clime`}
                            >
                              SELECT
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`w-full h-[1px] mt-6  lg:mb-[22px] bg-zinc-700 mb-[25px] ${
                          expand && selected == index ? "" : "lg:block hidden"
                        }`}
                      ></div>
                      <div
                        className={`flex-col ${
                          expand && selected === index
                            ? "h-auto opacity-100 transition-all"
                            : "transition-opacity lg:h-auto h-0 overflow-hidden lg:opacity-100 opacity-0"
                        }  flex gap-[14px] mb-[0px] lg:mb-[37px] `}
                      >
                        {item.attributes.features.data.map((item, index) => (
                          <div
                            className="flex items-center h-[35px] "
                            key={index}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="10.3918"
                                cy="10.6437"
                                r="9.39175"
                                stroke={`${isDashboard ? "white" : "#B3E833"}`}
                              />
                              <path
                                d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"
                                stroke={`${isDashboard ? "white" : "#B3E833"}`}
                              />
                            </svg>

                            <span className="ml-2 text-sm text-white  gotham">
                              {item.attributes.title}
                            </span>
                          </div>
                        ))}
                        <span className="mt-[10px] lg:hidden block gotham font-normal text-sm leading-[160%] text-gray">
                          *Subject to terms and conditions
                        </span>
                      </div>

                      {/* Select plan */}
                      <div className="hidden lg:block">
                        {isDashboard ? (
                          <button
                            onClick={() => {
                              setStep(4);
                              setPlan(item);
                            }}
                            className={`w-full bg-brand-clime text-white hover:text-black  bg-transparent hover:bg-white   border-white text-white'} h-[40px] rounded-[4px] border-[0.8px] uppercase flex items-center justify-center font-medium transition-colors text-[11.2px] gotham`}
                          >
                            CHOOSE PLAN
                          </button>
                        ) : (
                          <>
                            {window.location.pathname !== "/register" ? (
                              <Link to="/register">
                                <ButtonSelected text={"SELECT"} />
                              </Link>
                            ) : (
                              <div
                                onClick={() => {
                                  setStep(4);
                                  setPlan(item);
                                }}
                              >
                                <button
                                  className={`w-full ${
                                    plan?.id == item?.id
                                      ? "bg-brand-clime text-black hover:text-black border-brand-clime"
                                      : "bg-transparent hover:text-brand-clime border-white text-white"
                                  } h-[40px] rounded-[4px] border-[0.8px] uppercase flex items-center justify-center font-medium transition-colors text-[11.2px] gotham hover:border-brand-clime`}
                                >
                                  SELECT
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="lg:flex-row flex-col flex justify-between lg:items-center items-start lg:mb-0 mb-[50px]">
              <div className="flex items-center gap-[15px]">
                <div
                  className={`w-[30px] my-[40px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                    isChecked ? "border-brand-clime" : "border-white"
                  }`}
                  onClick={() => setChecked(!isChecked)}
                >
                  {isChecked && (
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 4.69231L5.2 9L13 1"
                        stroke={"#B3E833"}
                        strokeWidth="1.02"
                      />
                    </svg>
                  )}
                </div>
                <p className="text-white gotham lg:block hidden">
                  See student rates*
                </p>
                <p className="gotham w-[266px] font-normal text-white text-sm lg:hidden block leading-[104%]">
                  See student rates <br /> (For persons under 17 only).{" "}
                </p>
              </div>
              <p className="text-[rgba(255,255,255,0.4)] lg:block hidden lg:mt-0 mt-[-20px] gotham">
                *Subject to terms and conditions
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MembershipPlan;
