import React, { useEffect, useState, useContext } from "react";
import { payment, yourMemberships } from "../../../mock/memberships";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import InputAnimated from "../../common/inputs/InputAnimated";
import { RegisterContext } from "../../context/RegisterContext";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

function Payment({ sliderRef }) {
  const [code, setCode] = useState("");
  const [tooltip, setTooltip] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [typeOfPayment, setTypeOfPayment] = useState("card");

  const handleMoveSlide = (index: number) => {
    sliderRef.current.slickGoTo(index);
  };

  // Context
  const yourClub = useContext(RegisterContext).yourClub;
  const upgrades = useContext(RegisterContext).upgrades;
  const addOns = useContext(RegisterContext).addOns;
  const membershipPlan = useContext(RegisterContext).membershipPlan;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;

  // Total Price
  let total = 0;
  upgrades.forEach((e) => {
    total += e?.attributes?.price?.[1]?.value;
  });

  return (
    <>
      <div className="h-auto mt-[50px] flex items-center">
        <div className="w-full flex lg:flex-row flex-col px-6 lg:pr-0 lg:pl-[102px] items-start justify-between">
          <div className="w-full">
            {/* Upfront payment */}
            <h2 className="text-brand-orange relative text-[20px] font-bold  border-b border-b-brand-orange pb-2">
              {payment.upfrontText}
              <span className="absolute top-0 graphik-regular right-0 text-brand-orange font-normal text-[14px]">
                Total due today{" "}
                <span className="text-brand-orange font-bold ml-1">$44.00</span>
              </span>
            </h2>
            <div className="mt-[35px] text-white lg:w-[796px]">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 text-white gap-x-[32px]">
                instagram embed{" "}
                <div>
                  <InputAnimated label="Card number*" type="text" />
                </div>
                <div>
                  <InputAnimated label="Name on card*" type="text" />
                </div>
                <div>
                  <InputAnimated label="Expiry date*" type="date" />
                </div>
                <div>
                  <InputAnimated label="CVV*" type="text" />
                </div>
              </div>
            </div>
            {/* Ongoing payment details */}
            <div className="mt-[53px]">
              <h2 className="text-brand-orange text-[20px] relative  font-bold  border-b border-b-brand-orange pb-2 ">
                {payment.ongoingText}
                <span className="absolute left-0 top-[calc(100%+14px)] lg:top-0 graphik-regular lg:right-0 text-brand-orange font-normal text-[14px]">
                  Direct debit (fornightly){" "}
                  <span className="text-brand-orange font-bold ml-1">
                    $52.00
                  </span>
                </span>
              </h2>
              <div className="mt-10 lg:mt-4">
                <h6 className="graphik-regular font-normal text-sm text-gray">
                  {payment.selectPaymentText}
                </h6>
                <div className="mt-[17px] lg:flex-row lg:items-center lg:gap-0 gap-6 items-start flex-col flex justify-between">
                  <div className="flex items-center">
                    <button
                      onClick={() => setTypeOfPayment("card")}
                      className={`text-white h-[48px] w-[160px] font-bold hover:border-white hover:text-white ${
                        typeOfPayment !== "card"
                          ? "border-b border-[#777777] text-[#777777]"
                          : "border-b-brand-orange pt-[3px] border-b-[4px]"
                      } border-t border-l bg-[black] border-r border-white`}
                    >
                      card
                    </button>
                    <button
                      onClick={() => setTypeOfPayment("bank")}
                      className={`text-white h-[48px] w-[160px] font-bold hover:border-white hover:text-white ${
                        typeOfPayment !== "bank"
                          ? "border-b border-[#777777] text-[#777777]"
                          : "border-b-brand-orange pt-[3px] border-b-[4px]"
                      } border-t border-l bg-[black] border-r border-white`}
                    >
                      bank account
                    </button>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => setIsAccepted(!isAccepted)}
                      style={
                        isAccepted
                          ? { borderBottom: "3.5px solid #FF6900" }
                          : { borderBottom: "1px solid white" }
                      }
                      className={`text-white font-bold h-[40px] w-[40px] border-t border-l bg-[black] border-r border-white flex justify-center items-center`}
                    >
                      {isAccepted ? (
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4.69231L5.2 9L13 1"
                            stroke="white"
                            stroke-width="1.02"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                    </button>
                    <span className="font-normal graphik-regular text-sm ml-[14px] text-white">
                      {payment.ongoingAcceptText}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Bank info */}
            {typeOfPayment === "bank" ? (
              <div className="mt-[38px]">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white gap-x-[32px]">
                  <div>
                    <InputAnimated
                      label="Name of account holder*"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputAnimated label="Bank*" type="text" />
                  </div>
                  <div>
                    <InputAnimated label="BSB*" type="text" />
                  </div>
                  <div>
                    <InputAnimated label="Account number*" type="text" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-[38px]">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white gap-x-[32px]">
                  <div>
                    <InputAnimated label="Card number*" type="text" />
                  </div>
                  <div>
                    <InputAnimated label="Name on card*" type="text" />
                  </div>
                  <div>
                    <InputAnimated label="Expiry date*" type="date" />
                  </div>
                  <div>
                    <InputAnimated label="CVV*" type="text" />
                  </div>
                </div>
              </div>
            )}

            {/* Prev + Next Page */}
            <div className="items-center w-full justify-between mt-[83px] mb-[90px] lg:flex hidden">
              <button
                onClick={() => sliderRef.current.slickPrev()}
                className={
                  "h-[56px] w-[55px] flex items-center justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white "
                }
              >
                <div className="rotate-[180deg] text-center ">
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767"
                      stroke="white"
                      stroke-width="1.3261"
                    />
                    <path
                      d="M15.9743 11.1309H0.998047"
                      stroke="white"
                      stroke-width="1.3261"
                    />
                  </svg>
                </div>
              </button>

              <ButtonNavigate
                onClick={() => sliderRef.current.slickNext()}
                text="pay now"
                style="h-[56px] w-[160px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                isArrowRight={true}
              ></ButtonNavigate>
            </div>
          </div>
          {/* Your membership */}
          <div className="w-[339px] mt-6 lg:mt-0 ">
            <h2 className="text-brand-orange text-[20px] font-bold lowercase border-b border-b-brand-orange pb-2">
              {yourMemberships.title}
            </h2>
            <div className="w-full justify-between flex items-center h-4 mt-4 pr-[40px]">
              <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
                {yourMemberships.homeClubText}
              </h2>
              <span
                onClick={() => setIsChangeClub(true)}
                className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer "
              >
                Edit
              </span>
            </div>
            <div className="font-bold text-sm mt-3 leading-[100%] lowercase text-white pb-4 border-b border-b-gray">
              {yourClub?.attributes?.title}
            </div>
            <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
              <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
                {yourMemberships.membershipPlanText}
              </h2>
              <span
                onClick={() => handleMoveSlide(1)}
                className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer"
              >
                Edit
              </span>
            </div>
            {/* List selected */}
            <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                  {membershipPlan?.attributes?.title}
                </h2>
                <span className="text-white font-bold lowercase">
                  ${parseFloat(membershipPlan?.attributes?.price).toFixed(2)}
                </span>
              </div>
              {membershipPlan?.attributes?.features?.data?.map(
                (item, index) => (
                  <div
                    key={index}
                    className="flex items-center mt-[11px] justify-between"
                  >
                    <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                      {item?.attributes?.title}
                    </h2>
                  </div>
                )
              )}
            </div>
            <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
              <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
                Upgrades/add-ons
              </h2>
              <span
                onClick={() => handleMoveSlide(2)}
                className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer"
              >
                Edit
              </span>
            </div>
            {/* List selected */}
            <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
              {upgrades?.map((item: object, index: number) => (
                <div key={index} className="flex items-center justify-between">
                  <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                    {item?.attributes?.title}
                  </h2>
                  <span className="text-white font-bold lowercase">
                    ${parseFloat(item?.attributes?.price?.[0]?.value).toFixed(2)}
                  </span>
                </div>
              ))}
              {addOns?.map((item: object, index: number) => (
                <div key={index} className="flex items-center justify-between">
                  <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                    {item?.attributes?.title}
                  </h2>
                  <span className="text-white font-bold lowercase">
                    ${parseFloat(item?.attributes?.price?.[0]?.value).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>

            {/* Promo code */}
            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
              <h5 className="font-normal text-sm text-white graphik-regular">
                {yourMemberships.promoCodeText}
              </h5>
              <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                <span className="w-[20px] text-sm text-brand-orange">hr-</span>
                <input
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                  className="w-full h-full ml-1 border-0 text-brand-orange outline-0 bg-transparent"
                />
              </div>
            </div>
            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
              <h5 className="font-normal text-sm text-white graphik-regular">
                {yourMemberships.dateText}
              </h5>
              <div className="rounded flex items-center py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                <span className="datepicker-toggle">
                  <Flatpickr
                    placeHolder="YYY/MM/DDD"
                    options={{ enableTime: false, time_24hr: false }}
                  />
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_711_10427)">
                    <path
                      d="M2.86372 3.79346H2.86632C3.2918 3.79346 3.63672 3.43333 3.63672 2.9891L3.63672 0.801606C3.63672 0.357377 3.2918 -0.00273919 2.86632 -0.00273919H2.86372C2.43823 -0.00273919 2.09331 0.357377 2.09331 0.801606L2.09331 2.9891C2.09331 3.43333 2.43823 3.79346 2.86372 3.79346Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M11.2582 3.79346H11.2608C11.6863 3.79346 12.0312 3.43333 12.0312 2.9891V0.801606C12.0312 0.357377 11.6863 -0.00273919 11.2608 -0.00273919H11.2582C10.8328 -0.00273919 10.4878 0.357377 10.4878 0.801606V2.9891C10.4878 3.43333 10.8328 3.79346 11.2582 3.79346Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M1.06321 14.0002H12.942C13.2232 13.9995 13.4926 13.8823 13.6912 13.6745C13.8898 13.4666 14.0013 13.1851 14.0013 12.8915V2.63604C14.0013 2.34247 13.8898 2.06088 13.6912 1.85304C13.4926 1.64519 13.2232 1.52806 12.942 1.52734H1.06321C0.782029 1.52806 0.512595 1.64519 0.314011 1.85304C0.115427 2.06088 0.00390541 2.34247 0.00390625 2.63604V12.8915C0.00390541 13.1851 0.115427 13.4666 0.314011 13.6745C0.512595 13.8823 0.782029 13.9995 1.06321 14.0002ZM1.20896 5.03279H12.7962V12.511H1.20376L1.20896 5.03279Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_10427">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
              <h5 className="font-normal graphik-regular text-sm text-white ">
                {yourMemberships.costPerForNightText}
              </h5>
              <div className="text-brand-orange text-sm font-bold leading-[100%]">
                ${parseFloat(total + membershipPlan?.attributes?.price).toFixed(2)}
              </div>
            </div>
            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
              <div className="flex items-center relative justify-center">
                <h5 className="font-normal graphik-regular text-sm text-white ">
                  {yourMemberships.totalText}
                </h5>
                <span
                  onMouseLeave={() => setTooltip(false)}
                  onMouseEnter={() => setTooltip(true)}
                  className="ml-2 w-[21px] h-[21px] rounded-[50%] graphik-regular flex items-center cursor-pointer justify-center text-white text-[11px] border border-white"
                >
                  ?
                </span>
                {/* Tooltip */}
                {tooltip && (
                  <div
                    onMouseEnter={() => setTooltip(true)}
                    onMouseLeave={() => setTooltip(false)}
                    className="absolute text-[12px] border border-white font-normal right-[22px] top-0 bg-black w-[221px] py-[10px] px-3 text-white graphik-regular"
                  >
                    {yourMemberships.description}
                  </div>
                )}
              </div>
              <div className="text-brand-orange text-sm font-bold leading-[100%]">
                $44.80
              </div>
            </div>
            <div className="underline pr-[40px] graphik-regular mt-[13px] float-right font-normal text-[12px] text-gray">
              {yourMemberships.contractText}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full justify-between mt-[83px] mb-[90px] px-6">
        <button
          onClick={() => sliderRef.current.slickPrev()}
          className={
            "h-[56px] w-[55px] flex items-center justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white "
          }
        >
          <div className="rotate-[180deg] text-center ">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767"
                stroke="white"
                stroke-width="1.3261"
              />
              <path
                d="M15.9743 11.1309H0.998047"
                stroke="white"
                stroke-width="1.3261"
              />
            </svg>
          </div>
        </button>

        <ButtonNavigate
          onClick={() => sliderRef.current.slickNext()}
          text="pay now"
          style="h-[56px] w-[160px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
          isArrowRight={true}
        ></ButtonNavigate>
      </div>
    </>
  );
}

export default Payment;
