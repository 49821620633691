import React, { useContext, useEffect, useState } from "react";
import dotFull from "../../assets/images/icons/circleFull.png";
import { RegisterContext } from "../../context/RegisterContext";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { Script } from "gatsby";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";

const ChangeLocation = () => {
  const open = useContext(RegisterContext).open;
  const setOpen = useContext(RegisterContext).setOpen;
  const [address, setAddress] = useState("");
  const [isGetAll, setIsGetAll] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [clubNearby, setClubNearby] = useState<[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const yourClub = useContext(RegisterContext).yourClub;
  const setYourClub = useContext(RegisterContext).setYourClub;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;
  const [quickSeach, setQuickSearch] = useState<boolean>(false);

  const handleSelect = async (value: string) => {
    if (value != "") {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setStep(2);
      setCoordinates(latLng);
    }
  };
  useEffect(() => {
    address.length > 0 ? setQuickSearch(false) : setQuickSearch(true);
  }, [address]);
  useEffect(() => {
    open
      ? document.body.classList.add("hide-scroll")
      : document.body.classList.remove("hide-scroll");
  }, [open]);

  // useEffect(() => {
  //   if (coordinates.lat !== null) {
  //     const url = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${coordinates.lng}&location[]=${coordinates.lat}&distance=12089566&populate=image,access,address.state,address.country&pagination`;
  //     axios.get(url).then((res) => {
  //       setClubNearby(res?.data?.data);
  //     });
  //   }
  // }, [coordinates]);
  const [currentState, setCurrentState] = useState(false);
  useEffect(() => {
    if (coordinates.lat !== null || quickSeach) {
      let searchByState = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${currentState?.lng}&location[]=${currentState?.lng}&distance=12089566&populate=image,access,address.state&pagination`;
      let searchByName = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${coordinates.lng}&location[]=${coordinates.lat}&distance=12089566&populate=image,access,address.state&pagination`;
      axios.get(quickSeach ? searchByState : searchByName).then((res) => {
        setClubNearby(res?.data?.data);
      });
    }
  }, [coordinates, currentState]);
  const handleClose = () => {
    setIsChangeClub(false);
    setClubNearby([]);
  };

  // const [searching, setSearching] = useState(false);

  // useEffect(() => {
  //   setSearching(true);
  // }, [address]);

  const setClub = (i) => {
    setYourClub(i);
    setIsChangeClub(false);
  };
  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
        onLoad={() => setLoaded(true)}
      />
      <div className="md:px-[44px] px-3 z-[999999] sticky top-0 py-2 bg-[#1C1C1C] border-y border-brand-orange graphik-regular md:text-sm text-[12px] text-[rgba(255,255,255,0.6)] flex justify-between">
        <div className="w-[1440px] flex mx-auto items-center justify-between">
          <span className="graphik-regular text-sm hidden md:block">
            Part of Viva Leisure Group
          </span>
          <div className="flex items-center gap-[10px] justify-between w-full md:w-auto">
            <div className="flex items-center">
              <img src={dotFull} />
              {yourClub ? (
                <p className="text-base ml-[7px] font-medium text-white graphik-regular lowercase">
                  {yourClub?.attributes?.title},{" "}
                  {yourClub?.attributes?.address?.Name
                    ? yourClub?.attributes?.address?.Name
                    : yourClub?.attributes?.address?.data?.attributes?.Name}

                </p>
              ) : (
                <p className="text-base ml-[7px] font-medium text-white graphik-regular ">
                  Select your location
                </p>
              )}
            </div>
            <span
              className="text-gray underline  graphik-regular text-base cursor-pointer"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? "Done" : "Change"}
            </span>
          </div>
        </div>
      </div>
      {open && (
        <div className="absolute  right-0 top-0 h-full w-[0px]">
          <div className=" z-[999999] mt-[40px] overflow-y-auto sticky border border-brand-orange top-[40px] cursor-pointer h-[100vh] max-h-[761px] w-[361px] transform  translate-x-[-100%] bg-[#1c1c1c]">
            <div className="flex items-center pt-[48px] pb-[65px] pr-[29px] pl-[44px] h-[223px]">
              <p className="text-white text-[52.31px] h-[108px] w-[288px]  tracking-[-0.03em] leading-[57.54px]">
                select your <span className="text-brand-orange">location</span>
              </p>
            </div>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="flex flex-col changeLocation w-full">
                  <div>
                    <div className="flex border-b px-[13px] border-x-[0px] border-y-brand-orange border-[1px] border-gray min-w-full items-center relative md:justify-between">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 25 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <line
                          x1="15.8912"
                          y1="14.8002"
                          x2="24.1415"
                          y2="22.2113"
                          stroke={"#FF6900"}
                          stroke-width="1.57426"
                        />
                        <circle
                          cx="10.0022"
                          cy="10.3639"
                          r="7.15275"
                          transform="rotate(-72.0294 10.0022 10.3639)"
                          stroke={"#FF6900"}
                          stroke-width="1.57426"
                        />
                      </svg>
                      <input
                        className="bg-transparent placeholder:text-white placeholder:tracking-[0.071em] py-[13px] pl-[14px] graphik-regular font-normal w-[80%] text-sm leading-[160%] text-white outline-none"
                        {...getInputProps({
                          placeholder: "Search by City, State, Postcode",
                        })}
                      />
                      <div
                        onClick={handleClose}
                        className="cursor-pointer md:block hidden "
                      >
                        <svg
                          onClick={() => setAddress("")}
                          width="41"
                          height="41"
                          viewBox="0 0 41 41"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.7275 15.1323L15.3977 24.4622"
                            stroke="#FFFFFF"
                            stroke-width="1.32496"
                          />
                          <path
                            d="M24.8311 24.5654L15.5598 15.2942"
                            stroke="#FFFFFF"
                            stroke-width="1.32496"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    className=" h-[197px] max-h-[328px] overflow-y-auto"
                    id="changeLocationScroll"
                  >
                    {suggestions.map((suggestion) => {
                      return (
                        <div
                          className={`${suggestion.active
                            ? "bg-brand-orange text-black"
                            : ""
                            } border border-brand-orange min-h-[46px] text-white py-[12px] px-[15px] text-sm font-normal leading-[160%] graphik-regular `}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                    {clubNearby.map((i) => {
                      return (
                        <div
                          onClick={() => {
                            setClub(i);
                            setOpen(false);
                            localStorage.setItem("yourClub", JSON.stringify(i));
                          }}
                          className="flex gap-4 mb-2"
                        >
                          <img
                            src={i?.attributes?.image?.url}
                            className="w-[150px] h-[150px] object-cover border border-brand-orange"
                          />
                          <div className="py-2 flex flex-col justify-between">
                            <p className="text-brand-orange  ">
                              {i?.attributes?.title}
                            </p>
                            <p className="text-white graphik-regular">
                              {i?.attributes?.fullAddress}
                            </p>
                            {/* <p className="text-white graphik-regular my-1">{i?.attributes?.email}</p> */}
                            <p className="text-white graphik-regular ">
                              ${i?.attributes?.price}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <div className="h-[244px]  border-y-[1px] border-brand-orange px-[43px] py-[15px] md:py-[38px]">
              <div className="h-full w-full">
                <p className="mb-[13px] text-[20px] text-white">
                  quick select your{" "}
                  <span className="text-[20px] text-brand-orange">state</span>
                </p>
                <div className="h-[120px] grid grid-rows-2 grid-flow-col gap-[23px]">
                  <ButtonNavigate
                    onClick={() =>
                      setCurrentState({ lat: -35.27075, lng: 149.134637 })
                    }
                    style="w-[126.84px] text-[#777777] h-[49px] border-[1px] border-b border-[#777777] "
                    text="act"
                  ></ButtonNavigate>
                  <ButtonNavigate
                    onClick={() =>
                      setCurrentState({ lat: -22.575197, lng: 144.084793 })
                    }
                    style="w-[126.84px] text-[#777777] h-[49px] border-[1px] border-b border-[#777777] "
                    text="qld"
                  ></ButtonNavigate>
                  <ButtonNavigate
                    onClick={() =>
                      setCurrentState({ lat: -31.253218, lng: 146.921099 })
                    }
                    style="w-[126.84px] text-[#777777] h-[49px] border-[1px] border-b border-[#777777] "
                    text="nsw"
                  ></ButtonNavigate>
                  <ButtonNavigate
                    onClick={() =>
                      setCurrentState({ lat: -36.984781, lng: 143.390607 })
                    }
                    style="w-[126.84px] text-[#777777] h-[49px] border-[1px] border-b border-[#777777] "
                    text="vic"
                  ></ButtonNavigate>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ChangeLocation;
