import React, { useState } from "react";
import arrowLeftShort from "../../assets/images/icons/arrowLeftShort.svg";
import TableBase from "../../common/tables/TableBase";
import inviteFriendIcon from "../../assets/images/dashboard/inviteFriendIcon.png";
import woman from "../../assets/images/classes/allClassPic3.jpg";
import ModalBase from "../../common/modals/ModalBase";
import BookClassModal from "./modals/BookClassModal.tsx";
import ButtonNavigate from "../../common/buttons/ButtonNavigateDashBoard";

function BookingDetail() {
  const dataHead = [
    "date",
    "time",
    "class",
    "coach",
    "brand",
    "place",
    {
      label: "action",
      elm: (i) => (
        <div>
          <div className="flex gap-[10px]">
            <button
              className=" flex gap-2 items-center uppercase text-white h-[37px] justify-center  font-medium w-[37px]  md:w-[105px] border rounded-[5px] border-white"
              onClick={() => {
                setModalInvite(true);
                setCurrentClass(i);
              }}
            >
              <img src={inviteFriendIcon} className="w-[25px] h-[25px]" />{" "}
              <span className=" uppercase text-white text-sm gotham md:block hidden">
                invite
              </span>
            </button>
            <div
              className="flex justify-center items-center w-[37px] h-[37px] font-semibold border border-white rounded-[5px]"
              onClick={() => {
                setModalCancel(true);
                setCurrentClass(i);
              }}
            >
              <svg
                className="rotate-45"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 0.0027771L8.90027 18.0021" stroke="white" />
                <line y1="8.5" x2="18" y2="8.5" stroke="white" />
              </svg>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const color = {
    "club lime": "#B3E833",
    "hiit republic": "#FF6900",
    "ground up": "#A4ACD7",
  };
  const dataHeadClassBuddies = [
    "date",
    "time",
    "class",
    "buddies",
    "brand",
    "place",
    {
      label: "action",
      elm: (v) => {
        return (
          <button
            style={{
              borderColor: v.status !== "cancel" ? color[v.brand] : "white",
              color: v.status !== "cancel" ? color[v.brand] : "white",
            }}
            className={`text-white uppercase h-[37px] justify-center gotham font-medium text-sm  w-[105px] border rounded-[5px]  border-[]}`}
            onClick={() => {
              setModalBookClass(true);
              setCurrentClass(v);
            }}
          >
            {v.status}
          </button>
        );
      },
    },
  ];
  const dataBody = [
    {
      date: "Today",
      time: "07:30-08:15",
      class: "Barre",
      coach: "sarah k ",
      brand: "club lime",
      place: "braddon",
      action: "book me",
      instructor: "Laura B",
    },
    {
      date: "22/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      coach: "sarah k ",
      brand: "hiit republic",
      place: "braddon",
      instructor: "Laura B",
      action: "book me",
    },
    {
      date: "23/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      coach: "sarah k ",
      brand: "club lime",
      place: "braddon",
      action: "book me",
      instructor: "Laura B",
    },
    {
      date: "24/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      coach: "sarah k ",
      brand: "ground up",
      place: "braddon",
      action: "book me",
      instructor: "Laura B",
    },
    {
      date: "25/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      coach: "sarah k ",
      brand: "hiit republic",
      place: "braddon",
      action: "book me",
      instructor: "Laura B",
    },
  ];
  const dataBody2 = [
    {
      date: "Today",
      time: "07:30-08:15",
      class: "Barre",
      brand: "club lime",
      place: "braddon",
      status: "book me",
      buddies: "sarah k ",
      coach: "Laura B",
      duration: "45min",
      description:
        "Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum .",
      moodPercent: 10,
      sweatRating: 25,
      studio: 35,
      full: 80,
    },
    {
      date: "tmr",
      time: "07:30-08:15",
      class: "Barre",
      brand: "hiit republic",
      status: "book me",
      place: "braddon",
      buddies: "sarah k ",
      coach: "Laura B",
      duration: "45min",
      description:
        "Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum .",
      moodPercent: 10,
      sweatRating: 25,
      studio: 35,
      full: 80,
    },
    {
      date: "22/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      brand: "ground up",
      place: "braddon",
      status: "cancel",
      buddies: "sarah k ",
      coach: "Laura B",
      duration: "45min",
      description:
        "Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum .",
      moodPercent: 10,
      sweatRating: 25,
      studio: 35,
      full: 80,
    },
  ];
  const dataHeadPast = ["date", "time", "class", "brand", "place"];
  const dataBodyPast = [
    {
      date: "Today",
      time: "07:30-08:15",
      class: "Barre",
      brand: "club lime",
      place: "braddon",
    },
    {
      date: "tmr",
      time: "07:30-08:15",
      class: "Barre",
      brand: "hiit republic",
      place: "braddon",
    },
    {
      date: "22/2/2023",
      time: "07:30-08:15",
      class: "Barre",
      brand: "ground up",
      place: "braddon",
    },
  ];

  const [selected, setSelected] = useState("");
  const [isShow, setShow] = useState(false);
  const [typeTable1, setTable1] = useState(true);

  const [modalCancel, setModalCancel] = useState(false);
  const [modalInvite, setModalInvite] = useState(false);
  const [modalBookClass, setModalBookClass] = useState(false);

  const [currentClass, setCurrentClass] = useState({});
  const [friendList, setFriendList] = useState([
    { name: "sarah", invite: false },
    { name: "leah", invite: false },
    { name: "captain", invite: false },
  ]);
  const inviteLayout = () => (
    <div className="w-[688px] flex flex-col gap-[40px] p-[33px]">
      <div>
        <p className="mb-[12px] bebas text-[35px]  ">
          Invite your Class buddies
        </p>
        <p className="mb-[12px] gotham text-sm">
          Barre, 7:30am, Monday, 10 July 2021 at ANU
        </p>
      </div>
      <div>
        <p className="border-b border-white gotham text-xs pb-[7px]">
          invite your class buddies
        </p>
        {friendList.map((i, ind) => (
          <div className="flex items-center px-[7px] justify-between border-b border-white py-[9px] ">
            <p className="gotham tracking-[0.01em] text-sm font-medium ">
              {i.name}
            </p>
            <div
              className={`w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                i.invite ? "border-brand-clime" : "border-white"
              }`}
              onClick={() => {
                const newData = [...friendList].map((item, index) =>
                  index == ind ? { ...item, invite: !item.invite } : item
                );
                setFriendList(newData);
              }}
            >
              {i.invite && (
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.69231L5.2 9L13 1"
                    stroke={"#82FA00"}
                    strokeWidth="1.02"
                  />
                </svg>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <ButtonNavigate text="SEND REQUEST " isArrowRight={true} />
      </div>
    </div>
  );
  const cancelLayout = () => {
    return (
      <div className="w-[688px] p-[33px] flex ">
        <div className="w-1/2">
          <img src={woman} />
        </div>
        <div className="w-1/2 -ml-[20px] flex flex-col justify-center">
          <p className="gotham text-xs mb-[15px] tracking-[0.01em] font-medium uppercase">
            are you sure you want to cancel?
          </p>
          <p className="bebas text-[35px] leading-[31px] ">
            {currentClass?.class}
          </p>
          <p className="bebas text-[35px] leading-[31px] ">
            {currentClass?.time}
          </p>
          <p className="bebas text-[35px] leading-[31px] ">
            {currentClass?.date}
          </p>
          <p className="bebas text-[35px] leading-[31px] ">
            {currentClass?.place}
          </p>
          <div className="mt-[30px] flex gap-[30px]">
            <button className="px-[19px] py-[14px] w-[110px] border border-white rounded-[5px] ">
              yes
            </button>
            <button className="px-[19px] py-[14px] w-[110px] border border-white rounded-[5px] ">
              no
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {modalBookClass && (
        <ModalBase closeModal={setModalBookClass}>
          <BookClassModal {...currentClass} closeModal={setModalBookClass}/>
        </ModalBase>
      )}
      {modalCancel && (
        <ModalBase closeModal={setModalCancel}>{cancelLayout()}</ModalBase>
      )}
      {modalInvite && (
        <ModalBase closeModal={setModalInvite}>{inviteLayout()}</ModalBase>
      )}
      <div className="text-white mx-6 lg:mx-[52px]  mt-[34px] ">
        <div className=" flex gap-[8px] items-center ">
          <div className="w-[48px] h-[48px] p-[7px]">
            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
              <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
            </div>
          </div>
          <span className="gotham text-sm leading-[19px] tracking-widest">
            Booking Detail
          </span>
        </div>
        <div className="mt-[37px]  flex justify-between lg:items-center lg:flex-row flex-col items-start">
          <p className="uppercase text-[54px] bebas leading-[50px] font-normal">
            {typeTable1 ? "MY BOOKINGS" : "CLASS HISTORY"}
          </p>
          <div className="flex">
            <button
              onClick={() => setTable1(true)}
              className={`border h-[40px] border-white uppercase pt-[5px] pb-[8px] flex justify-center w-[150px] gotham text-[11px] leading-[24px] gotham font-medium rounded-l-md  ${
                typeTable1 ? "bg-white text-black" : ""
              }`}
            >
              upcoming
            </button>
            <button
              onClick={() => setTable1(false)}
              className={`border h-[40px] border-white uppercase pt-[5px] pb-[8px] flex justify-center w-[150px] gotham text-[11px] leading-[24px] gotham font-medium  rounded-r-md ${
                typeTable1 ? "" : "bg-white text-black"
              }`}
            >
              class history
            </button>
          </div>
        </div>
        {typeTable1 ? (
          <>
            <div className="mt-[30px]">
              <TableBase
                responsiveChange={true}
                data="invite"
                headerCells={dataHead}
                bodyCells={dataBody}
              />
            </div>
            <div className="mt-[75px] ">
              <div className="flex justify-between lg:flex-row flex-col mb-6">
                <p className="text-[54px] font-normal bebas leading-[49px]">
                  your class buddies
                </p>
                <div>
                  <p className="gotham font-normal text-xs  px-[12px]">
                    Show Upcoming Classes for
                  </p>
                  <div>
                    {/* <Dropdown ></Dropdown> */}
                    <div className="relative lg:w-[365px]">
                      <div
                        className={`flex items-center mt-1 px-[12px] pb-[12px] bg-black border-b border-white cursor-pointer w-full focus:ring-1 `}
                        onClick={() => setShow(!isShow)}
                      >
                        <input
                          type="text"
                          className="bg-black outline-none w-full text-sm gotham font-medium tracking-widest uppercase"
                          onInput={(e) => setSelected(e?.target?.value)}
                          value={selected}
                        />
                        <div
                          className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                            isShow ? "border-brand-clime rotate-[135deg]" : ""
                          }`}
                        ></div>
                      </div>
                      <div
                        className={`w-full bg-black border-brand-clime text-white border-2 absolute z-40 ${
                          isShow ? "" : "hidden"
                        }`}
                      >
                        {["sarah", "lead", "captain"].map(
                          (item: any, index: number) => (
                            <div
                              key={index}
                              className="cursor-pointer uppercase gotham font-medium tracking-widest px-4 py-4  hover:text-black hover:bg-brand-clime"
                              onClick={() => {
                                setShow(false);
                                setSelected(item);
                                // setDropdownSelected(true);
                              }}
                            >
                              {item}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <TableBase
                  responsiveChange={true}
                  headerCells={dataHeadClassBuddies}
                  bodyCells={dataBody2}
                  // buttonRender={(e) => <button>{e.action}</button>}
                ></TableBase>
              </div>
            </div>
          </>
        ) : (
          <TableBase
            responsiveChange={false}
            headerCells={dataHeadPast}
            bodyCells={dataBodyPast}
          />
        )}
      </div>
    </>
  );
}

export default BookingDetail;
