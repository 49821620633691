import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";

function YourCoach() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    centerMode: true,
    arrows: false,
    afterChange: () => countTiming(),

  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    arrows: false,
    afterChange: () => countTiming(),

  };
  const [curr, setCurrentId] = useState(0);
  const [slider, setSlider] = useState<any>();
  const [slider2, setSlider2] = useState<any>();

  const { data, loading } = useFetch({
    params: "home-page",
    populate: "coach.coachList.image",
  });

  const coaches = data?.data?.attributes?.coach?.coachList;

  let isCountingDown = false;
  const countDown = () => {
    isCountingDown = false;
    clearTimeout(countTiming());
  };
  const countTiming = () => setTimeout(countDown, 3000);

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && coaches && data?.data?.attributes?.coach?.isShow && (
        <div className="relative w-full overflow-y-hidden overflow-x-hidden slick-your-coach">
          <div className="absolute top-full z-10 left-full lg:w-[815px] w-[429px] font-bold lg:text-[90px] text-[60px] text-center leading-[110%] tracking-[0.05em] outline-text stroke-gray origin-left -rotate-90">
            {data?.data?.attributes?.middleText}
          </div>
          <div className="absolute top-full left-0 z-10 lg:w-[815px] w-[429px] font-bold lg:text-[90px] text-[60px] text-center leading-[110%] tracking-[0.05em] outline-text stroke-gray -rotate-90 origin-left">
            {data?.data?.attributes?.middleText}
          </div>
          <div className="lg:h-[700px] h-[439px] lg:block hidden">
            <Slider
              {...settings}
              ref={(c: any) => setSlider(c)}
              className="h-[100%] my-auto  yourCoachSlider"
            >
              {coaches.map((item, i) => (
                <div
                  key={item?.id}
                  className={`mt-[100px] mb-[100px] ${"opa"}`}
                  onMouseEnter={() => {
                    slider.slickGoTo(i);
                  }}
                >
                  <img
                    className="lg:w-[469px] min-w-[321px] lg:h-[500px] min-h-[240px] object-cover opacity-60"
                    src={item?.image?.data?.attributes?.url}
                  />
                  <div className=" w-[504px] object-cover">
                    <p className=" font-bold mt-3 text-white">
                      {item?.name}
                      {i}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className=" h-[439px] lg:hidden block">
            <Slider
              {...settings2}
              ref={(c: any) => setSlider2(c)}
              className="h-[100%] my-auto  yourCoachSlider"
            >
              {coaches?.map((item, i) => (
                <div
                  key={item?.id}
                  className={`mt-[100px] mb-[100px] ${"opa"}`}
                  onMouseEnter={() => {
                    slider2.slickGoTo(i);
                  }}
                >
                  <img
                    className="lg:h-[469px]  w-[325px] h-[300px] lg:w-[500px] min-w-[240px] object-cover opacity-60"
                    src={item?.image?.data?.attributes?.url}
                  />
                  <div className=" w-[504px] object-cover">
                    <p className=" font-bold mt-3 text-white">
                      {item?.name}
                      {i}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="md:hidden block"></div>
          <div className="absolute top-1/2 -z-10 left-0 w-full -translate-y-1/2 font-bold lg:text-[150px] text-[50px] text-center leading-[110%] tracking-[-0.03em] outline-text text-white">
            {data?.data?.attributes?.bestMemberTitle}
          </div>
        </div>
      )}
    </>
  );
}

export default YourCoach;
