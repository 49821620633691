import axios from 'axios'

// Keepme
export const newLetterAPI = async (data: object) => {
    const res = axios({
        method: "post",
        url: `${process.env.STRAPI_API_URL}/api/new-letters/publish`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(() => { })
        .catch((err) => console.log(err))
    return res
}

// Join the home of hiit.
export const joinHiitAPI = async (data: object) => {


    const res = axios({
        method: "post",
        url: 'https://api.franchizemanager.com/fzm/loadenquiry',
        data: data,
        headers: { "Content-Type": "application/json" },
    }).then(() => { })
        .catch((err) => console.log(err))
    return res
}