import React, { useEffect, useState } from "react";
import { graphql, HeadFC, useStaticQuery } from "gatsby";
import Layout from "../components/layout/Layout";
import MoreThanHiit from "../components/about/MoreThanHiit";
import AboutSlider from "../components/about/AboutSlider";
import WhatWeOffer from "../components/about/WhatWeOffer";
import MemberStories from "../components/about/MemberStories";
import MembersAppBanner from "../components/about/MembersAppBanner";
import useFetch from "../hooks/useFetch";
import LoadingScreen from "../common/loading/LoadingScreen";
import MoveClasses from "../components/about/MoveClasses";

const About = () => {
  const { data, loading } = useFetch({
    params: "about-page",
    populate:
      "topBanner,topBanner.image,videosCarousels,videosCarousels.images,midBanner,midBanner.images, midBanner.background,belowAccessImage,endBanner, nearlyEndedBanner, nearlyEndedBanner.image1, nearlyEndedBanner.image2, nearlyEndedBanner.image3, nearlyEndedBanner.image4",
  });
  const [dataVideo, setDataVideo] = useState([]);

  useEffect(() => {
    if (data != null) {
      const videoArray = data.data.attributes.videosCarousels.map(
        (i: any) => i?.images?.data[0]?.attributes?.url
      );
      setDataVideo(videoArray);
    }
  }, [data]);
  return (
    <Layout>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <AboutSlider />

          <div className="max-w-[1440px] mx-auto">
            <MoreThanHiit {...data} />
            <WhatWeOffer />
            <MoveClasses />
          </div>
          <div className="bg-[#212121] w-full">
            <MemberStories videoData={dataVideo} />
          </div>
          <div className="max-w-[1440px] mx-auto">
            <MembersAppBanner />
          </div>
        </>
      )}
    </Layout>
  );
};

export default About;

export const Head: HeadFC = () => <title>About</title>;
