import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby';
import useFetch from '../../hooks/useFetch';
import ggPlay from "../../assets/images/ggplay.png";
import appStore from "../../assets/images/appstore.png";


const MenuItem = (props: any) => {
    const { item } = props
    const [toggle, setToggle] = useState<boolean>(false)

    const { data } = useFetch({ params: "footer", populate: "image,socials,socials.image,brands,brands.logo", });

    return (

        <div className='relative py-[16px]'>
            <div onClick={() => { setToggle(!toggle); item?.name === 'book a class' && navigate('/book-a-class') }} className='flex items-center'>
                <span className={`${toggle ? 'block' : 'hidden'} transition-all h-1 w-7 bg-brand-orange pl-[20px]`}></span>
                <p className="text-placeholder text-white  pl-[23px] min-h-[25px] flex-1 text-[20px] lowercase">{item?.name}</p>
            </div>
            <ul className={`text-white mx-5 ${toggle && item?.name !== 'member id app' ? 'block' : 'hidden'} pl-8 border-b-brand-orange border-b pb-[15px] flex flex-col`}>
                {item?.subName?.map(e => (
                    <Link to={e?.path}>
                        <li className='font-normal graphik-regular pt-[12px] text-[16px]'>{e?.name}</li>
                    </Link>
                ))}
            </ul>
            {item?.name === 'member id app' && (
                <ul className={`text-white mx-5 ${toggle ? 'block' : 'hidden'} pt-[12px] pl-8 border-b-brand-orange border-b pb-[15px] flex items-center `}>
                    <a href={data?.data?.attributes?.appStoreUrl}>
                        <img src={appStore} className="w-[148px] " /></a>
                    <a href={data?.data?.attributes?.playStoreUrl}><img src={ggPlay} className="w-[170px] h-[52px] object-cover" /></a>
                </ul>
            )}
        </div>

    );
};

export default MenuItem;