import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { arrowRight } from "../assets/images/icons/arrowRight.tsx";
import plus from "../assets/images/memberships/plus.png";
import minus from "../assets/images/memberships/minus.png";

import pic1 from "../assets/images/faq/pic1.png";
import pic2 from "../assets/images/faq/pic2.png";

import faqPhotos from "../assets/images/faq/faqPhotos.jpg";
import useFetch from "../hooks/useFetch";
import { Link } from "gatsby";
import ButtonNavigate from "../common/buttons/ButtonNavigate";

function faqInfo() {
  const { data, loading } = useFetch({
    params: "faq-page",
    populate: "collections,collections.questions, collections.image",
  });
  const [displayImg, setImg] = useState();
  const [isActive, setActive] = useState();
  const [isShow, setShow] = useState();
  return (
    <>
      {data && (
        <div className="flex items-center transition-all duration-900  mr-[4.9%] lg:mr-0">
          <div className="w-[30%] hidden lg:block">
            <div className="w-full relative h-[609px]  pl-[83px]  bg-blackSecondary  ">
              <img
                className="absolute top-10 right-0 transition-all fade"
                src={
                  data.data.attributes.collections[displayImg]?.image.data
                    ?.attributes.url ?? faqPhotos
                }
              />
            </div>
          </div>
          <div className="w-full lg:w-[70%] ">
            {data.data.attributes.collections.map((data, index) => (
              <>
                <div
                  className={`ml-[25px] md:ml-[60px] my-[20px]${isActive === index ? "text-brand-orange" : ""
                    }`}
                  onClick={() => {
                    setActive(index);
                    setImg(index);
                  }}
                >
                  <p
                    className={` mb-[31px] text-4xl textEffect ${isActive === index ? "text-brand-orange" : "text-white"
                      }`}
                  >
                    {data.title}
                  </p>
                  <div
                    className={`${isActive === index ? "showList" : "hidden"}`}
                  >
                    {data.questions.map((content, index) => (
                      <div
                        className={
                          isShow === content.question + index
                            ? "border-b border-brand-orange bg-[rgba(128,128,128,0.21)]"
                            : "border-[rgba(255,255,255,0.4)]"
                        }
                      >
                        <div
                          className={`text-base flex cursor-pointer items-center py-3  px-4 justify-between   ${isShow === content.question + index
                              ? "border-t border-brand-orange text-brand-orange"
                              : "border-[rgba(255,255,255,0.4)] border-t border-b text-white"
                            }
                        `}
                          onMouseEnter={() => {
                            setShow(content.question + index);
                          }}
                          onMouseLeave={() => { }}
                        >
                          {content.question}
                          <div
                            className={`text-brand-orange lg:block hidden
                            ${isShow === content.question + index
                                ? "rotate-45"
                                : ""
                              }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              // class="bi bi-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                            </svg>
                          </div>
                        </div>
                        <div>
                          <div
                            className={`flex justify-between items-start lg:items-end px-4 pb-7 transition-all duration-500 flex-col lg:flex-row  ${isShow === content.question + index
                                ? "show"
                                : "hidden"
                              }`}
                          >
                            <p className="text-white graphik-regular w-full lg:w-[573px] text-[14px] mt-[11px]">
                              {content.anwser}
                            </p>
                            {content.url && (
                              <Link to={content.url}>
                                <button
                                  className={
                                    " mt-2 lg:mt-0 border-b border-[#777777] text-[#777777] h-[56px] justify-evenly items-center flex w-[182px] hover:text-white hover:border-white font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r "
                                  }
                                >
                                  take me {arrowRight()}
                                </button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      )}
      <div className="lg:h-[575px] xs:mr-0 xs:ml-[25px] xs:overflow-hidden xs:mt-[44px] md:mt-[60px] mb-[44px]  sm:mt-0 sm:mb-0  lg:mr-[0] md:mr-[0px] h-content flex flex-col-reverse md:items-center lg:mx-[4.9%] justify-between lg:flex-row">
        <div className=" ">
          <p className=" text-white text-[60px] font-bold tracking-[-0.03em] leading-[66px]">
            can't find what
            <br /> you're looking for? <br />
            <span className="outline-text-1">visit our help centre</span>
          </p>
          <ButtonNavigate
            isArrowRight={true}
            text="take me"
            style="text-white border-b border-[#777777] text-[#777777] w-[182px] h-[50px] flex justify-evenly items-center mt-[45px]"
          ></ButtonNavigate>
        </div>
        <div className=" w-[100%] lg:w-[600px] mx-0 md:w-[600px] relative xs:mb-[44px] h-[335px] sm:h-[575px] md:mx-[-4.9%] lg:mx-[4.9%]">
          <img
            className="absolute top-0 left-0 w-[218px] h-[215px]  sm:w-auto sm:h-auto"
            src={pic1}
          />
          <img
            className="absolute bottom-0 right-0 w-[177px] h-[223px]  sm:w-auto sm:h-auto"
            src={pic2}
          />
        </div>
      </div>
    </>
  );
}

function Faq() {
  return (
    <Layout>
      <div className="max-w-[1440px] mx-auto">
        <div className="text-white text-6xl lg:m-[77px] mx-[4.9%] lg:my-[77px] my-[57px] -tracking-[0.03em]">
          faqs
        </div>
        {faqInfo()}
      </div>
    </Layout>
  );
}

export default Faq;
