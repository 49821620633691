import { Link } from "gatsby";
import React from "react";
import ButtonActive from "../../common/buttons/Active";
import LoadingScreen from "../../common/loading/LoadingScreen";
import useFetch from "../../hooks/useFetch";

function Intro() {
  const { data, loading } = useFetch({
    params: "home-page",
    populate: "middleBanner.images",
  });

  function createMarkup() {
    return { __html: data?.data?.attributes?.middleBanner?.title };
  }
  const images = data?.data?.attributes?.middleBanner?.images;

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <div>
          <div className="lg:h-[715px] h-[950px] max-w-[1440px] relative block overflow-hidden mx-auto ">
            <div className="absolute lg:left-[321px]  left-[143px] lg:top-[94px] bottom-0 lg:bottom-auto top-auto z-0">
              <img
                src={images?.data?.[1]?.attributes?.url}
                className="lg:w-[252px] w-[129px] lg:h-[359px] h-[184px]"
                alt="text effect"
              />
            </div>
            <div className="absolute lg:left-[92px] left-auto right-[25px] lg:right-auto lg:top-[308px] top-auto  bottom-[150px] lg:bottom-auto z-[-1]">
              <img
                src={images?.data?.[0]?.attributes?.url}
                className="lg:w-[271px] w-[141px] h-[193px] lg:h-[393px]"
                alt="coach"
              />
            </div>
            <div className="absolute lg:right-[33.06%] right-auto left-[25px] lg:left-auto  lg:top-0 top-auto  bottom-0 lg:bottom-auto z-[-1]">
              <img
                src={images?.data?.[2]?.attributes?.url}
                className="lg:w-[274px] lg:h-[376px] h-[139px] w-[202px] object-cover"
                alt="coach"
              />
            </div>
            <div className="relative">
              <div className="absolute lg:right-0 left-0 lg:left-auto right-auto top-[325px]">
                <img
                  src={images?.data?.[3]?.attributes?.url}
                  className="w-[277px] hidden lg:block h-[376px] "
                  alt="text effect"
                />
              </div>
            </div>
            <div className="absolute z-0 text-white pl-[25px] lg:pl-0 lg:right-[115px] right-[25px] mid-title lg:top-[222px] top-0 max-w-[604px] ">
              <div style={{}} className="text-[40px]" dangerouslySetInnerHTML={createMarkup()} />
              <p className="text-[14px] leading-[160%] font-normal graphik-regular mb-[72px] text-white max-w-[382px] mt-[62px]">
                {data?.data?.attributes?.middleBanner?.description}
              </p>
              <Link to="/register">
                <div className="relative bottom-5 left-0 z-10">
                  <ButtonActive text="get started" height={56} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
}

export default Intro;
