import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slider } from "../../../mock/about.js";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import ArrowRight from "../../assets/images/icons/arrowRight.svg";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";

function WhatWeOffer() {
  const { data, loading } = useFetch({
    params: "about-page",
    populate: "midCarousels,midCarousels.background,midCarouselsHeadline",
  });
  const { data: accessData, loading: accessLoading } = useFetch({
    params: "studio-accesses",
    populate: "image",
  });
  const [sliderControl, setSlider] = useState<any>();
  const [sliderControl2, setSlider2] = useState<any>();

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    centerMode: false,
    variableWidth: true,
    arrows: false,
  };
  const settings2 = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: false,
    arrows: false,
  };
  const next = () => {
    sliderControl.slickNext();
    sliderControl2.slickNext();
  };
  const prev = () => {
    sliderControl.slickPrev();
    sliderControl2.slickPrev();
  };
  return (
    <>
      <div className="relative what-we-offer mt-6 hidden lg:block">
        <div className="w-full absolute text-white text-center text-[150px] leading-[165px] tracking-[-0.03em] outline-text stroke-gray ">
          {data?.data?.attributes?.midCarouselsHeadline}
        </div>
        <div className="pt-[113px]  min-h-[600px] pl-[4.9%]">
          {accessLoading && <LoadingScreen />}
          {!accessLoading && accessData && (
            <Slider
              ref={(c: any) => setSlider2(c)}
              {...settings}
              className="offer-slider"
            >
              {accessData.data.map((e: any, i: number) => (
                <div className="text-white slide-content  mr-[26px] w-[506px] relative outline-none" onClick={() => sliderControl2.slickGoTo(i)}>
                  <img
                    src={e.attributes.image.data.attributes.url}
                    className="w-full h-auto object-cover opacity-60 outline-none"
                  />
                  <div className="absolute top-[275px] outline-none">
                    <span className="offer-title text-[40px] leading-8 -tracking-[0.03em] text-white">
                      {e.attributes.title.trim().split(" ").length == 2
                        ? e.attributes.title.split(" ")[0]
                        : e.attributes.title.split(" ")[0] +
                        " " +
                        e.attributes.title.split(" ")[1]}
                    </span>
                    <br />
                    <span className="offer-title text-[40px] leading-8 -tracking-[0.03em] text-white">
                      {e.attributes.title.trim().split(" ").length == 2
                        ? e.attributes.title.split(" ")[1]
                        : e.attributes.title.split(" ").splice(2).join(" ")}
                    </span>
                  </div>
                  <div className="mt-[85px] graphik-regular">
                    {e.attributes.description}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="flex items-center mx-[4.9%] ">
          <Link to="/register">
            <ButtonNavigate
              style="w-[165px] h-[56px] text-[#777777] border-[#777777] border-b"
              text="join now"
            ></ButtonNavigate>
          </Link>
          <div className="mx-auto my-9 mr-[71px] flex justify-around pb-5 w-[106px] border-b-[5px] border-b-brand-orange">
            <button onClick={() => prev()}>
              <img className="-rotate-[180deg]" src={ArrowRight} />
            </button>
            <button onClick={() => next()}>
              <img className="" src={ArrowRight} />
            </button>
          </div>
        </div>
      </div>
      <div className="relative what-we-offer mt-6 block lg:hidden">
        <div className="w-full absolute text-white text-center text-[51.43px] leading-[165px] tracking-[-0.03em] outline-text stroke-gray ">
          {data?.data?.attributes?.midCarouselsHeadline}
        </div>
        <div className="pt-[92px] pl-[4.9%]">
          {accessLoading && <LoadingScreen />}
          {!accessLoading && accessData && (
            <Slider
              ref={(c: any) => setSlider(c)}
              {...settings2}
              className="offer-slider-phone"
            >
              {accessData.data.map((e: any) => (
                <div className="text-white slide-content flex justify-center pr-[4.9%] pt-1 relative">
                  <img
                    src={e.attributes.image.data.attributes.url}
                    className=" object-cover opacity-90 w-full h-[189px]"
                  />
                  <div className="absolute top-[175px] md:top-[10] text-brand-orange">
                    <span className="offer-title text-[30px] leading-8 -tracking-[0.03em] text-brand-orange">
                      {e.attributes.title.split(" ")[0]}
                    </span>
                    <br />
                    <span className="offer-title text-[30px] leading-8 -tracking-[0.03em] text-brand-orange">
                      {e.attributes.title.split(" ")[1]}
                    </span>
                  </div>
                  <div className="mt-[54px] text-[14px] graphik-regular">
                    {e.attributes.description}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="flex items-center justify-between mx-[4.9%] ">
          <Link to="/register">
            <ButtonNavigate
              style="w-[165px] h-[56px] text-[#777777] border-[#777777] border-b"
              text="join now"
            ></ButtonNavigate>
          </Link>
          <div className=" my-9  flex justify-around pb-5 w-[106px] border-b-[5px] border-b-brand-orange">
            <button onClick={() => prev()}>
              <img className="-rotate-[180deg]" src={ArrowRight} />
            </button>
            <button onClick={() => next()}>
              <img className="" src={ArrowRight} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatWeOffer;
