import React, { useState, useEffect, useRef } from "react";
import ButtonActive from "../../common/buttons/Active";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
const totalRate = (total: number) => {
  let rate = [];
  let unRate = [];
  for (let i = 1; i <= total; i++) {
    rate.push(i);
  }
  for (let i = 1; i <= 5 - total; i++) {
    unRate.push(i);
  }

  return (
    <div className="flex gap-2">
      {rate.map(() => (
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.435547 8.38662C0.435547 6.32523 3.24352 3.40494 4.47903 1C5.60221 3.1759 8.5225 6.00458 8.5225 8.38662C8.5225 10.7687 7.39932 12.6812 4.47903 12.6812C1.55874 12.6812 0.435547 10.448 0.435547 8.38662Z"
            fill="#FF6900"
            stroke="#FF6900"
            stroke-width="0.64878"
            stroke-linejoin="round"
          />
        </svg>
      ))}
      {unRate.map(() => (
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.912109 8.38662C0.912109 6.32523 3.72008 3.40494 4.95559 1C6.07878 3.1759 8.99907 6.00458 8.99907 8.38662C8.99907 10.7687 7.87588 12.6812 4.95559 12.6812C2.0353 12.6812 0.912109 10.448 0.912109 8.38662Z"
            stroke="#FF6900"
            stroke-linejoin="round"
          />
        </svg>
      ))}
    </div>
  );
};
function OurClass(props) {
  const [hover, setHover] = useState(null);
  const [isClose, setIsCLose] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);

  const elementRef = useRef(null);

  const handleDetailClass = (index: number, e: any) => {
    setCurrentClass(index);
    e.target.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  const { loading, data } = useFetch({
    params: "home-page",
    populate: "bottomCarousels.background , bottomCarousels.customMetadata",
  });
  const ourClasses = data?.data?.attributes?.bottomCarousels;

  return (
    <>
      {!((currentClass !== null && !isClose)) ? (
        <div >
          <div className="border border-brand-primary border-l-0 border-r-0  mt-6 overflow-hidden px-11 py-6 relative ">
            <div className="flex animate absolute ">
              <div className="flex w-[1440px] justify-around items-center logo">
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
              </div>
              <div className="flex w-[1440px] justify-around items-center logo">
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
                <a href="#">
                  <p className="text-effect lg:text-[60px] text-[40px] whitespace-nowrap">
                    our classes
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full  overflow-hidden">
          <span
            className={`text-effect  lg:text-[60px] text-[40px]  whitespace-nowrap animationSlide  opacity-1   font-bold tracking-tighter leading-[110%] transition-all duration-300
          `}
          >
            ur classes &nbsp;&nbsp;&nbsp;our classes&nbsp;&nbsp;&nbsp; our
            classes&nbsp;&nbsp;&nbsp;
          </span>
          <span
            className={`text-effect lg:text-[60px] text-[40px] font-bold tracking-tighter animationOpa whitespace-nowrap  leading-[110%] transition-all opacity-1 duration-300
          ${currentClass !== null && !isClose ? "" : " "}`}
          >
            our classes&nbsp;&nbsp; our cl
          </span>
        </div>
      )}
      {loading && <LoadingScreen />}
      {!loading && ourClasses && (
        <div>
          <div
            ref={elementRef}
            id="scroll"
            className="lg:ml-[70px] ml-[25px] mr-[25px] lg:mr-0 items-end mt-[50px] flex overflow-y-hidden pb-[33px] overflow-x-scroll lg:gap-[30px] gap-[12px]"
          >
            {ourClasses?.map((item: any, index: number) => (
              <div
                onClick={(e) => handleDetailClass(index, e)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(null)}
                className="block cursor-pointer our-classes relative"
                key={index}
              >
                <div className="mb-[14px] flex items-center justify-between">
                  <span
                    className={`font-bold text-[23.5px] text-class  text-${hover === index || (currentClass === index && !isClose)
                      ? "brand-orange"
                      : "white"
                      } leading-[100%] `}
                  >
                    {item.title}
                  </span>
                  <span>
                    {currentClass === index && !isClose ? (
                      <span onClick={() => setIsCLose(true)}>
                        <svg
                          width="41"
                          height="41"
                          viewBox="0 0 41 41"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                            stroke="#FF6900"
                            stroke-width="1.32496"
                          />
                          <path
                            d="M24.7275 15.1323L15.3977 24.4622"
                            stroke="#FF6900"
                            stroke-width="1.32496"
                          />
                          <path
                            d="M24.8311 24.5654L15.5598 15.2942"
                            stroke="#FF6900"
                            stroke-width="1.32496"
                          />
                        </svg>
                      </span>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7.82345"
                          cy="8.23544"
                          r="6.47059"
                          fill={hover === index ? "#ff6900" : "transparent"}
                          stroke={hover === index ? "#ff6900" : "white"}
                          stroke-width="2.35294"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <img
                  onClick={() => setIsCLose(false)}
                  src={item?.background?.data?.attributes?.url}
                  style={{
                    height:
                      currentClass === index && !isClose ? 445 : 295 + "px",
                    minWidth:
                      currentClass === index && !isClose ? 501 : 354 + "px",
                  }}
                  className="object-cover hidden lg:block transition-all duration-500  "
                />
                <img
                  onClick={() => setIsCLose(false)}
                  src={item?.background?.data?.attributes?.url}
                  style={{
                    height:
                      currentClass === index && !isClose ? 325 : 255 + "px",
                    minWidth:
                      currentClass === index && !isClose ? 260 : 185 + "px",
                  }}
                  className="object-cover transition-all block lg:hidden duration-500  "
                />
                {/* Info */}
                {currentClass === index && !isClose && (
                  <div className="absolute bottom-[36px] left-0 w-full px-[16px] text-white">
                    <h4 className="text-brand-orange font-bold text-[20px] leading-[100%] mb-[39px]">
                      about the class
                    </h4>
                    <p className="graphik-regular font-normal text-[16px] leading-[160%] text-white">
                      {item.description}
                    </p>
                    <div className="flex items-center justify-between mt-[28px]">
                      <span className="text-brand-orange leading-[100%] text-[20px] font-bold">
                        sweat rating
                      </span>
                      <div className="w-[78px] flex gap-2">
                        {totalRate(item?.customMetadata?.rate)}
                      </div>
                    </div>
                  </div>
                )}
                {/* Overlay + Detail Class */}
                {currentClass === index && !isClose && (
                  // <Link to={`/class/${item.id}/`}>
                  <a href="/class">
                    <div className="absolute left-0 top-[35px] w-full h-full"></div>
                  </a>
                  // </Link>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center mt-[48px] justify-between lg:ml-[70px] ml-[25px]">
            <a href="/class">
              <ButtonActive height={56} text="all classes" />
            </a>
            <p className="max-w-[584px] text-white font-normal leading-[160%] graphik-regular mr-[70px]">
              {ourClasses?.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default OurClass;
