import React, { useState, useEffect, useRef } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";

type selectDropdownInfo = {
    style: string;
    data: Array<string>;
    title: string;
    titleStyle: string;
    placeholder: string;
    placeholderColor: string;
    isTitle: boolean;
    isShowRequired: boolean;
    onSelect: any;
};
function SelectOutLine({
    style,
    data = [],
    title,
    titleStyle,
    placeholder,
    isTitle = false,
    isShowRequired = false,
    onSelect = () => { },
}: selectDropdownInfo) {
    const [isShow, setShow] = useState(false);
    const [selected, setSelected] = useState("");
    const [dropdownSelected, setDropdownSelected] = useState(false);

    const target = useRef<any>(null);

    useEffect(() => { if (isTitle) setSelected(data[0]); }, [data]);
    useEffect(() => { onSelect(selected); }, [selected]);


    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isShow && target.current && !target.current?.contains(e.target)) { setShow(false); }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isShow]);
    return (
        <div ref={target}>
            {!isTitle ? (
                <div className="relative">
                    <span className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}                    >                    </span>
                    <span
                        className={`absolute  block  graphik-regular font-medium  transition-all duration-300 
                        ${selected ? "top-[10px] left-0 text-[12px] text-white" : "top-[29px] left-0 text-white  text-[14px]"}`}
                    >
                        {placeholder}
                    </span>
                    <div className={`flex  items-center mt-1 2 py-2 bg-black border-b h-[36px] cursor-pointer w-full focus:ring-1 ${style} ${selected
                        ? "border-b-brand-orange text-brand-orange justify-between "
                        : "border-b-gray text-[#FFFFFF] justify-end"
                        } `}
                        onClick={() => setShow(!isShow)}
                    >
                        {isShowRequired && !selected ? (
                            <div className="graphik-regular w-[45%] sabsolute top-[53%] mr-[25px] right-[20px] text-end font-normal text-xs text-brand-orange">
                                This Field is required
                                <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
                            </div>
                        ) : (
                            ""
                        )}
                        <input
                            type="text"
                            className="bg-black outline-none w-full"
                            onInput={(e) => setSelected(e.target.value)}
                            value={selected}
                        />
                        <div className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 mr-2 -rotate-45 ${isShow ? "border-brand-orange rotate-[135deg]" : "border-gray"}`}                        ></div>
                    </div>
                    <div
                        className={`w-full bg-black border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"}`}                    >
                        {data.map((item: any) => (
                            <div
                                className="cursor-pointer px-6 py-4 hover:bg-slate-400"
                                onClick={() => { setSelected(item); setDropdownSelected(true); }}                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="relative">
                    <span className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}                    >
                        {title}
                    </span>
                    <div
                        className={`flex items-center mt-1 px-6 py-2  bg-black border cursor-pointer w-full focus:ring-1 justify-between  ${style} ${selected
                            ? "border-brand-orange text-brand-orange "
                            : "border-[#FFFFFF] text-gray "
                            } `}
                        onClick={() => setShow(!isShow)}
                    >
                        {selected ? selected : placeholder}
                        <div
                            className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${isShow ? "border-brand-orange rotate-[135deg]" : ""}`}
                        ></div>
                    </div>
                    <div
                        className={`w-full bg-black border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"}`}
                    >
                        {data.map((item: any) => (
                            <div className="cursor-pointer px-6 py-4 hover:bg-slate-400" onClick={() => { setSelected(item); }}                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SelectOutLine;
