import React from "react";
import BenefitDetail from "../../components/dashboard/BenefitDetail";
import DashBoardLayout from "../../components/layout/DashboardLayout";

function Benefits(props) {
    return (
        <DashBoardLayout {...props}>
            <BenefitDetail />
        </DashBoardLayout>
    );
}

export default Benefits;
