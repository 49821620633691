import React from "react";
import { arrowRight } from "../../assets/images/icons/arrowRight.tsx";
interface btnType {
  text: string;
  height: number;
  style: string;
  isActive: boolean;
  onClick: () => void;
  isArrowRight: boolean;
  isArrowLeft: boolean;
}
function ButtonNavigate({
  style,
  text,
  isActive = false,
  onClick = () => {},
  isArrowRight = false,
  isArrowLeft = false,
}: btnType) {
  return (
    <button
      onClick={() => onClick()}
      className={
        " font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white " +
        (isActive ? "border-b-[5px] border-b-brand-orange " : " ") +
        (isArrowRight ? "flex justify-evenly p-2 border-b gap-2" : "") +
        style
      }
    >
      {text}
      {isArrowRight ? arrowRight() : ""}
    </button>
  );
}

export default ButtonNavigate;
