import React, { useState } from "react";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import TableBase from "../../../common/tables/TableBase";
import DashBoardLayout from "../../../components/layout/DashboardLayout";
import InputBase from "../../../common/inputs/InputBaseDashBoard";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import CheckboxActive from "../../../common/buttons/CheckboxActive";
import { Link } from "gatsby";
function Biling() {
  const [startDate, setStartDate] = useState<string>("");
  const [year, setYear] = useState("");
  const [typeOfPayment, setTypeOfPayment] = useState("card");
  const dataHeadPast = [
    "date",
    "item",
    "amount",
    {
      label: "action",
      elm: () => {
        return <div> <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-arrow-bar-to-down"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="4" y1="20" x2="20" y2="20" />
        <line x1="12" y1="14" x2="12" y2="4" />
        <line x1="12" y1="14" x2="16" y2="10" />
        <line x1="12" y1="14" x2="8" y2="10" />
      </svg></div>;
      },
    },
  ];
  const dataBodyPast = [
    {
      date: "08-Jul 2021",
      item: "Direct debit account ending in **** 4453",
      amount: "$50.80",
    },
    {
      date: "08-Jul 2021",
      item: "Direct debit account ending in **** 4453",
      amount: "$50.80",
    },
    {
      date: "08-Jul 2021",
      item: "Direct debit account ending in **** 4453",
      amount: "$50.80",
    },

    // [
    //   "08-Jul 2021",
    //   "Direct debit account ending in **** 4453",
    //   "$50.80",
    //   "download",
    // ],
    // [
    //   "08-Jul 2021",
    //   "Direct debit account ending in **** 4453",
    //   "$50.80",
    //   "download",
    // ],
  ];
  const [isUpdate, setUpdate] = useState(false);
  return (
    <DashBoardLayout>
      <div className="text-white mx-6 lg:mx-[52px] mt-[34px] ">
        <div className=" flex gap-[8px] items-center">
          <div className="w-[48px] h-[48px] p-[7px]">
            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
              <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
            </div>
          </div>
          <span className="gotham uppercase text-sm leading-[19px] tracking-widest">
            dashboard
          </span>
        </div>
        <div className="mt-[37px] text-[54px] bebas text-white uppercase">
          billing
        </div>
        <div className="mt-[38px] lg:w-[850px] pb-[32px] lg:h-[442px] border border-gray rounded bg-[#0F0F0F]">
          <div className="h-[57px] px-[27px] bg-[#0F0F0F] border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase">
            payment method
          </div>
          <div className="mt-[29px] flex lg:flex-row flex-col lg:items-center items-start   justify-between">
            <div className="flex items-center lg:w-auto lg:pl-[27px] w-full">
              <button
                onClick={() => setTypeOfPayment("card")}
                className={`text-white uppercase h-[40px] rounded-l lg:w-[150px] w-[50vw] text-[11px] gotham font-bold  ${
                  typeOfPayment !== "card"
                    ? "border border-white text-white"
                    : "border-white text-black  bg-white pt-[3px]"
                } border-t border-l bg-[black] border-r`}
              >
                BANK ACCOUNT
              </button>
              <button
                onClick={() => setTypeOfPayment("bank")}
                className={`text-white uppercase h-[40px] rounded-r lg:w-[150px] w-[50vw] text-[11px] gotham font-bold  ${
                  typeOfPayment !== "bank"
                    ? "border border-white text-white"
                    : "border-white text-black  bg-white pt-[3px]"
                } border-t border-l bg-[black] border-r`}
              >
                CREDIT CARD
              </button>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-[14px]  mt-[33px] px-[27px] ">
            <InputBase
              isDashboard={true}
              holder="Account name*"
              style="lg:w-full"
              isRequired={true}
            />
            <InputBase
              isDashboard={true}
              holder="Bank name*"
              style="lg:w-full"
              isRequired={true}
            />
            <InputBase
              isDashboard={true}
              holder="BOB*"
              style="lg:w-full"
              isRequired={true}
            />
            <InputBase
              isDashboard={true}
              holder="Account number*"
              style="lg:w-full"
              isRequired={true}
            />
          </div>
          <div className="mt-[37px] flex items-center pl-[27px] gap-[14px]">
            <div className="w-max">
              <CheckboxActive isDashboard={true} />
            </div>

            <p className="text-white gotham font-normal w-[542px] text-[11px]">
              I understand that these new details will replace the account
              details in my Direct Debit Request Service agreement with Viva
              Leisure Group.
            </p>
          </div>
          <button className="mt-[25px] text-[11.2px] uppercase rounded border  transition-all ml-[27px] border-gray text-white w-[146px] h-[36px] hover:bg-white hover:text-black">
            update
          </button>
        </div>

        {/* Membership plan */}
        <div className="mt-[45px] lg:w-[850px] pb-[32px]  border border-gray rounded bg-[#0F0F0F]">
          <div className="h-[57px] flex justify-between  px-[27px] bg-[#0F0F0F] border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase">
            <p>membership plan</p>
            <span
              className="text-gray underline text-xs lowercase cursor-pointer"
              onClick={() => setUpdate(!isUpdate)}
            >
              {isUpdate ? "close" : "edit"}
            </span>
          </div>
          <div className="py-[17px] flex flex-wrap border-b border-b-gray items-center mx-[27px]">
            <div className="w-1/2">
              <h6 className="text-white opacity-60 text-[11px] gotham font-normal">
                home club
              </h6>
              <h2 className="mt-[3px] text-white gotham text-sm">
                Club Lime Braddon
              </h2>
            </div>
            <div className="flex items-center w-1/2 lg:justify-between justify-end">
              <div>
                <h6 className="text-white opacity-60 text-[11px] gotham font-normal">
                  Per fortnight
                </h6>
                <h2 className="mt-[3px] text-white gotham text-sm text-end lg:text-start">
                  $32.80
                </h2>
              </div>
              {isUpdate && (
                <Link
                  to="/dashboard/billing/change-club"
                  className="lg:block hidden"
                >
                  <button className=" gotham text-[11.2px] lg:h-[37px] lg:px-6 uppercase rounded border border-white">
                    change home club
                  </button>
                </Link>
              )}
            </div>
            {isUpdate && (
              <Link
                to="/dashboard/billing/change-club"
                className="lg:hidden block w-full mt-[22px] "
              >
                <button className=" gotham text-[11.2px] w-full h-[39px] lg:px-6 uppercase rounded border border-white">
                  change home club
                </button>
              </Link>
            )}
          </div>
          <div className="py-[17px] flex-wrap flex border-b border-b-gray items-center mx-[27px] ">
            <div className="w-[50%]">
              <h6 className="text-white opacity-60 text-[11px] gotham font-normal">
                upgrades
              </h6>
              <h2 className="mt-[3px] text-white gotham text-sm">
                hiit Republic Braddon
              </h2>
              <h2 className="mt-[3px] text-white gotham text-sm">
                Ground Up Fyshwick
              </h2>
            </div>
            <div className="flex items-center h-full justify-end w-[50%] lg:gap-[120px]  lg:justify-between">
              <div className="mt-[11px]">
                <div className="flex items-center gap-5">
                  <h2 className=" text-white gotham text-sm">$10.00</h2>
                  <span className="text-white">x</span>
                </div>
                <div className="flex items-center gap-5">
                  <h2 className=" text-white gotham text-sm">$10.00</h2>
                  <span className="text-white">x</span>
                </div>
              </div>
              {isUpdate && (
                <button className="lg:block hidden w-[163px] gotham text-[11.2px] h-[37px] uppercase rounded border border-white">
                  add more
                </button>
              )}
            </div>
            {isUpdate && (
              <button className="lg:hidden block w-full lg:w-[163px] mt-[22px] lg:mt-0 gotham text-[11.2px] h-[37px] uppercase rounded border border-white">
                add more
              </button>
            )}
          </div>
          <div className="py-[17px] flex border-b border-b-gray  items-center mx-[27px]">
            <h2 className="mt-[3px] text-white gotham text-sm w-1/2">Total</h2>
            <div className="flex items-center gap-[120px] w-1/2 lg:justify-between justify-end">
              <div>
                <div className="flex items-center lg:justify-start justify-end gap-5">
                  <h2 className="mt-[3px] text-white gotham text-sm">$40.00</h2>
                  <span className="text-white lg:inline hidden">x</span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-[17px] flex flex-wrap border-b border-b-gray  items-center mx-[27px]">
            <h2 className="mt-[3px] text-white gotham text-sm w-1/2">
              Next direct debit
            </h2>
            <div className="flex items-center lg:gap-[120px] w-1/2 lg:justify-between justify-end">
              <div>
                <h2 className="mt-[3px] text-white gotham text-sm">
                  06/24/2023
                </h2>
              </div>
              {isUpdate && (
                <h2 className="text-white underline gotham font-normal text-sm lg:block hidden ">
                  Suspend
                </h2>
              )}
            </div>
            {isUpdate && (
              <button className="lg:hidden block w-full lg:w-[163px] mt-[22px] lg:mt-0 gotham text-[11.2px] h-[37px] uppercase rounded border border-white">
                Suspend
              </button>
            )}
          </div>
          <div className="py-[17px] flex border-b border-b-gray  items-center mx-[27px]">
            <h2 className="mt-[3px] text-white gotham text-sm w-1/2">
              Member since
            </h2>
            <div className="flex items-center gap-[120px] w-1/2 lg:justify-between justify-end">
              <div>
                <h2 className="mt-[3px] text-white gotham text-sm">
                  03/07/2020
                </h2>
              </div>
            </div>
          </div>

          <div className="py-[17px] flex border-b border-b-gray  items-center mx-[27px]">
            <h2 className="mt-[3px] text-white gotham text-sm w-1/2">
              Contract auto-renews
            </h2>
            <div className="flex items-center gap-[120px] w-1/2 lg:justify-between justify-end">
              <div>
                <h2 className="mt-[3px] text-white gotham text-sm">
                  06/24/2023
                </h2>
              </div>
            </div>
          </div>
          {!isUpdate && (
            <p
              className="lg:hidden text-white text-center text-sm  mt-[35px]  font-normal gotham underline text-white"
              onClick={() => {
                setUpdate(true);
              }}
            >
              Edit membership
            </p>
          )}
          {isUpdate && (
            <div className="flex lg:flex-row flex-col-reverse mt-[35px] mx-[27px] items-center justify-between gap-4">
              <button className=" text-[11.2px] uppercase rounded border  transition-all border-gray text-white w-[146px] h-[36px] hover:bg-white hover:text-black">
                update
              </button>
              <p className="text-white text-sm font-normal gotham underline text-white">
                Request membership cancellation{" "}
              </p>
            </div>
          )}
        </div>
        <div className="mt-[45px] lg:w-[850px] pb-[30px] border-gray mb-[90px] border rounded">
          <div className="h-[57px] px-[27px] bg-[#0F0F0F] border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase">
            purchase history
          </div>
          <div className="[-[27px]">
            <div className="mt-[34px] flex-col lg:flex-row flex items-center justify-between mx-[27px]">
              <div
                className={`lg:w-[400px] w-full border-b flex h-[59px] flex-col pb-[2px]  relative ${
                  startDate !== "" ? "border-b-gray" : "border-b-gray"
                } `}
              >
                <div className="absolute  bottom-[6px] w-full">
                  <Flatpickr
                    // placeHolder=""
                    onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                    options={{
                      enableTime: false,
                      time_24hr: false,
                      allowInput: false,
                    }}
                  />
                </div>
                {/* <label
                  className={`gotham text-[12px] absolute left-0 ${
                    startDate !== ""
                      ? "text-white top-[2px]"
                      : "text-gray bottom-[14px]"
                  }`}
                >
                  Date range*
                </label> */}
                <div className="absolute  z-10 right-3 bottom-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1209)">
                      <path
                        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                        fill="#82FA00"
                      />
                      <path
                        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1209">
                        <rect width="20" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                className={`lg:w-[180px] w-full border-b flex h-[59px] flex-col pb-[2px]  relative ${
                  year !== "" ? "border-b-gray" : "border-b-gray"
                } `}
              >
                <div className="absolute  bottom-[6px] w-full">
                  <Flatpickr
                    onChange={(dateObj, dateStr) => setYear(dateStr)}
                    options={{
                      enableTime: false,
                      time_24hr: false,
                      allowInput: false,
                    }}
                  />
                </div>
                {/* <label
                  className={`gotham text-[12px] absolute left-0 ${
                    year !== ""
                      ? "text-white top-[2px]"
                      : "text-gray bottom-[14px]"
                  }`}
                >
                  Date range*
                </label> */}
                <div className="absolute  z-10 right-3 bottom-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1209)">
                      <path
                        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                        fill="#82FA00"
                      />
                      <path
                        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1209">
                        <rect width="20" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="lg:mx-[27px] mt-[35px]">
              <TableBase
                data="download"
                headerCells={dataHeadPast}
                bodyCells={dataBodyPast}
              />
            </div>
          </div>
        </div>
      </div>
    </DashBoardLayout>
  );
}

export default Biling;
