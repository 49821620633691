import React, { useContext } from 'react'
import MembershipPlan from '../../../components/memberships/MembershipPlan'
import { RegisterContext } from '../../../context/RegisterContext'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
function SelectPlan(props) {
    const { setStep } = props

    const plan = useContext(RegisterContext).plan
    const setPlan = useContext(RegisterContext).setPlan
    return (
        <>
            <div className=" flex gap-[8px] items-center mt-[34px]">
                <div onClick={() => setStep(2)} className="w-[41px] h-[48px] p-[7px] pl-0">
                    <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                        <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                    </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                    step 2
                </span>
            </div>
            <div className='lg:w-[796px] mt-[35px]'>
                <h6 className='uppercase text-white text-sm leading-[140%] tracking-widest gotham'>step 3</h6>
                <p className='mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white'>select a new plan</p>
                <div className='lg:-ml-5 -mt-[50px] flex items-center justify-between'>
                    <MembershipPlan setPlan={setPlan} setStep={setStep} isLabel={false} size='945px' color='#111111' isDashboard={true} />
                </div>
            </div>
        </>
    )
}

export default SelectPlan