import React, { useEffect } from "react";
import LoadingScreen from "../../common/loading/LoadingScreen";

interface IProps {
  loading: boolean;
  data: object;
}
function Banner(props: IProps) {
  const { data, loading } = props;

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <div className="w-full h-[692px] relative">
            <img
              src={
                data?.data?.attributes?.topBanner?.background?.data?.attributes
                  ?.url
              }
              className="w-full h-[692px] object-cover"
              alt="hiit intro"
            />
            <div className="w-full absolute top-[50%] transform  translate-y-[-50%]">
              <h6 className="text-[#FF6900] text-[30px] sm:text-2xl font-bold text-center">
                {data?.data?.attributes?.topBanner?.title}
              </h6>
              <p className="mt-[38px] max-w-[585px] mx-auto outline-text-md text-[50px] sm:text-[80px] leading-[90%] font-bold  text-center text-white tracking-wider">
                {data?.data?.attributes?.topBanner?.description}
              </p>
            </div>
          </div>
          <div className="border-l-0 border-r-0  mt-6 overflow-hidden px-10 py-10 relative">
            <div className="flex animateBuild absolute text-[50px]  sm:text-[61px] outline-text-1 ">
              <div className="flex w-[1440px] justify-around items-center logo">
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap">
                    build your membership
                  </p>
                </a>
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap"></p>
                </a>
                <a href="#">
                  <p className="hidden lg:block tracking-tight whitespace-nowrap">
                    build your membership
                  </p>
                </a>
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap"></p>
                </a>
              </div>
              <div className="hidden lg:flex w-[1440px]  justify-around items-center logo">
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap">
                    build your membership
                  </p>
                </a>
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap"></p>
                </a>
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap">
                    build your membership
                  </p>
                </a>
                <a href="#">
                  <p className=" tracking-tight whitespace-nowrap"></p>
                </a>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  );
}

export default Banner;
