import React, { useState } from 'react'
import plus from "../../assets/images/memberships/plus.png";
interface Iprops {
    color: string
}
function Checkbox(props: Iprops) {
    const { color } = props
    const [isChecked, setIsChecked] = useState<boolean>(false)

    return (
        <div style={{ borderBottomColor: '#' + color }} onClick={() => setIsChecked(!isChecked)} className={`w-[40px] h-[38px] border flex items-center justify-center border-b-[3.43px]`}>
            {!isChecked
                ? <img src={plus} />
                : <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="transparent"
                >
                    <path
                        d="M1 4.69231L5.2 9L13 1"
                        stroke={"white"}
                        stroke-width="1.02"
                    />
                </svg>
            }
        </div>
    )
}

export default Checkbox