import React, { useContext, useState } from "react";
import DashBoardLayout from "../../../components/layout/DashboardLayout";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { RegisterContext } from "../../../context/RegisterContext";
import { yourMemberships } from "../../../../mock/memberships";
import { arrowRight } from "../../../assets/images/icons/arrowRight.tsx";
import { Link } from "gatsby";
import Selected from "../../../common/buttons/Selected";
import useFetch from "../../../hooks/useFetch";
import CheckboxActive from "../../../common/buttons/CheckboxActive";
function Upgrade(props) {
  const [toggle, setToggle] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");

  // Context
  const upgrades = useContext(RegisterContext).upgrades;
  const setUpgrades = useContext(RegisterContext).setUpgrades;

  // Get Upgrades
  const { data } = useFetch({
    params: "membership-upgrades",
    populate: "logo,image,features,price",
  });

  // Check value upgrades exists
  const handleCheck = (club: object) => {
    setUpgrades((prev) => {
      const isChecked = upgrades.some(
        (item) => item?.attributes?.title === club?.attributes?.title
      );
      return isChecked
        ? upgrades?.filter(
            (item) => item?.attributes?.title !== club?.attributes?.title
          )
        : [...prev, club];
    });
  };

  // Total Price
  let total = 0;
  upgrades.forEach((e) => {
    total += e?.attributes?.price?.[1]?.value;
  });

  return (
    <DashBoardLayout {...props}>
      <div className="text-white  ml-6 mr-6 xl:mr-[0px] xl:ml-[60px] pb-[95px]">
        {/* Breakcrumb */}
        <div className=" flex gap-[8px] items-center mt-[34px]">
          <div className="w-[41px] h-[48px] p-[7px] pl-0">
            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
              <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
            </div>
          </div>
          <span className="gotham text-sm leading-[19px] text-white tracking-widest">
            Dashboard
          </span>
        </div>
        {/* Layout */}
        <div className="flex items-start xl:gap-[177px] md:justify-between lg:flex-row flex-col  ">
          <div>
            <p className="bebas font-normal text-[55px] text-white leading-[90%] my-[32px]">
              Upgrade your membership
            </p>
            {/* Upgrade start date */}
            <div className="mt-[40px] bg-[#0F0F0F] w-full xl:w-[531px] rounded-[5px] h-[194px] border border-gray">
              <p className="py-[20px] px-[27px] tracking-widest text-white uppercase text-[14px] gotham border-b border-b-gray font-medium leading-[140%]">
                upgrade start date
              </p>
              <div className="pt-[25px] pl-[27px] calendar-dashboard">
                <div
                  className={`w-[239px] border-b flex h-[59px] flex-col pb-[2px]  relative ${
                    startDate !== "" ? "border-b-gray" : "border-b-gray"
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    <Flatpickr
                      onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                      options={{
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      startDate !== ""
                        ? "text-white top-[2px]"
                        : "text-gray bottom-[14px]"
                    }`}
                  >
                    Start date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="#82FA00"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* Select your upgrades */}
            <div
              className={`h-[49px] lg:w-[530px] w-full bg-black border border-[#6C6C6C] ${
                toggle ? "rounded-md" : "rounded-t-md"
              } mt-[45px] px-[25px] flex items-center justify-between`}
            >
              <h6 className="gotham font-medium text-sm leading-[215%] uppercase text-white">
                select you upgrades
              </h6>
            </div>
            <div
              className={`${
                toggle ? "h-0 overflow-hidden opacity-0 hidden" : "opacity-100"
              } lg:w-[530px] w-full  transition-transform grid lg:grid-cols-2 grid-cols-1 bg-[#0F0F0F] text-white gap-0 max-w-[796px]`}
            >
              {data?.data?.map((item) => (
                <div className="px-[20px] border   border-[#6C6C6C] pt-[28px] pb-[14px]">
                  {/*  Logo */}
                  <img
                    className="lg:h-[104px] h-[131px] w-full object-cover"
                    src={item?.attributes?.image?.data?.attributes?.url}
                  />
                  <img
                    className="h-[21px] w-auto mt-[20px]"
                    src={item?.attributes?.logo?.data?.attributes?.url}
                  />
                  {/* Description */}
                  <div className="mt-4 ">
                    {item?.attributes?.features?.data?.map((i) => (
                      <div className="flex mb-[12px] gap-[10px] items-center">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.3918"
                            cy="10.6437"
                            r="9.39175"
                            stroke={"#" + item?.attributes?.color}
                          />
                          <path
                            d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"
                            stroke={"#" +item?.attributes?.color}
                          />
                        </svg>
                        <p className="text-[11px] leading-[160%] font-normal gotham">
                          {i?.attributes?.title}
                        </p>
                      </div>
                    ))}
                  </div>
                  {/* Price */}
                  <div className="mt-[20px] flex items-center justify-between">
                    <div className="flex-col">
                      <p className="text-[14px] tracking-widest font-bold text-white">
                        $
                        {parseFloat(
                          item?.attributes?.price?.[0]?.value
                        ).toFixed(2)}
                        /wk
                      </p>
                      <p className="text-[12px] font-normal leading-[160%] opacity-50  gotham text-white">
                        ${item?.attributes?.price?.[1]?.value}.00 per fornight{" "}
                      </p>
                    </div>
                    <div onClick={() => handleCheck(item)}>
                      <Selected
                        style="h-[28px] w-[38px] rounded-[5px] "
                        isPlus={true}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Your membership */}
          {upgrades?.length > 0 && (
            <div className="text-white">
              <div className="w-[339px] mt-6 lg:mt-[70px]">
                <div className="flex-col gap-y-[11px] mt-[15px] pb-5"></div>
                {/* Upgrades */}
                <div className="w-full justify-between flex items-center  mt-3">
                  <h2 className="text-[12px] leading-[160%] pb-[14px] border-b w-full border-b-gray font-normal gotham text-white">
                    Upgrades/Add-ons
                  </h2>
                </div>
                <div className="flex-col gap-y-[11px] pb-5 pr-[40px] border-b border-b-gray">
                  <div className="flex mt-[10px] gap-[10px] flex-col">
                    {upgrades?.map((item) => (
                      <div className="flex items-center justify-between">
                        <h2
                          style={{ color: item?.attributes?.color }}
                          className={`gotham  uppercase text-[12px]  leading-[140%] `}
                        >
                          {item?.attributes?.title}
                        </h2>
                        <span className="gotham  uppercase text-[12px] text-white leading-[140%]">
                          ${item?.attributes?.price[1]?.value}.00
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="py-[14px] flex items-center justify-between border-b border-b-gray">
                  <p className="gotham font-normal text-[12px] text-white">
                    Additional cost per fortnight
                  </p>
                  <span className="text-white gotham text-[12px] pr-[40px]">
                    $30.00
                  </span>
                </div>
                <div className="py-[14px] flex items-center justify-between border-b border-b-gray">
                  <p className="gotham font-normal text-[12px] text-white">
                    Total due today (pro-rate)
                  </p>
                  <span className="text-white gotham text-[12px] pr-[40px]">
                    $5.80
                  </span>
                </div>
                {/* Promo code */}
                <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                  <h5 className="font-normal text-sm text-white gotham">
                    {yourMemberships.promoCodeText}
                  </h5>
                  <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                    <span className="w-[20px] text-white text-[11px] tracking-widest gotham font-normal">
                      CL-
                    </span>
                    <input
                      // onChange={(e) => setCode(e.target.value)}
                      type="text"
                      className="w-full h-full ml-1 border-0 text-brand-clime outline-0 bg-transparent"
                    />
                  </div>
                </div>
                <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                  <h5 className="font-normal text-white gotham text-[12px]">
                    New direct debit starts
                  </h5>
                  <span className="text-white text-[12px] gotham font-normal">
                    01/07/2023
                  </span>
                </div>
                <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                  <h5 className="font-normal gotham text-[12px] text-white ">
                    {yourMemberships.costPerForNightText}
                  </h5>
                  <div className="text-white text-[12px] gotham font-normal leading-[100%]">
                    ${total}.00
                  </div>
                </div>
              </div>
              {/* Terms */}
              <div className="mt-[28px] flex items-start gap-[14px] w-full">
                <CheckboxActive />
                <span className="gotham text-[11px] w-[269px] -mt-1 text-white leading-[160%]">
                  I agree to the terms & conditions and the privacy policy
                  attached to my membership. I understand that I can cancel at
                  anytime with 14 days notice as outlined in the contract.
                </span>
              </div>
              <Link to="/dashboard/upgrade-congrats">
                <div className="mt-[22px]">
                  <div className="w-max">
                    <div className="flex group">
                      <button
                        className={`border-white text-[11px] uppercase px-[35px] border-b text-white gotham text-medium  py-[11px] font-bold flex
                    border-t border-l  
                    rounded-bl-[5px] rounded-tl-[5px]  border-r group-hover:border-brand-clime group-hover:text-brand-clime`}
                      >
                        UPDATE
                      </button>{" "}
                      <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-white text-white group-hover:border-brand-clime group-hover:text-brand-clime ">
                        {arrowRight()}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </DashBoardLayout>
  );
}

export default Upgrade;
