import React, { useContext } from 'react'
import DashBoardLayout from '../../../components/layout/DashboardLayout'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link } from 'gatsby'
import InputBase from '../../../common/inputs/InputBase.tsx';
import Switch from '../../../common/buttons/Switch';
function Notification() {


    return (
        <DashBoardLayout>
            <div className='lg:ml-[60px] lg:ml-0 pb-[24px] mx-6 '>
                <Link to='/dashboard'>
                    <div className=" flex gap-[8px] items-center mt-[34px]">
                        <div className="w-[41px] h-[48px] p-[7px] pl-0">
                            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                                <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                            </div>
                        </div>
                        <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                            dashboard
                        </span>
                    </div>
                </Link>
                {/* Layout */}
                <p className="bebas font-normal text-[40px] lg:text-[55px] text-white leading-[90%] my-[32px]">
                    NOTIFICATIONS
                </p>
                <div className='mt-[32px]'>
                    <h6 className='text-white uppercase tracking-widest gotham text-sm font-medium'>notification settings</h6>
                    <div className='mt-[37px] gap-[44px] flex flex-col'>
                        <div className='flex items-center gap-[25px]'>
                            <Switch initial='off' />
                            <span className='text-white gotham font-normal text-sm leading-[160%]'>Show notifications for milestones</span>
                        </div>
                        <div className='flex items-center gap-[25px]'>
                            <Switch initial='off' />
                            <span className='text-white gotham font-normal text-sm leading-[160%]'>Show notifications for promotional offers</span>
                        </div>
                        <div className='flex items-center gap-[25px]'>
                            <Switch initial='on' />
                            <span className='text-white gotham font-normal text-sm leading-[160%]'>Show notifications for upcoming bookings</span>
                        </div>
                        <div className='flex items-center gap-[25px]'>
                            <Switch initial='on' />
                            <span className='text-white gotham font-normal text-sm leading-[160%]'>Show notifications for club alerts</span>
                        </div>
                        <div className='flex items-center gap-[25px]'>
                            <Switch initial='off' />
                            <span className='text-white gotham font-normal text-sm leading-[160%]'>Show notifications for class buddies bookings</span>
                        </div>
                    </div>
                </div>
            </div>
        </DashBoardLayout>
    )
}

export default Notification