import React from "react";
import noNameAvatar from "../../assets/images/icons/noNameAvatar.png";
import circlePlus from "../../assets/images/icons/plusIcon.svg";

function ModalBase(props) {
  return (
    <div className=" fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-black text-white z-50 border border-white">
      <div className="flex items-center justify-between pt-6 pr-6 pl-[33px] ">
        <div className="flex items-center gap-[15px] " >
          <img src={noNameAvatar} className="inline-block" />
          <span className="uppercase gotham tracking-[0.01em]">hello amy</span>
        </div>
        <img
          className="rotate-45"
          src={circlePlus}
          onClick={() => props.closeModal(false)}
        />
      </div>
      {props.children}
    </div>
  );
}

export default ModalBase;
