import React from "react";
import DashBoardLayout from "../../components/layout/DashboardLayout";
import BookingDetail from "../../components/dashboard/BookingDetail";
function Bookings(props) {
    return (
        <DashBoardLayout {...props}>
            <BookingDetail />
        </DashBoardLayout>
    );
}

export default Bookings;
