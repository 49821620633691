import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import loadingData from "../../assets/images/loading.mp4";
import { Link } from "gatsby";
interface content {
  text: string;
  isReverse: boolean;
}

// Get Distance from current position to Studios
function Distance(pos: number) {
  const distance = pos?.km;
  return <span>{distance?.toFixed(1)} km</span>;
}
function StudiosNearYou({
  text = "find a studio",
  isReverse = false,
}: content) {
  const [param, setParam] = useState<string>("");
  const [studios, setStudios] = useState<[]>([]);
  const [allowed, setAllowed] = useState<boolean>(false);

  const { data: nearYour, loading } = useFetch({
    params: param,
    populate: "image,access,address.state,address.country",
  });
  const { data: allStudios } = useFetch({
    params: "studios",
    populate: "image,access,address.state,address.country",
  });

  // Studios near you
  useEffect(() => {
    const getPost = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getPost()
  }, [])


  // Tracking allowing get the location
  useEffect(() => {
    if (nearYour == null) {
      setAllowed(false);
      setStudios(allStudios);
    } else {
      setAllowed(true);
      setStudios(nearYour);
    }
  }, [nearYour, allStudios]);

  return (
    <>
      {loading ? (
        <video
          style={{ width: "100%", objectFit: "fill", height: "513px" }}
          autoPlay
        >
          <source src={loadingData} type="video/mp4"></source>
        </video>
      ) : (
        <div className="lg:px-[70px] px-[25px]  max-w-[1440px] mx-auto justify-between studios-nearby flex lg:mt-[80px] mt-[50px] lg:mb-[80px] mb-[50px]">
          <div className="lg:w-[23%] w-full">
            <h2 className="lg:text-[60px] text-[40px] leading-[110%] lg:w-[260px] w-full lg:h-[188px] h-[115px] -tracking-[0.03em] font-bold text-white">
              we have{" "}
              <span className="text-brand-orange">{studios?.data?.length}</span>{" "}
              <br className="block lg:hidden" />
              studios near you.
            </h2>
            <Link to="/studio">
              <button
                className={` lg:mt-[222px] mt-0 border-b-[1px] w-[178px] large:w-auto focus:border-b-brand-orange hover:border-white hover:text-white focus:text-white focus:border-white focus:border-b-[5px] font-bold h-[51px] text-[18px] large:px-[32px]  px-[12px] border-t border-l bg-[black] border-r border-[#777777] text-[#777777]`}
              >
                <span className="flex lg:justify-between justify-center">
                  <svg
                    className="mr-4 mt-[2px] hidden lg:block"
                    width="17"
                    height="16.5"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 4.94971L11.8995 4.94971L11.8995 14.8492"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M12 5L1 16"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                  <p className="">{text}</p>
                </span>
              </button>
            </Link>
          </div>
          <div
            className="lg:ml-[40px] ml-0 mt-[32px] lg:mt-0 overflow-x-scroll flex-1 flex lg:gap-x-[40px] gap-[33px]"
            id="scroll"
          >
            {studios?.data?.map((item: object, index: number) => (
              <div
                className="lg:min-w-[324px] min-w-[284px] block cursor-pointer lg:pb-[63px] pb-8 "
                key={index}
              >
                <Link key={index} to={`/studio/${item.id}`}>
                  <img
                    src={
                      allowed
                        ? item?.attributes?.image?.url
                        : item?.attributes?.image?.data?.attributes?.url
                    }
                    className="w-[324px] h-[312px] object-cover"
                    alt="studio"
                  />
                  <div className="mt-[28px] flex items-center justify-between">
                    <h3
                      className={` text-2xl lowercase font-bold + ${isReverse ? "text-white" : "text-brand-orange"
                        }`}
                    >
                      {item?.attributes?.title}
                    </h3>
                    <span
                      className={`text-base white  graphik-regular+ ${isReverse
                        ? "text-brand-orange font-normal text-base"
                        : "text-white font-normal"
                        }`}
                    >
                      {allowed && <Distance km={item?.attributes?.distance} />}
                    </span>
                  </div>
                  <div className="w-full h-[1.5px] mt-[11px] border-b border-b-gray"></div>
                  <div className="mt-[17px] text-white  flex text-base font-normal flex-col gap-2">
                    {allowed ? (
                      <>
                        {item?.attributes?.access?.map((item) => (
                          <span className="graphik-regular">{item?.title}</span>
                        ))}
                      </>
                    ) : (
                      <>
                        {item?.attributes?.access?.data?.map((item) => (
                          <span className="graphik-regular">
                            {item?.attributes?.title}
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>

        </div>
      )}
    </>
  );
}

export default StudiosNearYou;
