import React, { useContext, useState, useEffect } from "react";
import DashBoardLayout from "../../../components/layout/DashboardLayout";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link } from "gatsby";
import SearchClubNearby from "../../../components/dashboard/billing/ChangeClub";
import SelectPlan from "../../../components/dashboard/billing/SelectPlan";
import Upgrade from "../../../components/dashboard/billing/Upgrade";
import ConfirmDetail from "../../../components/dashboard/billing/ConfirmDetail";
import YourPayment from "../../../components/dashboard/billing/YourPayment";
import Congrats from "../../../components/dashboard/billing/Congrats";
function ChangeClub() {
  const [step, setStep] = useState(1);

  return (
    <DashBoardLayout>
      <div className="lg:ml-[60px] mx-6 lg:mr-0 pb-[24px] ">
        {/* Layout */}
        {step == 1 && (
          <>
            <Link to="/dashboard/account/biling">
              <div className=" flex gap-[8px] items-center mt-[34px]">
                <div className="w-[41px] h-[48px] p-[7px] pl-0">
                  <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                    <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                  </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                  billing
                </span>
              </div>
            </Link>
            <div className="lg:w-[796px] mt-[35px]">
              <h6 className="uppercase text-white text-sm leading-[140%] tracking-widest gotham">
                step 1
              </h6>
              <p className="mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white">
                Let’s change your membership
              </p>
              <h6 className="mt-[36px] text-white gotham text-sm leading-[140%] uppercase font-normal pb-[7px] w-full border-b border-b-white">
                Before you Start
              </h6>
              <div className="mt-[21px] flex lg:flex-row flex-col items-center justify-between">
                <p className="lg:w-[489px] text-white gotham text-sm leading-[160%] font-normal">
                  Hello legend, we just wanted to let you know changing your
                  home club will change the cost of your fortnightly membership,
                  please confirm you are happy to proceed.
                </p>
                <div className="flex items-center justify-between lg:mt-0 mt-6 gap-[16px]">
                  <button
                    onClick={() => setStep(2)}
                    className="uppercase text-white border hover:bg-white hover:text-black border-white rounded h-[37px] w-[99px] bg-black gotham text-[11.2px]"
                  >
                    yes
                  </button>
                  <button
                    onClick={() => navigate("/dashboard/account/biling")}
                    className="uppercase text-white border border-white rounded h-[37px] hover:bg-white hover:text-black w-[107px] bg-black gotham text-[11.2px]"
                  >
                    no thanks
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Change home club */}
        {step == 2 && <SearchClubNearby setStep={setStep} />}
        {/* Change Membership plan */}
        {step == 3 && <SelectPlan setStep={setStep} />}
        {/* Add & Confirm Upgrade */}
        {step == 4 && <Upgrade setStep={setStep} />}
        {/* Confirm Detail */}
        {step == 5 && <ConfirmDetail setStep={setStep} />}
        {/* Your Payment */}
        {step == 6 && <YourPayment setStep={setStep} />}
        {/* Congrats */}
        {step == 7 && <Congrats setStep={setStep} />}
      </div>
    </DashBoardLayout>
  );
}

export default ChangeClub;
