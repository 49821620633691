import React, { useEffect, useState } from "react";
import plus from "../../assets/images/memberships/plus.png";
function Checkbox(props: any) {
  let { onChecked, isPlus, style, isBorderBottomColor, isChecked, controlled } =
    props;
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <button
      onClick={() => {
        if (typeof onChecked == "function") {
          onChecked(!isAccepted);
          setIsAccepted(!isAccepted);
        }
      }}
      style={
        isChecked
          ? {
            borderBottom: `2px solid ${isBorderBottomColor ? isBorderBottomColor : "#FF6900"
              }`,
          }
          : !controlled && isAccepted
            ? {
              borderBottom: `2px solid ${isBorderBottomColor ? isBorderBottomColor : "#FF6900"
                }`,
            }
            : {
              borderBottom: `1px solid ${isBorderBottomColor
                ? isPlus
                  ? isBorderBottomColor
                  : "#FF6900"
                : "white"
                }`,
            }
      }
      className={
        `text-white font-bold h-[24px] w-[24px] border-t border-l bg-none border-r border-white flex justify-center items-center ` +
        style
      }
    >
      {isChecked ? (
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          xmlns="http://www.w3.org/2000/svg"
          fill="transparent"
        >
          <path
            d="M1 4.69231L5.2 9L13 1"
            stroke={"white"}
            stroke-width="1.02"
          />
        </svg>
      ) : !controlled && isAccepted ? (
        <svg
          fill="transparent"
          width="14"
          height="10"
          viewBox="0 0 14 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.69231L5.2 9L13 1"
            stroke={isAccepted ? "white" : "hidden"}
            stroke-width="1.02"
          />
        </svg>
      ) : isPlus ? (
        <img src={plus} />
      ) : (
        ""
      )}
    </button>
  );
}

export default Checkbox;
