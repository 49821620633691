import React, { useEffect, useState } from "react";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import { motion } from "framer-motion";

import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
const legalGroup = {
  "privacy policy": {
    description: [
      "This privacy policy applies to the collection, storage, use and disclosure of personal information by or on behalf of VIVA Leisure Limited (ABN 76 607 079 792), VIVA Leisure Operations Pty Ltd (ABN 20 609 536 665), VIVA Leisure Property Pty Ltd (ABN 22 609 536 674), VIVA Leisure People Pty Ltd (ABN 12 609 536 629), Hiit Republic Australia Pty Ltd (ACN: 627 442 353), (referred to in this policy as “Viva”, “our”, “we” or “us”). Please read it carefully.",
      "We are committed to protecting your personal information, and ensuring its privacy, accuracy and security. We handle your personal information in a responsible manner in accordance with the Privacy Act 1988 (Act), the Australian Privacy Principles (APPs) and any State or Territory privacy legislation enacted in your jurisdiction (see https://www.oaic.gov.au for more information).",
      "You do not have to provide us with your personal information. However, if you do not, we may not be able to conduct business with you.",
      "By using any of our products or services, visiting this website (www.clublime.com.au) or giving us your personal information, you agree to your information being collected, stored, used and disclosed as set out in this Privacy Policy.",
    ],
    startDate: "This privacy policy was last updated on September 28, 2020.",
    content: [
      {
        headers: "personal information",
        items: [
          "References to ‘personal information’ or ‘sensitive information’ in this Privacy Policy have the same meaning as in the Act.",
          "In summary: Personal information means information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether true or not, and whether or not recorded in a material form.",
          "Sensitive information (a type of personal information), means information or an opinion about an individual’s race or ethnic origins, political opinions and associations, religious beliefs or affiliations, philosophical beliefs, sexual preferences or practices, trade or professional associations and memberships, union membership, criminal record, health or genetic information or biometric information.",
        ],
      },
      {
        id: "contact",
        headers: "how to contact us",
        items: [
          {
            title:
              "If you have any questions about this Privacy Policy, please contact Viva’s Privacy Officer:",
            content: "",
          },
          { title: "(a) by email to: ", content: "info@vivaleisure.com.au" },
          {
            title: "(b) by writing to: ",
            content: [
              "Privacy Officer",
              "Viva leisure",
              "PO Box 1",
              "Mitchell ACT 2911",
            ],
          },
          { title: "(c) by telephone: ", content: "(02) 6163 8011" },
        ],
      },
    ],
  },
  "term & conditions": {
    description: [
      "This privacy policy applies to the collection, storage, use and disclosure of personal information by or on behalf of VIVA Leisure Limited (ABN 76 607 079 792), VIVA Leisure Operations Pty Ltd (ABN 20 609 536 665), VIVA Leisure Property Pty Ltd (ABN 22 609 536 674), VIVA Leisure People Pty Ltd (ABN 12 609 536 629), Hiit Republic Australia Pty Ltd (ACN: 627 442 353), (referred to in this policy as “Viva”, “our”, “we” or “us”). Please read it carefully.",
      "We are committed to protecting your personal information, and ensuring its privacy, accuracy and security. We handle your personal information in a responsible manner in accordance with the Privacy Act 1988 (Act), the Australian Privacy Principles (APPs) and any State or Territory privacy legislation enacted in your jurisdiction (see https://www.oaic.gov.au for more information).",
      "You do not have to provide us with your personal information. However, if you do not, we may not be able to conduct business with you.",
      "By using any of our products or services, visiting this website (www.clublime.com.au) or giving us your personal information, you agree to your information being collected, stored, used and disclosed as set out in this Privacy Policy.",
    ],
    startDate: "This privacy policy was last updated on September 28, 2020.",
    content: [
      {
        headers: "personal information",
        items: [
          "References to ‘personal information’ or ‘sensitive information’ in this Privacy Policy have the same meaning as in the Act.",
          "In summary: Personal information means information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether true or not, and whether or not recorded in a material form.",
          "Sensitive information (a type of personal information), means information or an opinion about an individual’s race or ethnic origins, political opinions and associations, religious beliefs or affiliations, philosophical beliefs, sexual preferences or practices, trade or professional associations and memberships, union membership, criminal record, health or genetic information or biometric information.",
        ],
      },
    ],
  },
  "membership policy": {
    description: [
      "This privacy policy applies to the collection, storage, use and disclosure of personal information by or on behalf of VIVA Leisure Limited (ABN 76 607 079 792), VIVA Leisure Operations Pty Ltd (ABN 20 609 536 665), VIVA Leisure Property Pty Ltd (ABN 22 609 536 674), VIVA Leisure People Pty Ltd (ABN 12 609 536 629), Hiit Republic Australia Pty Ltd (ACN: 627 442 353), (referred to in this policy as “Viva”, “our”, “we” or “us”). Please read it carefully.",
      "We are committed to protecting your personal information, and ensuring its privacy, accuracy and security. We handle your personal information in a responsible manner in accordance with the Privacy Act 1988 (Act), the Australian Privacy Principles (APPs) and any State or Territory privacy legislation enacted in your jurisdiction (see https://www.oaic.gov.au for more information).",
      "You do not have to provide us with your personal information. However, if you do not, we may not be able to conduct business with you.",
      "By using any of our products or services, visiting this website (www.clublime.com.au) or giving us your personal information, you agree to your information being collected, stored, used and disclosed as set out in this Privacy Policy.",
    ],
    startDate: "This privacy policy was last updated on September 28, 2020.",
    content: [
      {
        headers: "personal information",
        items: [
          "References to ‘personal information’ or ‘sensitive information’ in this Privacy Policy have the same meaning as in the Act.",
          "In summary: Personal information means information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether true or not, and whether or not recorded in a material form.",
          "Sensitive information (a type of personal information), means information or an opinion about an individual’s race or ethnic origins, political opinions and associations, religious beliefs or affiliations, philosophical beliefs, sexual preferences or practices, trade or professional associations and memberships, union membership, criminal record, health or genetic information or biometric information.",
        ],
      },
    ],
  },
  "offers policy": {
    description: [
      "This privacy policy applies to the collection, storage, use and disclosure of personal information by or on behalf of VIVA Leisure Limited (ABN 76 607 079 792), VIVA Leisure Operations Pty Ltd (ABN 20 609 536 665), VIVA Leisure Property Pty Ltd (ABN 22 609 536 674), VIVA Leisure People Pty Ltd (ABN 12 609 536 629), Hiit Republic Australia Pty Ltd (ACN: 627 442 353), (referred to in this policy as “Viva”, “our”, “we” or “us”). Please read it carefully.",
      "We are committed to protecting your personal information, and ensuring its privacy, accuracy and security. We handle your personal information in a responsible manner in accordance with the Privacy Act 1988 (Act), the Australian Privacy Principles (APPs) and any State or Territory privacy legislation enacted in your jurisdiction (see https://www.oaic.gov.au for more information).",
      "You do not have to provide us with your personal information. However, if you do not, we may not be able to conduct business with you.",
      "By using any of our products or services, visiting this website (www.clublime.com.au) or giving us your personal information, you agree to your information being collected, stored, used and disclosed as set out in this Privacy Policy.",
    ],
    startDate: "This privacy policy was last updated on September 28, 2020.",
    content: [
      {
        headers: "personal information",
        items: [
          "References to ‘personal information’ or ‘sensitive information’ in this Privacy Policy have the same meaning as in the Act.",
          "In summary: Personal information means information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether true or not, and whether or not recorded in a material form.",
          "Sensitive information (a type of personal information), means information or an opinion about an individual’s race or ethnic origins, political opinions and associations, religious beliefs or affiliations, philosophical beliefs, sexual preferences or practices, trade or professional associations and memberships, union membership, criminal record, health or genetic information or biometric information.",
        ],
      },
    ],
  },
};

import { createReactEditorJS } from "react-editor-js";
import LoadingScreen from "../common/loading/LoadingScreen";
import SelectOption from "../common/dropdowns/SelectOption";
import dropdownTriangle from "../assets/images/icons/dropdownTriangle.svg";
function loadContent(name: string) {
  const { data, loading } = useFetch({
    params: "term-of-service",
    populate: "item.image",
  });
  const EditorJS = createReactEditorJS();
  const [EDITOR_JS_TOOLS, setTools] = useState<any>();
  useEffect(async () => {
    const { EDITOR_JS_TOOLS } = await import("../utils/Tools");
    setTools(EDITOR_JS_TOOLS);
  }, []);
  return (
    <div>
      {loading && <LoadingScreen />}
      {data && (
        <>
          <div className="text-white text-6xl leading-[66px] -tracking-[0.03em] my-[30px]">
            {name}
          </div>
          <div className="w-full text-white">
            {name == "privacy policy" && (
              <EditorJS
                readOnly={true}
                tools={EDITOR_JS_TOOLS}
                defaultValue={
                  data?.data?.attributes &&
                  JSON.parse(data?.data?.attributes?.privatePolicy)
                }
              />
            )}
            {name == "term & conditions" && (
              <EditorJS
                readOnly={true}
                tools={EDITOR_JS_TOOLS}
                defaultValue={
                  data?.data?.attributes &&
                  JSON.parse(data?.data?.attributes?.termAndConditions)
                }
              />
            )}
            {name == "membership policy" && (
              <EditorJS
                readOnly={true}
                tools={EDITOR_JS_TOOLS}
                defaultValue={
                  data?.data?.attributes &&
                  JSON.parse(data?.data?.attributes?.membershipPolicy)
                }
              />
            )}
            {name == "offers policy" && (
              <EditorJS
                readOnly={true}
                tools={EDITOR_JS_TOOLS}
                defaultValue={
                  data?.data?.attributes &&
                  JSON.parse(data?.data?.attributes?.offersPolicy)
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

function navBar() {
  const [currentTab, setCurrentTab] = useState("privacy policy");
  const [show, setShow] = useState(false);
  const [isShowDropdown, showDropdown] = useState(true);
  Object.keys(legalGroup).map((key) => { });
  return (
    <>
      <div className="lg:flex hidden gap-[10px] ">
        {Object.keys(legalGroup).map((key) => (
          <div
            className={`text-white w-1/4 relative bg-blackSecondary flex justify-center items-center h-[68px] border-b-[6px] border-blackSecondary  transition-all duration-500 cursor-pointer }`}
            onClick={() => setCurrentTab(key)}
          >
            <span className="text-white text-lg font-bold">{key}</span>
            {currentTab === key && (
              <motion.div
                layoutId="underline"
                style={{ height: "5px" }}
                className="absolute top-full w-full bg-brand-orange"
              />
            )}
          </div>
        ))}
      </div>
      <div className="lg:hidden block bg-[#1c1c1c]">
        <div
          className="h-[61px] mx-[12px]  px-[12px] py-[20px] flex justify-between text-brand-orange"
          onClick={() => setShow(!show)}
        >
          {currentTab}
          <img className='h-[15px] w-[15px]' src={dropdownTriangle} />
        </div>
        <div className={`mx-[24px] ${show ? 'show' : 'hidden'}`}>
          {Object.keys(legalGroup).map((key) => (
            <div
              className={`text-white py-[18px] cursor-pointer `}
              onClick={() => {
                setCurrentTab(key);
                setShow(!show);
              }}
            >
              {key}
            </div>
          ))}
        </div>
      </div>
      <div className="mx-[4.9%]">{loadContent(currentTab)}</div>
    </>
  );
}

function Legal() {
  return (
    <Layout>
      <div className="max-w-[1440px] mx-auto">
        {navBar()}
        <div className="mx-[4.9%] my-12">
          <ButtonNavigate
            text="download pdf"
            style="h-[56px] w-[188px] border-b border-[#777777] text-[#777777]"
          ></ButtonNavigate>
        </div>
      </div>
    </Layout>
  );
}

export default Legal;
