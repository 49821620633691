import React, { useContext, useEffect, useState } from "react";
import ButtonActive from "../../common/buttons/Active";
import InputBase from "../../common/inputs/InputBase.tsx";
import { RegisterContext } from "../../context/RegisterContext";
import { newLetterAPI } from "../../utils/fetchData";
import { navigate } from "@reach/router";
function GetYourFreePass() {
  const setOpen = useContext(RegisterContext).setOpen;
  const yourClub = useContext(RegisterContext).yourClub;
  const [form, setForm] = useState({
    app: "NEWSLETTER",
    venue_id: 106,
    source_name: "Newsletter",
    gender: "male",
    source_group: "Website",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const [formControlled, setFormControlled] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
    app: true,
    venue_id: true,
    source_group: true,
    gender: true,
  });
  const [errText, setErrText] = useState({ email: "" });
  const [msg, setMsg] = useState(false);

  const submit = async () => {
    let control = { ...formControlled };

    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
    if (Object.values(control).every((e) => e === false)) {
      await newLetterAPI(form);
      setMsg(true);
      setForm({
        ...form,
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
      });
    }
  };

  return (
    <div className="text-white max-w-[1440px] mx-auto lg:px-[70px] px-[25px] flex getYourFreePass justify-between mt-[0px] mb-[40px]">
      <div className="lg:flex flex-col lg:text-[60px] text-[40px] leading-[110%] lg:w-[40%] w-full">
        <h2 className="text-white font-bold">get your</h2>
        <h2 className="text-brand-orange font-bold">free pass.</h2>
      </div>
      <div className="flex-1">
        <h3 className="lg:text-[24px] text-[16px] mt-4 lg:mt-0 font-bold mb-[30px]">
          <span className="text-white">i want to visit</span>

          {yourClub ? (
            <span className="text-brand-orange mx-2 lowercase">
              {yourClub?.attributes?.title},{" "}
              {yourClub?.attributes?.address?.Name
                ? yourClub?.attributes?.address?.Name
                : yourClub?.attributes?.address?.data?.attributes?.Name}

            </span>
          ) : (
            <span className="mx-1"></span>
          )}

          <span
            className="text-gray underline graphik-regular text-base"
            onClick={() => {
              setOpen(true);
            }}
          >
            {!yourClub ? 'select' : 'change'}
          </span>
        </h3>
        <div className="grid grid-rows-4 overflow-hidden lg:grid-rows-2 grid-flow-col gap-x-[50px] gap-y-[12px]">
          <InputBase
            width="100%"
            type="string"
            holder="First name*"
            nameInput="first_name"
            value={form.first_name}
            isRequired={true}
            isShowRequired={formControlled.first_name}
            onchange={(e) => setForm({ ...form, first_name: e.target.value })}
          />
          <InputBase
            width="100%"
            nameInput="last_name"
            type="string"
            value={form.last_name}
            holder="Last name*"
            isRequired={true}
            isShowRequired={formControlled.last_name}
            onchange={(e) => setForm({ ...form, last_name: e.target.value })}
          />
          <InputBase
            width="100%"
            nameInput="email"
            type="email"
            value={form.email}
            holder="Email address*"
            errorText={errText.email}
            isRequired={true}
            isShowRequired={formControlled.email}
            onchange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <InputBase
            width="100%"
            value={form.phone_number}
            nameInput="phone_number"
            type="string"
            holder="Phone number*"
            isRequired={true}
            isShowRequired={formControlled.phone_number}
            onchange={(e) => setForm({ ...form, phone_number: e.target.value })}
          />
        </div>
        <div className="flex  justify-between items-center">
          <p className="text-gray text-sm graphik-regular">Required*</p>
          <div
            className="float-right mt-[28px] flex flex-col items-end"
            onClick={() => {
              submit();
            }}
          >
            <ButtonActive text="lets go" height={53} />
            {msg && (
              <p className="text-brand-orange mt-[10px]">
                Thanks our hiit squad will be in touch
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetYourFreePass;
