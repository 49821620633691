import React, { useContext, useState } from 'react'
import DashBoardLayout from '../../../components/layout/DashboardLayout'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link } from 'gatsby'
import plus from "../../../assets/images/memberships/plus.png";
import InputBase from '../../../common/inputs/InputBaseDashBoard';
function Account() {
    const [avatar, setAvatar] = useState(null)

    const handleChangeAvatar = async (e: any) => {
        const file = e.target.files[0];
        if (file) setAvatar(file);
    };


    return (
        <DashBoardLayout>
          <div className='lg:ml-[60px] lg:mr-0 pb-[24px] ml-6 mr-6 '>
            <Link to='/dashboard'>
              <div className=" flex gap-[8px] items-center mt-[34px]">
                <div className="w-[41px] h-[48px] p-[7px] pl-0">
                  <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                    <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                  </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                  dashboard
                </span>
              </div>
            </Link>
            {/* Layout */}
            <div className='flex lg:items-center lg:w-[850px] w-full lg:flex-row flex-col justify-between '>
              <p className="bebas font-normal text-[55px] text-white leading-[90%] my-[32px]">
                ACCOUNT
              </p>
              <div>
                <h6 className='gotham text-white text-[11px] font-normal mb-2 '>Your membership includes:</h6>
                <div className='flex items-center gap-[16px] flex-wrap'>
                  <button
                    className=' px-[14px] h-[32px] leading-[215%] font-medium rounded-[4px] bg-brand-clime uppercase flex items-center justify-center text-[12.1px] gotham text-black'>
                    CLUB LIME
                  </button>
                  <button
                    className='px-[14px] h-[32px] leading-[215%] font-medium rounded-[4px] bg-brand-orange uppercase flex items-center justify-center text-[12.1px] gotham text-black'>
                    HIIT REPUBLIC
                  </button>
                  <button
                    className='px-[14px] h-[32px] leading-[215%] font-medium rounded-[4px] bg-[#A4ACD7] uppercase flex items-center justify-center text-[12.1px] gotham text-black'>
                    GROUND UP
                  </button>
                  <div className='flex items-center gap-[10px]'>
                    <button className='h-[30px] w-[30px] rounded border border-white flex items-center justify-center'>    <img src={plus} /></button>
                    <span className='uppercase text-white gotham text-[12.3px]'>upgrade</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Membership Details */}
            <div className='mt-[34px] lg:w-[850px] pb-[54px] lg:h-[264px] border border-gray rounded bg-[#0F0F0F]'>
              <div className='h-[57px] px-[27px] bg-[#0F0F0F] border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase'>Membership details</div>
              <div className='grid lg:grid-cols-2 grid-cols-1 mt-[33px] gap-y-[14px] px-[27px]'>
                <InputBase isDashboard={true} holder="Home Club" style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Membership no." style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Membership level" style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Upgrades" style="lg:w-full" isRequired={true} />
              </div>
            </div>
            {/* Member Info*/}
            <div className='mt-[45px] lg:w-[850px]  pb-[54px] lg:h-[264px] border border-gray rounded bg-[#0F0F0F]'>
              <div className='h-[57px] px-[27px] bg-[#0F0F0F] border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase'>Membership info</div>
              <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-[14px]  mt-[33px] px-[27px]'>
                <InputBase isDashboard={true} holder="First name" style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Last name" style="lg:w-full" isRequired={true} />
              </div>
              <div className='px-[27px] mt-[14px]'>
                <InputBase isDashboard={true} holder="DOB" style="w-full" isRequired={true} />
              </div>
            </div>
    
            {/* Contact details*/}
            <div className='mt-[45px] lg:w-[850px] pb-[54px] lg:h-[427px] border border-gray rounded bg-[#0F0F0F]'>
              <div className='h-[57px]  bg-[#0F0F0F] border-b border-b-white text-white  flex items-center justify-between px-[27px]'>
                <div className='tracking-widest gotham text-sm  flex items-center uppercase'>contact detail</div>
                <span className='gotham text-start text-white font-normal'>Edit</span>
              </div>
              <label >
                <div className='flex items-center px-[27px] mt-[28px] gap-[16px]'>
                  <div className='h-[53px] border border-white w-[53px] overflow-hidden rounded-[50%]'>
                    <img className='w-full h-full object-cover' src={avatar ? URL.createObjectURL(avatar) : null} />
                  </div>
                  <span className='gotham text-sm text-white font-normal'>Upload new photo
                  </span>
                  <input className='hidden' type="file" onChange={handleChangeAvatar} />
                </div>
              </label>
              <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-[14px]  mt-[28px] px-[27px]'>
                <InputBase isDashboard={true} holder="Email address*" style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Phone number*" style="lg:w-full" isRequired={true} />
              </div>
              <div className='px-[27px] mt-[14px]'>
                <InputBase isDashboard={true} holder="Postal address*" style="w-full" isRequired={true} />
              </div>
              <div className='px-[27px] mt-[24px]'>
                <h6 className='text-white gotham text-[11px] font-normal opacity-50'>Upload ID</h6>
                <div className='mt-[15px] flex items-center gap-[15px]'>
                  <div className='w-[30px] cursor-pointer h-[30px] rounded border border-white flex items-center justify-center '><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                    <path d="M4.02828 3.85485L1.08672 0.91328L0.0684861 1.93151L3.01005 4.87308L0.096771 7.78636L1.14329 8.83288L4.05657 5.9196L6.99813 8.86116L8.01637 7.84293L5.0748 4.90136L7.98808 1.98808L6.94156 0.941565L4.02828 3.85485Z" fill="white" />
                  </svg></div>
                  <span className='text-white gotham font-normal text-sm'>Drivers-License.jpeg</span>
                </div>
              </div>
            </div>
    
            {/* Emergency contact*/}
            <div className='mt-[45px] lg:w-[850px] pb-[54px] lg:h-[264px] border border-gray rounded bg-[#0F0F0F]'>
              <div className='h-[57px]  bg-[#0F0F0F] border-b border-b-white text-white  flex items-center justify-between px-[27px]'>
                <div className='tracking-widest gotham text-sm  flex items-center uppercase'>emergency contact</div>
                <span className='gotham text-start text-white font-normal'>Edit</span>
              </div>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-[14px]  mt-[33px] px-[27px]'>
                <InputBase isDashboard={true} holder="Emergency contact" style="lg:w-full" isRequired={true} />
                <InputBase isDashboard={true} holder="Emergency phone*" style="lg:w-full" isRequired={true} />
              </div>
              <div className='px-[27px] mt-[14px]'>
                <InputBase isDashboard={true} holder="Relation to you*" style="w-full" isRequired={true} />
              </div>
            </div>
    
    
          </div>
        </DashBoardLayout>
      )
}

export default Account