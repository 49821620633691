import { Link } from 'gatsby';
import React, { useRef, useState } from 'react';
import Layout from '../../components/layout/Layout';
import useFetch from '../../hooks/useFetch';
import { Location } from '@reach/router'
import LoadingScreen from '../../common/loading/LoadingScreen';
import ArrowRight from "../../assets/images/icons/arrowRight.svg";
import { createReactEditorJS } from 'react-editor-js';
import { useEffect } from 'react';

function BlogDetail() {
  const [id, setId] = useState<number>()
  const [selected, setSelected] = useState<number>(0)

  const { data, loading } = useFetch({ params: `blogs/${id}`, populate: 'thumbnail,catagory,quotes' });
  const EditorJS = createReactEditorJS()
  const [EDITOR_JS_TOOLS, setTools] = useState<any>()

  useEffect(async () => {
    const { EDITOR_JS_TOOLS } = await import("../../utils/Tools")
    setTools(EDITOR_JS_TOOLS)
  }, [])
  return (
    <Layout>
      <Location>
        {({ location }) => {
          setId(location?.state?.id)
          return null
        }}
      </Location>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <div className="mt-[29px]">
          <div className="max-w-[1297px] mx-auto pl-0">
            {/* Breadcrumb */}
            <div className="flex items-center  cursor-pointer graphik-regular text-[16px] font-normal text-gray ">
              <Link to="/blog">
                <p className="border-b-[0.1px] border-b-gray font-normal ml-[12px] lg:ml-0 pb-[0.3px] graphik-regular">Blog / </p>
              </Link>
              <p className="ml-1 border-b-[0.1px] border-b-gray pb-[0.3px] font-normal graphik-regular">
                {data?.data?.attributes?.title}
              </p>
            </div>
            {/* Intro */}
            <div className="lowercase text-center mt-[54px] mx-auto tracking-tight font-bold leading-[97.5%] text-[61.8px]">
              {/* <h3 className="outline-text-1">how too adjust your</h3> */}
              <h3 style={{ color: 'white' }} className="first-line:text-transparent outline-text-1 max-w-[678px] mx-auto">       {data?.data?.attributes?.title}</h3>
              <span className="mt-[88px] font-bold text-[20px] lowercase text-brand-orange">
                by leah k , personal trainer
              </span>
            </div>

          </div>

          <div className='mt-[58px] w-full px-[17px] md:px-0'>
            <EditorJS tools={EDITOR_JS_TOOLS} readOnly defaultValue={(data?.data?.attributes && JSON.parse(data?.data?.attributes?.content))} />
          </div>
          <div className="w-full mx-auto py-[44px] border-y border-y-brand-orange">
            <h6 className="text-white text-center graphik-regular text-sm font-normal">Share this post</h6>
            <div className="mt-[10px] flex items-center justify-center gap-[14px]">
              <div className="text-white flex items-center gap-3 cursor-pointer">
                <span>
                  <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="19.5" stroke="white" />
                    <path
                      d="M24.2806 11.1336V14.0796H22.5941C21.2728 14.0796 21.0259 14.7381 21.0259 15.6865V17.7956H24.1733L23.7546 21.0987H21.0259V29.5684H17.739V21.0987H15V17.7956H17.739V15.363C17.739 12.5398 19.4041 11 21.8317 11C22.9915 11 23.9911 11.0893 24.2812 11.1336H24.2806Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>
                  <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="19.5" stroke="white" />
                    <path
                      d="M15.0898 16.3236V27.3821H11.2227V16.3236H15.0898ZM15.3358 12.9083C15.3478 13.9682 14.504 14.8166 13.1562 14.8166H13.133C11.8325 14.8166 11 13.9682 11 12.9083C11 11.8256 11.867 11 13.1795 11C14.504 11 15.3238 11.8256 15.3358 12.9083ZM29 21.0437V27.3821H25.1442V21.4679C25.1442 19.9838 24.5817 18.9682 23.1875 18.9682C22.121 18.9682 21.488 19.6488 21.2067 20.3073C21.113 20.553 21.0778 20.8765 21.0778 21.2115V27.3828H17.222C17.2692 17.362 17.222 16.3243 17.222 16.3243H21.0778V17.9312H21.0545C21.5585 17.1727 22.4727 16.0679 24.5585 16.0679C27.1017 16.0679 29 17.6527 29 21.0451V21.0437Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>
                  <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="19.5" stroke="white" />
                    <path
                      d="M28.0474 14.7305C27.5711 15.4447 26.9764 16.081 26.2951 16.5938C26.3062 16.7502 26.3062 16.9066 26.3062 17.0623C26.3062 21.8271 22.7907 27.3169 16.3654 27.3169C14.3861 27.3169 12.5466 26.7256 11 25.6986C11.2811 25.7322 11.5518 25.7436 11.8439 25.7436C13.4771 25.7436 14.9808 25.1744 16.1819 24.2039C14.6457 24.1703 13.3587 23.1326 12.9149 21.7043C13.1316 21.7379 13.3476 21.76 13.5747 21.76C13.8883 21.76 14.2019 21.715 14.4941 21.6372C12.8935 21.3022 11.6923 19.8518 11.6923 18.0999V18.055C12.1575 18.3228 12.6982 18.4899 13.2715 18.5127C12.3306 17.8657 11.7138 16.7609 11.7138 15.5111C11.7138 14.8412 11.8868 14.2278 12.1901 13.6921C13.9098 15.8789 16.4955 17.3079 19.3942 17.4636C19.3402 17.1958 19.3077 16.9166 19.3077 16.6381C19.3077 14.652 20.8654 13.0337 22.8018 13.0337C23.8077 13.0337 24.7167 13.4686 25.3543 14.1721C26.1442 14.0157 26.9009 13.7143 27.5717 13.3015C27.3121 14.1385 26.7604 14.8412 26.0355 15.2876C26.7389 15.2097 27.4201 15.0083 28.0474 14.7298V14.7305Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>
                  <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="19.5" stroke="white" />
                    <rect x="11" y="13" width="18" height="14" rx="1" stroke="white" />
                    <path
                      d="M11 14L19.3598 20.9665C19.7307 21.2756 20.2693 21.2756 20.6402 20.9665L29 14"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M22 20L28 26" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18 20L12 26" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {/* Navigation Mobile */}
          <div className='lg:hidden mt-[37px] text-white flex items-center justify-center'>
            <div className="mx-auto pb-[20px] flex justify-around border-b-[5px] w-[106px] border-b-brand-orange">
              <button >
                <img className="-rotate-[180deg]" src={ArrowRight} />
              </button>
              <button >
                <img src={ArrowRight} />
              </button>
            </div> </div>
          <div className="mt-[102px] hidden lg:flex max-w-[1296px pb-[33px] mx-auto pl-[73px] pr-[69px] justify-between flex">
            <div>
              <h2 className="text-white mb-[64px] lowercase font-bold text-[40px]">find your best self</h2>
              <button
                className={`text-white h-[56px] flex items-center justify-center  border-b-[6px] border-b-brand-orange font-bold w-[60px] border-t border-t-white bg-[black] border-x border-x-white`}
              >
                <div className="rotate-[180deg] transform translate-x-[-3px]">
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767" stroke="white" stroke-width="1.3261" />
                    <path d="M15.9743 11.1309H0.998047" stroke="white" stroke-width="1.3261" />
                  </svg>
                </div>
              </button>
            </div>
            <div className="flex flex-col justify-end items-end">
              <h2 className="text-white mb-[31px] lowercase font-bold text-[40px] max-w-[399px] tracking-tight leading-[110%] text-right">
                and inside look at our 10 new locations
              </h2>
              <button
                className={`text-white h-[56px] flex items-center justify-center  border-b-[6px] border-b-brand-orange font-bold w-[60px] border-t border-t-white bg-[black] border-x border-x-white`}
              >
                <div className="transform translate-x-[3px]">
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767" stroke="white" stroke-width="1.3261" />
                    <path d="M15.9743 11.1309H0.998047" stroke="white" stroke-width="1.3261" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className="lg:mt-[83px] mt-[45px] hidden  lg:flex border-y-[1px] border-y-brand-orange overflow-hidden  border-l-transparent h-[607px] ">
            <div className="min-w-[176px] flex">
              <li className="w-full border-[1px] relative">
                <p className="text-2xl font-bold  text-[61.8px] tracking-tight text-white absolute leading-[97%] w-max bottom-[0px] outline-text-1  left-[80px]  origin-left -rotate-90 ">
                  {data?.data?.attributes?.quotes?.[0]?.title}
                </p>
              </li>
            </div>
            <ul className="flex flex-1">
              {data?.data?.attributes?.quotes?.slice(1, data?.data?.attributes?.quotes?.length)?.map((item: object, index: number) => (
                <li
                  onClick={() => setSelected(index)}
                  key={index}
                  className={`w-[86px] border-l-[1px] relative border-l-brand-orange transition-all ${selected == index ? 'w-[740px]' : 'h-auto'}  `}>
                  <p className="text-[20px] font-bold  text-white relative w-full h-full lowercase text-center ">
                    {selected !== index && (
                      <span className='origin-left -rotate-90 absolute left-1/2 bottom-[188px] w-[200px] text-white'>
                        {item?.title}
                      </span>
                    )}
                  </p>
                  {selected == index && (
                    <div className='w-full h-full absolute left-0 top-0'>
                      <h2 className="font-bold max-w-[566px]  text-left absolute top-[47px] left-[27px] lowercase  tracking-tight text-[40px]  leading-[110%] text-white">
                        {item?.content}
                      </h2>
                      <h4 className="text-white absolute left-[40px] bottom-[75px] tracking-tight lowercase font-bold text-[40px] ">
                        {item?.title}
                      </h4>
                      <span className="font-bold  graphik-regular w-full text-left absolute  bottom-[40px] left-[40px] lowercase  tracking-tight text-[16px] text-sm  leading-[110%] text-white">{item?.subtitle}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* Mobile */}
          <div className="lg:mt-[83px] mt-[45px]  flex lg:hidden flex-col  border-l-transparent w-full h-fit">
            <div className="flex h-[83px] border-t border-t-brand-orange">
              <li className="w-full border-[1px]">
                <p className="text-[40px] font-bold h-full w-full tracking-tight text-white absolute leading-[97%] text-center  outline-text-1">
                  {data?.data?.attributes?.quotes?.[0]?.title}
                </p>
              </li>
            </div>
            <ul className="flex flex-1 flex-col">
              {data?.data?.attributes?.quotes?.slice(1, data?.data?.attributes?.quotes?.length)?.map((item: object, index: number) => (
                <li
                  onClick={() => setSelected(index)}
                  key={index}
                  className={`w-full border-y-[0.3px] border-y-brand-orange py-[19px] relative transition-all ${selected == index ? 'h-[450px]' : 'h-auto'}  `}>
                  <p className="text-[20px] font-bold  text-white w-full h-full lowercase text-center ">
                    {selected !== index && (
                      <span className='w-full text-center text-white'>
                        {item?.title}
                      </span>
                    )}
                  </p>
                  {selected == index && (
                    <div className='w-full px-[26px] py-[37px] absolute left-0  bottom-0 '>
                      <h2 className="font-bold max-w-[324px] text-left  lowercase tracking-tight text-[30px]  leading-[110%] text-white">
                        {item?.content}
                      </h2>
                      <h4 className="text-white tracking-tight mt-[10px] pb-[15px] lowercase font-bold text-[30px] ">
                        {item?.title}
                      </h4>
                      <span className="font-bold  graphik-regular w-full text-left lowercase  tracking-tight text-[16px] text-sm  leading-[110%] text-white">{item?.subtitle}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BlogDetail;
