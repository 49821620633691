import React, { useState } from "react";
import rebelImg from "../../assets/images/dashboard/rebel.png";
import { benefits } from "../../../mock/dashboard";
import arrowLeftShort from "../../assets/images/icons/arrowLeftShort.svg";
import ButtonNavigate from "../../common/buttons/ButtonNavigateDashBoard";
function BenefitDetail() {
  const [list, setList] = useState(true);
  const [promo, setPromo] = useState();

  return (
    <>
      {list ? (
        <div className="md:mx-[60px] mx-6 pb-[80px]">
          <div className=" flex gap-[8px] items-center mt-[34px]">
            <div className="w-[41px] h-[48px] p-[7px] pl-0">
              <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
              </div>
            </div>
            <span className="gotham text-sm leading-[19px] text-white tracking-widest">
              Dashboard
            </span>
          </div>
          <p className="bebas font-normal text-[55px] text-white leading-[90%] my-[32px]">
            Member benefits
          </p>
          <p className="gotham text-sm font-medium text-white mb-[15px] tracking-widest">
            Exclusive offers for all Viva members
          </p>
          <div className="text-white grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-max ">
            {benefits.map((i, index) => (
              <div
                key={index}
                className={`w-[265px] h-[338px] px-[20px] pt-[28px] pb-[17px]  border-gray border bg-[#0F0F0F] ${
                  index === 0
                    ? "rounded-tl-[5px]"
                    : index === 2
                    ? "rounded-tr-[5px]"
                    : index === 3
                    ? "rounded-bl-[5px]"
                    : index === 5
                    ? "rounded-br-[5px]"
                    : ""
                }`}
              >
                <img src={i.img} />
                <img
                  src={rebelImg}
                  className="w-[74px] h-[38px] object-fill mt-[6px]"
                />
                <div>
                  <p className="gotham text-xs tracking-widest my-[9px]">
                    {i.title}
                  </p>
                  <p className="gotham text-[11px] leading-[160%] mb-[17px] ">
                    {i.description}
                  </p>
                  <button
                    className="gotham text-[12px] font-medium border-white border rounded-[5px] px-[20px] pt-[7px] pb-[9px] uppercase "
                    onClick={() => {
                      setPromo(i);
                      setList(false);
                    }}
                  >
                    Claim offer
                  </button>
                  <p className="gotham mt-[13px] text-[10px] text-gray">
                    {i.note}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="xl:mx-[60px] mx-[24px] xl:pb-[80px] ">
          <div className=" flex gap-[8px] items-center mt-[34px]">
            <div className="w-[41px] h-[48px] p-[7px] pl-0">
              <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
              </div>
            </div>
            <span className="gotham text-sm leading-[19px] text-white tracking-widest">
              Member benefits
            </span>
          </div>
          <div className="xl:w-[797px] xl:h-[467px]  border-gray border bg-[#0F0F0F] rounded-[5px] px-[40px] py-[40px]">
            <div className="h-[71%]  xl:flex ">
              <div className="xl:w-[60%] w-full flex flex-col justify-between">
                <div>
                  <img className="w-[123px] h-[64px]" src={rebelImg} />
                  <p className="text-sm gotham text-white mt-[7px]">
                    Rebel Sport
                  </p>
                </div>
                <div>
                  <p className="gotham text-sm tracking-widest font-medium text-white mb-[11px]">
                    {promo?.title}
                  </p>
                  <p className="gotham text-sm  font-medium text-white mb-5">
                    {promo?.descriptionFull}
                  </p>
                </div>
              </div>
              <div className="xl:w-[40%] w-full">
                <img className=" w-full h-full object-cover" src={promo?.img} />
              </div>
            </div>
            <div className="h-[29%]  pt-[35px] text-white">
              <p className="gotham text-sm ">Your Exclusive discount code:</p>
              <div className="flex mt-[21px] flex justify-between">
                <div>
                  <button className="gotham text-[12px] font-medium mr-[20px] border-white border rounded-[5px] px-[20px] pt-[11px] pb-[11px] uppercase ">
                    CL-x-RS-2021
                  </button>
                  <span className="gotham underline text-gray text-sm ">
                    copy code
                  </span>
                </div>
                <ButtonNavigate
                  isArrowRight={true}
                  isInSlider={true}
                  style="py-[9px] md:text-xs"
                  text="VISIT SITE"
                >
                  {" "}
                </ButtonNavigate>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BenefitDetail;
