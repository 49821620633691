import React, { useEffect, useState } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";
interface InputType {
  holder: string;
  label: string;
  type: string;
  nameInput: string;
  onchange: any;
  isActiveData: boolean;
  img: any;
  isRequired: boolean;
  isShowRequired: boolean;
  errorText: string;
  width: string;
  style: string;
  value: string
}
function InputBase({
  holder,
  label,
  type,
  onchange,
  nameInput,
  isActiveData = false,
  img = null,
  isRequired = false,
  isShowRequired = false,
  value,
  errorText = "",
  width = "",
  style = "",
}: InputType) {
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    return e.target.value === "" || value == ''
      ? (setActive(false),
        (e.target.style.borderColor = ""),
        (e.target.style.color = ""),
        (e.target.style.caretColor = ""))
      : (setActive(true),
        (e.target.style.borderColor = "#FF7900"),
        (e.target.style.caretColor = "#FF7900"),
        (e.target.style.color = "#FF7900"));
  };

  // Cleanup the form
  useEffect(() => { value === '' ? setActive(false) : setActive(true) }, [value])

  return (
    <div
      style={{
        width: `${width !== "" ? `${width}` : style == "" ? "373px" : ""}`,
      }}
      className={` relative ${style}`}
    >
      <label>
        <span className="after:content-[''] after:ml-0.5 after:text-white block text-[12px] graphik-regular font-medium text-white">
          {active ? "" : ""}
        </span>
        <span
          className={`absolute  block  graphik-regular font-medium  transition-all duration-300 ${active ? "top-0 left-0 text-white text-xs" : "top-[39px] left-7 text-gray text-[16px]"
            }`}
        >
          {holder}
        </span>
        {isRequired ? (
          <div
            style={{ borderColor: active ? "#FF7900" : "" }}
            className="flex inputBorder pl-6 items-center mt-1 border border-[#FFFFFF]"
          >
            <input
              onChange={onchange}
              type={type}
              value={value}
              onInput={(e) => {
                activeValue(e);
              }}
              name={nameInput}
              className="mt-0  w-[55%] placeholder:text-white py-[15px] bg-transparent graphik-regular  placeholder-slate-400 block sm:text-base focus:outline-none"
              placeholder={""}
            />

            <div className="graphik-regular absolute right-3 font-normal text-xs text-brand-orange">
              {(isShowRequired && !active) || errorText !== "" ? (
                <>
                  {errorText !== "" ? errorText : "This Field is required"}
                  <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <input
            onChange={onchange}
            type={type}
            value={value}
            onInput={(e) => {
              activeValue(e);
            }}
            name={nameInput}
            className="mt-1 px-6 placeholder:text-white py-4 bg-black border graphik-regular border-[#FFFFFF] placeholder-slate-400  focus:outline-none  block w-full sm:text-sm focus:ring-1"
            placeholder={""}
          />
        )}

        {img}
      </label>
    </div>
  );
}

export default InputBase;
