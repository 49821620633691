import React, { useEffect, useState } from 'react';
function CheckboxActive(props: any) {
  const { onChecked } = props;
  const [isAccepted, setIsAccepted] = useState(false);
  useEffect(() => {
    if (typeof onChecked == 'function') {
      onChecked(isAccepted);
    }
  }, [isAccepted]);

  return (
    <button
      onClick={() => setIsAccepted(!isAccepted)}
      style={isAccepted ? { border: '1px solid #FF6900' } : { border: '1px solid white' }}
      className={`text-white font-bold rounded h-[30px] w-[30px] border-t border-l bg-[black] border-r border-white flex justify-center items-center`}
    >
      {isAccepted ? (
        <svg width="14" height="10" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.69231L5.2 9L13 1" stroke={isAccepted ? '#FF6900' : 'hidden'} stroke-width="1.02" />
        </svg>
      ) : (
        ''
      )}
    </button>
  );
}

export default CheckboxActive;
