import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import StudioDetail from "../../components/studios/StudioDetail";

function studioId(props: any) {
  //   const test = useLocation();

  return (
    <Layout>
      <StudioDetail id={props}></StudioDetail>
    </Layout>
  );
}

export default studioId;
