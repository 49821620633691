import React, { useEffect, useState, useContext } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Script } from "gatsby";
import CheckedMark from "../../assets/images/icons/CheckedMark.svg";
import ButtonActive from "../../common/buttons/Active";
import axios from "axios";
import { RegisterContext } from "../../context/RegisterContext";

export const ChangeYourHomeClub: React.FC = () => {
  const [address, setAddress] = useState("");
  const [isGetAll, setIsGetAll] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [clubNearby, setClubNearby] = useState<[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const access = useContext(RegisterContext).access;
  const setYourClub = useContext(RegisterContext).setYourClub;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;

  // Select address
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setStep(2);
    setCoordinates(latLng);
  };

  const handleClose = () => {
    setIsChangeClub(false);
  };

  // Get Clubs by distance
  useEffect(() => {
    if (coordinates.lat !== null) {
      const url = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${coordinates.lng}&location[]=${coordinates.lat}&distance=12089566&populate=image,access,address.state,address.country&pagination`;
      axios.get(url).then((res) => {
        setClubNearby(res?.data?.data);
      });
    }
  }, [coordinates]);

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <>
          <div className="fixed w-full top-0 left-0 overflow-y-scroll scrollbar-map h-full z-10 bg-black">
            <div className="max-w-[1440px] mx-auto pt-[25px] bg-black w-full h-full">
              <div className="flex items-start flex-col lg:pl-[75px] px-6 lg:px-0">
                <div className="lg:flex-row flex-col flex items-start lg:items-center w-full lg:mt-[157px] lg:mb-[111px] lg:mb-[94px]  ">
                  <div className="flex  ">
                    <div
                      onClick={handleClose}
                      className="rotate-180 h-[33px] w-[33px] rounded-[50%] flex items-center justify-center border border-brand-orange"
                    >
                      <span>
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.3145 9.26074L25.3145 16.2607L18.3145 23.2607"
                            stroke="#FF6900"
                            stroke-width="1.5"
                          />
                          <path
                            d="M25 16.0532L0 16.0532"
                            stroke="#FF6900"
                            stroke-width="1.5"
                          />
                        </svg>
                      </span>
                    </div>
                    <p className="text-white text-lg font-bold ml-6 lg:hidden">
                      Home club
                    </p>
                  </div>
                  <p className="text-white text-[52px]  w-full lg:-mt-2 mt-[90px] lg:mb-0 mb-[70px] lg:ml-[19px] font-bold leading-[110%] tracking-tight lowercase">
                    {step == 1
                      ? "change your home club"
                      : `${
                          clubNearby?.length < 9 && clubNearby?.length !== 0
                            ? "0"
                            : ""
                        }${clubNearby?.length} clubs nearby`}
                  </p>
                </div>
                {/* Search box */}
                <div className="lg:ml-[37px] w-full ">
                  <div className="flex items-center w-full">
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                      }) => (
                        <div className="flex flex-col lg:w-[794px] w-full">
                          <div>
                            <div className="flex border-b border-gray min-w-full items-center relative justify-between">
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 25 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                {" "}
                                <line
                                  x1="15.8912"
                                  y1="14.8002"
                                  x2="24.1415"
                                  y2="22.2113"
                                  stroke={"#FF6900"}
                                  stroke-width="1.57426"
                                />
                                <circle
                                  cx="10.0022"
                                  cy="10.3639"
                                  r="7.15275"
                                  transform="rotate(-72.0294 10.0022 10.3639)"
                                  stroke={"#FF6900"}
                                  stroke-width="1.57426"
                                />
                              </svg>
                              <input
                                className="bg-transparent  py-[15px] ml-[15px] graphik-regular font-normal w-full text-sm leading-[160%] text-white outline-none"
                                {...getInputProps({ placeholder: "" })}
                              />
                              <span
                                onClick={() => setAddress("")}
                                className="absolute bottom-1/2 translate-y-1/2 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin"
                              >
                                <svg
                                  onClick={() => setAddress("")}
                                  width="41"
                                  height="41"
                                  viewBox="0 0 41 41"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M24.7275 15.1323L15.3977 24.4622"
                                    stroke="#FFFFFF"
                                    stroke-width="1.32496"
                                  />
                                  <path
                                    d="M24.8311 24.5654L15.5598 15.2942"
                                    stroke="#FFFFFF"
                                    stroke-width="1.32496"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div className="mt-[15px]">
                            {suggestions.map((suggestion) => {
                              return (
                                <div
                                  className={`${
                                    suggestion.active
                                      ? "bg-brand-orange text-black"
                                      : ""
                                  } border border-brand-orange text-white py-[12px] px-[15px] text-sm font-normal leading-[160%] graphik-regular `}
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                {/* Studios */}
                {clubNearby
                  ?.slice(0, isGetAll ? clubNearby?.length : 4)
                  ?.map((item: object, index: number) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("yourClub", JSON.stringify(item));
                        setYourClub(item);
                        setIsChangeClub(false);
                      }}
                      key={index}
                      className="pt-[18px] lg:w-[794px] justify-between  hover:bg-graySecondary items-center flex lg:ml-8 lg:flex-row flex-col lg:pb-[21px] border-b border-b-gray px-3 text-white"
                    >
                      <img
                        src={
                          item?.attributes?.image?.url ||
                          item?.attributes?.image?.data?.url
                        }
                        className="lg:w-[242px] lg:h-[195px] w-[351px] h-[235px]"
                      />

                      <div className="lg:ml-[22px] mt-[16px] lg:mt-0 flex-1">
                        <div className="flex lg:flex-row flex-col  lg:items-center items-start justify-between">
                          <div className="flex justify-between items-center lg:w-unset w-full">
                            <h6 className="text-brand-orange font-bold text-[20px]">
                              {item?.attributes?.title}
                            </h6>
                            <p className="text-brand-orange text-lg font-normal lg:hidden block ">
                              ${item?.attributes?.price}/week
                            </p>
                          </div>
                          <span className="graphik-regular text-white text-sm font-normal ">
                            12km
                          </span>
                        </div>
                        <div className="mt-[17px] flex items-start justify-between">
                          <div className="max-w-[218px] lg:block hidden">
                            <h6 className="graphik-regular text-white font-normal text-sm leading-[160%]">
                              {item?.attributes?.fullAddress}
                            </h6>
                            <h6 className="graphik-regular text-brand-orange mt-[11px] font-normal text-sm leading-[160%]">
                              {item?.attributes?.phoneNumber}
                            </h6>
                            <div className="mt-[21px] flex items-center">
                              <span className="text-white graphik-regular text-sm font-normal ">
                                Memberships from 123
                              </span>
                              <p className="text-brand-orange text-sm font-normal ml-2">
                                ${item?.attributes?.price}/week
                              </p>
                            </div>
                          </div>
                          <div className="ml-[60px]">
                            {access !== undefined &&
                              access?.length &&
                              access?.map((e: object, index: number) => (
                                <div
                                  key={index}
                                  className="flex mb-[16px] items-center"
                                >
                                  <div className="h-[21px] w-[21px] border border-brand-orange rounded-[50%] flex items-center justify-center">
                                    <img src={CheckedMark} />
                                  </div>
                                  <p className="ml-[11px] text-white font-normal text-sm">
                                    {e?.title}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {clubNearby?.length > 0 && (
                  <div className="mt-[33px] flex justify-between items-center ml-8 pb-8 relative w-[794px]">
                    <h6 className="graphik-regular text-white font-normal text-sm">
                      Don’t know what you’re looking for?{" "}
                      <span className="graphik-regular underline font-normal text-sm">
                        Explore all club locations.
                      </span>
                    </h6>
                    <div onClick={() => setIsGetAll(true)}>
                      <ButtonActive height={56} text="all clubs" />
                    </div>
                  </div>
                )}
              </div>

              {/* Close */}
              <div
                onClick={handleClose}
                className="cursor-pointer md:block hidden absolute right-4 top-[25px]"
              >
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                  <path
                    d="M24.7275 15.1323L15.3977 24.4622"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                  <path
                    d="M24.8311 24.5654L15.5598 15.2942"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
