import React, { useState } from 'react';
import ButtonActive from '../../common/buttons/Active';
import plus from '../../assets/images/memberships/plus.png';
import minus from '../../assets/images/memberships/minus.png';
import { Link } from 'gatsby';
import useFetch from '../../hooks/useFetch';
import LoadingScreen from '../../common/loading/LoadingScreen';

function Memberships() {
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(0);
  const { loading, data } = useFetch({ params: 'home-page', populate: 'membershipFeatures.image,membershipFeatures.feature,membershipFeatureBanner.background' });
  const membershipsData = data?.data?.attributes?.membershipFeatures


  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && membershipsData && (
        <div className={`text-white flex large:flex-row max-w-[1440px] mx-auto flex-col items-center mb-[73px] justify-between relative large:h-[483px] ${toggle ? 'h-[70]px' : '600px'}`}>
          <div className='w-full'>
            <div>
              <img className="large:w-[373px] w-full large:h-[483px] h-[194px] object-cover" src={data?.data?.attributes?.membershipFeatureBanner?.background?.data?.attributes?.url} alt="coach" />
            </div>
            <div>
              <div className="absolute large:top-[61px] top-[166px] large:left-[299px] left-[25px] ">
                <h2 className="large:text-[60px] text-[40px] text-white font-bold large:leading-[62px] leading-[110%]">{data?.data?.attributes?.membershipFeatureBanner?.title}</h2>
                <h2 className="large:text-[60px] text-[40px] font-bold text-effect large:leading-[62px] leading-[110%] text-transparent text-effect">
                  {data?.data?.attributes?.membershipFeatureBanner?.description}
                </h2>
                <div className="large:mt-[136px] mt-10 large:float-right float-left">
                  <Link to="/memberships">
                    <ButtonActive text="build a membership" height={56} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-white large:min-w-[544px] large:max-w-[544px] w-full large:mt-0 mt-[250px]">
            {membershipsData.map((item: object, index: number) => (
              <div
                onMouseEnter={() => {
                  setToggle(true);
                  setSelected(index);
                }}
                onMouseLeave={() => setToggle(false)}
                key={index}
              >
                <div
                  style={{
                    borderTop: selected === index && toggle ? '1px solid #FF6900' : '1px solid white',
                    borderBottom: index === 4 && !toggle ? '1px solid #C6C6C6' : '',
                  }}
                  className="flex cursor-pointer items-center py-5 large:pr-[53px] pr-[25px] pl-4 justify-between"
                >
                  <p style={{ color: selected === index && toggle ? '#FF6900' : 'white' }} className="text-xl font-bold">
                    {item?.feature?.data?.attributes?.title}
                  </p>
                  <span
                    onClick={() => {
                      setToggle(!toggle);
                      setSelected(index);
                    }}
                  >
                    <img src={toggle && selected === index ? minus : plus} alt="plus icon" />
                  </span>
                </div>
                <ul
                  id={selected === index && toggle ? 'showlist' : 'hideList'}
                  style={{ paddingBottom: selected == index && toggle ? '25px' : '' }}
                  className="list-mems large:px-0 px-[25px] large:ml-4 ml-0"
                >
                  <img src={item?.image?.data?.attributes?.url} className="large:w-[445px] min-w-full h-[158px] object-cover" alt="coach" />
                  <p className="mt-[15px] text-base large:w-[440px] w-full font-normal text-white graphik-regular">{item?.description}</p>
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </>

  );
}

export default Memberships;
