import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import movedClasses1 from "../../assets/images/about/movedClasses1.jpg";
import movedClasses2 from "../../assets/images/about/movedClasses2.jpg";
import movedClasses3 from "../../assets/images/about/movedClasses3.jpg";
import movedClasses4 from "../../assets/images/about/movedClasses4.jpg";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import LoadingScreen from "../../common/loading/LoadingScreen";
import useFetch from "../../hooks/useFetch";

function MoveClasses() {
  const { data, loading } = useFetch({
    params: "about-page",
    populate: "belowAccessCarousel, belowAccessCarousel.images",
  });
  const [animated, setAnimation] = useState(-1);
  const [isActive, setActive] = useState(false);
  const imgArr = [movedClasses1, movedClasses2, movedClasses3, movedClasses4];
  useEffect(() => {
    let par = document.querySelector(`#join-${animated}`);
    let child1 = document.querySelector(`#join-${animated}-1`);
    let child2 = document.querySelector(`#join-${animated}-2`);
  }, [animated]);

  const handleMouseIn = (e: any, i: number) => {
    setAnimation(i);

    setActive(true);
  };

  const handleMouseOff = (e: any, i: number) => {
    setAnimation(-1);

    setActive(false);
  };
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <div className=" h-[458px] xl:h-[458px] lg:h-[650px] flex flex-col md:flex-col xl:flex-row my-24">
          <div className="flex flex-col  justify-between text-white mx-[4.9%] ">
            <p
              className="w-full lg:w-[432px] lg:text-6xl text-[40px]"
              dangerouslySetInnerHTML={createMarkup(
                data.data.attributes.belowAccessCarousel.title
              )}
            >
            </p>
            <Link to="/memberships" className="xl:block hidden">
              <ButtonNavigate
                style="border-[#777777] text-[#777777] border-b h-[50px] lg:h-[56px] w-[140px] w-[165px] "
                text="all classes"
              ></ButtonNavigate>
            </Link>
          </div>
          <div className="moveClassSlider lg:h-[458px]   gap-[15px] w-[867px] lg:flex hidden mx-auto ">
            {data.data.attributes.belowAccessCarousel.images.data.map(
              (e, i) => (
                <div
                  id={`join-${i}`}
                  key={i}
                  className={`cursor-pointer relative flex transition-all duration-500 w-[250px] over ${i + 1 > 2
                      ? "min-w-[120px]"
                      : i + 1 == 2
                        ? "min-w-[195px]  195px"
                        : "min-w-[378px]  378px"
                    } ${animated === i
                      ? isActive
                        ? "!min-w-[378px] !w-[378px]"
                        : ""
                      : animated !== -1 && i + 1 == 1
                        ? "!min-w-[120px]"
                        : ""
                    }`}
                  onMouseEnter={(e) => handleMouseIn(e, i)}
                  onMouseLeave={(e) => handleMouseOff(e, i)}
                >
                  <img
                    id={`join-${i}-1`}
                    src={e.attributes.url}
                    className={`object-cover absolute w-[378px] h-[458px] transition-all duration-700 ${animated === i ? (isActive ? "" : null) : null
                      }`}
                  />
                </div>
              )
            )}
          </div>
          <div className="moveClassSlider   gap-[5px] xl:gap-[15px] my-[26px] lg:hidden flex ">
            {data.data.attributes.belowAccessCarousel.images.data.map(
              (e, i) => (
                <>
                  <div
                    id={`join-${i}`}
                    key={i}
                    className={` lg:hidden  cursor-pointer relative flex transition-all duration-500  over ${i + 1 > 2
                        ? "min-w-[120px] min-w-[55px]"
                        : i + 1 == 2
                          ? "min-w-[195px] min-w-[85px]"
                          : "min-w-[378px] min-w-[163px]"
                      } ${animated === i
                        ? isActive
                          ? "!min-w-[163px] !w-[163px] lg:!min-w-[378px] lg:!w-[378px] "
                          : ""
                        : animated !== -1 && i + 1 == 1
                          ? "!min-w-[55px]"
                          : ""
                      }`}
                    style={{
                      width: `${animated === i
                          ? isActive
                            ? "378px; !important"
                            : ""
                          : null
                        }`,
                    }}
                    onMouseEnter={(e) => handleMouseIn(e, i)}
                    onMouseLeave={(e) => handleMouseOff(e, i)}
                  >
                    <img
                      id={`join-${i}-1`}
                      src={e.attributes.url}
                      className={`object-cover  w-[378px] h-[256px] lg:h-[458px] transition-all duration-700 ${animated === i ? (isActive ? "" : null) : null
                        }`}
                    />

                    {/* <img
                    id={`join-${i}-2`}
                    src={e.attributes.url}
                    className={`object-cover absolute z-[-1] w-[378px] h-[458px] transition-all duration-[0.5s] bottom-[150%] ${
                      animated === i
                        ? isActive
                          ? "!bottom-[0] z-[1]"
                          : 'opacity-0'
                        : 'opacity-0'
                    }`}
                  /> */}
                  </div>
                </>
              )
            )}
          </div>
          <Link to="/memberships" className="xl:hidden block">
            <ButtonNavigate
              style="border-[#777777] text-[#777777] border-b h-[50px] w-[140px]  ml-[26px]"
              text="all classes"
            ></ButtonNavigate>
          </Link>
        </div>
      )}
    </>
  );
}
export default MoveClasses;
