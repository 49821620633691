import React, { useEffect, useState } from "react";
import InputBase from "../common/inputs/InputBase.tsx";
import Layout from "../components/layout/Layout";
// import faqIcon from "../assets/images/icons/faq-icon.svg";
// import helpCentreIcon from "../assets/images/icons/help-centre-icon.svg";
// import chatWithUsIcon from "../assets/images/icons/chat-with-us-icon.svg";
// import callUsIcon from "../assets/images/icons/call-us-icon.svg";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import SelectOption from "../common/dropdowns/SelectOption";
import useFetch from "../hooks/useFetch";
import warnCircle from "../assets/images/icons/warnCircle.svg";

function contactForm() {
  const { data, loading } = useFetch({
    params: "contact-us-page",
    populate: "sideLink,sideLink.image",
  });
  const studioMocking = useFetch({ params: "studios", populate: "" });
  const [filteredStudio, setFilteredStudio] = useState([]);

  useEffect(() => {
    if (studioMocking.data && !filteredStudio.length) {
      const dataFiltered = studioMocking.data.data
        .filter((c) => c.attributes.email === "info@hiitrepublic.com.au")
        .map((i) => i.attributes.title);
      setFilteredStudio(dataFiltered);
    }
  }, [studioMocking]);
  // ['belconnen', 'anu', 'braddon']
  function createMarkup(data) {
    return { __html: data };
  }
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    setActive(true);
    return e.target.value === ""
      ? ((e.target.style.borderColor = ""),
        (e.target.style.color = ""),
        setActive(false))
      : ((e.target.style.borderColor = "#FF7900"),
        (e.target.style.color = "#FF7900"),
        setActive(true));
  };

  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    location: false,
    message: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" || e === "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  return (
    !loading &&
    data && (
      <div className="max-w-[1440px] mx-auto">
        <p className="text-[60px] leading-[66px] -tracking-[0.03em] font-bold my-8 mx-[4.9%] text-white">
          {data.data.attributes.title}
        </p>
        <div className="flex mx-[4.9%] flex-col-reverse md:flex-row">
          <div className="text-white  w-full md:w-3/5">
            <p className="text-2xl font-bold leading-6 text-brand-orange">
              {data.data.attributes.subtitle}
            </p>
            <p className="graphik-regular font-normal text-base mb-8">
              {data.data.attributes.description}
            </p>
            <div className="grid md:grid-rows-2 md:grid-flow-col gap-x-[50px] gap-y-[12px]">
              <InputBase
                width="100%"
                type="string"
                label="Full name"
                holder="Full name*"
                isRequired={true}
                isShowRequired={formControlled.fullName}
                onchange={(e) => setForm({ ...form, fullName: e.target.value })}
              />
              <InputBase
                width="100%"
                type="string"
                holder="Phone number*"
                isRequired={true}
                isShowRequired={formControlled.phoneNumber}
                onchange={(e) =>
                  setForm({ ...form, phoneNumber: e.target.value })
                }
              />
              <InputBase
                width="100%"
                type="email"
                holder="Email address*"
                isActiveData={true}
                isRequired={true}
                isShowRequired={formControlled.email}
                onchange={(e) => setForm({ ...form, email: e.target.value })}
                errorText={errText.email}
              />
              <SelectOption
                titleStyle="text-[12px]"
                title="location*"
                style=" h-[54px] graphik-regular text-[14px] w-full"
                placeholder="Location*"
                placeholderColor="text-slate-400"
                isShowRequired={formControlled.location}
                data={["anu", "belconnen", "braddon"]}
                onSelect={(v) => setForm({ ...form, location: v })}
              ></SelectOption>
            </div>
            <div className="mt-[12px] relative flex w-[100%]">
              <div className="flex flex-col w-full">
                <label>
                  <span className="after:content-[''] text-gray after:ml-0.5 after:text-white block text-[12px] graphik-regular font-medium text-white">
                    {active ? "" : ""}
                  </span>
                  <span
                    className={`absolute  block graphik-regular font-medium  transition-all duration-300 ${active
                        ? "top-0 left-0 text-white text-xs"
                        : "top-[39px] left-7 text-gray text-base"
                      }`}
                  >
                    {"Message*"}
                  </span>
                </label>
                <textarea
                  onInput={(e) => {
                    activeValue(e);
                  }}
                  className="mt-1 px-6 h-[136px] w-full placeholder:text-white py-4 bg-black border graphik-regular border-[#FFFFFF] placeholder-slate-400  focus:outline-none  block  sm:text-sm focus:ring-1 text-start"
                  placeholder={""}
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                />
              </div>

              <div className="absolute top-10  right-3 graphik-regular font-normal text-xs text-brand-orange">
                {formControlled.message ? (
                  <>
                    This Field is required{" "}
                    <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex w-full gap-[20px]  flex-col-reverse md:flex-row mt-[23px] md:mt-[50px]  justify-between">
              <p className="text-white max-w-[387px] graphik-regular text-sm leading-[22px] text-gray">
                {data.data.attributes?.faq}
              </p>
              <ButtonNavigate
                style="h-[56px] w-[171px] border-b border-[#777777] text-[#777777]"
                text="get in touch"
                onClick={() => {
                  submit();
                }}
              ></ButtonNavigate>
            </div>
          </div>
          <div className=" w-full mb-10 md:mb-[0px] md:w-2/5 text-white flex flex-col justify-end gap-[43px]">
            {data.data.attributes.sideLink.map((i) => (
              <div className="flex items-start">
                <img
                  className=" ml-[0px] md:ml-[64px]   mr-[24px]"
                  src={i.image.data.attributes.url}
                />
                <div>
                  <p className="text-brand-orange text-lg font-bold">
                    {i.title}
                  </p>
                  <div dangerouslySetInnerHTML={createMarkup(i.html)}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}

function ContactUs() {
  return <Layout>{contactForm()}</Layout>;
}

export default ContactUs;
