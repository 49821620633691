import React, { useEffect, useState } from 'react';
import ButtonActive from '../common/buttons/Active';
import Checkbox from '../common/buttons/Checkbox';
import InputBase from '../common/inputs/InputBase.tsx';
import { navigate } from '@reach/router';
import { Link } from 'gatsby';

function Access() {
  const [status, setStatus] = useState('login');
  const [ready, setReady] = useState(false);
  const [account, setAccount] = useState({
    password: '',
  });

  const handleChangeValue = e => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    setReady(true);
    navigate(`/`);
  };

  useEffect(() => {
    if (account.password === '123123' && ready) {
      window.localStorage.setItem('userGatsby', 'cris');
    }
  }, [handleSubmit]);

  return (
    <div className="text-white w-full">
      <div className="flex justify-center mt-[30px]"></div>

      {/* Form login */}
      {status === 'login' && (
        <div className="md:w-[473px] w-full md:px-0  absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] mx-auto">
          <h1 className="text-[50px] text-white font-bold text-center mb-[45px]">View Page</h1>
          <div className="grid grid-rows-2 grid-flow-col  gap-y-[18px]">
            <InputBase
              onchange={handleChangeValue}
              nameInput="password"
              type="password"
              style='w-full'
              label="Password"
              holder="Password*"
            />
          </div>

          <div className="flex items-center justify-end w-full mt-[-70px]">
            <div onClick={handleSubmit}>
              <ButtonActive height={56} text="Login" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Access;
