import React from "react";
import {
  dashboardUserBasicInfo,
  dataCollection,
} from "../../../mock/dashboard.js";
function DashboardDetail() {
  return (
    <div className="text-white m-[24px] md:mx-[60px] md:mt-[67px]">
      <p className="uppercase text-white font-medium text-sm leading-[19.6px] tracking-[0.1em] gotham">
        Member portal
      </p>
      <p className="uppercase text-white text-[54.88px] font-normal leading-[49px] bebas mt-[9px]">
        hey, {dashboardUserBasicInfo.name}
      </p>
      <div className="my-[36px] grid  grid-flow-row gap-x-[15px] gap-y-[22px] xl:grid-cols-4 grid-cols-1">
        {dataCollection.map((data) => {
          if (data.img != "") {
            return (
              <div className="lg:h-[269px] h-[61px] text-white border border-[rgb(255,255,255,0.4)] rounded-[5px] relative ">
                <p className="leading-[19.6px] tracking-[0.1em] font-medium text-[14px] gotham absolute top-[26px] left-[16px]">
                  {data.text}
                </p>
                <img src={data.img} className="h-full w-full object-cover" />
                <img
                  src={data.icon}
                  className="absolute top-[13px] right-[16px] object-cover"
                />
              </div>
            );
          } else
            return (
              <div className="lg:h-[80px] h-[61px] text-white  border border-[rgb(255,255,255,0.4)] rounded-[5px] gotham text-[14px] px-[16px] flex justify-between items-center leading-[19.6px] tracking-[0.1em] font-medium">
                {data.text}
                <img src={data.icon} className="  " />
              </div>
            );
        })}
      </div>
      <div className="my-[5px]">
        <p className="gotham text-sm leading-[22px] font-normal ">
          Your membership includes:
        </p>
        <div className="flex gap-[16px] mt-[10px] flex-wrap">
          {dashboardUserBasicInfo.brandIncludes.map((i, index) => (
            <div
              className="gotham py-[7px] px-[16px] text-black font-medium text-[12px] uppercase rounded-[5px]"
              style={{ background: i.color }}
            >
              {i.text}
            </div>
          ))}
          <div className="flex justify-center items-center w-[32px] h-[32px] font-semibold border border-white rounded-[5px]">
            +
          </div>
          <div className="flex justify-center items-center  h-[32px] font-semibold ">
            Upgrade
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardDetail;
