import React, { useEffect, useState } from 'react'
import MenuToggler from "./MenuToggler";
import Navigation from "./Navigation";
export const HambergerMenu = () => {
    const [toggle, setToggle] = useState<boolean>(false)
    useEffect(() => {
        toggle
            ? document.body.classList.add('hide-scroll')
            : document.body.classList.remove('hide-scroll')
    }, [toggle])


    return (
        <>
            <div onClick={() => setToggle(true)} className='h-[50px] w-[50px] flex items-center justify-center relative '>
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="12" viewBox="0 0 37 12" fill="none">
                    <line x1="6.55672e-08" y1="1.25" x2="37" y2="1.25" stroke="white" stroke-width="1.5" />
                    <line x1="6.55672e-08" y1="11.25" x2="37" y2="11.25" stroke="white" stroke-width="1.5" />
                </svg>
            </div>
            <Navigation toggle={toggle} setToggle={setToggle} />
        </>
    )
}
