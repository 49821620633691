import React, { useContext, useState } from 'react'
import { RegisterContext } from '../../../context/RegisterContext'
import Selected from '../../../common/buttons/Selected'
import { yourMemberships, } from "../../../../mock/memberships";
import useFetch from '../../../hooks/useFetch'
import ArrowButton from '../../../common/buttons/ArrowButton'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
function Upgrade(props) {
    const { setStep } = props
    const [toggle, setToggle] = useState<boolean>(false)


    // Context
    const yourClub = useContext(RegisterContext).yourClub
    const setIsChangeClub = useContext(RegisterContext).setIsChangeClub
    const upgrades = useContext(RegisterContext).upgrades
    const setUpgrades = useContext(RegisterContext).setUpgrades
    const plan = useContext(RegisterContext).plan

    // Get Upgrades
    const { data } = useFetch({ params: 'membership-upgrades', populate: 'logo,image,features,price' })


    // Check value upgrades exists
    const handleCheck = (club: object) => {
        setUpgrades((prev) => {
            const isChecked = upgrades.some((item) => item?.attributes?.title === club?.attributes?.title);
            return isChecked ? upgrades?.filter((item) => item?.attributes?.title !== club?.attributes?.title) : [...prev, club];
        });
    };

    // Total Price
    let total = 0
    upgrades.forEach(e => { total += e?.attributes?.price?.[1]?.value; });

    return (
        <>
             <div className=" flex gap-[8px] items-center mt-[34px]">
                <div onClick={() => setStep(3)} className="w-[41px] h-[48px] p-[7px] pl-0">
                    <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                        <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                    </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                    step 3
                </span>
            </div>
            <div className='mt-[35px]'>
                <h6 className='uppercase text-white text-sm leading-[140%] tracking-widest gotham'>step 4</h6>
                <p className='mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white'>add/confirm upgrades</p>
                <div className='lg:flex items-start justify-between w-full'>
                    <div>
                        <div className='mt-[60px] lg:w-[530px]'>
                            <div className={`${toggle ? 'h-0 overflow-hidden opacity-0 hidden' : 'opacity-100'}  min-w-full  transition-transform grid lg:grid-cols-2 grid-cols-1 bg-[#1B1B1B] text-white gap-0 lg:max-w-[796px]`}>
                                {data?.data?.map((item) => (
                                    <div className="px-[20px] border  border-[#6C6C6C] pt-[28px] pb-[14px]">
                                        {/*  Logo */}
                                        <img className="lg:h-[104px] h-[131px] w-full object-cover" src={item?.attributes?.image?.data?.attributes?.url} />
                                        <img className="h-[21px] w-auto mt-[20px]" src={item?.attributes?.logo?.data?.attributes?.url} />
                                        {/* Description */}
                                        <div className="mt-4 h-[100px]">
                                            {item?.attributes?.features?.data?.map((i) => (
                                                <div className="flex  mb-[12px] gap-[10px] items-center">
                                                    <svg
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 21 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="10.3918"
                                                            cy="10.6437"
                                                            r="9.39175"
                                                            stroke={item?.attributes?.color}
                                                        />
                                                        <path
                                                            d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"
                                                            stroke={item?.attributes?.color}
                                                        />
                                                    </svg>
                                                    <p className="text-[12px]  leading-[160%] font-normal gotham">
                                                        {i?.attributes?.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        {/* Price */}
                                        <div className="mt-[20px] flex items-center justify-between">
                                            <div className="flex-col">
                                                <p className="text-[14px] tracking-widest font-bold text-white">
                                                    ${item?.attributes?.price?.[0]?.value}.00/wk
                                                </p>
                                                <p className="text-[12px] font-normal leading-[160%] opacity-50  gotham text-white">
                                                    ${item?.attributes?.price?.[1]?.value}.00 per fornight{" "}
                                                </p>
                                            </div>
                                            <div onClick={() => handleCheck(item)}>
                                                <Selected style="h-[28px] w-[38px] rounded-[5px] "
                                                    isPlus={true} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className=" items-center lg:w-[530px] hidden lg:flex  justify-between mt-[53px] mb-[90px]">
                            <ArrowButton text="BACK" isRight={false} onClick={() => { setStep(3) }} />
                            <ArrowButton text="NEXT" isRight={true} onClick={() => { setStep(5) }} />
                        </div>
                    </div>
                    <div className='text-white mt-6 lg:mt-[-60px]'>
                        <div className="lg:w-[339px] ">
                            <h2 className="text-white gotham text-[14px] font-normal  border-b border-b-white leading-[90%] uppercase pb-2">
                                Your new membership
                            </h2>
                            <div className="w-full justify-between flex items-center h-4 mt-4 pr-[40px]">
                                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                                    Home Club
                                </h2>
                                <span
                                    onClick={() => setStep(2)}
                                    className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer "
                                >
                                    Edit
                                </span>
                            </div>
                            <div style={{ color: yourClub?.attributes?.color }} className="font-normal  text-[12px] mt-3 uppercase gotham leading-[140%] pb-4 border-b border-b-gray">
                                {yourClub?.attributes?.title}
                            </div>
                            {/* Home Club */}
                            <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white gotham">
                                    Membership Plan
                                </h2>
                                <span
                                    onClick={() => setStep(3)}
                                    className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer"
                                >
                                    Edit
                                </span>
                            </div>
                            {/* Membership Plan */}
                            <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                                <div className="flex items-center justify-between">
                                    <h2 className="gotham uppercase text-[11px] text-white leading-[140%] ">
                                        {plan?.attributes?.title} membership
                                    </h2>
                                    <span className="text-white gotham text-[11px] leading-[140%] font-normal uppercase">${plan?.attributes?.price}.00</span>
                                </div>
                                <div className="flex mt-[10px] gap-[10px] flex-col">
                                    {plan?.attributes?.features?.data?.map(item => (
                                        <h2 className="gotham  uppercase text-[11px] text-white leading-[140%] ">
                                            {item?.attributes?.title}
                                        </h2>
                                    ))}
                                </div>
                            </div>
                            {/* Upgrades */}
                            <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                                    Upgrades/Add-ons
                                </h2>
                                <span className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer"            >
                                    Edit
                                </span>
                            </div>
                            <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                                <div className="flex mt-[10px] gap-[10px] flex-col">
                                    {upgrades?.map(item => (
                                        <div className="flex items-center justify-between">
                                            <h2 style={{ color: item?.attributes?.color }} className="gotham  uppercase text-[11px] text-white leading-[140%] ">{item?.attributes?.title}</h2>
                                            <span className="gotham  uppercase text-[11px] text-white leading-[140%]">${item?.attributes?.price[1]?.value}.00</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                                <h5 className="font-normal gotham text-sm text-white ">
                                    {yourMemberships.costPerForNightText}
                                </h5>
                                <div className="text-white text-sm font-normal gotham text-[12px] leading-[100%]">
                                    ${total + plan?.attributes?.price}.00
                                </div>
                            </div>
                            <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                                <div className="flex items-center relative justify-center">
                                    <h5 className="font-normal gotham text-[12px] text-white ">
                                        {yourMemberships.totalText}
                                    </h5>
                                </div>
                                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                                    $44.80
                                </div>
                            </div>
                            {/* Promo code */}
                            <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                                <h5 className="font-normal text-sm text-white gotham">
                                    {yourMemberships.promoCodeText}
                                </h5>
                                <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                                    <span className="w-[20px] text-white gotham text-[12px] uppercase">cl-</span>
                                    <input
                                        // onChange={(e) => setCode(e.target.value)}
                                        type="text"
                                        className="w-full h-full ml-1 border-0 text-white font-normal gotham text-[12px] outline-0 bg-transparent"
                                    />
                                </div>
                            </div>
                            <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                                <div className="flex items-center relative justify-center">
                                    <h5 className="font-normal gotham text-[12px] text-white ">
                                        Direct debit starts
                                    </h5>
                                </div>
                                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                                    01/09/2023
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center lg:w-[530px]  lg:hidden flex  justify-between mt-[53px] mb-[90px]">
                            <ArrowButton text="BACK" isRight={false} onClick={() => { setStep(3) }} />
                            <ArrowButton text="NEXT" isRight={true} onClick={() => { setStep(5) }} />
                        </div>
                </div>
            </div>
        </>

    )
}

export default Upgrade