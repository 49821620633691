import React, { useContext, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { congrats } from '../../../../mock/memberships';
import { arrowRight } from "../../../assets/images/icons/arrowRight.tsx";
import store from '../../../assets/images/memberships/store.png';
import chPlay from '../../../assets/images/memberships/ggPlay.png';
import congrat from '../../../assets/images/memberships/congrat.png'
import { Link } from 'gatsby';

function Congrats(props) {
    const { setStep } = props

    const { data: app } = useFetch({ params: 'global', populate: 'appPromotion' })
    return (
        <>
      <Link to="/dashboard">
        <div className=" flex gap-[8px] items-center mt-[34px]">
          <div className="w-[41px] h-[48px] p-[7px] pl-0">
            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
              <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
            </div>
          </div>
          <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
            dashboard
          </span>
        </div>
      </Link>

      <div className="flex w-full items-start">
        {/* Info */}
        <div className="flex xl:flex-row flex-col items-start w-full justify-between">
          <div className="mt-[30px] ">
            <h2 className="text-white  uppercase gotham text-[14px] leading-[90%] font-normal tracking-widest  pb-2">
              Your membership is updated
            </h2>
            <h1 className="text-[54px] bebas font-normal leading-[90%] uppercase mt-[10px] text-white">
              congrats any
            </h1>
            {/* Next steps */}
            <div className="lg:mt-[26px] mt-[56px]">
              <div className="mt-[57px]">
                <h6 className="text-sm text-white font-normal gotham">
                  New membership starts
                </h6>
                <p className="text-white gotham font-normal uppercase tracking-widest">
                  today, 10 aug 2021
                </p>
              </div>
              <h6 className="text-white font-normal gotham text-sm border-b border-b-white pb-2 uppercase mt-[55px]">
                next steps
              </h6>
              {congrats.nextStepsDashboard.steps.map(
                (item: any, index: number) => (
                  <div className="py-[18px] flex items-center justify-between border-b border-b-gray xl:last:border-b-0">
                    <div className="flex  items-center">
                      <span className="text-start text-white bebas text-[40px] font-normal leading-[90%] lowercase">
                        0{index + 1}
                      </span>
                      <p className="text-[12px] text-white text-start ml-4  w-[209px] gotham lg:block hidden  font-medium uppercase  tracking-widest leading-[100%]">
                        {item.name}
                      </p>
                    </div>
                    <div
                      className={`gotham ${
                        index == 1
                          ? "ml-5  xl:transform xl:translate-x-[-35px]"
                          : "ml-5"
                      }  text-[14px] font-normal xl:max-w-[367px] leading-[160%] text-white`}
                    >
                      <p className="text-[12px] xl:w-[269px] text-white gotham lg:hidden block mb-[17px]  font-medium uppercase  tracking-widest leading-[100%]">
                        {item.name}
                      </p>
                      {item.desc}
                      {index === 1 && (
                        <div className="mt-[8px] gap-[8px] flex items-center">
                          <img
                            onClick={() =>
                              window.open(
                                app?.data?.attributes?.appPromotion?.appStoreUrl
                              )
                            }
                            className="xl:w-[115px xl:h-[34px]"
                            src={store}
                          />
                          <img
                            onClick={() =>
                              window.open(
                                app?.data?.attributes?.appPromotion
                                  ?.playStoreUrl
                              )
                            }
                            className="xl:w-[134px] xl:h-[52px]"
                            src={chPlay}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          {/* Intro image */}
          <img
            src={congrat}
            className="xl:w-[360px] h-[500] xl:mt-0 mt-6"
          />
        </div>
      </div>
    </>

    )
}

export default Congrats