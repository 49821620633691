import React, { useState, useEffect, useRef, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { timeLine } from "../../mock/memberships";
import { Link } from "gatsby";
import "slick-carousel/slick/slick-theme.css";
import SelectClub from "../components/joining-process/SelectClub";
import ChoosePlan from "../components/joining-process/ChoosePlan";
import UpgradesAndAddOns from "../components/joining-process/UpgradesAndAddOns";
import YourDetail from "../components/joining-process/YourDetail";
import Payment from "../components/joining-process/Payment";
import Congrats from "../components/joining-process/Congrats";
import { ChangeYourHomeClub } from "../components/joining-process/ChangeYourHomeClub";
import { RegisterContext } from "../context/RegisterContext";

function register() {
  const sliderRef = useRef<any>();
  const [current, setCurrent] = useState(0);
  const [percent, setPercent] = useState(16.67);

  const yourClub = useContext(RegisterContext).yourClub;
  const isChangeClub = useContext(RegisterContext).isChangeClub;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    swipe: false,
    swipeToSlide: false,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
    dotsClass: "time-line",
    beforeChange: (prev: number, current: number) => {
      setCurrent(current);
      setPercent(16.67 * (current + 1));
    },
  };

  const handleMoveSlide = (index: number) => {
    sliderRef.current.slickGoTo(index);
  };

  // useEffect(() => {
  //   yourClub === undefined && setIsChangeClub(true)
  // }, [yourClub])

  return (
    <>
      <div className="overflow-auto max-w-[1440px] mx-auto">
        <div
          style={{ width: percent + "%" }}
          className={` h-[6px] transition-transform bg-brand-orange z-10 static top-0 flex`}
        ></div>
        {/* Navigation */}
        {current < 5 && (
          <div className="flex items-center justify-between lg:mt-[19px] pb-[21px] pt-[13px]">
            <div className="flex relative md:items-start items-center cursor-pointer justify-evenly w-full">
              {timeLine.map((item, index) => (
                <div
                  onClick={() =>
                    current >= index ? handleMoveSlide(index) : null
                  }
                  key={index}
                  className="lg:px-[39px] flex items-center"
                >
                  <div
                    className={`z-10 link w-[34px] h-[34px] rounded-[50%] flex items-center justify-center font-[400] lg:font-bold text-sm ${
                      current === index && "bg-brand-orange text-black "
                    } ${
                      current < index &&
                      "border lg:border-white lg:opacity-60 lg:w-[34px] lg:h-[34px] w-[12px] h-[12px]  lg:bg-transparent text-white bg-white"
                    } ${
                      current > index &&
                      "border border-brand-orange text-white bg-black "
                    } `}
                  >
                    {index + 1}
                  </div>
                  <p
                    className={`text-white ml-[15px] font-bold text-sm lg:block hidden ${
                      current < index ? "text-opacity-60" : ""
                    }`}
                  >
                    {item}
                  </p>
                  
                </div>
              ))}<hr className="absolute border-brand-orange w-[70%] z-[-1] lg:hidden"></hr>
            </div>
            {/* Close */}
            <Link to="/">
              <div className="cursor-pointer">
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                  <path
                    d="M24.7275 15.1323L15.3977 24.4622"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                  <path
                    d="M24.8311 24.5654L15.5598 15.2942"
                    stroke="#FF6900"
                    stroke-width="1.32496"
                  />
                </svg>
              </div>
            </Link>
          </div>
        )}

        <Slider ref={sliderRef} {...settings} className='registerSlide'>
          <SelectClub sliderRef={sliderRef} />
          <ChoosePlan sliderRef={sliderRef} />
          <UpgradesAndAddOns sliderRef={sliderRef} />
          <YourDetail sliderRef={sliderRef} />
          <Payment sliderRef={sliderRef} />
          <Congrats />
        </Slider>
      </div>
      {isChangeClub && <ChangeYourHomeClub />}
    </>
  );
}

export default register;
