import React, { useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import { workAbout } from "../../../mock/work";
import ButtonActive from "../../common/buttons/Active";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ArrowRight45 from "../../assets/images/icons/ArrowRight45.svg";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import moment from "moment";
import InputBase from "../../common/inputs/InputBase.tsx";
import SelectOption from "../../common/dropdowns/SelectOption";
import Carousel from "../../components/work/Carousel";
function WorkWithUs() {
  const sliderRef = useRef<any>();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
  };

  const { data, loading } = useFetch({
    params: "work-with-us-page",
    populate:
      "thumbnail,catagory,topBanner.images,midBanner.background,bottomsCarousels.background",
  });
  const { data: jobs } = useFetch({
    params: "recruitments",
    populate: "image,studio",
  });

  const topBannerImages = data?.data?.attributes?.topBanner?.images?.data;

  const carousel = data?.data?.attributes?.bottomsCarousels;

  const [appear, setAppear] = useState<Boolean>(false);
  const [workId, setWorkId] = useState<string>("");

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    location: "",
    department: "",
    position: "",
    heard: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    location: false,
    department: false,
    position: false,
    heard: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };

  return (
    <Layout>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <div className=" ">
          {/* Intro */}
          <div className="relative max-w-[1440px] mx-auto h-[414px] lg:h-[634px]">
            <img
              src={topBannerImages?.[0]?.attributes?.url}
              className="absolute left-0 top-0 h-[320px] w-full object-cover lg:h-[634px] lg:w-[439px] "
            />
            <img
              src={topBannerImages?.[2]?.attributes?.url}
              className="absolute top-[235px] right-0 h-[398px] w-[328px] lg:block hidden"
            />
            <img
              src={topBannerImages?.[1]?.attributes?.url}
              className="absolute top-0 right-[247px] h-[398px] w-[317px] lg:block hidden"
            />
            <div className="mx-auto w-full flex items-center flex-col top-[75%] lg:top-1/2 transform translate-y-[-50%] justify-center z-10 absolute">
              <h4 className="lowercase text-brand-orange text-center  font-bold text-[20px] lg:text-[24]">
                {data?.data?.attributes?.topBanner?.title}
              </h4>
              <p className="uppercase font-bold text-[40px] lg:text-[80px]  hidden lg:block px-3 leading-[90%] lg:px-0 lg:mt-[46px] mt-[26px] max-w-[673px] text-center tracking-wider outline-text-md">
                {data?.data?.attributes?.topBanner?.description}
              </p>
              <p className="uppercase font-bold text-[40px] lg:text-[80px] block lg:hidden outline-text-1 px-3 leading-[90%] lg:px-0 lg:mt-[46px] mt-[26px] max-w-[673px] text-center tracking-wider">
                {data?.data?.attributes?.topBanner?.description}
              </p>
            </div>
          </div>
          {/* About us */}
          <div className="lg:mt-[145px] max-w-[1440px] mx-auto mt-0 lg:h-[625px] lg:relative block px-3 lg:px-0">
            <img
              src={workAbout.textEffect}
              className="absolute left-0 top-[-45px] w-[36x] z-10 h-full hidden lg:block"
            />
            <img
              src={workAbout.textEffect}
              className="absolute right-0 top-[-45px] w-[36x] h-full hidden lg:block"
            />
            <div className="lg:absolute block left-0 top-0 w-full lg:w-[916px] lg:h-[554px] h-auto">
              <img
                src={
                  data?.data?.attributes?.midBanner?.background?.data
                    ?.attributes?.url
                }
                className="w-full h-full hidden lg:block"
              />
              <div className="lg:absolute block h-auto lg:top-[151px] top-0 right-0 left-[24px] lg:left-auto lg:right-[-380px] lg:-translate-x-[110%] xl:translate-x-0">
                <h2 className="font-bold lg:text-[60px] text-[52px] px-3 lg:px-0 leading-[110%] tracking-tight lowercase opacity-90 outline-text-1">
                  {data?.data?.attributes?.midBanner?.title}
                </h2>
                <p className="font-normal leading-[160%] text-sm text-white graphik-regular mt-[31px] lg:float-right px-3 lg:px-0 max-w-[433px]">
                  {data?.data?.attributes?.midBanner?.description}
                </p>
              </div>
            </div>
          </div>
          {/* Slider */}
          <div className="lg:mt-[40px] max-w-[1440px] mx-auto mt-[54px] text-white">
            <h4 className="text-white leading-[110%] tracking-tight lg:text-[60px] text-[48px] text-center mb-[60px]">
              our values
            </h4>
            <div className="hidden lg:block">
              <Slider ref={sliderRef} {...settings}>
                {carousel?.map((item: object, index: number) => (
                  <div
                    style={{ display: "none !important" }}
                    className="w-full slides-work relative items-center justify-between"
                  >
                    <div className="left-0 top-[281px] absolute">
                      <h1
                        className={`font-bold text-[80px] leading-[90%] max-w-[783px] outline-text-1 tracking-wider uppercase text-center text-white ${index === 1 ? "absolute left-0 " : ""
                          }`}
                      >
                        {item?.customMetadata?.content?.[0]?.title}
                      </h1>
                      <p
                        className={`graphik-regular left-[114px] max-w-[345px] font-normal  text-sm leading-[160%] text-center tracking-wider text-white ${index === 1
                          ? "mt-[173px] ml-[83px]"
                          : "mt-[53px]  absolute"
                          }`}
                      >
                        {item?.customMetadata?.content?.[0]?.description}
                      </p>
                    </div>
                    <div className="flex w-full justify-center mx-auto">
                      <img
                        src={item?.background?.data?.attributes?.url}
                        className="w-[521px] mx-auto h-[628px]"
                      />
                    </div>

                    <div className="right-0 top-[281px] absolute">
                      {index === 0 ? (
                        <h1
                          className={`font-bold text-[80px] leading-[90%] max-w-[783px] text-white tracking-wider uppercase text-center ${index === 1 ? "absolute left-0" : ""
                            }`}
                        >
                          {item?.customMetadata?.content?.[1]?.title}
                        </h1>
                      ) : (
                        <h1 className="font-bold text-white text-[80px] relative leading-[90%] flex justify-end min-w-[449px] max-w-[783px]  float-right tracking-wider uppercase text-center  ">
                          <span className="absolute right-0">
                            {item?.customMetadata?.content?.[1]?.title}
                          </span>
                        </h1>
                      )}
                      <p
                        className={`graphik-regular absolute right-[45px] mt-[53px] font-normal text-sm leading-[160%] text-center ${index === 1
                          ? "mt-[173px] ml-[43px] max-w-[335px]"
                          : "max-w-[305px] mt-[53px]"
                          } tracking-wider text-white`}
                      >
                        {item?.customMetadata?.content?.[1]?.description}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* Mobile */}
            <div className="block relative lg:hidden h-full w-full">
              <Slider ref={sliderRef} {...settings}>
                {carousel?.map((item: object, index: number) => (
                  <>
                    <div
                      style={{ display: "none !important" }}
                      className="w-full slides-work relative items-center justify-between"
                    >
                      <div className="left-[50%] top-[145px] absolute -translate-x-1/2">
                        <h1
                          className={`font-bold text-[50px] leading-[90%] max-w-[783px] outline-text-1 tracking-wider uppercase text-center text-white ${index === 1 ? " " : ""
                            }`}
                        >
                          {item?.customMetadata?.content?.[0]?.title}
                        </h1>
                        <p
                          className={`graphik-regular max-w-[345px] font-normal text-sm leading-[160%] text-center tracking-wider text-white ${index === 1 ? " " : "mt-[53px]  "
                            }`}
                        >
                          {item?.customMetadata?.content?.[0]?.description}
                        </p>
                      </div>
                      <div className="flex w-full justify-center mx-auto">
                        <img
                          src={item?.background?.data?.attributes?.url}
                          className="w-[521px] mx-auto h-[428px] object-cover"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
              {/* <Carousel /> */}
            </div>
            <div className="mx-auto mt-[46px] flex gap-[50px]  justify-center pb-5 w-[106px] border-b-[5px] border-b-brand-orange">
              <button onClick={() => sliderRef.current.slickPrev()}>
                <img className="rotate-[225deg]" src={ArrowRight45} />
              </button>
              <button onClick={() => sliderRef.current.slickNext()}>
                <img className="rotate-45" src={ArrowRight45} />
              </button>
            </div>
          </div>
          {/* Current opportunities */}
          <div className="mt-[113px] max-w-[1300px] mx-auto">
            <h1 className="max-w-[467px] px-6 lg:px-0 lowercase tracking-tight font-bold lg:text-[60px] text-[40px] leading-[110%] text-white text-left">
              curent opportunities
            </h1>
            <div className="mt-[57px] mx-6 grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 gap-x-[87px] md:gap-y-[49px] gap-y-[34px]">
              {jobs?.data?.map((item) => (
                <Link
                  state={{ id: item?.id }}
                  to={`/work-with-us/recruitment/${item.id}`}
                >
                  <div
                    onMouseEnter={() => {
                      setAppear(true);
                      setWorkId(item?.id);
                    }}
                    onMouseLeave={() => setAppear(false)}
                    className=" h-[422px] border border-brand-orange transition-all overflow-hidden  bg-no-repeat relative"
                  >
                    {appear && workId == item?.id && (
                      <img
                        src={item?.attributes?.image?.data?.attributes?.url}
                        className="absolute transition-all left-0 top-0 w-full h-full object-cover -z-10"
                      />
                    )}
                    <h6 className="pt-[20px]  max-w-[293px] pl-[27px] text-white font-bold tracking-tight lowercase text-[52.3px] leading-[110%]">
                      {item?.attributes?.title}
                    </h6>
                    <div className="absolute left-[27px] bottom-[33px]">
                      <h5 className="text-brand-orange font-bold  mb-[15px] text-[20px] leading-[100%] lowercase">
                        {item?.attributes?.studio?.data?.attributes?.title}
                      </h5>
                      <p className="graphik-regula font-normal text-sm leading-[160%] graphik-regular text-white max-w-[204px]">
                        {
                          item?.attributes?.studio?.data?.attributes
                            ?.fullAddress
                        }
                      </p>
                    </div>
                    <span className="graphik-regular font-normal text-sm right-0 -translate-y-1/2 absolute  origin-bottom  rotate-90 bottom-[100px] text-white">
                      Posted{" "}
                      {moment(item?.attributes?.createdAt).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="w-full  border-y border-y-brand-orange expressions pt-[54px] px-[70px] pb-[62px] flex justify-between  mt-[110px]">
            <div className="lg:w-[1440px] w-full mx-auto lg:flex flex-row items-center justify-between">
              <div>
                <h2 className="font-bold text-white tracking-tight lowercase expression_title text-[60px] max-w-[337px] leading-[110%]">
                  {data?.data?.attributes?.requestTitle}
                </h2>
                <p className="mt-[211px] expression_dec graphik-regular font-normal text-sm leading-[160%] text-white max-w-[372px]">
                  {data?.data?.attributes?.requestDescription}
                </p>
              </div>
              <div className="flex-row overflow-hidden">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-[21px] gap-x-[40px] graphik-regular text-brand-orange">
                  <InputBase
                    style={"md:w-[391px] w-full "}
                    type="string"
                    holder="First name*"
                    isRequired={true}
                    isShowRequired={formControlled.firstName}
                    onchange={(e) =>
                      setForm({ ...form, firstName: e.target.value })
                    }
                  />
                  <InputBase
                    style={"md:w-[391px] w-full "}
                    type="email"
                    holder="Email address*"
                    isRequired={true}
                    isShowRequired={formControlled.email}
                    errorText={errText.email}
                    onchange={(e) => setForm({ ...form, email: e.target.value })}
                  />
                  <InputBase
                    style={"md:w-[391px] w-full "}
                    type="string"
                    holder="Last name*"
                    isRequired={true}
                    isShowRequired={formControlled.lastName}
                    onchange={(e) =>
                      setForm({ ...form, lastName: e.target.value })
                    }
                  />
                  <InputBase
                    style={"md:w-[391px] w-full "}
                    type="string"
                    holder="Phone number*"
                    isRequired={true}
                    isShowRequired={formControlled.phoneNumber}
                    onchange={(e) =>
                      setForm({ ...form, phoneNumber: e.target.value })
                    }
                  />

                  <SelectOption
                    titleStyle="text-[12px]"
                    title=""
                    isShowRequired={formControlled.location}
                    style=" h-[54px] graphik-regular text-[14px] md:w-[391px] w-full "
                    placeholder="location*"
                    placeholderColor="text-slate-400"
                    data={["act", "nsw", "qld", "vic"]}
                  ></SelectOption>
                  <SelectOption
                    titleStyle="text-[12px]"
                    isShowRequired={formControlled.department}
                    style=" h-[54px] graphik-regular text-[14px] md:w-[391px] w-full"
                    placeholder="Department*"
                    placeholderColor="text-slate-400"
                    data={["dev", "marketing"]}
                  ></SelectOption>
                  <SelectOption
                    titleStyle="text-[12px]"
                    // title="Position*"
                    isShowRequired={formControlled.position}
                    style=" h-[54px] graphik-regular text-[14px] md:w-[391px] w-full"
                    placeholder="Position*"
                    placeholderColor="text-slate-400"
                    data={["shred", "amrap"]}
                  ></SelectOption>
                  <SelectOption
                    titleStyle="text-[12px]"
                    title=""
                    isShowRequired={formControlled.heard}
                    style=" h-[54px] graphik-regular text-[14px] md:w-[391px] w-full"
                    placeholder="How did you hear about us?.*"
                    placeholderColor="text-slate-400"
                    data={["gyms", "friends", "internet", "advertise"]}
                  ></SelectOption>
                </div>
                <div
                  className="float-right mt-[36px]"
                  onClick={() => {
                    submit();
                  }}
                >
                  <ButtonActive height={56} text="send now" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default WorkWithUs;
