import React, { useState } from 'react';
import app from '../../assets/images/memberships/app.png';
import booking from '../../assets/images/memberships/booking.png';
import track from '../../assets/images/memberships/track.png';
import plus from '../../assets/images/memberships/plusActive.png';
import minus from '../../assets/images/memberships/minusActive.svg';
import acheieve from '../../assets/images/memberships/acheieve.png';
import LoadingScreen from '../../common/loading/LoadingScreen';
import ggPlay from "../../assets/images/ggplay.png";
import appStore from "../../assets/images/appstore.png";
import useFetch from '../../hooks/useFetch';
interface IProps {
  loading: boolean,
  data: object
}

function MembersApp(props: IProps) {
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(0);

  const { loading, data } = props
  const features = data?.data?.attributes?.features?.data
  const { data: appData } = useFetch({
    params: "global",
    populate: "appPromotion, appPromotion.image,appPromotion.items.icon",
  });


  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <div className="text-white mt-[57px] ">
            {features.map((item: object, index: number) => (
              <div
                onMouseEnter={() => {
                  setToggle(true);
                  setSelected(index);
                }}
                key={index}
              >
                <div
                  style={{
                    borderTop: selected === index && toggle ? '1px solid #FF6900' : '1px solid white',
                    borderBottom: index === 4 && !toggle ? '1px solid #C6C6C6' : '',
                  }}
                  className="flex cursor-pointer items-center py-5 pr-[24px] lg:pr-[65px] pl-[24px] lg:pl-[74px] justify-between"
                >
                  <p style={{ color: selected === index && toggle ? '#FF6900' : 'white' }} className="text-[16px] md:text-xl font-bold">
                    {item?.attributes?.title}
                  </p>
                  <span
                    onMouseLeave={() => {
                      setToggle(false);
                      setSelected(index);
                    }}
                  >
                    <img src={toggle && selected === index ? minus : plus} alt="plus icon" />
                  </span>
                </div>
                <ul
                  id={selected === index && toggle ? 'showlist' : 'hideList'}
                  style={{ paddingBottom: selected == index && toggle ? '25px' : '' }}
                  className="list-mems"
                >
                  <div className="flex items-center justify-between pl-[24px] lg:pl-[74px] lg:pr-[65px] pr-[24px] flex-col lg:flex-row">
                    <p className="mt-[0px] lg:mt-[15px] mb-[15px] lg:mb-0 leading-[160%] text-base font-normal max-w-[384px] graphik-regular text-white">
                      {item?.attributes?.description}
                    </p>
                    <img src={item?.attributes?.image?.data?.attributes?.url} className="w-[445px] h-[158px] object-cover" alt="coach" />
                  </div>
                </ul>
              </div>
            ))}
          </div>
          <div className="flex max-w-[1440px] mx-auto items-start lg:items-center flex-col lg:flex-row justify-between mt-[77px] pl-[24px] lg:pl-[66px] lg:pr-[141px] pr-[0px]">
            <img className="w-[388px] lg:w-[641px] h-[350px] lg:h-[578px]" src={app} alt="mobile" />
            <div className="font-bold text-[40px] lg:text-[62px] leading-[100%] text-white tracking-tighter">
              <h1 className="outline-text-1  mt-5 lg:mt-0 block lg:hidden">
                take <br /> control with our
              </h1>
              <h1 className="outline-text-1 hidden lg:block  mt-5 lg:mt-0">
                take control <br /> with our
              </h1>
              <h1 className='text-brand-orange lg:text-white'>members app</h1>
              <div className="mt-[32px] flex flex-col gap-[28px] ">
                {appData?.data?.attributes?.appPromotion?.items?.map(item => (
                  <div className='flex items-center gap-[16px]'>
                    <img className="h-[38.7px]" src={item?.icon?.data?.attributes?.url} alt="booking" />
                    <p className='text-white text-[20px] tracking-normal  font-bold leading-[100%]'>{item?.text}</p>
                  </div>
                ))}
              </div>
              <div className="flex  items-center ">
                <img
                  onClick={() => window.open(`${appData?.data?.attributes?.appPromotion?.appStoreUrl}`, "_blank")}
                  src={appStore}
                  className="mt-[24px] w-[148px] ml-2 mb-[20px]"
                />
                <a href={appData?.data?.attributes?.appPromotion?.playStoreUrl}>
                  <img
                    onClick={() => window.open(`${appData.data.attributes.appPromotion.playStoreUrl}`, "_blank")}
                    src={ggPlay}
                    className="  w-[170px] h-[52px] object-cover"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MembersApp;
