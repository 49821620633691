import React, { useEffect, useState, useContext } from "react";
import { yourDetail, yourMemberships } from "../../../mock/memberships";
import InputAnimated from "../../common/inputs/InputAnimated";
import Checkbox from "../../common/buttons/Checkbox";
import CheckboxActive from "../../common/buttons/CheckboxActive";
import ScrollToTop from "../../common/scrollEffect/ScrollToTop";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { RegisterContext } from "../../context/RegisterContext";
import SelectOutLine from "../../common/dropdowns/SelectOutline";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";

function YourDetail({ sliderRef }) {
  const [code, setCode] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  // Contact info
  const [contact, setContact] = useState<object>({
    firstName: "",
    lastName: "",
    Email: "",
    phone: "",
    gender: "",
    dob: "",
    address: "",
    startDate: "",
  });
  const [emergencyContact, setEmergencyContact] = useState<object>({});
  const [studentInfo, setStudentInfo] = useState({
    parentPhone: "",
    parentName: "",
  });

  // Context
  const yourClub = useContext(RegisterContext).yourClub;
  const membershipPlan = useContext(RegisterContext).membershipPlan;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;
  const upgrades = useContext(RegisterContext).upgrades;
  const addOns = useContext(RegisterContext).addOns;

  // Go to the next page
  const handleMoveSlide = (index: number) => {
    sliderRef.current.slickGoTo(index);
  };

  useEffect(() => {
    <ScrollToTop id={5} />;
  }, []);

  const handleChangeValue = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  // Get Age
  let age = moment(new Date()).diff(moment(contact.dob), "years");

  // Total Price
  let total = 0;
  upgrades.forEach((e) => {
    total += e?.attributes?.price?.[1]?.value;
  });

  return (
    <>
      <div className="w-full flex lg:flex-row flex-col lg:pl-[102px] lg:pr-0 px-6 items-start mt-[56px] justify-between">
        <div className="lg:w-[796px]">
          {/* Time start */}
          <h2 className="text-brand-orange text-[20px]  font-bold lowercase border-b border-b-brand-orange pb-2">
            {yourDetail.title}
          </h2>
          <div className="mt-4 text-white w-[50%]">
            <div
              className={`w-full border-b flex h-[59px]  flex-col pb-[2px]  relative ${
                contact.startDate !== ""
                  ? "border-b-brand-orange"
                  : "border-b-gray"
              } `}
            >
              <div className="absolute  bottom-0 w-full">
                <Flatpickr
                  onChange={(dateObj, dateStr) =>
                    setContact({ ...contact, startDate: dateStr })
                  }
                  options={{
                    enableTime: false,
                    time_24hr: false,
                    allowInput: false,
                  }}
                />
              </div>
              <label
                className={`gotham text-[12px] absolute left-0 ${
                  contact.startDate !== ""
                    ? "text-white top-[14px]"
                    : "text-white bottom-[12px]"
                }`}
              >
                Start date*
              </label>
              <div className="absolute -z-10 right-3 bottom-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_711_10427)">
                    <path
                      d="M2.86372 3.79346H2.86632C3.2918 3.79346 3.63672 3.43333 3.63672 2.9891L3.63672 0.801606C3.63672 0.357377 3.2918 -0.00273919 2.86632 -0.00273919H2.86372C2.43823 -0.00273919 2.09331 0.357377 2.09331 0.801606L2.09331 2.9891C2.09331 3.43333 2.43823 3.79346 2.86372 3.79346Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M11.2582 3.79346H11.2608C11.6863 3.79346 12.0312 3.43333 12.0312 2.9891V0.801606C12.0312 0.357377 11.6863 -0.00273919 11.2608 -0.00273919H11.2582C10.8328 -0.00273919 10.4878 0.357377 10.4878 0.801606V2.9891C10.4878 3.43333 10.8328 3.79346 11.2582 3.79346Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M1.06321 14.0002H12.942C13.2232 13.9995 13.4926 13.8823 13.6912 13.6745C13.8898 13.4666 14.0013 13.1851 14.0013 12.8915V2.63604C14.0013 2.34247 13.8898 2.06088 13.6912 1.85304C13.4926 1.64519 13.2232 1.52806 12.942 1.52734H1.06321C0.782029 1.52806 0.512595 1.64519 0.314011 1.85304C0.115427 2.06088 0.00390541 2.34247 0.00390625 2.63604V12.8915C0.00390541 13.1851 0.115427 13.4666 0.314011 13.6745C0.512595 13.8823 0.782029 13.9995 1.06321 14.0002ZM1.20896 5.03279H12.7962V12.511H1.20376L1.20896 5.03279Z"
                      fill="#FF6900"
                      fill-opacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_10427">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          {/* Contact Detail */}
          <div className="mt-[53px]">
            <h2 className="text-brand-orange text-[20px]  font-bold lowercase border-b border-b-brand-orange pb-2">
              {yourDetail.contactText}
            </h2>
          </div>
          <div className="mt-[34px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-[32px] text-white">
              <div>
                <InputAnimated
                  name="firstName"
                  onChange={handleChangeValue}
                  value={contact.firstName}
                  label="First name*"
                  type="text"
                />
              </div>
              <div>
                <InputAnimated
                  name="lastName"
                  onChange={handleChangeValue}
                  value={contact.lastName}
                  label="Last name*"
                  type="text"
                />
              </div>
              <div>
                <InputAnimated
                  name="email"
                  onChange={handleChangeValue}
                  value={contact.email}
                  label="Email address*"
                  type="email"
                />
              </div>
              <div>
                <InputAnimated
                  name="phone"
                  onChange={handleChangeValue}
                  value={contact.phone}
                  label="Phone number*"
                  type="text"
                />
              </div>
              <div>
                {/* <InputAnimated label="Gender*" type="date" /> */}
                <SelectOutLine
                  titleStyle="text-[12px]"
                  style=" h-[54px] w-full graphik-regular text-[14px]"
                  placeholder="State*"
                  // isShowRequired={formControlled.state}
                  placeholderColor="text-slate-400"
                  // onSelect={(v) => setForm({ ...form, state: v })}
                  data={["Male", "Female"]}
                ></SelectOutLine>
              </div>
              <div>
                <div className="text-white ">
                  <div
                    className={`w-full border-b flex h-[59px]  flex-col pb-[2px]  relative ${
                      contact.dob !== ""
                        ? "border-b-brand-orange"
                        : "border-b-gray"
                    } `}
                  >
                    <div className="absolute  bottom-0 w-full">
                      <Flatpickr
                        onChange={(dateObj, dateStr) =>
                          setContact({ ...contact, dob: dateStr })
                        }
                        options={{
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                        }}
                      />
                    </div>
                    <label
                      className={`gotham text-[12px] absolute left-0 ${
                        contact.dob !== ""
                          ? "text-white top-[14px]"
                          : "text-white bottom-[12px]"
                      }`}
                    >
                      DOB*
                    </label>
                    <div className="absolute -z-10 right-3 bottom-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_711_10427)">
                          <path
                            d="M2.86372 3.79346H2.86632C3.2918 3.79346 3.63672 3.43333 3.63672 2.9891L3.63672 0.801606C3.63672 0.357377 3.2918 -0.00273919 2.86632 -0.00273919H2.86372C2.43823 -0.00273919 2.09331 0.357377 2.09331 0.801606L2.09331 2.9891C2.09331 3.43333 2.43823 3.79346 2.86372 3.79346Z"
                            fill="#FF6900"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M11.2582 3.79346H11.2608C11.6863 3.79346 12.0312 3.43333 12.0312 2.9891V0.801606C12.0312 0.357377 11.6863 -0.00273919 11.2608 -0.00273919H11.2582C10.8328 -0.00273919 10.4878 0.357377 10.4878 0.801606V2.9891C10.4878 3.43333 10.8328 3.79346 11.2582 3.79346Z"
                            fill="#FF6900"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M1.06321 14.0002H12.942C13.2232 13.9995 13.4926 13.8823 13.6912 13.6745C13.8898 13.4666 14.0013 13.1851 14.0013 12.8915V2.63604C14.0013 2.34247 13.8898 2.06088 13.6912 1.85304C13.4926 1.64519 13.2232 1.52806 12.942 1.52734H1.06321C0.782029 1.52806 0.512595 1.64519 0.314011 1.85304C0.115427 2.06088 0.00390541 2.34247 0.00390625 2.63604V12.8915C0.00390541 13.1851 0.115427 13.4666 0.314011 13.6745C0.512595 13.8823 0.782029 13.9995 1.06321 14.0002ZM1.20896 5.03279H12.7962V12.511H1.20376L1.20896 5.03279Z"
                            fill="#FF6900"
                            fill-opacity="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_711_10427">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cols-span-1 lg:col-span-2 ">
                <InputAnimated
                  name="address"
                  onChange={handleChangeValue}
                  value={contact.address}
                  label="Search your address*"
                  type="text"
                />
              </div>
            </div>
            {age < 18 && (
              <>
                <div className="mt-[62px]">
                  <h2 className="text-[20px] text-brand-orange  font-bold graphik-regular lowercase border-b border-b-brand-orange pb-2">
                    under 18
                  </h2>
                </div>
                <div className="mt-[34px]">
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white gap-x-[32px]">
                    <InputAnimated
                      onchange={(e) =>
                        setStudentInfo({
                          ...studentInfo,
                          ["parentName"]: e.target.value,
                        })
                      }
                      nameInput="parentName"
                      label="Parent/Guardian name*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputAnimated
                      onchange={(e) =>
                        setStudentInfo({
                          ...studentInfo,
                          ["parentPhone"]: e.target.value,
                        })
                      }
                      nameInput="parentPhone"
                      label="Parent/Guardian phone number*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                  </div>
                </div>
                {/* Privacy policy */}
                <div className="flex items-center mt-[46px] gap-[22px]">
                  <div className="text-white">
                    <CheckboxActive />
                  </div>
                  <p className="font-normal max-w-[562px] leading-[160%] text-white graphik-regular lg:text-[12px] text-[11px]">
                    I consent that I have permission from my legal
                    parent/guardian to join Viva Leisure Group. I understand
                    that misleading information will result in termination of my
                    membership.
                  </p>
                </div>
              </>
            )}
          </div>
          {/* Heaalth check */}
          <div className="mt-[63px]">
            <h2 className="text-brand-orange text-[20px]  font-bold lowercase border-b border-b-brand-orange pb-2">
              {yourDetail.health.title}
            </h2>
          </div>
          {/* Actions */}
          <div className="mt-[21px] flex flex-col lg:flex-row gap-6 lg:gap-0 items-center justify-between">
            <h2 className="graphik-regular font-normal text-sm max-w-[448px] text-white">
              {yourDetail.health.description}
            </h2>
            <div className="flex items-center gap-[20px]">
              <button
                onClick={() => setAccepted(false)}
                className={`text-white h-[56px] w-[117px] font-bold  ${
                  accepted
                    ? "border-b"
                    : "border-b-brand-orange border-b-[5px] pt-[5px]"
                } px-[32px] border-t border-l bg-[black] border-r border-white`}
              >
                no
              </button>
              <button
                onClick={() => setAccepted(true)}
                className={`text-white h-[56px] ${
                  !accepted
                    ? "border-b"
                    : "border-b-brand-orange border-b-[5px] pt-[5px]"
                } w-[117px] font-bold px-[32px] border-t border-l bg-[black] border-r border-white`}
              >
                yes
              </button>
            </div>
          </div>
          {/* Tick all that apply */}
          {accepted && (
            <div className="text-white mt-5">
              <h6 className="text-gray font-normal text-sm pb-2 border-b graphik-regular border-b-gray">
                {yourDetail.health.tickTitle}
              </h6>
              {yourDetail.health.listApply.map(
                (item: string, index: number) => (
                  <div
                    key={index}
                    className="py-[17px] border-b border-b-gray flex items-center justify-between"
                  >
                    <h5 className="graphik-regular font-normal text-sm">
                      {item}
                    </h5>
                    <CheckboxActive />
                  </div>
                )
              )}
            </div>
          )}
          {/* Emergency contact */}
          <div className="mt-[53px]">
            <h2 className="text-brand-orange text-[20px]  font-bold lowercase border-b border-b-brand-orange pb-2">
              {yourDetail.emergencyText}
            </h2>
          </div>
          <div className="mt-[34px]">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white gap-x-[32px]">
              <div>
                <InputAnimated label="Emergency contact*" type="text" />
              </div>
              <div>
                <InputAnimated label="Relation to you*" type="text" />
              </div>

              <div>
                <InputAnimated label="Emergency phone*" type="text" />
              </div>
            </div>
          </div>
          {/* Privacy policy */}
          <div className="flex items-center mt-[46px] gap-[25px]">
            <div className="text-white">
              <Checkbox />
            </div>
            <p className="font-normal max-w-[598px] text-[13px] leading-[160%] text-white graphik-regular">
              {yourDetail.privacy}
            </p>
          </div>
          {/* Prev + Next Page */}
          <div className="flex items-center w-full justify-between mt-[40px] mb-[90px]  lg:flex hidden ">
            <button
              onClick={() => sliderRef.current.slickPrev()}
              className={
                "h-[56px] w-[55px] flex items-center justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white "
              }
            >
              <div className="rotate-[180deg] text-center ">
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767"
                    stroke="white"
                    stroke-width="1.3261"
                  />
                  <path
                    d="M15.9743 11.1309H0.998047"
                    stroke="white"
                    stroke-width="1.3261"
                  />
                </svg>
              </div>
            </button>

            <ButtonNavigate
              onClick={() => sliderRef.current.slickNext()}
              text="Confirm"
              style="h-[56px] w-[160px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
              isArrowRight={true}
            ></ButtonNavigate>
          </div>
        </div>
        {/* Your membership */}
        <div className="w-[339px]">
          <h2 className="text-brand-orange text-[20px] font-bold lowercase border-b border-b-brand-orange pb-2">
            {yourMemberships.title}
          </h2>
          <div className="w-full justify-between flex items-center h-4 mt-4 pr-[40px]">
            <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
              {yourMemberships.homeClubText}
            </h2>
            <span
              onClick={() => setIsChangeClub(true)}
              className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer "
            >
              Edit
            </span>
          </div>
          <div className="font-bold text-sm mt-3 leading-[100%] lowercase text-white pb-4 border-b border-b-gray">
            {yourClub?.attributes?.title}
          </div>
          <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
            <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
              {yourMemberships.membershipPlanText}
            </h2>
            <span
              onClick={() => handleMoveSlide(1)}
              className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer"
            >
              Edit
            </span>
          </div>
          {/* List selected */}
          <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
            <div className="flex items-center justify-between">
              <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                {membershipPlan?.attributes?.title}
              </h2>
              <span className="text-white font-bold lowercase">${parseFloat(membershipPlan?.attributes?.price).toFixed(2)}</span>
            </div>
            {membershipPlan?.attributes?.features?.data?.map((item, index) => (
              <div
                key={index}
                className="flex items-center mt-[11px] justify-between"
              >
                <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                  {item?.attributes?.title}
                </h2>
              </div>
            ))}
          </div>
          <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
            <h2 className="text-[12px] leading-[160px] font-normal graphik-regular text-white">
              Upgrades/add-ons
            </h2>
            <span
              onClick={() => handleMoveSlide(2)}
              className="text-gray text-right  text-[12px] underline graphik-regular font-normal cursor-pointer"
            >
              Edit
            </span>
          </div>
          {/* List selected */}
          <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
            {upgrades?.map((item: object, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                  {item?.attributes?.title}
                </h2>
                <span className="text-white font-bold lowercase">${parseFloat(item?.attributes?.price?.[0]?.value).toFixed(2)}</span>
              </div>
            ))}
            {addOns?.map((item: object, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <h2 className="text-sm font-bold text-white leading-[100%] lowercase">
                  {item?.attributes?.title}
                </h2>
                <span className="text-white font-bold lowercase">${parseFloat(item?.attributes?.price?.[0]?.value).toFixed(2)}</span>
              </div>
            ))}
          </div>

          {/* Promo code */}
          <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
            <h5 className="font-normal text-sm text-white graphik-regular">
              {yourMemberships.promoCodeText}
            </h5>
            <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
              <span className="w-[20px] text-sm text-brand-orange">hr-</span>
              <input
                onChange={(e) => setCode(e.target.value)}
                type="text"
                className="w-full h-full ml-1 border-0 text-brand-orange outline-0 bg-transparent"
              />
            </div>
          </div>
          <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
            <h5 className="font-normal text-sm text-white graphik-regular">
              {yourMemberships.dateText}
            </h5>
            <div className="rounded flex items-center py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
              <span className="datepicker-toggle">
                <Flatpickr
                  placeHolder="YYY/MM/DDD"
                  options={{ enableTime: false, time_24hr: false }}
                />
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_711_10427)">
                  <path
                    d="M2.86372 3.79346H2.86632C3.2918 3.79346 3.63672 3.43333 3.63672 2.9891L3.63672 0.801606C3.63672 0.357377 3.2918 -0.00273919 2.86632 -0.00273919H2.86372C2.43823 -0.00273919 2.09331 0.357377 2.09331 0.801606L2.09331 2.9891C2.09331 3.43333 2.43823 3.79346 2.86372 3.79346Z"
                    fill="#FF6900"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M11.2582 3.79346H11.2608C11.6863 3.79346 12.0312 3.43333 12.0312 2.9891V0.801606C12.0312 0.357377 11.6863 -0.00273919 11.2608 -0.00273919H11.2582C10.8328 -0.00273919 10.4878 0.357377 10.4878 0.801606V2.9891C10.4878 3.43333 10.8328 3.79346 11.2582 3.79346Z"
                    fill="#FF6900"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M1.06321 14.0002H12.942C13.2232 13.9995 13.4926 13.8823 13.6912 13.6745C13.8898 13.4666 14.0013 13.1851 14.0013 12.8915V2.63604C14.0013 2.34247 13.8898 2.06088 13.6912 1.85304C13.4926 1.64519 13.2232 1.52806 12.942 1.52734H1.06321C0.782029 1.52806 0.512595 1.64519 0.314011 1.85304C0.115427 2.06088 0.00390541 2.34247 0.00390625 2.63604V12.8915C0.00390541 13.1851 0.115427 13.4666 0.314011 13.6745C0.512595 13.8823 0.782029 13.9995 1.06321 14.0002ZM1.20896 5.03279H12.7962V12.511H1.20376L1.20896 5.03279Z"
                    fill="#FF6900"
                    fill-opacity="0.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_711_10427">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
            <h5 className="font-normal graphik-regular text-sm text-white ">
              {yourMemberships.costPerForNightText}
            </h5>
            <div className="text-brand-orange text-sm font-bold leading-[100%]">
              ${parseFloat(total + membershipPlan?.attributes?.price).toFixed(2)}
            </div>
          </div>
          <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
            <div className="flex items-center relative justify-center">
              <h5 className="font-normal graphik-regular text-sm text-white ">
                {yourMemberships.totalText}
              </h5>
              <span
                onMouseLeave={() => setTooltip(false)}
                onMouseEnter={() => setTooltip(true)}
                className="ml-2 w-[21px] h-[21px] rounded-[50%] graphik-regular flex items-center cursor-pointer justify-center text-white text-[11px] border border-white"
              >
                ?
              </span>
              {/* Tooltip */}
              {tooltip && (
                <div
                  onMouseEnter={() => setTooltip(true)}
                  onMouseLeave={() => setTooltip(false)}
                  className="absolute text-[12px] border border-white font-normal right-[22px] top-0 bg-black w-[221px] py-[10px] px-3 text-white graphik-regular"
                >
                  {yourMemberships.description}
                </div>
              )}
            </div>
            <div className="text-brand-orange text-sm font-bold leading-[100%]">
              $44.80
            </div>
          </div>
          <div className="underline pr-[40px] graphik-regular mt-[13px] float-right font-normal text-[12px] text-gray">
            {yourMemberships.contractText}
          </div>
        </div>
      </div>
      <div className="flex items-center  justify-between mt-[40px] mb-[90px] mx-6 lg:hidden block ">
        <button
          onClick={() => sliderRef.current.slickPrev()}
          className={
            "h-[56px] w-[55px] flex items-center justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white "
          }
        >
          <div className="rotate-[180deg] text-center ">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.20117 4.28906L15.9402 11.028L9.20117 17.767"
                stroke="white"
                stroke-width="1.3261"
              />
              <path
                d="M15.9743 11.1309H0.998047"
                stroke="white"
                stroke-width="1.3261"
              />
            </svg>
          </div>
        </button>

        <ButtonNavigate
          onClick={() => sliderRef.current.slickNext()}
          text="Confirm"
          style="h-[56px] w-[160px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
          isArrowRight={true}
        ></ButtonNavigate>
      </div>
    </>
  );
}

export default YourDetail;
