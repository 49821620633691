import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import Banner from "../components/memberships/Banner";
import MembersApp from "../components/memberships/MembersApp";
import PricesForBraddon from "../components/memberships/PricesForBraddon";
import WelcomeHiit from "../components/memberships/WelcomeHiit";
import useFetch from "../hooks/useFetch";

function Memberships() {
  const { data, loading } = useFetch({ params: 'membership-page', populate: 'topBanner.background,features,features.image,midBanner.images' });

  return (
    <div className="text-white">
      <Layout>
        <Banner data={data} loading={loading} />
        <div className="max-w-[1440px] mx-auto">
          <PricesForBraddon />
          <WelcomeHiit data={data} loading={loading} />
          <MembersApp data={data} loading={loading} />
        </div>
      </Layout>
    </div>
  );
}

export default Memberships;
