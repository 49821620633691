import React, { useContext, useState } from "react";
import { RegisterContext } from "../../../context/RegisterContext";
import { yourMemberships, payment } from "../../../../mock/memberships";
import ArrowButton from "../../../common/buttons/ArrowButton";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import InputBase from "../../../common/inputs/InputBaseDashBoard";
import CheckboxActive from "../../../common/buttons/CheckboxActive";
function YourPayment(props) {
  const { setStep } = props;
  const [typeOfPayment, setTypeOfPayment] = useState("card");
  // Context
  const yourClub = useContext(RegisterContext).yourClub;
  const upgrades = useContext(RegisterContext).upgrades;
  const setUpgrades = useContext(RegisterContext).setUpgrades;
  const plan = useContext(RegisterContext).plan;

  // Total Price
  let total = 0;
  upgrades.forEach((e) => {
    total += e?.attributes?.price?.[1]?.value;
  });

  return (
    <>
      <div className=" flex gap-[8px] items-center mt-[34px]">
        <div
          onClick={() => setStep(5)}
          className="w-[41px] h-[48px] p-[7px] pl-0"
        >
          <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
            <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
          </div>
        </div>
        <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
          step 5
        </span>
      </div>
      <div className="mt-[35px]">
        <h6 className="uppercase text-white text-sm leading-[140%] tracking-widest gotham">
          step 6
        </h6>
        <p className="mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white">
          your payment method
        </p>
        <div className="flex items-start justify-between w-full xl:flex-row flex-col">
          <div className=" ">
            <div className="mt-[60px] lg:w-[597px]">
              {/* Ongoing payment details */}
              <div className="mt-[36px]">
                <h2 className="text-white border-b border-b-white pb-2 w-full  font-normal tracking-widest  gotham text-sm uppercase ">
                  payment method
                </h2>
                <div>
                  <div className="mt-[36px] flex lg:flex-row flex-col lg:items-center items-start   justify-between">
                    <div className="flex items-center lg:w-auto w-full">
                      <button
                        onClick={() => setTypeOfPayment("card")}
                        className={`text-white uppercase h-[40px] rounded-l lg:w-[150px] w-1/2 text-[11px] gotham font-bold  ${
                          typeOfPayment !== "card"
                            ? "border-b border-white text-white"
                            : "border-b-white text-black  bg-white pt-[3px]"
                        } border-t border-l bg-[black] border-r`}
                      >
                        BANK ACCOUNT
                      </button>
                      <button
                        onClick={() => setTypeOfPayment("bank")}
                        className={`text-white uppercase h-[40px] rounded-r lg:w-[150px] w-1/2 text-[11px] gotham font-bold  ${
                          typeOfPayment !== "bank"
                            ? "border-b border-white text-white"
                            : "border-b-white text-black  bg-white pt-[3px]"
                        } border-t border-l bg-[black] border-r`}
                      >
                        CREDIT CARD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Bank info */}
              {typeOfPayment === "bank" ? (
                <div className="mt-[27px]">
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white">
                    <InputBase
                      holder="Account name*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputBase
                      holder="Bank name*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputBase
                      holder="BSB*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputBase
                      holder="Account number*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-[38px]">
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-4 text-white">
                    <InputBase
                      holder="Card number*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputBase
                      holder="Name on cart*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                    <InputBase
                      holder="CVV*"
                      style="lg:w-full"
                      isRequired={true}
                    />
                  </div>
                </div>
              )}
              <div className="flex items-start justify-between gap-[15px] mt-[37px] xl:border-none border-b xl:pb-0 pb-[37px] border-white">
                <div className="text-white">
                  <CheckboxActive isDashboard={true} />
                </div>
                <p className="font-normal text-[13px] -mt-1 leading-[160%] text-white gotham lg:w-[549px]">
                  I confirm these details are true and correct, my existing
                  direct debit amount to amended.
                </p>
              </div>
            </div>
            <div className="flex items-center w-full  justify-between mt-[58px] mb-[90px] xl:flex hidden">
              <ArrowButton
                text="BACK"
                isRight={false}
                onClick={() => {
                  setStep(5);
                }}
              />
              <ArrowButton
                text="NEXT"
                isRight={true}
                onClick={() => {
                  setStep(7);
                }}
              />
            </div>
          </div>
          <div className="text-white mt-6 xl:mt-[-60px]">
            <div className="lg:w-[339px] ">
              <h2 className="text-white gotham text-[14px] font-normal  border-b border-b-white leading-[90%] uppercase pb-2">
                Your new membership
              </h2>
              <div className="w-full justify-between flex items-center h-4 mt-4 pr-[40px]">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                  Home Club
                </h2>
                <span
                  onClick={() => setStep(2)}
                  className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer "
                >
                  Edit
                </span>
              </div>
              <div
                style={{ color: yourClub?.attributes?.color }}
                className="font-normal  text-[12px] mt-3 uppercase gotham leading-[140%] pb-4 border-b border-b-gray"
              >
                {yourClub?.attributes?.title}
              </div>
              {/* Home Club */}
              <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white gotham">
                  Membership Plan
                </h2>
                <span
                  onClick={() => setStep(3)}
                  className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer"
                >
                  Edit
                </span>
              </div>
              {/* Membership Plan */}
              <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                <div className="flex items-center justify-between">
                  <h2 className="gotham uppercase text-[11px] text-white leading-[140%] ">
                    {plan?.attributes?.title} membership
                  </h2>
                  <span className="text-white gotham text-[11px] leading-[140%] font-normal uppercase">
                    ${plan?.attributes?.price}.00
                  </span>
                </div>
                <div className="flex mt-[10px] gap-[10px] flex-col">
                  {plan?.attributes?.features?.data?.map((item) => (
                    <h2 className="gotham  uppercase text-[11px] text-white leading-[140%] ">
                      {item?.attributes?.title}
                    </h2>
                  ))}
                </div>
              </div>
              {/* Upgrades */}
              <div className="w-full justify-between flex items-center h-4 pr-[40px] mt-3">
                <h2 className="text-[12px] leading-[160px] font-normal gotham text-white">
                  Upgrades/Add-ons
                </h2>
                <span className="text-gray text-right  text-[12px] underline gotham font-normal cursor-pointer">
                  Edit
                </span>
              </div>
              <div className="flex-col gap-y-[11px] mt-[15px] pb-5 pr-[40px] border-b border-b-gray">
                <div className="flex mt-[10px] gap-[10px] flex-col">
                  {upgrades?.map((item) => (
                    <div className="flex items-center justify-between">
                      <h2
                        style={{ color: item?.attributes?.color }}
                        className="gotham  uppercase text-[11px] text-white leading-[140%] "
                      >
                        {item?.attributes?.title}
                      </h2>
                      <span className="gotham  uppercase text-[11px] text-white leading-[140%]">
                        ${item?.attributes?.price[1]?.value}.00
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                <h5 className="font-normal gotham text-sm text-white ">
                  {yourMemberships.costPerForNightText}
                </h5>
                <div className="text-white text-sm font-normal gotham text-[12px] leading-[100%]">
                  ${total + plan?.attributes?.price}.00
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                <div className="flex items-center relative justify-center">
                  <h5 className="font-normal gotham text-[12px] text-white ">
                    {yourMemberships.totalText}
                  </h5>
                </div>
                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                  $44.80
                </div>
              </div>
              {/* Promo code */}
              <div className="py-[8px] border-b border-b-gray justify-between flex items-center pr-[40px] ">
                <h5 className="font-normal text-sm text-white gotham">
                  {yourMemberships.promoCodeText}
                </h5>
                <div className="rounded flex items-center  py-2 px-[10px] w-[147px] h-[33px] w border border-gray">
                  <span className="w-[20px] text-white gotham text-[12px] uppercase">
                    cl-
                  </span>
                  <input
                    // onChange={(e) => setCode(e.target.value)}
                    type="text"
                    className="w-full h-full ml-1 border-0 text-white font-normal gotham text-[12px] outline-0 bg-transparent"
                  />
                </div>
              </div>
              <div className="py-[8px] border-b border-b-gray justify-between text-[12px] gotham font-normal flex items-center pr-[40px] ">
                <div className="flex items-center relative justify-center">
                  <h5 className="font-normal gotham text-[12px] text-white ">
                    Direct debit starts
                  </h5>
                </div>
                <div className="text-white text-[12px] font-normal gotham leading-[100%]">
                  01/09/2023
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full  justify-between mt-[58px] mb-[90px] xl:hidden block ">
            <ArrowButton
              text="BACK"
              isRight={false}
              onClick={() => {
                setStep(5);
              }}
            />
            <ArrowButton
              text="NEXT"
              isRight={true}
              onClick={() => {
                setStep(7);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default YourPayment;
