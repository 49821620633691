import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import { Location } from "@reach/router";
import moment from "moment";
import plus from "../../assets/images/memberships/plus.png";
import { createReactEditorJS } from "react-editor-js";
import LoadingScreen from "../../common/loading/LoadingScreen";
import InputBase from "../../common/inputs/InputBase.tsx";
import {
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from "react-share";
import InputTextArea from "../../common/inputs/textArea";
import SelectOption from "../../common/dropdowns/SelectOption";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
function WorkDetail() {
  const [id, setId] = useState<number>();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isApply, setIsApply] = useState<boolean>(false);
  const { data, loading } = useFetch({ params: `recruitments/${id}`, populate: "studio.image,image", });
  const { data: jobs } = useFetch({ params: `recruitments`, populate: "studio,image", });
  const EditorJS = createReactEditorJS();
  const [files, setFiles] = useState([]);
  const [workId, setWorkId] = useState<string>('')
  const [EDITOR_JS_TOOLS, setTools] = useState<any>()
  useEffect(async () => {
    const { EDITOR_JS_TOOLS } = await import("../../utils/Tools")
    setTools(EDITOR_JS_TOOLS)
  }, [])
  const handleUploadResume = (e: any) => {
    const file = e.target.files;
    setFiles([...file]);
    // const err = beforeUploadPdfOrWord(file);
    // if (!err) return;
  };
  const closeAttched = (value: string) => {
    const fileAF = [...files];
    fileAF.splice(value, 1);
    setFiles(fileAF);
  };
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    heard: "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    heard: false,
    q1: false,
    q2: false,
    q3: false,
    q4: false,
  });
  const [errText, setErrText] = useState({ email: "" });


  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {

          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);

      if (Object.values(control).some((i) => i)) setIsApply(true);
    });
  };


  return (
    <Layout>
      <Location>
        {({ location }) => {
          setId(location?.state?.id);
          return null;
        }}
      </Location>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          {/* Breadcrumb */}
          <div className="flex items-center max-w-[1300px] lg:mx-auto mx-[25px] lg:mt-[70px] mt-[33px] mb-[17px] cursor-pointer graphik-regular text-[16px] font-normal text-gray ">
            <Link to="/work-with-us">
              <p className="border-b-[0.1px] border-b-gray font-normal pb-[0.3px] mx-1 graphik-regular">
                Work with us /{" "}
              </p>
            </Link>
            <p className="ml-1 border-b-[0.1px] border-b-gray pb-[0.3px] font-normal graphik-regular">
              {data?.data?.attributes?.title}
            </p>
            {isApply && (
              <p className="border-b-[0.1px] border-b-gray  font-normal pb-[0.3px] mx-1 graphik-regular">
                / Apply now{" "}
              </p>
            )}
          </div>
          <div className="max-w-[1300px] mx-auto  flex work-container lg:gap-[95px] gap-[25px] justify-between">
            {/* Info */}
            <div className="flex-1 ">
              {!isApply && (
                <div className="flex items-start lg:mx-0 mx-[25px] justify-between">
                  <div>
                    <h1 className="font-bold lg:text-[60px] text-[40px] lowercase tracking-tight leading-[110%] text-white max-w-[460px]">
                      {data?.data?.attributes?.title}
                    </h1>
                    <h5 className="text-brand-orange text-[24px] font-bold">
                      {data?.data?.attributes?.studio?.data?.attributes?.title}
                    </h5>
                  </div>
                  <div className="apply-btn">
                    <ButtonNavigate
                      text="apply now"
                      isArrowRight={true}
                      onClick={() => setIsApply(true)}
                      style="h-[56px] w-[160px] flex items-center float-right gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                    ></ButtonNavigate>
                  </div>
                </div>
              )}

              {/* Editor rendering */}
              {!isApply && (
                <div className="max-w-[790px] lg:mx-0 mx-[25px] lg:mt-[58px] mt-[25px]">
                  <EditorJS
                    readOnly={true}
                    tools={EDITOR_JS_TOOLS}
                    defaultValue={
                      data?.data?.attributes &&
                      JSON.parse(data?.data?.attributes?.content)
                    }
                  />
                </div>
              )}
              {/* Apply job  */}
              {isApply && (
                <div className=" text-white lg:mx-0 mx-[25px] mt-[17px]">
                  <h1 className="outline-text-1 font-bold lg:text-[60px] text-[40px] tracking-tight lowercase leading-[110%]">
                    you are applying for
                  </h1>
                  <h1 className="text-white font-bold lg:text-[60px] text-[40px] tracking-tight lowercase leading-[110%]">
                    {data?.data?.attributes?.title}
                  </h1>
                  <h5 className="mt-[32px] font-bold text-[24px] leading-[100%] text-brand-orange">
                    {data?.data?.attributes?.studio?.data?.attributes?.title}
                  </h5>
                  {/* Form */}
                  <div className="mt-[44px] grid gap-y-[3px] gap-x-[35px] xl:grid-cols-2 grid-cols-1">
                    <InputBase
                    style='w-full max-w-[788px]'
                      type="string"
                      holder="First name*"
                      isRequired={true}
                      isShowRequired={formControlled.firstName}
                      onchange={(e) =>
                        setForm({ ...form, firstName: e.target.value })
                      }
                    />
                    <InputBase
                    style='w-full max-w-[788px]'
                      type="string"
                      holder="Last name*"
                      isRequired={true}
                      isShowRequired={formControlled.lastName}
                      onchange={(e) =>
                        setForm({ ...form, lastName: e.target.value })
                      }
                    />
                    <InputBase
                    style='w-full max-w-[788px]'
                      type="email"
                      holder="Email address*"
                      errorText={errText.email}
                      isRequired={true}
                      isShowRequired={formControlled.email}
                      onchange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                    <InputBase
                    style='w-full max-w-[788px]'
                      type="string"
                      holder="Phone number*"
                      isRequired={true}
                      isShowRequired={formControlled.phoneNumber}
                      onchange={(e) =>
                        setForm({ ...form, phoneNumber: e.target.value })
                      }
                    />
                    <div className=" w-full max-w-[788px] xl:col-span-2 ">
                      <div className="xl:col-span-2 w-full max-w-[788px] ">
                        <SelectOption
                          titleStyle="text-[12px]"
                          title=""
                          style=" h-[54px] graphik-regular w-full text-[14px]"
                          placeholder="how did you hear about us?"
                          placeholderColor="text-slate-400"
                          data={[
                            "gym time",
                            "friend",
                            "facebook",
                            "google",
                            "family",
                            "other",
                          ]}
                          isShowRequired={formControlled.heard}
                          onSelect={(v) => setForm({ ...form, heard: v })}
                        ></SelectOption>
                      </div>
                    </div>
                    <div className="xl:col-span-2 w-full max-w-[788px] ">
                      <InputTextArea
                        isShowRequired={formControlled.q1}
                        placeholder="Question 1 here"
                        type="text"
                        className="border-0 text-sm bg-transparent outline-0 graphik-regular w-full h-[144px] placeholder:text-white  px-6"
                        onchange={(e) =>
                          setForm({ ...form, q1: e.target.value })
                        }
                      />
                    </div>
                    <div className="xl:col-span-2 w-full max-w-[788px] ">
                      <InputTextArea
                        isShowRequired={formControlled.q2}
                        placeholder="Question 2 here"
                        type="text"
                        className="border-0 text-sm bg-transparent outline-0 graphik-regular w-full h-[144px] placeholder:text-white  px-6"
                        onchange={(e) =>
                          setForm({ ...form, q2: e.target.value })
                        }
                      />
                    </div>
                    <div className="xl:col-span-2 w-full max-w-[788px] ">
                      <InputTextArea
                        isShowRequired={formControlled.q3}
                        placeholder="Question 3 here"
                        type="text"
                        className="border-0 text-sm bg-transparent outline-0 graphik-regular w-full h-[144px] placeholder:text-white  px-6"
                        onchange={(e) =>
                          setForm({ ...form, q3: e.target.value })
                        }
                      />
                    </div>
                    <div className="xl:col-span-2 w-full max-w-[788px] ">
                      <InputTextArea
                        isShowRequired={formControlled.q4}
                        placeholder="Question  here"
                        type="text"
                        className="border-0 text-sm bg-transparent outline-0 graphik-regular w-full h-[144px] placeholder:text-white  px-6"
                        onchange={(e) =>
                          setForm({ ...form, q4: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  {/* Upload */}
                  <div className="flex gap-6">
                    {files.length == 0 && (
                      <div>
                        <div className="mt-[32px] flex items-center">
                          <button
                            className={`text-white  font-bold border-b-[5px] relative h-[56px] flex items-center justify-center w-[58px] border-b-brand-orange border-t border-l bg-[black] border-r border-white`}
                          >
                            <label>
                              <input
                                onChange={handleUploadResume}
                                type="file"
                                accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word"
                                className="w-[56px] opacity-0 py-2 absolute z-10 left-0 top-0"
                                multiple
                              />
                            </label>
                            <img
                              src={plus}
                              className="absolute left-1/2 -translate-x-1/2"
                            />
                          </button>
                          <p className="ml-[14px] graphik-regular text-white text-sm">
                            Attach your resume
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col">
                      {files?.map((file, i) => (
                        <div className="mt-[32px] flex items-center">
                          <button
                            className={`text-white  font-bold border-b-[5px] relative h-[56px] flex items-center justify-center w-[58px] border-b-brand-orange border-t border-l bg-[black] border-r border-white`}
                            onClick={() => closeAttched(i)}
                          >
                            <img
                              src={plus}
                              className="absolute rotate-45 left-1/2 -translate-x-1/2"
                            />
                          </button>
                          <p className="ml-[14px] graphik-regular text-brand-orange text-sm">
                            {file.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <h4 className="text-white text-sm graphik-regular mt-[34px] max-w-[261px]">
                    File type must be Word or PDF and total file size must be
                    1mb or lower.{" "}
                  </h4>
                  <ButtonNavigate
                    text="apply"
                    isArrowRight={true}
                    onClick={() => submit()}
                    style="h-[56px] w-[160px] flex items-center float-right gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                  ></ButtonNavigate>

                </div>
              )}
              {!isApply && (
                <ButtonNavigate
                  text="apply"
                  isArrowRight={true}
                  onClick={() => setIsApply(true)}
                  style="h-[56px] w-[160px] flex items-center lg:ml-0 ml-[25px] gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                ></ButtonNavigate>
              )}
            </div>

            {/* Adress */}
            <div className="text-white order-1 lg:mx-0 mx-[25px] ">
              <p className="text-white font-normal text-[16px] mb-[10px] graphik-regular max-w-[267px] ">
                {data?.data?.attributes?.studio?.data?.attributes?.address}
              </p>
              <p className="text-brand-orange text-[16px] font-normal graphik-regular">
                {" "}
                {data?.data?.attributes?.studio?.data?.attributes?.phoneNumber}
              </p>

              <img
                src={data?.data?.attributes?.image?.data?.attributes?.url}

                className="w-[415px] h-[282px] mt-[23px]"
              />
              <p className="text-white font-normal mt-[31px] text-[16px] mb-[10px] graphik-regular max-w-[257px] ">
                Share this opportunities
              </p>
              <div className="text-white flex items-center gap-[14px] cursor-pointer">
                <EmailShareButton url="" subject="Join the hiit republic team">
                  <span>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="20" cy="20" r="19.5" stroke="white" />
                      <rect
                        x="11"
                        y="13"
                        width="18"
                        height="14"
                        rx="1"
                        stroke="white"
                      />
                      <path
                        d="M11 14L19.3598 20.9665C19.7307 21.2756 20.2693 21.2756 20.6402 20.9665L29 14"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 20L28 26"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18 20L12 26"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </EmailShareButton>
                {/* Fb */}
                <FacebookShareButton
                  url={`${process.env.STRAPI_API_URL}/work-with-us/recruitment/${id}`}
                  hashtag={`#${data?.data?.attributes?.title}`}
                >
                  <span>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="20" cy="20" r="19.5" stroke="white" />
                      <path
                        d="M24.2806 11.1336V14.0796H22.5941C21.2728 14.0796 21.0259 14.7381 21.0259 15.6865V17.7956H24.1733L23.7546 21.0987H21.0259V29.5684H17.739V21.0987H15V17.7956H17.739V15.363C17.739 12.5398 19.4041 11 21.8317 11C22.9915 11 23.9911 11.0893 24.2812 11.1336H24.2806Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </FacebookShareButton>
                <LinkedinShareButton url="https://www.linkedin.com/feed">
                  <span>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="20" cy="20" r="19.5" stroke="white" />
                      <path
                        d="M15.0898 16.3236V27.3821H11.2227V16.3236H15.0898ZM15.3358 12.9083C15.3478 13.9682 14.504 14.8166 13.1562 14.8166H13.133C11.8325 14.8166 11 13.9682 11 12.9083C11 11.8256 11.867 11 13.1795 11C14.504 11 15.3238 11.8256 15.3358 12.9083ZM29 21.0437V27.3821H25.1442V21.4679C25.1442 19.9838 24.5817 18.9682 23.1875 18.9682C22.121 18.9682 21.488 19.6488 21.2067 20.3073C21.113 20.553 21.0778 20.8765 21.0778 21.2115V27.3828H17.222C17.2692 17.362 17.222 16.3243 17.222 16.3243H21.0778V17.9312H21.0545C21.5585 17.1727 22.4727 16.0679 24.5585 16.0679C27.1017 16.0679 29 17.6527 29 21.0451V21.0437Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          {/* Interested */}
          <div className="mt-[78px] max-w-[1300px] lg:mx-auto mx-[25px]">
            <h5 className="text-brand-orange font-bold text-[20px] mb-[24px]">
              you might be interested in
            </h5>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-[88px] gap-y-[49px] mb-[31px]">
              {jobs?.data?.map((item: object, index: number) => (
                <Link
                  state={{ id: item.id }}
                  to={`/work-with-us/recruitment/${item.id}`}
                >
                  <div
                    onClick={() => setIsApply(false)}
                    onMouseEnter={() => { setIsHover(true); setWorkId(index) }}
                    onMouseLeave={() => setIsHover(false)}
                    className="h-[422px] border border-brand-orange relative"
                  >
                    {isHover && workId == index && (
                      <img src={item?.attributes?.image?.data?.attributes?.url} className='absolute transition-all left-0 top-0 w-full h-full object-cover -z-10' />
                    )}
                    <h6 className="pt-[20px]  max-w-[293px] pl-[27px] text-white font-bold tracking-tight lowercase text-[52.3px] leading-[110%]">
                      {item?.attributes?.title}
                    </h6>
                    <div className="absolute left-[27px] bottom-[33px]">
                      <h5 className="text-brand-orange font-bold  mb-[15px] text-[20px] leading-[100%] lowercase">
                        {item?.attributes?.studio?.data?.attributes?.title}
                      </h5>
                      <p className="graphik-regula font-normal text-sm leading-[160%] graphik-regular text-white max-w-[244px]">
                        {item?.attributes?.studio?.data?.attributes?.address}
                      </p>
                    </div>
                    <span className="graphik-regular font-normal text-sm  absolute right-0 -translate-y-1/2 origin-bottom  rotate-90 bottom-[100px] text-white">
                      Posted{" "}
                      {moment(item?.attributes?.createdAt).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>

        </>
      )}
    </Layout>
  );
}

export default WorkDetail;
