import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import logo from "../assets/images/hiit-logo.png";
import { Link } from "gatsby";
import AnimatedMenu from "./animated/AnimatedMenu";
import { menuNavbar } from "../../mock/homePage";
import { HambergerMenu } from "./animated/HambergerMenu";
import ChangeLocation from "./map/ChangeLocation"
import { RegisterContext } from "../context/RegisterContext";
function Navbar() {
  const [animated, setIsAnimated] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const modalVivas = useContext(RegisterContext).modalVivas
  // Active When jumping new page
  useEffect(() => {
    if (window.location.pathname === "/book-a-class") setCurrentItem(2);
    if (window.location.pathname === "/own-a-hiit") setCurrentItem(5);
  }, []);

  return (
    <>
      {!modalVivas && (<ChangeLocation />)}
      <div className="bg-[black] w-full header flex items-center border-b border-b-brand-orange justify-between h-[70px] px-[12px] md:px-[40px] sticky top-[40px] z-[9999]">
        <div className="w-[1440px] mx-auto flex items-center justify-between ">
          <Link to="/">
            <img src={logo} className="w-[140px] sm:w-[230px]" alt="logo" />
          </Link>
          <div className="menu-mobile hidden">
            <HambergerMenu />
          </div>
          <ul
            id="animated-menu"
            onClick={() => setIsAnimated(true)}
            className="flex  items-center cursor-pointer text-[18px] gap-7 text-white font-bold  button a img "
          >
            {menuNavbar.map((item: string, index: number) => (
              <div className="cursor-pointer">
                <li
                  className="relative cursor-pointer"
                  key={index}
                  onClick={() => setCurrentItem(index)}
                >
                  {item}
                  {currentItem === index && index !== 0 && (
                    <motion.div
                      layoutId="underline"
                      style={{ height: "4px" }}
                      className="absolute bottom-[-23px] w-full left-0 bg-brand-orange"
                    ></motion.div>
                  )}
                </li>
              </div>
            ))}
          </ul>
          <div className="flex h-[70px] text-[18px] register">
            {/* <a href="https://members.hiitrepublic.com.au/join-now" > */}
            <Link to="/register">
              <button className="bg-brand-orange h-full w-[145px] text-black cursor-pointer font-bold">
                join now
              </button>
            </Link>
            {/* </a> */}
            {/* <a href="https://members.hiitrepublic.com.au/members"> */}
            <Link to="/login">
              <button className="h-full w-[145px] text-white font-bold">
                login
              </button>
            </Link>
            {/* </a> */}
          </div>
        </div>
      </div>
      {/* Animated menu */}
      <div
        onClick={() => setIsAnimated(false)}
        style={{ display: animated ? "block" : "none" }}
        className="fixed top-0 z-10  w-full h-full"
      ></div>

      <div id="animated-menu" className={animated ? "animated mt-[40px] " : "hide-menu "}>
        <AnimatedMenu
          currentItem={currentItem}
          setAnimated={setIsAnimated}
          setCurrentItem={setCurrentItem}
        />
      </div>
      <p className="text-brand-orange sticky  w-max h-0 left-full right-[69px]  top-[95%] transform  z-10  origin-left  text-xl ml-5">
        <div className="h-[46px] bg-black cursor-pointer w-[179px] mr-[70px] rounded-tl-lg rounded-tr-lg graphik-regular font-normal flex items-center text-[16px] justify-center border border-brand-orange">
          Chat with us ...
        </div>
      </p>
    </>
  );
}

export default Navbar;
