import React, { useState, useEffect } from "react";
import { dashboardUserBasicInfo, sidebarMenu } from "../../mock/dashboard";
import Logo from "../assets/images/viva/logo.png";
import logoutIcon from "../assets/images/dashboard/logoutIcon.png";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import { navigate } from "gatsby";
function DashboardSidebar(props) {
  const path = globalHistory.location.pathname;
  const [expand, setExpand] = useState<boolean>(false);

  const [modal, setModal] = useState(false);
  useEffect(() => {
    path.split("/").includes("account") ? setExpand(true) : false;
  }, [path]);

  return (
    <>
      <div className="right-[26px] top-[36px] absolute">
        <div className="relative">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="20"
              cy="20"
              r="19"
              fill="none"
              stroke="#82FA00"
              stroke-width="1.5"
            />
            <path
              d="M25 18.4H20.9091L21.8182 10L15 22.6H19.0909L18.1818 31L25 18.4Z"
              fill="#82FA00"
            />
          </svg>
          <div className="w-[14px] h-[14px] bg-brand-clime rounded-full absolute top-0"></div>
        </div>
      </div>
      <div
        id="scroll-hidden"
        className={`text-white  border-r border-white relative md:block hidden ${
          props.sideBarOpen ? "w-[339px]" : " "
        }`}
      >
        {/* <div
        className="absolute w-[44px] h-[44px] top-[22.3%] right-0 translate-x-full text-white  z-10  flex h-[2.75rem] w-[2.75rem] items-center justify-center rounded-tr-[0.938rem] rounded-br-[0.938rem] border-t-[2px] border-r-[2px] border-b-[2px] border-l-[2px]  border-solid border-white border-l-white bg-black text-[#273B91]"
        onClick={() => props.setSideBarOpen(!props.sideBarOpen)}
      >
        <svg
        className={`${props.sideBarOpen?'':'rotate-180'}`}
          width="6"
          height="12"
          viewBox="0 0 6 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-v-101ad45f=""
        >
          <path
            d="M5.01562 0.984375V11.0156L0 6L5.01562 0.984375Z"
            fill="white"
          ></path>
        </svg>
      </div> */}
        <div className={`${props.sideBarOpen ? "" : "hidden"}`}>
          <img src={Logo} className="mt-[34px] ml-[24px] mb-[26px]" />
          <div className="ml-[24px] grid grid-cols-2 grid-flow-row gap-y-[16px] mb-[23px]">
            <div>
              <p className="text-white text-sm gotham leading-[22.4px]">
                Membership level
              </p>
              <p className="gotham uppercase text-sm leading-[19.6px] tracking-[0.1em] font-medium">
                {dashboardUserBasicInfo.level}
              </p>
            </div>
            <div></div>
            <div>
              <p className="text-white text-sm gotham leading-[22.4px]">
                Membership status
              </p>
              <p className="gotham uppercase text-sm leading-[19.6px] tracking-[0.1em] font-medium">
                {dashboardUserBasicInfo.status}
              </p>
            </div>
            <div>
              <p className="text-white text-sm gotham leading-[22.4px]">
                Member ID
              </p>
              <p className="gotham uppercase text-sm leading-[19.6px] tracking-[0.1em] font-medium">
                {dashboardUserBasicInfo.id}
              </p>
            </div>
          </div>
          <div className="">
            {sidebarMenu.map((item: any, index: number) => (
              <>
                {item.name == "account" ? (
                  <div>
                    <div
                      onClick={() => setExpand(!expand)}
                      key={index}
                      className={`gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase ${
                        index === 0 ? "border-y" : ""
                      }`}
                    >
                      {item.name}
                    </div>
                    <div
                      className={`overflow-hidden ${
                        expand
                          ? "h-auto opacity-100 transition-all"
                          : "h-0 opacity-0 transition-all"
                      }`}
                    >
                      {item.tabs.map((item, index) => (
                        <Link to={`/${item.link}`}>
                          <p
                            className={`h-[36px] flex items-center ${
                              window.location.pathname == "/" + item.link
                                ? "border-r-[5px] border-r-white bg-[#292929]"
                                : ""
                            } pl-[24px] gotham text-white capitalize text-sm font-normal flex items-center" key={index} `}
                          >
                            {item.name}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link to={`/dashboard${index != 0 ? "/" : ""}${item.id}`}>
                    <div
                      key={index}
                      className={` gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase ${
                        index === 0 ? "border-y" : ""
                      }          ${
                        window.location.pathname.split("/")[
                          window.location.pathname.split("/").length - 1
                        ] === item.link
                          ? "border-r-[5px] border-r-white bg-[#292929]"
                          : index === 0
                      }`}
                    >
                      {item.name}
                    </div>
                  </Link>
                )}
              </>
            ))}
            <div
              onClick={() => setModal(true)}
              className={`flex justify-between items-center gotham border-b border-[rgba(255,255,255,0.4)] pl-[24px] pt-[12px] pb-[12px] font-medium text-sm leading-[19.6px] tracking-[0.1em] uppercase                   `}
            >
              LOG OUT <img className="w-6 h-6 mr-[17px]" src={logoutIcon} />
            </div>
          </div>

          {/* Modal logout */}
          {modal && (
            <div className="fixed z-[999999999] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] h-[500px] bg-black flex items-center justify-center">
              <div className="absolute left-5 top-5 flex items-center gap-3">
                <div className="w-[38px] h-[38px] rounded-[50%] border border-white"></div>
                <p className="uppercase text-white gotham">hello any</p>
              </div>
              <div
                onClick={() => setModal(false)}
                className="absolute right-5 top-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <path
                    d="M12.4321 36.4923C5.99926 30.0594 5.9701 19.6673 12.3565 13.2809C18.7429 6.89449 29.135 6.92366 35.5679 13.3565C42.0007 19.7894 42.0299 30.1815 35.6435 36.5679C29.2571 42.9543 18.865 42.9251 12.4321 36.4923Z"
                    stroke="white"
                    strokeWidth="1.11532"
                  />
                  <path
                    d="M30.0186 30.3115L18.9311 19.2241"
                    stroke="white"
                    strokeWidth="1.11532"
                  />
                  <path
                    d="M18.8047 30.4343L29.8225 19.4165"
                    stroke="white"
                    strokeWidth="1.11532"
                  />
                </svg>
              </div>
              <div>
                <h2 className="bebas text-[35px] font-normal leading-[90%] text-white uppercase">
                  are you sure you want to log out ?{" "}
                </h2>
                <div className="flex items-center mt-[30px] justify-center gap-[20px]">
                  <button
                    onClick={() => {
                      window.localStorage.removeItem("userInfo");
                      navigate("/login");
                    }}
                    className="w-[110px] h-[40px] uppercase rounded border-white border bg-black text-[11.2px] gotham  text-white hover:bg-white hover:text-black transition-all"
                  >
                    log out
                  </button>
                  <button
                    onClick={() => setModal(false)}
                    className="w-[110px] h-[40px] border-white uppercase border rounded text-[11.2px] gotham  bg-black text-white hover:bg-white hover:text-black transition-all"
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
