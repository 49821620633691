import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import ExploreOtherVivaBrands from "../../components/home/ExploreOtherVivaBrands";
import Layout from "../../components/layout/Layout";
import StudioList from "../../components/studios/StudioList";
import TestProps from "../../components/studios/TestProps";
import StudioSearch from "../../components/studios/StudioSearch";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";

function Studio() {
  const [isMap, setIsMap] = useState<boolean>(false);
  const [studios, setStudios] = useState();
  const [studiosDummy, setStudiosDummy] = useState();

  const [param, setParam] = useState<string>("");
  const [allowed, setAllowed] = useState<boolean>(false);

  const { data: nearYour, loading } = useFetch({
    params: param,
    populate: "image,access,address.country",
  });
  const { data: allStudios } = useFetch({
    params: "studios",
    populate: "image,access,address.country",
  });

  // Studios near you
  const positionSet = () => {
    const getPost = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getPost();
  };
  useEffect(() => {
    positionSet();
  }, []);

  // Tracking allowing get the location
  useEffect(() => {
    if (param == '') {
      setAllowed(false);
      setStudios(allStudios);
      const clubList = handleData(allStudios);
      setStudios(clubList);
      setStudiosDummy(clubList);
      setFullClubList(clubList);
    } else {
      setAllowed(true);
      setStudios(nearYour);
      const fullClubList = handleData(allStudios);
      const clubList = handleData(nearYour);
      setFullClubList(fullClubList);
      setStudios(clubList);
      setStudiosDummy(clubList);
    }

  }, [allStudios, nearYour, param]);


  function handleData(data: any) {
    if (data && data.data) {
      data = data.data.map((i: any) => {
        return {
          id: i.id,
          image: i.attributes?.image.data
            ? i.attributes?.image.data.attributes.url
            : i.attributes?.image.url,
          title: i.attributes?.title.toLowerCase(),
          distance: 0.3, // need calculate
          access: ["24/7", "swimming pool", "health club"], //missing field data
          address: i?.attributes?.fullAddress,
          price: i.attributes?.price,
          phoneNumber: i.attributes?.phoneNumber,
          isShow: false,
          regionState: i?.attributes?.address?.data
            ? i?.attributes?.address.data.attributes.Name.toLowerCase()
            : i?.attributes?.address?.Name?.toLowerCase(),
          regionCity: i?.attributes?.address?.data
            ? i?.attributes?.address?.data?.attributes?.country?.data?.attributes?.Name?.toLowerCase()
            : i?.attributes?.address?.country?.Name.toLowerCase(),
        };
      });
      return data;
    }
  }

  const handleSearch = ({ key = "", target = "" }) => {
    if (key === "Enter") {
      if (target.value === "") setStudios(studiosDummy);
      else
        setStudios(
          studiosDummy.filter((i) =>
            i.address.toLowerCase().includes(target.value.toLowerCase())
          )
        );
    }
  };
  const setView = (value) => {
    setStudios(value);
  };
  const [location, setLocation] = useState([]);
  const setFilterLocation = (value) => {
    // setFilter(value);
    const filter = value.map((e) => e.text);
    setLocation(filter);
    let data = [...studiosDummy];
    if (filter.length > 0) {
      data = data.filter(
        (e) =>
          filter.some((v) =>
            e?.regionState?.toLowerCase()?.includes(v?.toLowerCase())
          ) ||
          filter?.some((v) =>
            e?.regionCity?.toLowerCase()?.includes(v?.toLowerCase())
          ) ||
          filter?.some((v) =>
            e?.address?.toLowerCase()?.includes(v?.toLowerCase())
          )
      );
    }
    setStudios(data);
    // const res = data.filter(item => !arr2.includes(item));
  };
  const [fullClubList, setFullClubList] = useState();
  const setSortData = (value, position) => {
    let data = [...studios];
    if (!position) {
      data = [...studiosDummy];
    } else {
      if (fullClubList && fullClubList.length) {
        data = [...fullClubList];
      } else data = [...studiosDummy];
    }
    if (value.text == "a-z")
      data.sort((a, b) => a.title.localeCompare(b.title));
    if (value.text == "z-a")
      data.sort((a, b) => a.title.localeCompare(b.title)).reverse();
    setStudios(data);
    // setFilter(value);
  };

  return (
    <Layout>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="max-w-[1440px] mx-auto">
          <StudioSearch
            isMap={isMap}
            setIsMap={setIsMap}
            set={(e: any) => handleSearch(e)}
            data={studios}
            filterLocation={setFilterLocation}
            sortData={setSortData}
          ></StudioSearch>
          <StudioList
            searching={location}
            isMap={isMap}
            data={studios}
            setList={(v) => setView(v)}
          ></StudioList>
          {/* <ExploreOtherVivaBrands></ExploreOtherVivaBrands> */}
        </div>
      )}
    </Layout>
  );
}

export default Studio;
