import React from "react";
import TimeTableDetail from "../../components/dashboard/TimeTableDetail";
import DashBoardLayout from "../../components/layout/DashboardLayout";

function Timetable(props) {
    return <DashBoardLayout {...props}>
        <TimeTableDetail />
    </DashBoardLayout>;
}

export default Timetable;
