import React, { useState } from "react";
import arrowRight from "../../assets/images/icons/arrowRight.svg";
import tag from "../../assets/images/NewsLetter/tagUs.png";
import dots from "../../assets/images/NewsLetter/dot.png";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import { Script } from "gatsby";
import { newLetterAPI } from "../../utils/fetchData";
import InstagramEmbed from 'react-instagram-embed';
function NewsLetterSignUp() {
  const { loading, data } = useFetch({ params: "home-page", populate: "tagUs", });
  const [msg, setMsg] = useState<boolean>(false)
  const tagUs = data?.data?.attributes?.tagUs?.data;
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    const elm = document.getElementById("email");
    return e.target.value === ""
      ? ((elm.style.borderColor = ""), (elm.style.color = ""), setActive(false))
      : ((elm.style.borderColor = "#FF7900"),
        (elm.style.color = "#FF7900"),
        setActive(true));
  };
  const [form, setForm] = useState({
    app: "NEWSLETTER",
    venue_id: 106,
    source_name: "Newsletter",
    gender: "",
    source_group: "Website",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const handleSubmit = async () => {
    await newLetterAPI(form)
    setMsg(true)
    setForm({ ...form, email: '' })
  }
  return (
    <div className=" max-w-[1440px] mx-auto mt-[100px] mb-[65px]">
      <Script src="https://apps.elfsight.com/p/platform.js" defer />
      {loading && <LoadingScreen />}
      {!loading && tagUs && (
        <div className="grid grid-cols-3 grid-flow-row text-white gap-8">
          <div className="col-span-3 lg:col-span-2 relative">
            {/* <div className="lg:w-[90%] w-full overflow-x-scroll flex" id="scroll"> */}
            <div className="lg:w-[95%] w-full">
              <div className="h-[280px] overflow-hidden">
                <div className="elfsight-app-1bcf7774-9041-4a5b-8b81-67b9a6fc97dc"></div>
              </div>
            </div>
            {/* </div> */}
            <div>
              <img
                className="absolute z-20 right-10 lg:right-[25px] top-0 h-[293px]"
                src={tag}
                alt="tagUs "
              />
              <img
                className="absolute right-2 lg:right-[-15px] z-10 top-[50%] transform translate-y-[-50%] h-[47px]"
                src={dots}
                alt="dots "
              />
            </div>
          </div>
          <div className="flex justify-center mx-[26px] items-center lg:justify-end lg:items-center col-span-3 lg:col-span-1">
            <div className="flex flex-col">
              <h2 className="text-brand-orange col-span-1 font-normal text-[26px] graphik-regular">
                Newsletter Sign Up
              </h2>
              <p className="mt-[15px] max-w-[534px] leading-[160%] text-white font-normal graphik-regular text-sm">
                Qui blanditiis praesentium voluptatum deleniti atque <br />{" "}
                corrupti quos dolores et quas.
              </p>

              <div
                className="mt-[34px]  relative h-[56px] xl:w-[363px] border border-[#777777] flex items-center justify-between"
                id="email"
              >
                <div className="absolute bottom-full pb-1 text-[12px] graphik-regular font-medium text-white">
                  {active ? "Enter email" : " "}
                </div>
                <div className="flex flex-col ">
                  <div className="flex">
                    <input
                      value={form.email}
                      onChange={(e) => setForm({ ...form, email: e.target.value })}
                      onInput={(e) => {
                        activeValue(e);
                      }}
                      className="h-full xl:w-[300px] graphik-regular py-4 px-6 border-none bg-transparent outline-none "
                      type="email"
                      placeholder="Enter email"
                    />
                    <div className="flex items-center justify-center" onClick={handleSubmit}>
                      <img src={arrowRight} alt="icon" className="pr-[25px]" />
                    </div>
                  </div>
                </div>
              </div>
              {msg && (
                <p className="text-brand-orange mt-[10px]">
                  Thanks our hiit squad will be in touch
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsLetterSignUp;
