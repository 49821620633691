export const footer = {
  explore: {
    title: 'EXPLORE',
    categories: [
      {
        name: 'Studios',
        link: 'studio',
      },
      {
        name: 'Classes',
        link: 'class',
      },
      {
        name: 'Timetable',
        link: 'book-a-class',
      },
      {
        name: 'About',
        link: 'about',
      },
      {
        name: 'Blog',
        link: 'blog',
      },
    ],
  },
  support: {
    title: 'SUPPORT',
    categories: [
      {
        name: 'Contact us',
        link: 'contact',
      },
      {
        name: 'FAQ',
        link: 'faq',
      },
      {
        name: 'Legal',
        link: 'legal',
      },
      {
        name: 'Help centre',
        link: '',
      },
    ],
  },
  joinUs: {
    title: 'JOIN US',
    categories: [
      {
        name: 'Memberships',
        link: 'memberships',
      },
      {
        name: 'Work with us',
        link: 'work-with-us',
      },
    ],
  },
  members: {
    title: 'MEMBERS',
    categories: [
      {
        name: 'Login',
        link: 'login',
      },
    ],
  },
  membersIdApp: {
    title: 'MEMBERS ID APP',
  },
  newsLetter: {
    title: 'Newsletter Sign Up',
    description: 'Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.',
  },
  copyrightText: 'Viva Leisure Pty Ltd Copyright 2021',
  exploreText:
    'We are part of the Viva Leisure Group. Viva operates over 100 health clubs across Australia, New Zealand and India.',
};
