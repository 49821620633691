import coach from '../src/assets/images/work/work.png';
import coach2 from '../src/assets/images/work/work2.png';
import textEffect from '../src/assets/images/work/textEffect.png';
import textEffect2 from '../src/assets/images/work/textEffect2.png';
import bag from '../src/assets/images/work/bag.png';

export const workIntro = {
  title: 'come throw down with us',
  subTitle: 'work hard, play hard',
  coach: coach,
  coach2: coach2,
  textEffect: textEffect,
};
export const workAbout = {
  title: 'about us',
  subTitle:
    "A paragraph that invites potential staff to join a fast paced, and leading team. It's not just a gym; it's a lifestyle. Our clubs aren't just rooms where people come to exercise – they're purpose-built to help each of our members challenge their limits, achieve their goals and escape the daily grind. We offer a seamless training experience that's engineered for your performance and convenience.",
  textEffect: textEffect2,
};
export const workOpportunities = {
  title: 'current opportunities',
  jobs: [
    {
      id: 1,
      title: 'Group fitness coach',
      subTitle: 'belconnen',
      address: '25 Oatley Court, Belconnen ACT 2612',
      label: 'Posted 06 July 2021',
      background: bag,
    },
    {
      id: 2,
      title: 'brand manager',
      subTitle: 'head office',
      address: '00 Lemon Street,Fyshwick ACT 2612',
      label: 'Posted 06 July 2021',
      background: '',
    },
    {
      id: 3,
      title: 'sales executive',
      subTitle: 'belconnen',
      address: '25 Oatley Court, Belconnen ACT 2612',
      label: 'Posted 06 July 2021',
    },
    {
      id: 4,
      title: 'general manager',
      subTitle: 'belconnen',
      address: '25 Oatley Court, Belconnen ACT 2612',
      label: 'Posted 06 July 2021',
    },
    {
      id: 5,
      title: 'pool manager',
      subTitle: 'head office',
      address: '25 Oatley Court, Belconnen ACT 2612',
      label: 'Posted 06 July 2021',
    },
    {
      id: 6,
      title: 'group fitness coach',
      subTitle: 'head office',
      address: '25 Oatley Court, Belconnen ACT 2612',
      label: 'Posted 06 July 2021',
    },
  ],
};
