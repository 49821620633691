import React, { useContext, useEffect, useState } from "react";
import MenuIcon from "../../assets/images/icons/MenuIcon.svg";
import MapIcon from "../../assets/images/icons/MapIcon.svg";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import Dropdown from "../../common/dropdowns/Dropdown.tsx";
import { dropdown } from "../../../mock/classes.js";
import moment from "moment";
import { timeTable } from "../../../mock/classesTodayTimeTable.js";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import plus from "../../assets/images/memberships/plus.png";
import fullRateIcon from "../../assets/images/icons/rating.svg";
import noneRatingIcon from "../../assets/images/icons/noneRatingIcon.svg";
import InputBase from "../../common/inputs/InputBase.tsx";
import ButtonActive from "../../common/buttons/Active";
import CheckboxActive from "../../common/buttons/CheckboxActive";
import noNameAvatar from "../../assets/images/icons/noNameAvatar.png";
import course from "../../assets/images/StudioDetailInfo/classes.jpg";
import dotNone from "../../assets/images/icons/circleNone.png";
import dotFull from "../../assets/images/icons/circleFull.png";
import { RegisterContext } from "../../context/RegisterContext";
import filterIconTimeTable from "../../assets/images/icons/filterIconTimeTable.svg";

function calculateRating(value: number, isSmall = false, isDot = false) {
  const sweat: any = [];
  const number = Math.floor(value);
  for (let i = 0; i < number; i++) {
    const data = (
      <img
        className={`${isSmall ? "mr-[4px] w-[11px] h-[11px]" : "mr-2"}`}
        src={isDot ? dotFull : fullRateIcon}
      />
    );
    sweat.push(data);
  }
  if (sweat.length < 5) {
    for (let i = 0; i < 5 - number; i++) {
      const data = (
        <img
          className={`${isSmall ? "mr-[4px] w-[11px] h-[11px]" : "mr-2"}`}
          src={isDot ? dotNone : noneRatingIcon}
        />
      );
      sweat.push(data);
    }
  }

  return sweat;
}
function calculateDates() {
  return (
    <>
      <div className="text-brand-orange underline graphik-regular font-bold xl:block hidden">
        today {moment().format("DD/MM")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(1, "days").format("ddd")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(2, "days").format("ddd")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(3, "days").format("ddd")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(4, "days").format("ddd")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(5, "days").format("ddd")}
      </div>
      <div className="graphik-regular font-bold lowercase xl:block hidden">
        {moment().add(6, "days").format("ddd")}
      </div>
      {/* mobile */}
      <div className="text-brand-orange lowercase underline graphik-regular font-bold xl:hidden block">
        {moment().format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(1, "days").format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(2, "days").format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(3, "days").format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(4, "days").format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(5, "days").format("ddd").split("")[0]}
      </div>
      <div className="graphik-regular lowercase font-bold xl:hidden block">
        {moment().add(6, "days").format("ddd").split("")[0]}
      </div>
    </>
  );
}

function allTimeTable() {
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [isLoggedIn, setLoggin] = useState(false);
  return (
    <>
      <div className="text-white mx-[4.9%]  mt-[] mb-[11px] xl:hidden block ">
        {timeTable.map((item: any, index: number) => (
          <div
            onMouseEnter={() => {
              setToggle(true);
              setSelected(index);
            }}
            onMouseLeave={() => setToggle(false)}
            key={index}
          >
            <div
              style={{
                borderTop:
                  selected === index && toggle
                    ? "1px solid #ff6900"
                    : selected + 1 === index && toggle
                      ? "1px solid #ff6900"
                      : "1px solid white",
                background: selected === index && toggle ? "#1C1C1C" : "",
                borderBottom: index === 4 && !toggle ? "1px solid #C6C6C6" : "",
              }}
              className="flex flex-col cursor-pointer items-center py-3  px-4 "
            >
              <div className="w-full flex justify-between px-1">
                <div
                  // style={{
                  //   color: selected === index && toggle ? "white" : "white",
                  // }}
                  className={`text-sm font-semibold graphik-regular ${selected === index && toggle
                    ? "text-brand-orange"
                    : "text-white"
                    }`}
                >
                  {item.time}
                </div>
                <span
                  onClick={() => {
                    setToggle(!toggle);
                    setSelected(index);
                  }}
                >
                  {/* <img
                  src={selected === index && toggle ? plus : plus}
                  alt="plus icon"
                  className={`
                    ${
                      selected === index && toggle
                        ? "rotate-45 text-brand-orange"
                        : ""
                    }`}
                /> */}
                  <svg
                    className={`
                 ${selected === index && toggle
                        ? "rotate-45 text-brand-orange"
                        : ""
                      }`}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.751 6.9624H0.556599"
                      stroke="currentColor"
                      stroke-width="1.32496"
                    />
                    <path
                      d="M7.1543 13.7085L7.1543 0.596987"
                      stroke="currentColor"
                      stroke-width="1.32496"
                    />
                  </svg>
                </span>
              </div>
              <div
                className={`graphik-regular lowercase font-bold self-start ${selected === index && toggle ? "text-brand-orange" : ""
                  }`}
              >
                {item.class}
              </div>

              <div className="w-full flex justify-between px-1">
                <div
                  className={` flex items-end ${selected === index && toggle ? "text-white" : ""
                    } `}
                >
                  <div className=" flex items-center graphik-regular text-xs tracking-[0.1em] ">
                    <p className=" lowercase">{item.coach}</p> &nbsp;| &nbsp;
                    <p className=" lowercase"> {item.club}</p>
                  </div>
                </div>
                <div
                  style={{
                    color: selected === index && toggle ? "#ff6900" : "white",
                  }}
                  className=" flex graphik-regular text-sm"
                >
                  <div className=" " onClick={() => setIsShow(true)}>
                    <button
                      className={`w-[125px] h-[40px] border-b font-bold border-t border-l border-r
                        ${item.club.length % 2 == 0
                          ? "border-[#777777]"
                          : item.club.length % 3 == 0
                            ? "border-brand-orange cursor-default"
                            : "border-[#777777]"
                        } ${item.club.length % 2 == 0
                          ? "text-[#777777]"
                          : item.club.length % 3 == 0
                            ? "text-brand-orange"
                            : "text-[#777777]"
                        }
                      ${item.club.length % 2 == 0
                          ? "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-brand-orange focus:text-brand-orange hover:text-white"
                          : item.club.length % 3 == 0
                            ? ""
                            : "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-white focus:text-white hover:text-white"
                        }
                      `}
                    >
                      {item.club.length % 2 == 0
                        ? "book me"
                        : item.club.length % 3 == 0
                          ? "booked"
                          : "waitlist"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id={selected === index && toggle ? "showlist" : "hideList"}
              style={{
                paddingBottom: selected == index && toggle ? "25px" : "",
                padding:
                  selected === index && toggle
                    ? "1.25rem 14px 1.25rem 14px"
                    : "",
                background: selected === index && toggle ? "#1C1C1C" : "",
              }}
              className=""
            >
              <div className="overflow-hidden lg:flex-col flex-row flex transition-all duration-300 gap-[14px]">
                <div className="md:min-w-[2/4] flex lg:flex-row flex-col gap-[16px] items-center">
                  <img
                    className="max-w-[125px] max-h-[125px] md:max-w-none  md:max-h-none"
                    src={item.image}
                  />
                  <div className="lg:block hidden">
                    <p className="my-[27px] font-bold  graphik-regular text-sm uppercase">
                      {item.descTitle}
                    </p>
                    <p className="lg:w-[395px] font-light  graphik-regular text-sm">
                      {item.desc}
                    </p>
                  </div>
                </div>
                <div className="w-2/4 flex flex-col    gap-[28px]">
                  <div className="w-full flex flex-col gap-[28px]">
                    <div className="flex items-center">
                      <p className="w-1/2 text-xs graphik-regular  font-bold">
                        studio one
                      </p>
                      <p className="w-1/2 text-brand-clime text-xs text-brand-orange">
                        02
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p className="w-1/2 text-xs graphik-regular font-bold">
                        almost full
                      </p>
                      <p className="w-1/2 text-brand-clime text-xs text-brand-orange ">
                        17/20
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[28px]">
                    {/* <div className="flex items-center">
                      
                      <p className="w-2/3  text-xs graphik-regular text-brand-orange font-bold">hardcore</p><p className="w-1/3 text-brand-clime text-xs ">
                       1
                      </p>
                    </div> */}

                    <div className="flex items-center justify-between">
                      <p className="w-1/2 text-xs graphik-regular  font-bold">
                        sweat rating
                      </p>
                      <p className="">
                        <span className="flex items-center text-brand-orange">
                          {calculateRating(item.sweatRating, true)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <p className="mt-[27px] mb-[18px] font-bold  graphik-regular text-sm uppercase">
                  {item.descTitle}
                </p>
                <p className="lg:w-[395px] font-light  graphik-regular text-sm">
                  {item.desc}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-white mx-[4.9%]   mb-[11px] xl:block hidden ">
        {timeTable.map((item: any, index: number) => (
          <div
            onMouseEnter={() => {
              setToggle(true);
              setSelected(index);
            }}
            onMouseLeave={() => setToggle(false)}
            key={index}
          >
            <div
              style={{
                borderTop:
                  selected === index && toggle
                    ? "1px solid #ff6900"
                    : "1px solid white",
                background: selected === index && toggle ? "#1C1C1C" : "",
                borderBottom: index === 4 && !toggle ? "1px solid #C6C6C6" : "",
              }}
              className="flex cursor-pointer items-center py-3 pr-[63px] pl-4 "
            >
              <div className="w-1/4 flex items-center justify-between px-1">
                <div
                  style={{
                    color: selected === index && toggle ? "white" : "white",
                  }}
                  className=" text-brand-orange text-sm tracking-[0.1em] font-semibold graphik-regular "
                >
                  {item.time}
                </div>
                <span
                  onClick={() => {
                    setToggle(!toggle);
                    setSelected(index);
                  }}
                >
                  <img
                    src={selected === index && toggle ? plus : plus}
                    alt="plus icon"
                    className={
                      selected === index && toggle
                        ? "rotate-45 text-brand-orange"
                        : ""
                    }
                  />
                </span>
              </div>
              <div
                style={{
                  color: selected === index && toggle ? "#ff6900" : "white",
                }}
                className="w-1/4 text-sm font-semibold  graphik-regular "
              >
                {item.class}
              </div>
              <div
                style={{
                  color: selected === index && toggle ? "#ff6900" : "white",
                }}
                className="w-1/4 flex text-sm font-normal graphik-regular "
              >
                {item.coach}
              </div>
              <div
                style={{
                  color: selected === index && toggle ? "#ff6900" : "white",
                }}
                className="w-1/4 flex"
              >
                <div className="w-1/2 flex text-sm font-normal items-center graphik-regular ">
                  {item.club}
                </div>
                <div className="w-1/2 flex ">
                  <div onClick={() => setIsShow(true)}>
                    <button
                      className={`w-[125px] h-[40px] border-b font-bold border-t border-l border-r
                        ${item.club.length % 2 == 0
                          ? "border-[#777777]"
                          : item.club.length % 3 == 0
                            ? "border-brand-orange cursor-default"
                            : "border-[#777777]"
                        } ${item.club.length % 2 == 0
                          ? "text-[#777777]"
                          : item.club.length % 3 == 0
                            ? "text-brand-orange"
                            : "text-[#777777]"
                        }
                      ${item.club.length % 2 == 0
                          ? "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-brand-orange focus:text-brand-orange hover:text-white"
                          : item.club.length % 3 == 0
                            ? ""
                            : "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-white focus:text-white hover:text-white"
                        }
                      `}
                    >
                      {item.club.length % 2 == 0
                        ? "book me"
                        : item.club.length % 3 == 0
                          ? "booked"
                          : "waitlist"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id={selected === index && toggle ? "showlist" : "hideList"}
              style={{
                paddingBottom: selected == index && toggle ? "25px" : "",
                padding:
                  selected === index && toggle ? "1.25rem 63px 5rem 1rem" : "",
                background: selected === index && toggle ? "#1C1C1C" : "",
              }}
              className="overflow-hidden flex transition-all duration-300 "
            >
              <div className="w-2/4 flex gap-[16px]">
                <img src={item.image} />
                <div>
                  <p className="my-[27px] font-bold text-2xl">
                    {item.descTitle}
                  </p>
                  <p className="w-[395px] font-light text-sm graphik-regular">
                    {item.desc}
                  </p>
                </div>
              </div>
              <div className="w-2/4 flex pt-[70px]">
                <div className="w-1/3 flex flex-col gap-[28px]">
                  <div className="flex">
                    <p className="w-1/2">studio</p>
                    <p className="w-1/2 text-brand-orange">{item.studio}</p>
                  </div>
                  <div className="flex">
                    <p className="w-1/2">almost full</p>
                    <p className="w-1/2 text-brand-orange">{item.almostFull}</p>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col gap-[28px]">
                  <div className="flex items-center">
                    <p className="w-1/2">sweat rating</p>
                    <p className="ml-3">
                      <span className="flex items-center ">
                        {calculateRating(item.sweatRating)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{ display: !isShow && !isLoggedIn ? "none" : "block" }}
        className="fixed top-1/2 left-1/2 bg-black z-[99999999] -translate-x-1/2 -translate-y-1/2 w-[657px] h-[646px] border-2 border-white"
      >
        <div className="text-white ">
          {isLoggedIn && (
            <div className="pt-6 pl-9 flex gap-3 items-center">
              <div>
                <img src={noNameAvatar} />
              </div>
              <div className="text-white text-[16px] font-bold">hello amy</div>
            </div>
          )}
          <button
            className="w-fit absolute rotate-45 right-4 top-4 rounded-full border-white border-[1px]"
            onClick={() => {
              setIsShow(false), setLoggin(false);
            }}
          >
            <img src={plus} />
          </button>
          <div className="w-full h-full  ">
            {!isLoggedIn &&
              register(() => {
                setLoggin(true);
              })}
            {isShow && isLoggedIn && confirmBook()}
          </div>
        </div>
      </div>
    </>
  );
}
function register(loggin) {
  const account = {
    email: "abc",
    password: "abc",
  };

  const [accountInfo, setAccountInfo] = useState({ userEmail: '', userPass: '' })

  const handleChangeValue = (e) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };
  const checked = () => {
    if (JSON.stringify(account) === JSON.stringify(accountInfo)) {
      loggin();
    }
  };


  return (
    <div className="w-[398px] h-[616px] flex flex-col justify-end gap-[100px] m-auto">
      <div>
        <p className="text-center text-[40px] font-bold">member login</p>
        <InputBase
          width="100%"
          type="string"
          nameInput="userEmail"
          holder="Email address*"
          value={accountInfo.userEmail}
          onchange={handleChangeValue}
        />
        <InputBase
          width="100%"
          value={accountInfo.userPass}
          nameInput="userPass"
          type="password"
          holder="Password*"
          onchange={handleChangeValue}
        />
        <p className="underline text-right hover:text-white text-xs text-blackSecondary mt-[11px]">
          Forgot your email/password?
        </p>
        <div className="flex justify-between mt-[19px] items-center">
          <div className="flex gap-[12px] text-sm items-center">
            <CheckboxActive></CheckboxActive>Remember me
          </div>
          <div onClick={() => checked()}>
            <ButtonNavigate
              style="w-[151px] border-b h-[56px]"
              text="log in now "
            ></ButtonNavigate>
          </div>
        </div>
      </div>
      <p className="text-center text-xl">
        not a member? <span className="text-brand-orange">join now</span>
      </p>
    </div>
  );
}

function confirmBook() {
  const currentPlan = "no";
  return (
    <div className="w-[582px] h-full mx-auto ">
      <p className="text-[40px] font-bold -tracking-[0.03em] border-b-[1px] pb-[11px] border-[rgba(255,255,255,0.4)]">
        you are booking amrap
      </p>
      <div className="flex justify-between py-[14px] border-b-[1px] border-[rgba(255,255,255,0.4)]">
        <div className="flex flex-col gap-[2px] graphik-regular font-normal">
          <p className=" graphik-regular font-normal">7:30-8:30</p>
          <p className=" graphik-regular font-normal">Monday, 10 July 2021</p>
          <p className=" graphik-regular font-normal">Braddon</p>
        </div>
        <div className=" graphik-regular font-normal">coach: Ashton K</div>
      </div>
      <div className="py-[25px] flex justify-between border-b-[1px] border-[rgba(255,255,255,0.4)]">
        <img className="w-[40%]" src={course} />
        <div className="w-[55%] flex flex-col justify-between ">
          <p className="text-sm graphik-regular ">
            Vero eos et accusamus et iusto odio dig nissimos ducimus qui
            blanditiis praesentium.
          </p>
          <div className="grid grid-cols-2 gap-y-[27px] w-[214px]">
            <p>studio</p>
            <span className="text-brand-orange">01</span>
            <p>almost full</p>
            <span className="text-brand-orange">17/20</span>
            <p>sweat rating</p>
            <span className="text-brand-orange flex items-center">
              {calculateRating(4, true)}
            </span>
            <p>difficulty</p>
            <span className="text-brand-orange flex items-center">
              {calculateRating(4, true, true)}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center pt-[12px]">
        <div className="w-1/2 flex items-center">
          <button
            className={`text-white font-bold w-[62px] h-[56px] ${currentPlan === "no"
              ? "border-b-brand-orange border-b-[5px] pt-[5px]"
              : "border-b-white border-b"
              } border-t border-l bg-[black] border-r border-white`}
          >
            on
          </button>
          <button
            className={`text-white font-bold w-[62px] h-[56px] ${currentPlan === "yes"
              ? "border-b-brand-orange border-b-[5px] pt-[5px]"
              : "border-b-white border-b"
              } border-t border-l bg-[black] border-r border-white`}
          >
            yes
          </button>
          <p className="ml-[19px] graphik-regular text-sm">Sms reminder</p>
        </div>
        <ButtonNavigate
          style="border-b h-[56px] w-[151px] border-[#1c1c1c] "
          text="book now"
        ></ButtonNavigate>
      </div>
    </div>
  );
}
// function modal

function BookAClassToolBar() {
  const [isSelected, setSelected] = useState({
    studio: [],
    "sort by": [],
    class: [],
    mood: [],
  });
  const onChange = (e: any, name: string, type: any) => {
    // let nameArray = "";
    if (e) {
      setSelected({
        ...isSelected,
        [type]: (isSelected as any)[type].concat(name.text),
      });
    } else
      setSelected({
        ...isSelected,
        [type]: (isSelected as any)[type]?.filter(
          (i: string) => i != name.text
        ),
      });
  };

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  const setOpen = useContext(RegisterContext).setOpen;
  const yourClub = useContext(RegisterContext).yourClub;

  return (
    <>
      <div>
        <div className="sticky top-[105px] z-50">
          <div className="text-white grid grid-cols-5 grid-flow-row p-[13px] bg-black xl:bg-none">
            <div className="col-span-4 py-[23px] xl:py-0 xl:col-span-2 bg-[#292929] flex justify-between text-sm items-center text-[18px] font-bold px-[30px] xl:px-[40px] graphik-regular xl:border-r xl:border-y border-brand-orange">
              {calculateDates()}
            </div>
            <div className="xl:hidden flex items-center justify-center bg-[#292929]  ">
              <img src={filterIconTimeTable} />
            </div>
            <Dropdown
              style="h-[59px]  bg-[#292929]  font-[18px] font-bold px-[24px] graphik-regular border-r border-y border-brand-orange xl:flex hidden"
              data={dropdown.findStudio}
              onChange={onChange}
              selectedData={isSelected.studio}
            ></Dropdown>
            <Dropdown
              style="h-[59px]  bg-[#292929]  font-[18px] font-bold px-[24px] graphik-regular border-r border-y border-brand-orange xl:flex hidden"
              data={dropdown.class}
              onChange={onChange}
              selectedData={isSelected.class}
            ></Dropdown>
            {/* <Dropdown
              style="h-[59px]  bg-[#292929]  font-[18px] font-bold px-[24px] graphik-regular border-r border-y border-brand-orange xl:flex hidden"
              data={dropdown.mood}
              onChange={onChange}
              selectedData={isSelected.mood}
            ></Dropdown> */}
            <div className="h-[59px] bg-[#292929]  gap-3 items-center justify-center border-y graphik-regular border-brand-orange xl:flex hidden">
              <span className="graphik-regular font-bold text-sm">all </span>/
              <span className="graphik-regular font-bold text-sm"> am </span>/
              <span className="graphik-regular font-bold text-sm">pm</span>
            </div>
          </div>
          <div className="mx-[4.9%] xl:flex hidden  bg-black py-5 pr-[63px] pl-4 border-b-white border-b-[1px] ">
            <div className="w-1/4 bg-black  text-brand-orange text-sm leading-[140%] tracking-[0.1em] font-bold graphik-regular">
              time
            </div>
            <div className="w-1/4 bg-black  text-brand-orange text-sm leading-[140%] tracking-[0.1em] font-bold graphik-regular">
              class
            </div>
            <div className="w-1/4 bg-black  text-brand-orange text-sm leading-[140%] tracking-[0.1em] font-bold graphik-regular">
              coach
            </div>
            <div className="w-1/4 bg-black  text-brand-orange text-sm leading-[140%] tracking-[0.1em] font-bold graphik-regular">
              studio
            </div>
          </div>
          <div className=" px-[7%] xl:hidden flex justify-between bg-black">
            <p className="text-brand-orange graphik-regular font-bold py-[15px] ">
              today {moment().format("DD/MM")}
            </p>
            <p className="text-white graphik-regular py-[15px]">
              {timeTable.length} CLASSES
            </p>
          </div>
        </div>
        {allTimeTable()}
      </div>
      <div className="text-white  md:px-[70px] px-6  xl:flex-row flex-col flex justify-between mt-[75px] mb-[100px]">
        <div className="text-[60px] leading-[40px] md:leading-[110%] xl:w-[40%]  flex-col justify-between">
          <p className="text-white font-bold text-[40px] md:text-[55px] -tracking-[0.03em]">
            get your
            <br />
            <span className="text-brand-orange font-bold"> free pass.</span>
          </p>
          <p className="md:text-[24px] text-base font-bold mt-[50px]">
            <span className="text-white">i want to visit</span>
            {yourClub ? (
              <span className="text-brand-orange mx-2 lowercase">
                {yourClub?.attributes?.title},{" "}
                {yourClub?.attributes?.address?.Name
                  ? yourClub?.attributes?.address?.Name
                  : yourClub?.attributes?.address?.data?.attributes?.Name}
                {" "}
              </span>
            ) : (
              <span className="mx-1">
              </span>
            )}

            <span
              className="text-gray underline graphik-regular text-base"
              onClick={() => {
                setOpen(true);
              }}
            >
              {yourClub ? 'change' : 'select'}
            </span>
          </p>
        </div>
        <div className="max-w-[1440px] mx-auto">
          <div className="grid grid-rows-4 md:grid-rows-2 grid-flow-col gap-x-[40px] gap-y-[12px]">
            <InputBase
              width="315px"
              type="string"
              holder="First name*"
              isRequired={true}
              isShowRequired={formControlled.firstName}
              onchange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
            <InputBase
              width="315px"
              type="string"
              holder="Last name*"
              isRequired={true}
              isShowRequired={formControlled.lastName}
              onchange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
            <InputBase
              width="315px"
              type="email"
              holder="Email address*"
              errorText={errText.email}
              isRequired={true}
              isShowRequired={formControlled.email}
              onchange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <InputBase
              width="315px"
              type="string"
              holder="Phone number*"
              isRequired={true}
              isShowRequired={formControlled.phoneNumber}
              onchange={(e) =>
                setForm({ ...form, phoneNumber: e.target.value })
              }
            />
          </div>
          <div
            className="float-right mt-[28px] mr-[12px] md:mr-0"
            onClick={() => {
              submit();
            }}
          >
            <ButtonActive text="lets go" height={53} />
          </div>
        </div>
      </div>
    </>
  );
}
export default BookAClassToolBar;
