import React, { useContext, useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CheckedMark from "../../../assets/images/icons/CheckedMark.svg";
import { arrowRight } from "../../../assets/images/icons/arrowRight.tsx";
import axios from "axios";
import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { RegisterContext } from "../../../context/RegisterContext";
import { Script } from "gatsby";

function SearchClubNearby(props) {
  const { setStep } = props;
  const [address, setAddress] = useState("");
  const [isGetAll, setIsGetAll] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [clubNearby, setClubNearby] = useState<[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const setYourClub = useContext(RegisterContext).setYourClub;
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;

  // Select address
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  // Get Clubs by distance
  useEffect(() => {
    if (coordinates.lat !== null) {
      const url = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${coordinates.lng}&location[]=${coordinates.lat}&distance=12089566&populate=image,access,address.state&pagination`;
      axios.get(url).then((res) => {
        setClubNearby(res?.data?.data);
      });
    }
  }, [coordinates]);

  return (
    <>
      <div className=" flex gap-[8px] items-center mt-[34px]">
                <div onClick={() => setStep(1)} className="w-[41px] h-[48px] p-[7px] pl-0">
                    <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                        <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                    </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                    step 1
                </span>
            </div>
            <Script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`} onLoad={() => setLoaded(true)} />
            {loaded && (
                <div className='lg:w-[796px] mt-[35px]'>
                    <h6 className='uppercase text-white text-sm leading-[140%] tracking-widest gotham'>step 2</h6>
                    <p className='mt-[10px] bebas font-normal text-[54px] leading-[90%] uppercase text-white'>change your home club</p>
                    <h6 className='mt-[36px] text-white gotham text-sm leading-[140%] uppercase font-normal pb-[7px] w-full border-b border-b-white'>search for your new home club</h6>
                    <div className='mt-[56px] '>
                        <div className='w-full'>
                            <div className='flex items-center w-full'>
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={setAddress}
                                    onSelect={handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                        <div className='flex flex-col lg:w-[794px] w-full'>
                                            <div >
                                                <div className='flex border-b border-gray min-w-full items-center relative justify-between'>
                                                    <svg width="22" height="22" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                    >      <line x1="15.8912" y1="14.8002" x2="24.1415" y2="22.2113" stroke={"white"} strokeWidth="1.57426" />
                                                        <circle cx="10.0022" cy="10.3639" r="7.15275" transform="rotate(-72.0294 10.0022 10.3639)" stroke={"white"} strokeWidth="1.57426"
                                                        />
                                                    </svg>
                                                    <input className='bg-transparent  py-[15px] ml-[15px] gotham font-normal w-full text-sm leading-[160%] text-white outline-none'  {...getInputProps({ placeholder: "" })} />
                                                    <span onClick={() => setAddress('')} className='absolute bottom-3 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin'><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                                        <path d="M16.7559 16.4368L5.66841 5.34938" stroke="white" strokeWidth="1.11532" />
                                                        <path d="M5.54199 16.5595L16.5598 5.5417" stroke="white" strokeWidth="1.11532" />
                                                    </svg></span>
                                                </div>
                                            </div>
                                            <div className='mt-[15px]'>
                                                {suggestions.map(suggestion => {
                                                    return (
                                                        <div className={`${suggestion.active ? 'bg-[#0F0F0F] text-white' : ''} border border-gray  text-white py-[12px] px-[15px] text-sm font-normal leading-[160%] gotham `}
                                                            {...getSuggestionItemProps(suggestion)}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </div>
                        {/* Studios */}
                        {clubNearby?.slice(0, isGetAll ? clubNearby?.length : 4)?.map((item: object, index: number) => (
                            <div
                                onClick={() => {
                                    setYourClub(item);
                                    setIsChangeClub(false)
                                    setStep(3)
                                    window.localStorage.setItem('yourClub', JSON.stringify(item))
                                }}
                                key={index}
                                className='pt-[18px] w-full justify-between hover:bg-graySecondary items-start flex lg:flex-row flex-col pb-[21px] border-b border-b-gray lg:px-3 px-0 text-white'>
                                {item?.attributes?.image?.url?.slice(-3) == 'mp4'
                                    ? <video muted className='lg:w-[242px] w-full' src={item?.attributes?.image?.url} autoPlay loop></video>
                                    : <img src={item?.attributes?.image?.url || item?.attributes?.image?.data?.url} className='lg:w-[242px] w-full object-cover h-[195px]' />
                                }

                                <div className='block lg:hidden mt-[23px] w-full'>
                                    <div className='flex items-center w-full justify-between'>
                                        <h6 className='text-brand-clime font-medium text-sm gotham uppercase tracking-widest'>{item?.attributes?.title}</h6>
                                        <p className='text-brand-clime text-[12px] gotham font-normal  ml-2'>${item?.attributes?.price}/week</p>
                                    </div>
                                    <div className='mt-1 flex items-center'>
                                        <span className='gotham text-white text-sm font-normal '>12km</span>
                                    </div>
                                </div>
                                <div className='ml-[22px] flex-1 lg:block hidden'>
                                    <div className='flex items-start justify-between'>
                                        <h6 className='text-brand-clime font-medium text-sm gotham uppercase tracking-widest'>{item?.attributes?.title}</h6>
                                        <span className='gotham text-white text-sm font-normal '>12km</span>
                                    </div>
                                    <div className='mt-[17px] flex items-start '>
                                        <div className='w-[218px]'>
                                            <h6 className='gotham text-white font-normal text-sm leading-[160%]'>{item?.attributes?.fullAddress}</h6>
                                            <h6 className='gotham text-brand-clime mt-[11px] font-normal text-sm leading-[160%]'>{item?.attributes?.phoneNumber}</h6>
                                        </div>
                                        <div className='ml-[60px]'>
                                            {item?.attributes?.access?.map((e: object, index: number) => (
                                                <div key={index} className='flex mb-[16px] items-center'>
                                                    <div className='h-[21px] w-[21px] border border-brand-clime rounded-[50%] flex items-center justify-center'>
                                                        <img src={CheckedMark} />
                                                    </div>
                                                    <p className='ml-[11px] text-white font-normal gotham text-sm'>{e?.title}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='mt-[21px] flex items-center'>
                                        <span className='text-white text-[12px] gotham font-normal '>Memberships from</span>
                                        <p className='text-brand-clime text-[12px] gotham font-normal ml-2'>${item?.attributes?.price}/week</p>
                                    </div>
                                </div>

                            </div>
                        ))}
                        {clubNearby?.length > 0 && (
                            <div className='mt-[33px] flex justify-between lg:flex-row flex-col lg:items-center items-start pb-8 relative w-full'>
                                <h6 className='gotham text-white font-normal w-full text-sm'>Don’t know what you’re looking for? Explore all club locations</h6>
                                <div onClick={() => setIsGetAll(true)}>
                                    <div className="w-max lg:mt-0 mt-[30px]">
                                        <div className="flex group">
                                            <button
                                                className={`border-white text-[11px] uppercase px-[26px] border-b text-white gotham text-medium  py-[11px] font-bold flex border-t border-l  
                 rounded-bl-[5px] rounded-tl-[5px]  border-r group-hover:border-brand-clime group-hover:text-brand-clime`}
                                            >
                                                ALL CLUBS
                                            </button>

                                            <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-white text-white group-hover:border-brand-clime group-hover:text-brand-clime ">
                                                {arrowRight()}
                                            </div>

                                        </div></div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            )}
    </>
  );
}

export default SearchClubNearby;
