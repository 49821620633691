import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useLoadScript } from '@react-google-maps/api'
import marker from '../../assets/images/icons/marker.png'
import markerActive from '../../assets/images/icons/cursorPointer.png'
import { Link } from 'gatsby'
import LoadingScreen from '../../common/loading/LoadingScreen'
import axios from 'axios'
type MapOptions = google.maps.MapOptions
import mapStyles from './style'

let locations: [] | undefined
let studios: [] | undefined

const API = process.env.STRAPI_API_URL;
const url = `${API}/api/studios?populate=image,access,address.state,address.country`;
axios.get(url).then((res) => {
    studios = res?.data
    locations = res?.data?.data?.map((item: object) => {
        return {
            lat: item?.attributes?.location?.coordinates?.[1],
            lng: item?.attributes?.location?.coordinates?.[0],
        }
    })
})

export const Map: React.FC = (props) => {
    const { clubSelected } = props

    const [currentInfo, setCurrentInfo] = useState<object>({ isShow: false, location: '', info: {} })
    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.GOOGLE_API_KEY, libraries: ['places'] })

    // Map Setting
    const mapRef = useRef<GoogleMap>()
    const onload = useCallback((map: any) => (mapRef.current = map), [])
    const options = useMemo<MapOptions>(() => ({ disableDefaultUI: true, clickableIcons: true, zoomControl: true, mapId: '3d7f8a4902336c6d', styles: mapStyles.dark }), [])

    // Get Info by Marker
    const getInfoStudio = (location: object) => {
        const findStudio = studios?.data?.find((e: object) => { return e?.attributes?.location?.coordinates?.[0] === location?.lng })
        setCurrentInfo({ ...currentInfo, info: findStudio })
    }

    // Loading
    if (!isLoaded) return <LoadingScreen />


    // Select studio and Find on the Map
    function FindStudio() {
        const getStudio = studios.data?.find(item => item.attributes?.title?.toUpperCase() == clubSelected?.title?.toUpperCase())
        const location = {
            lat: getStudio?.attributes?.location?.coordinates?.[1],
            lng: getStudio?.attributes?.location?.coordinates?.[0],
        }
        return mapRef.current?.panTo(location)
    }

    clubSelected !== undefined && FindStudio()

    return (
        <>
            {studios && (
                <GoogleMap
                    zoom={10}
                    center={locations?.[0]}
                    options={options}
                    onLoad={onload}
                    mapContainerClassName='map-container'
                >
                    {locations?.map((location: object) => (
                        <div>
                            <Marker
                                noClustererRedraw
                                noRedraw={true}
                                onClick={() => getInfoStudio(location)}
                                position={location} icon={currentInfo?.info?.attributes?.location?.coordinates?.[0] === location?.lng ? markerActive : marker} />
                            {currentInfo?.info?.attributes && (
                                <Link to={`/studio/${currentInfo?.info?.id}`}>
                                    <div className='text-white pb-4 bg-black w-[261px] border border-brand-orange absolute left-0 top-0'>
                                        <img src={currentInfo?.info?.attributes?.image?.data?.attributes?.url} className='w-full h-[149px] object-cover' />
                                        <div className='pl-[17px] h-full'>
                                            <h5 className='text-brand-orange mb-[10px] text-[20px] leading-[100%] mt-2 font-bold lowercase'>{currentInfo?.info?.attributes?.title}</h5>
                                            <span className='graphik-regular font-normal text-sm leading-[160%] lowercase text-white '>{currentInfo?.info?.attributes?.fullAddress}</span>
                                            <br />
                                            <span className='text-brand-orange graphik-regular pt-[6px] font-normal text-sm'>{currentInfo?.info?.attributes?.phoneNumber}</span>
                                            <p className='text-white graphik-regular pt-[5px] pb-[10px] text-sm font-normal'>Memberships from</p>
                                            <span className='mt-[10px] text-brand-orange graphik-regular font-medium text-base'>$ {currentInfo?.info?.attributes?.price}/week</span>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
                </GoogleMap>
            )}
        </>
    )
}
