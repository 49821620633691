import React, { useEffect, useState } from "react";
import { menuNavbar } from "../../../mock/homePage";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Link, navigate } from "gatsby";
import logo from "../../assets/images/hiit-logo.png";
import {
  featureStudio,
  featureClasses,
  featureMemberships,
  featureTheExperience,
} from "../../../mock/homePage";
import useFetch from "../../hooks/useFetch";

export default function AnimatedMenu({
  currentItem,
  setCurrentItem,
  setAnimated,
}: any) {
  const [studios, setStudios] = useState<[]>([])
  const [param, setParam] = useState<string>('')
  const [allowed, setAllowed] = useState<boolean>(false)


  const { data: nearYou, } = useFetch({ params: param, populate: "image", });
  const { data: allStudios } = useFetch({ params: 'studios', populate: 'image,access,address.state,address.country' })
  const { data } = useFetch({ params: "header", populate: "experiences.hightlightBlogCatagory,thumbnail,experiences.hightlightBlogs.thumbnail,classes.featuredClasses" });

  // Redirect
  useEffect(() => {
    if (currentItem === 2) navigate("/book-a-class")
    if (currentItem === 5) navigate("/own-a-hiit")
  }, [currentItem]);

  // Studios Near you
  useEffect(() => {
    const getStudiosNearYou = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(`studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`)
      });
    };
    getStudiosNearYou()
  }, [])

  // Get Distance of studio
  function Distance(pos: number) {
    const distance = pos?.km
    return <span>
      {distance?.toFixed(1)}
    </span>
  }

  // Tracking allowing get the location
  useEffect(() => {
    if (nearYou == null) {
      setAllowed(false)
      setStudios(allStudios)
    } else {
      setAllowed(true)
      setStudios(nearYou)
    }
  }, [data, nearYou])

  return (
    <>
      <div className="bg-[black] flex items-center   justify-between h-[70px] px-[40px] sticky top-0 z-30">
        <div className="w-[1440px] mx-auto flex items-center justify-between">

          <Link to="/">
            <img
              onClick={() => setAnimated(false)}
              src={logo}
              className="w-[230px]"
              alt="logo"
            />
          </Link>
          <AnimateSharedLayout>
            <ul className="flex  items-center text-[18px] gap-7 text-white font-bold cursor-pointer">
              {menuNavbar.map((item: object, index: number) => (
                <li
                  key={index}
                  className={`cursor-pointer relative `}
                  onClick={() => setCurrentItem(index)}
                >
                  <div className="py-2 z-10 relative cursor-pointer font-semibold ">{item}</div>
                  {currentItem === index && (
                    <motion.div
                      layoutId="underline"
                      style={{ height: "4px" }}
                      className="absolute bottom-[-15px]  w-full bg-brand-orange"
                    >
                    </motion.div>
                  )}
                </li>
              ))}
            </ul>
          </AnimateSharedLayout>

          <div className="flex h-[70px] text-[18px]">
            <a className="h-full" href="/register">
              <button className="bg-brand-orange h-full w-[145px] text-black cursor-pointer font-bold">
                join now
              </button>
            </a>
            <a className="h-full" href="/login">
              <button className="bg-black w-[145px] h-full text-white font-bold">
                login
              </button>
            </a>
          </div>
        </div>

      </div>
      <div className="bg-[#292929]">

        {currentItem !== 2 && currentItem !== 5 && (
          <div className="border-b border-b-brand-orange border-t border-t-brand-orange">
            <div className="text-white py-[45px] pl-[43px] w-[1440px] mx-auto  ">
              {/* Studios */}
              {currentItem === 0 && (
                <div className="text-white flex ">
                  <div className="min-w-[288px]  border-r border-r-gray">
                    <p className="text-gray graphik-regular font-normal text-base mb-3">
                      Find a studio
                    </p>
                    <ul className="flex flex-col gap-[14px]">
                      {featureStudio.findStudio.map((item: object) => (
                        <li className="text-white text-lg cursor-pointer font-bold">
                          <Link to={item.link}>{item.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="ml-[35px]">
                    <p className="text-gray graphik-regular font-normal text-base mb-[8px]">
                      Studios nearby
                    </p>
                    <div id={studios?.data?.length > 3 ? 'scroll' : ''} className={`${studios?.data?.length > 3 ? 'overflow-x-scroll' : ''} flex items-center justify-start gap-8 w-[1050px] pb-5`}>
                      {studios?.data?.map((item) => (
                        <div className="min-w-[255px]">
                          <img
                            className="w-[255px] h-[178px]"
                            src={
                              allowed
                                ? item?.attributes?.image?.url
                                : item?.attributes?.image?.data?.attributes?.url
                            }
                            alt="studio"
                          />
                          <div className="mt-[15px] flex items-center justify-between">
                            <p className="text-brand-orange font-bold text-xl">
                              {item?.attributes?.title}
                            </p>
                            {allowed && (
                              <span className="text-white text-sm">
                                <Distance km={item?.attributes?.distance} /> km
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* Classess */}
              {currentItem === 1 && (
                <div className="text-white">
                  <div className="text-white flex ">
                    <div className="w-[288px] border-r border-r-gray">
                      <p className="text-gray font-normal text-base mb-3 graphik-regular">
                        Classes
                      </p>
                      <ul className="flex flex-col gap-[14px]">
                        {featureClasses.classes.map((item: object) => (
                          <li className="text-white text-lg cursor-pointer font-bold">
                            <a href="/class">
                              {item.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="ml-[35px] border-r w-[288px] border-r-gray">
                      <p className="text-gray font-normal text-base mb-[8px]  graphik-regular">
                        Upcoming classes
                      </p>
                      <ul className="flex flex-col gap-[14px]">
                        {featureClasses.upComingClasses.map((item: object) => (
                          <li className="text-white text-lg cursor-pointer font-bold">
                            <a href="/book-a-class">
                              {item.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="ml-[35px] flex-1">
                      <p className="text-gray font-normal text-base  mb-[8px]  graphik-regular">
                        Featured classes
                      </p>
                      <div className="flex justify-between">
                        <ul className="flex flex-col gap-[14px]">
                          {data?.data?.attributes?.classes?.featuredClasses?.data
                            .slice(0, 5)
                            .map((item: object) => (
                              <a href="/class">
                                <li className="text-white text-lg cursor-pointer font-bold">
                                  {item?.attributes?.title}
                                </li>
                              </a>
                            ))}
                        </ul>

                        <ul className="flex flex-col gap-[14px]">
                          {data?.data?.attributes?.classes?.featuredClasses?.data
                            .slice(5, 10)
                            .map((item: object) => (
                              <Link to="/class">
                                <li className="text-white text-lg cursor-pointer font-bold">
                                  {item?.attributes?.title}
                                </li>
                              </Link>
                            ))}
                        </ul>
                        <div>
                          <img
                            className="w-[252px h-[251px] object-cover"
                            src={featureClasses.image}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Memberships */}
              {currentItem === 3 && (
                <div className="text-white flex ">
                  <div className="w-[288px] border-r border-r-gray">
                    <p className="text-gray font-normal text-base mb-3  graphik-regular">
                      Memberships
                    </p>
                    <ul className="flex flex-col gap-[14px]">
                      {featureMemberships.memberships.map((item: object) => (
                        <div>
                          {item.text == 'memberships' || item.text == 'build a membership'
                            ?
                            <a href="/memberships" className="text-white">
                              <li className="text-white text-lg cursor-pointer font-bold">
                                {item?.text}
                              </li>
                            </a>
                            :
                            (
                              <Link to={item.link}>
                                <li className="text-white text-lg cursor-pointer font-bold">
                                  {item.text}
                                </li>
                              </Link>

                            )
                          }
                        </div>
                      ))}
                    </ul>
                  </div>
                  <div className="ml-[35px]">
                    <p className="text-gray font-normal text-base mb-[8px] graphik-regular">
                      Memberships that hiit the spot
                    </p>
                    <div className="flex items-center justify-start gap-8">
                      {featureMemberships.hiitTheSpot.map((item) => (
                        <div>
                          <img
                            className="w-[255px] h-[178px]"
                            src={item.image}
                            alt="studio"
                          />
                          <div className="mt-[15px] flex items-center justify-between">
                            <p className="text-brand-orange font-bold text-xl">
                              {item.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* The experience */}
              {currentItem === 4 && (
                <div className="text-white">
                  <div className="text-white flex ">
                    <div className="w-[288px] border-r border-r-gray">
                      <p className="text-gray font-normal text-base mb-3  graphik-regular">
                        About us
                      </p>
                      <ul className="flex flex-col gap-[14px]">
                        {featureTheExperience.aboutUs.map((item: object) => (
                          <Link to={item.link}>
                            <li className="text-white text-lg cursor-pointer font-bold">
                              {item.text}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                    <div className="ml-[35px] border-r w-[288px] border-r-gray">
                      <p className="text-gray font-normal text-base mb-[8px] graphik-regular">
                        On the blog
                      </p>
                      <ul className="flex flex-col gap-[14px]">
                        <Link to="/blog">
                          <li className="text-white text-lg cursor-pointer font-bold">
                            blog
                          </li>
                        </Link>
                        {data?.data?.attributes?.experiences?.hightlightBlogCatagory?.data?.map(
                          (item) => (
                            <Link
                              state={{ category: item?.attributes?.title }}
                              to={"/blog"}
                            >
                              <li className="text-white text-lg cursor-pointer font-bold">
                                {item?.attributes?.title}
                              </li>
                            </Link>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="ml-[35px] flex-1">
                      <p className="text-gray font-normal text-base  mb-[20px] graphik-regular">
                        Featured blogs
                      </p>
                      <ul
                        id="scroll"
                        className="flex w-[838px] h-[276px] gap-[37px] overflow-x-scroll"
                      >
                        {data?.data?.attributes?.experiences?.hightlightBlogs?.data.map((item: object) => (
                          <div className="flex-col min-w-[252px]">
                            <img className="w-full h-[172px]" src={item?.attributes?.thumbnail?.data?.attributes?.url} />
                            <p className="mt-[13px] text-brand-orange font-bold text-[20px]">
                              {item?.attributes?.title}
                            </p>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
        }
      </div>

    </>
  );
}
