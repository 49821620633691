import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";

function HomeSlider() {
  const [slider, setSlider] = useState<any>();
  const { loading, data } = useFetch({
    params: "home-page",
    populate: "upperCarousels,upperCarousels.images,upperCarousels.background",
  });

  const sliderData = data?.data?.attributes?.upperCarousels;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots: any) => (
      <div
        className="cursor-pointer"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          margin: "0 0 42px 74px",
        }}
      >
        <ul
          className="cursor-pointer"
          style={{
            width: "100%",
            margin: "0 9px 0 0",
            display: "flex",
            gap: "9px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div className="h-full bg-transparent flex justify-center items-center cursor-pointer">
        <div
          className="bg-brand-orange"
          style={{
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
    ),
  };
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && sliderData && (
        <div className="homeCarousel lg:h-[690px] h-[555px] overflow-x-hidden overflow-y-hidden">
          <Slider
            ref={(c: any) => setSlider(c)}
            {...settings}
            className="h-[690px]"
          >
            {sliderData?.map((item: object, index: number) => (
              <div key={index} className="w-full relative ">
                <img
                  className="w-full md:h-auto h-[555px] opacity-90  object-cover"
                  src={item?.background?.data?.attributes?.url}
                />
                <img
                  className="md:w-[422px] w-[239px] md:h-[530px] object-cover h-[301px] absolute top-1/2 left-2/4 -translate-y-1/2 -translate-x-2/4"
                  src={item?.images?.data[0]?.attributes?.url}
                />
                <p className="outline-text font-bold  md:text-[80px] text-[50px] leading-[95%] w-full text-center  absolute top-1/2 -translate-y-2/4 -tracking-tighter">
                  {item?.title}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default HomeSlider;
