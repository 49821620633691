import React, { useEffect, useState } from "react";
import { HeadFC, navigate } from "gatsby";
import { Router, useLocation } from "@reach/router";
import Login from "../routes/Login";
import Register from "../routes/Register";
import HomePage from "../routes";
import PrivateRoute from "../components/PrivateRoute";
import Memberships from "../routes/Memberships";
import About from "../routes/About";
import Studio from "../routes/studio/index";
import BlogLayout from "../routes/blog";
import BlogDetail from "../routes/blog/[id]";
import studioId from "../routes/studio/[id]";
import ClassDetail from "../routes/class";
import BookAClass from "../routes/class/book-a-class";
import NotFoundPage from "../routes/404";
import WorkWithUs from "../routes/work";
import WorkDetail from "../routes/work/[id]";
import Access from "../routes/Access";
import Contact from "../routes/contact";
import Legal from "../routes/legal";
import Faq from "../routes/faq";
import { Franchise } from "../routes/Franchise";
import { StoreProvider } from "../context/RegisterContext";

// Dashboard
import DashBoard from "../routes/dashboard";
import Benefits from "../routes/dashboard/Benefits";
import Bookings from "../routes/dashboard/Bookings";
import Timetable from "../routes/dashboard/Timetable";
import Upgrade from "../routes/dashboard/upgrade/Upgrade";
import UpgradeCongrats from "../routes/dashboard/upgrade/Congrats";
import Account from "../routes/dashboard/account/Account";
import AccountPassWord from "../routes/dashboard/account/Password";
import Biling from "../routes/dashboard/account/Biling";
import Notifications from "../routes/dashboard/account/Notifications";
import ChangeClub from "../routes/dashboard/billing/ChangeClub";
import { SEO } from "../components/SEO";
import useFetch from "../hooks/useFetch";

import IpadCheckin from "../routes/IpadCheckin";

const IndexPage = () => {
  return (
    <StoreProvider>
      <Router basepath="/">
        {/* Protected Routes */}
        <PrivateRoute path="/" component={HomePage} />
        <PrivateRoute path="/legal" component={Legal} />
        <PrivateRoute path="/about" component={About} />
        <PrivateRoute path="/faq" component={Faq} />
        <PrivateRoute path="/studio" component={Studio} />
        <PrivateRoute path="/contact" component={Contact} />
        {/* <PrivateRoute path="/own-a-hiit" component={Franchise} /> */}
        <PrivateRoute path="/studio/:id" component={studioId} />
        <PrivateRoute path="/blog" component={BlogLayout} />
        <PrivateRoute path="/blog/:id" component={BlogDetail} />
        <PrivateRoute path="/class" component={ClassDetail} />
        <PrivateRoute path="/class/:id" component={ClassDetail} />
        <PrivateRoute path="/book-a-class" component={BookAClass} />
        <PrivateRoute path="/memberships" component={Memberships} />
        <PrivateRoute path="/work-with-us" component={WorkWithUs} />
        <PrivateRoute
          path="/work-with-us/recruitment/:id"
          component={WorkDetail}
        />
        <PrivateRoute path="/dashboard" component={DashBoard} />
        <PrivateRoute path="/dashboard/benefits" component={Benefits} />
        <PrivateRoute path="/dashboard/bookings" component={Bookings} />
        <PrivateRoute path="/dashboard/timetable" component={Timetable} />
        <PrivateRoute path="/dashboard/upgrade" component={Upgrade} />
        <PrivateRoute
          path="/dashboard/upgrade-congrats"
          component={UpgradeCongrats}
        />
        <PrivateRoute path="/dashboard/account" component={Account} />
        <PrivateRoute
          path="/dashboard/account/password"
          component={AccountPassWord}
        />
        <PrivateRoute path="/dashboard/account/biling" component={Biling} />
        <PrivateRoute
          path="/dashboard/account/notifications"
          component={Notifications}
        />
        <PrivateRoute
          path="/dashboard/billing/change-club"
          component={ChangeClub}
        />
        <PrivateRoute path="/ipad-checkin" component={IpadCheckin} />
        {/* Public Routes */}
        <Login path="/login" />
        <Access path="/access" />
        <Register path="/register" />
        <NotFoundPage path="*" />
      </Router>
    </StoreProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  const path = useLocation();
  const [content, setContent] = useState<string>("");
  const { data: seo } = useFetch({ params: "global", populate: "seo" });

  useEffect(() => {
    if (path.pathname.replace(/\//g, "") == "") {
      setContent(seo?.data?.attributes?.seo?.[0]);
    } else {
      setContent(
        seo?.data?.attributes?.seo?.find(
          (item) => item?.key == path.pathname.replace(/\//g, "")
        )
      );
    }
  }, [seo, path.pathname]);

  return <SEO title="Hiit Republic" description={content?.value} />;
};
