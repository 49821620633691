import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
interface SeoProps {
    title: string,
    description: string,
    children: any
}
export const SEO = ({ title, description, children }: SeoProps) => {
    const { title: defaultTitle, description: defaultDescription } = useSiteMetadata()

    const seo = {
        title: title || 'Hiit Republic',
        description: description || 'A workout like no other.  HIIT Republic a boutique functional fitness class based environment with a full gym upgrade membership option, open 24/7, all at an affordable price.  Experience Hiit Republic today to feel the difference.  No two classes are ever the same.',
    }

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            {children}
        </>
    )
}