import React from "react";
import DashBoardLayout from "../../components/layout/DashboardLayout";
import DashboardDetail from "../../components/dashboard/DashboardDetail";
function DashBoard() {
    return (
        <div>
            <DashBoardLayout>
                <DashboardDetail />
            </DashBoardLayout>
        </div>
    );
}

export default DashBoard;
