import React from 'react'

interface Iprops {
    isRight: boolean
    text: string
    onCLick: () => void
}
function ArrowButton(props: Iprops) {
    const { isRight, onClick, text } = props

    return (
        <>
            <div className="w-max">
                <div onClick={onClick} className="flex group">
                    {!isRight && (
                        <div className="px-3 py-[12px] border-l border-[1px] rounded-bl-[4px] rounded-tl-[4px] lg:rounded-tr-none rounded-tr lg:rounded-br-none rounded-br  flex items-center border-[#777777] text-[#777777] group-hover:border-brand-clime group-hover:text-brand-clime ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
                                <path d="M5.49219 9L1.00017 4.82884L5.49219 0.65768" stroke="currentColor" strokeWidth="1.2" />
                                <path d="M1.23487 5.02655L19.4004 5.02655" stroke="currentColor" strokeWidth="1.2" />
                            </svg>
                        </div>
                    )}
                    <button
                        className={`border-[#777777] text-[11px] uppercase px-[37px] border-b text-[#777777] gotham text-medium  py-[11px] font-bold flex
                    border-t border-l   ${!isRight ? 'rounded-tr-[4px] lg:block hidden  rounded-br-[4px] border-l-0' : 'rounded-tl-[4px] border-r-0 rounded-bl-[4px]'}
                     border-r group-hover:border-brand-clime group-hover:text-brand-clime`}
                    >
                        {text}
                    </button>{" "}
                    {isRight && (
                        <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-[#777777] text-[#777777] group-hover:border-brand-clime group-hover:text-brand-clime ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
                                <path d="M14.1074 1L18.5994 5.17116L14.1074 9.34232" stroke="currentColor" strokeWidth="1.2" />
                                <path d="M18.3647 4.97345H0.199219" stroke="currentColor" strokeWidth="1.2" />
                            </svg>
                        </div>
                    )}
                </div></div>
        </>
    )
}

export default ArrowButton