import React, { useState, createContext, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import { globalHistory } from "@reach/router";
const RegisterContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}

const StoreProvider = ({ children }: iProps) => {
  const [param, setParam] = useState<string>("");
  // Your clubs
  const [yourClub, setYourClub] = useState<object>();
  const [access, setAccess] = useState<[]>([]);

  const [plan, setPlan] = useState<null>(null);
  // Modal Global
  const [modalVivas, setModalVivas] = useState<boolean>(false);
  const [isChangeClub, setIsChangeClub] = useState<boolean>(false);

  // Register store
  const [addOns, setAddOns] = useState<[]>([]);
  const [upgrades, setUpgrades] = useState<[]>([]);
  const [membershipPlan, setMembershipPlan] = useState<[]>([]);

  // Get studios
  const { data: nearYou } = useFetch({
    params: param,
    populate: "image,access,address,address.state,address.country",
  });
  const { data } = useFetch({
    params: "studios",
    populate: "image,access,address,address.state,address.country",
  });

  const path = globalHistory.location.pathname;

  //open change location on change button only
  const [open, setOpen] = useState<boolean>(false);

  // Get studios nearby
  useEffect(() => {
    const getStudios = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getStudios();
  }, []);

  useEffect(() => {
    nearYou && setYourClub(nearYou?.data?.[0]);
  }, [nearYou]);

  useEffect(() => {
    if (nearYou && nearYou?.data?.length > 0) {
      setYourClub(nearYou?.data?.[0]);
      setAccess(yourClub?.attributes?.access);
    } else {
      if (path === "/register") {
        if (!localStorage.getItem("yourClub")) {
          setYourClub(
            data?.data?.find((item) => item?.attributes?.title === "braddon")
          );
        } else setYourClub(JSON.parse(localStorage.getItem("yourClub")));
      } else {
        if (!localStorage.getItem("yourClub")) {
          setYourClub();
        } else setYourClub(JSON.parse(localStorage.getItem("yourClub")));
      }
      setAccess(yourClub?.attributes?.access?.data);
    }
  }, [nearYou, data, path]);

  return (
    <RegisterContext.Provider
      value={{
        yourClub,
        setYourClub,
        isChangeClub,
        modalVivas,
        setModalVivas,
        setIsChangeClub,
        addOns,
        setAddOns,
        membershipPlan,
        setMembershipPlan,
        nearYou,
        upgrades,
        setUpgrades,
        access,
        open,
        setOpen,
        setPlan,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export { RegisterContext, StoreProvider };
