import React, { useState } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";

function InputTextArea(props: any) {
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    setActive(true);
    return e.target.value === ""
      ? ((e.target.style.borderColor = ""),
        (e.target.style.color = ""),
        setActive(false))
      : ((e.target.style.borderColor = "#FF7900"),
        (e.target.style.color = "#FF7900"),
        setActive(true));
  };
  return (
    <div className="flex relative flex-col w-full">
      <label>
        <span className="after:content-[''] text-gray after:ml-0.5 after:text-white block text-[12px] graphik-regular font-medium text-white">
          {active ? "" : ""}
        </span>
        <span
          className={`absolute  block graphik-regular font-medium  transition-all duration-300 ${active ? "top-0 left-0 text-white text-xs" : "top-[39px] left-7 text-gray text-base"
            }`}
        >
          {" "}
          {props.placeholder ?? ""}
        </span>
        {props.isShowRequired && !active && (
          <div className="absolute top-10 right-3 graphik-regular font-normal text-xs text-brand-orange">
            This Field is required{" "}
            <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
          </div>
        )}
      </label>
      <textarea
        onInput={(e) => {
          activeValue(e);
        }}
        className="mt-1 px-6 h-[136px] placeholder:text-white py-4 bg-black border graphik-regular border-[#FFFFFF] placeholder-slate-400  focus:outline-none  block w-full sm:text-sm focus:ring-1 text-start"
        placeholder={""}
        onChange={props.onchange ? () => { } : props.onchange}
      />
    </div>
  );
}
export default InputTextArea;
