import React from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import about3 from "../../assets/images/about/about3.jpg";
import about2 from "../../assets/images/about/about2.jpg";
import aboutHiit from "../../assets/images/about/aboutHiit.png";

function MoreThanHiit({ data }) {
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <div className="w-full md:h-[523px] max-w-[1440px] mx-auto flex md:flex-row flex-col">
      <div className="md:w-1/2  w-full pt-[84px] md:pl-[67px]">
        <div className="mx-[25px] mb-6 md:mb-0 md:mx-0">
          <p
            className="text-white text-[40px] lg:text-[61.81px] leading-[97.5%] tracking-[-0.03em]"
            dangerouslySetInnerHTML={createMarkup(
              data.attributes.midBanner.title
            )}
          ></p>
          <p className="text-white my-10 graphik-regular font-normal text-[14px]">
            {data.attributes.midBanner.description}
          </p>
          <div
            onClick={() => {
              document.getElementById("freepass").scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }}
          >
            <ButtonNavigate
              text="get a free pass"
              style="w-[183px] h-[56px] border-b text-[#777777]"
            ></ButtonNavigate>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 w-full  md:pl-[67px] flex h-[266px] md:h-auto">
        <div className="w-full relative">
          <img
            className="ml-auto block z-30  h-[237.97px] w-[173.51px] md:h-[427px] md:w-[304px] "
            src={data.attributes.midBanner.images.data[0].attributes.url}
          />
          <img
            className="absolute bottom-0 -z-10 opacity-1  w-[237.19px] h-[168.87px] md:h-[323px] md:w-[443px] "
            src={data.attributes.midBanner.background.data.attributes.url}
          />
          <img
            className="absolute top-[19%]  ml-[26px]  z-20 w-[41px] h-[102px] md:top-[15%] md:translate-y-7 md:h-[205px] md:w-[83px] "
            src={aboutHiit}
          />
        </div>
      </div>
    </div>
  );
}

export default MoreThanHiit;
