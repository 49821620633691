import React, { useState, useEffect } from "react";
import { studios } from "../../../mock/studios";
import { buttons } from "../../../mock/clubDetailNavigating";
import { timeTable } from "../../../mock/classesTodayTimeTable.js";
import GetYourFreePass from "../home/GetYourFreePass";
import plus from "../../assets/images/memberships/plus.png";
import ArrowRight45 from "../../assets/images/icons/ArrowRight45.svg";

import ButtonNavigate from "../../common/buttons/ButtonNavigate.tsx";
import mailIcon from "../../assets/images/icons/mailIcon.svg";
import phoneIcon from "../../assets/images/icons/phoneIcon.svg";
import fullRateIcon from "../../assets/images/icons/rating.svg";
import noneRatingIcon from "../../assets/images/icons/noneRatingIcon.svg";
import { Link, useStaticQuery } from "gatsby";
import { getStudioList } from "../../apis/studio/studio";
import DropDownIcon from "../../assets/images/icons/DropDownIcon.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StudiosNearYou from "../home/StudiosNearYou";
import { graphql } from "gatsby";
import PricesForBraddon from "../memberships/PricesForBraddon";
import SelectOption from "../../common/dropdowns/SelectOption";
import useFetch from "../../hooks/useFetch";
import TwoWeekFree from "./TwoWeekFree";

function handleData(data: any, id: string) {
  data = data.data.map((i: any) => {
    return {
      id: i.id,
      image: i.attributes.image.data.attributes.url,
      title: i.attributes.title.toLowerCase(),
      distance: 0.3, // need calculate
      access: ["24/7", "swimming pool", "health club"], //missing field data
      address: i.attributes.fullAddress,
      price: i.attributes.price,
      phoneNumber: i.attributes.phoneNumber,
      isShow: false,
      data: i.attributes,
      openTime: i.openTime
        ? i.openTime.map((i) => i.from + i.to)
        : ["thu 9:00- 10:00"],
      supportTime: i.supportTime
        ? i.supportTime.map((i) => i.from + i.to)
        : ["thu 9:00- 10:00"],
    };
  });
  data = data.filter((i: any) => i.id == id)[0];
  return data;
}
function calculateRating(value: number) {
  const sweat: any = [];
  const number = Math.floor(value);
  for (let i = 0; i < number; i++) {
    const data = <img className="mr-2" src={fullRateIcon} />;
    sweat.push(data);
  }
  if (sweat.length < 5) {
    for (let i = 0; i < 5 - number; i++) {
      const data = <img className="mr-2" src={noneRatingIcon} />;
      sweat.push(data);
    }
  }

  return sweat;
}
function slickCarousel(data) {
  const [slider, setSlider] = useState<any>();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    centerMode: true,
    arrows: false,
  };
  const next = () => {
    slider.slickNext();
  };
  const prev = () => {
    slider.slickPrev();
  };
  const [slider2, setSlider2] = useState<any>();
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: false,
    arrows: false,
  };
  const next2 = () => {
    slider2.slickNext();
  };
  const prev2 = () => {
    slider2.slickPrev();
  };


  return (
    <>
      {data && data.length > 0 && (
        <>
          <div className="mt-[100px] relative sliderStudio xl:block hidden">
            <div className="w-full absolute flex justify-between">
              <div className="outline-text text-[66px] font-bold">
                {" "}
                our coaches
              </div>
              <div className="z-50">
                <div className="mx-auto my-9 mr-[71px] flex justify-around pb-5 w-[106px] border-b-[5px] border-b-brand-orange">
                  <button onClick={() => prev()}>
                    <img className="rotate-[225deg]" src={ArrowRight45} />
                  </button>
                  <button onClick={() => next()}>
                    <img className="rotate-45" src={ArrowRight45} />
                  </button>
                </div>
              </div>
            </div>
            <Slider
              ref={(c: any) => setSlider(c)}
              {...settings}
              className="h-[678px]"
            >
              {data.map((e) => (
                <div className="mt-[100px]">
                  <img
                    className="h-[459px] w-[459px] object-cover"
                    src={e?.background?.data?.attributes?.url}
                  />
                  <div className=" w-[459px] object-cover">
                    <p className=" font-bold mt-3">{e.title}</p>
                    <div className="description  hidden font-light">
                      <p className="graphik-regular my-[14px]">
                        {e.description}
                      </p>
                      <div className="flex-col gap-[21px]">
                        <div className="flex justify-between">
                          <p className="text-brand-orange">superpower</p>
                          <p className="graphik-regular">
                            {e?.customMetadata?.supperpower}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-brand-orange">reknowned for</p>
                          <p className="graphik-regular">
                            {e?.customMetadata?.["reknowned for"]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="mt-[100px] relative  xl:hidden block">
            <div className="  flex justify-between  mx-[26px]">
              <div className="outline-text text-[50px] font-bold">
                {" "}
                our coaches
              </div>
              <div className="z-50">
                <div className="mx-auto my-9 flex justify-around pb-5 w-[74px] border-b-[5px] border-b-brand-orange">
                  <button onClick={() => prev2()}>
                    <img className="rotate-[225deg]" src={ArrowRight45} />
                  </button>
                  <button onClick={() => next2()}>
                    <img className="rotate-45" src={ArrowRight45} />
                  </button>
                </div>
              </div>
            </div>
            <Slider ref={(c: any) => setSlider2(c)} {...settings2} className="">
              {data.map((e) => (
                <div className="">
                  <img
                    className="w-[322px] h-[345px] object-cover mx-auto border-[1px] border-brand-orange"
                    src={e?.background?.data?.attributes?.url}
                  />
                  <div className="mx-[26px] ">
                    <p className=" font-bold mt-3">{e.title}</p>
                    <div className="description font-light">
                      <p className="graphik-regular my-[14px]">
                        {e.description}
                      </p>
                      <div className="flex-col flex gap-[21px]">
                        <div className="flex justify-between">
                          <p className="text-brand-orange">superpower</p>
                          <p className="graphik-regular">
                            {e?.customMetadata?.["supperpower"]}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-brand-orange">reknowned for</p>
                          <p className="graphik-regular">
                            {e?.customMetadata?.["reknowned for"]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
}
function studioInformation(props: any) {
  const routeId = props.id.id;
  const [show, setShow] = useState(false);
  //   const path = getRoutePath(location, params);
  const [clubInfo, setClubInfo] = useState<any>({});
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(0);
  const { data, loading } = useFetch({
    params: "studios",
    populate:
      "image, access, description, gallery, coachers, coachers.image, coachers.customMetadata, coachers.background,coachers.images, background, avaliableClass, avaliableClass.images, avalibleClass.customMetadata, midCarrousels, midCarrousels.background, midCarrousels.images, openTime, supportTime",
  });

  useEffect(() => {
    if (data && data.data) {
      const club = handleData(data, routeId);
      setClubInfo(club);
    }
  }, [data]);
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    });
  });
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [middleSlider, setMiddleSlider] = useState();
  const [classActive, setClassActive] = useState();
  const [isShowInfo, showInfo] = useState(false);


  return (
    <>
      {clubInfo && (
        <>
          <div className="sticky z-40 top-[30%] left-full bg-[red] w-full h-[0px] text-red-500">
            <button
              className="absolute right-0 h-[49px] w-[211px] origin-bottom-right -rotate-90 bg-black border-[1px] border-brand-orange text-brand-orange"
              onClick={() => setShow(true)}
            >
              Two weeks free
            </button>
          </div>
          <div
            className={`sticky z-50 top-[10%] right-0 bg-[red] w-full text-red-500  h-full  ${show ? "show" : "hidden"
              }`}
          >
            <div className="absolute  h-[49px]  right-0 bg-black border-[1px] border-brand-orange text-brand-orange ">
              <div className="relative">
                <div className="sticky z-40 top-[30%] right-full bg-[red] w-full h-[0px] text-red-500">
                  <button
                    className="absolute right-full h-[49px] w-[211px] origin-bottom-right -rotate-90 bg-black border-[1px] border-brand-orange text-brand-orange"
                    onClick={() => setShow(false)}
                  >
                    Two weeks free
                  </button>
                </div>
                {show && <TwoWeekFree setShow={setShow} />}
              </div>
            </div>
          </div>
          <div className=" mx-[4.9%] relative">
            <p className="text-gray-500 mb-[10px] mx-[10px] mt-[22px] text-gray  graphik-regular block xl:hidden">
              <Link to="/studio" className="graphik-regular underline">
                All Studios{" "}
              </Link>
              /{" "}
              <Link to="#" className="graphik-regular underline">
                {clubInfo?.title}
              </Link>
            </p>
            <img
              className="w-full max-h-[527px] xl:h-full h-[285px] object-cover"
              src={
                clubInfo?.data?.background?.data?.attributes.url ??
                clubInfo?.data?.image?.data?.attributes.url
              }
            />
            <p
              style={{ transform: "translate(-50%, -50%)" }}
              className="absolute text-center outline-text left-1/2 top-1/2 text-[80px] outline-[1px] outline-white text-transparent uppercase"
            >
              {clubInfo?.title}
            </p>
          </div>
          <div className=" gap-[9px] my-[11px] mx-[4.9%] xl:flex hidden">
            {buttons.map((i: any) => (
              <a href={i.link} className="w-1/5">
                <ButtonNavigate
                  style=" w-full h-[59px] bg-[#292929] border-transparent text-brand-orange border-b"
                  text={i.text}
                ></ButtonNavigate>
              </a>
            ))}
          </div>
          <p className="text-gray-500 mb-[10px] mx-[10%] mt-[62px] text-gray  graphik-regular  xl:block hidden">
            <Link to="/studio" className="graphik-regular underline">
              All Studios{" "}
            </Link>
            /{" "}
            <Link to="#" className="graphik-regular underline">
              {clubInfo?.title}
            </Link>
          </p>
          <div className="flex xl:hidden mx-6 my-[30px] justify-between items-center">
            <p className="text-brand-orange">memberships from </p>
            <p className="text-[25px] tracking-[-0.03em]">
              {" "}
              ${clubInfo?.price}/week
            </p>
          </div>
          <div className="flex xl:hidden justify-between items-center mx-[26px] mb-[25px]">
            <p>about the studio</p>
            <img
              className=" bg-transparent"
              src={DropDownIcon}
              onClick={() => showInfo(!isShowInfo)}
            />
          </div>
          <div
            className={` mx-[4.9%] mb-[50px] ${isShowInfo ? "flex " : "hidden"
              }`}
          >
            <div>
              <p className="font-[700] justify-between text-2xl text-brand-orange mb-[10px]">
                open 24/7
              </p>
              <SelectOption
                isTitle={true}
                title="Staffed hours"
                style="border-0 w-[206px] px-0 py-0 graphik-regular"
                titleStyle="graphik-regular mb-[11px] text-[16px]"
                placeholder={
                  clubInfo?.supportTime ? clubInfo?.supportTime[0] : ""
                }
                placeholderColor="text-white"
                data={clubInfo?.supportTime}
              ></SelectOption>
              <SelectOption
                isTitle={true}
                title="Member assist"
                style="border-0 px-0 py-0 graphik-regular"
                titleStyle="graphik-regular mb-[11px] text-[16px]"
                placeholder={clubInfo?.openTime ? clubInfo?.openTime[0] : ""}
                placeholderColor="text-white"
                data={clubInfo?.openTime}
              ></SelectOption>
              <div className=" flex flex-col ">
                <span className="graphik-regular mb-[40px]">
                  {clubInfo?.address}
                </span>
                <span className="flex  gap-[19px] mb-[26px]">
                  <img src={phoneIcon} />
                  <p className="graphik-regular">
                    {clubInfo?.data?.phoneNumber}
                  </p>
                </span>
                <span className="flex gap-[19px] ">
                  <img src={mailIcon} />
                  <p className="graphik-regular">
                    {clubInfo?.data?.email ?? "braddon@clublime.com.au"}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className=" mx-[10%] gap-[50px] xl:flex hidden">
            <div className="w-1/2">
              <p className="font-[700] text-2xl text-brand-orange mb-[10px] ">
                memberships from
              </p>
              <p className="outline-text-1 text-[40px] mt-5 mb-6">
                ${clubInfo?.price}/week
              </p>
              <p className="graphik-regular w-[460px] text-base">
                {clubInfo?.data?.description}
              </p>
            </div>
            <div className="w-1/2 flex justify-between  gap-[82px]">
              <div className="w-1/2 flex flex-col max-w-[285px] ">
                <p className="font-[700] text-2xl  text-brand-orange mb-[10px]">
                  find us at
                </p>
                <span className="graphik-regular mb-[38px] ">
                  {clubInfo?.address}
                </span>
                <span className="flex  gap-[16px] mb-[25px]">
                  <img src={phoneIcon} />
                  <p className="graphik-regular">
                    {clubInfo?.data?.phoneNumber}
                  </p>
                </span>
                <span className="flex gap-[16px] ">
                  <img src={mailIcon} />
                  <p className="graphik-regular">
                    {clubInfo?.data?.email ?? "braddon@clublime.com.au"}
                  </p>
                </span>
              </div>
              <div className="w-1/2 flex flex-col ">
                <div>
                  <p className="font-[700] justify-between text-2xl text-brand-orange mb-[10px]">
                    open 24/7
                  </p>
                  <SelectOption
                    isTitle={true}
                    title="Staffed hours"
                    style="border-0 px-0 py-0 graphik-regular"
                    titleStyle="graphik-regular mb-[11px] text-[16px]"
                    placeholder={
                      clubInfo?.supportTime ? clubInfo?.supportTime[0] : ""
                    }
                    placeholderColor="text-white"
                    data={clubInfo?.supportTime}
                  ></SelectOption>
                </div>
                <div className="mt-[38px]">
                  <SelectOption
                    isTitle={true}
                    title="Member assist"
                    style="border-0 px-0 py-0 graphik-regular"
                    titleStyle="graphik-regular mb-[11px] text-[16px]"
                    placeholder={clubInfo?.openTime ? clubInfo?.openTime[0] : ""}
                    placeholderColor="text-white"
                    data={clubInfo?.openTime}
                  ></SelectOption>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-[10%] mt-6  gap-[27px] mb-[56px] xl:flex hidden">
            <a href="/register">
              <ButtonNavigate
                style="h-[56px] w-[145px] border-b-[1px] border-[#777777] text-[#777777]"
                text="sign me up"
              ></ButtonNavigate>
            </a>
            <ButtonNavigate
              style="h-[56px] w-[145px] border-b-[1px] border-[#777777] text-[#777777]"
              text="virtual tour"
            ></ButtonNavigate>
          </div>

          <div id="gallery"></div>
          {clubInfo && clubInfo.data && clubInfo.data.gallery.data && (
            <div className="flex overflow-auto min-h-[465px]" id="scroll">
              <div className="min-w-[100%] relative bg-black flex  w-[100%] ">
                {clubInfo?.data?.gallery?.data && (
                  <>
                    <img
                      className="h-auto w-[30%] z-[-1] transition-all duration-300 hover:scale-x-[1.5] hover:z-40"
                      src={clubInfo?.data?.gallery?.data[1]?.attributes?.url}
                    />
                    <img
                      className="absolute z-20 bottom-0 w-3/12 hover:w-[30%] h-auto  transition-all duration-300  hover:z-40"
                      src={clubInfo?.data?.gallery?.data[0]?.attributes?.url}
                    />
                    <img
                      className="h-auto w-[30%] hover:w-[35%] absolute left-[calc(25%*90/100)] transition-all duration-300  hover:z-40"
                      src={clubInfo?.data?.gallery?.data[1]?.attributes?.url}
                    />

                    <img
                      className="absolute z-0 h-auto right-[15%] top-[8%] w-[35%] hover:w-[38%]  transition-all duration-300 hover:z-40"
                      src={clubInfo?.data?.gallery?.data[2]?.attributes?.url}
                    />

                    <img
                      className="absolute right-0 w-3/12 h-auto top-[5%]  transition-all duration-300  hover:w-[30%]  hover:z-40"
                      src={clubInfo?.data?.gallery?.data[3]?.attributes?.url}
                    />
                  </>
                )}
              </div>
              <div className="min-w-[100%] relative bg-black flex  w-[100%] ">
                {clubInfo?.data?.gallery?.data && (
                  <>
                    <img
                      className="absolute z-20 bottom-0 w-3/12 h-auto  transition-all duration-300  hover:w-[30%] hover:z-40"
                      src={clubInfo?.data?.gallery?.data[0]?.attributes?.url}
                    />
                    <img
                      className="h-auto w-[30%] hover:w-[35%] absolute left-[calc(25%*90/100)] transition-all duration-300 hover:z-40"
                      src={clubInfo?.data?.gallery?.data[1]?.attributes?.url}
                    />

                    <img
                      className="absolute z-0 h-auto right-[15%] top-[8%] w-[35%] hover:w-[38%]  transition-all duration-300  hover:z-40"
                      src={clubInfo?.data?.gallery?.data[2]?.attributes?.url}
                    />

                    <img
                      className="absolute right-0 w-3/12 h-auto top-[5%]  transition-all duration-300  hover:w-[30%] hover:z-40"
                      src={clubInfo?.data?.gallery?.data[3]?.attributes?.url}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {clubInfo &&
            clubInfo.data &&
            clubInfo.data.midCarrousels.length > 0 && (
              <>
                <Slider ref={(c) => setMiddleSlider(c)} {...settings}>
                  {clubInfo.data.midCarrousels.map((i) => (
                    <div>
                      <div className="flex justify-between mt-20">
                        <img
                          className="xl:max-w-[355px] xl:max-h-[338px] max-w-0 max-h-0 "
                          src={i?.images?.data[0]?.attributes.url}
                        />
                        <div className="text-brand-orange text-center">
                          Bring The Hit
                          <br />
                          <span className="outline-text-1 text-[50px] spacing-[-3%]">
                            {i?.title?.split("<br/>")[0]}
                            <br />
                            {i?.title?.split("<br/>")[1]}
                          </span>
                          <br />
                          <a href="/register">
                            <ButtonNavigate
                              style="h-[56px] w-[145px] border-b-[1px] mt-[32px] mb-[38px] border-[#777777] text-[#777777]"
                              text="join now"
                            ></ButtonNavigate>
                          </a>
                          <br />
                          <p className="text-white text-sm graphik-regular">
                            {i?.description?.split("<br/>")[0]}
                            <br /> {i?.description?.split("<br/>")[1]}
                          </p>
                        </div>
                        <img
                          className="xl:max-w-[355px] xl:max-h-[338px] max-w-0 max-h-0"
                          src={i?.background?.data?.attributes?.url}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
                <div>
                  <div className="mx-auto my-9 flex justify-around pb-5 w-[106px] border-b-[5px] border-b-brand-orange">
                    <button onClick={() => middleSlider.slickPrev()}>
                      <img className="rotate-[225deg]" src={ArrowRight45} />
                    </button>
                    <button onClick={() => middleSlider.slickNext()}>
                      <img className="rotate-45" src={ArrowRight45} />
                    </button>
                  </div>
                </div>
              </>
            )}

          <div id="membership">
            <PricesForBraddon></PricesForBraddon>
          </div>
          <p className="w-[90%] mx-auto text-right mt-6 graphik-regular text-gray ">
            *Subject to terms and conditions
          </p>
          {clubInfo &&
            clubInfo.data &&
            clubInfo.data.avaliableClass.length == 7 && (
              // 572px
              <>
                <div className="mt-5 flex border-[1px] border-brand-orange border-l-transparent xl:border-t-[1px] border-t-[0px]  xl:h-[85vh] lg:flex-row flex-col">
                  <div className="lg:w-[30%] w-full">
                    <p className="outline-text-1 text-black leading-[66px] -tracking-[0.03em] block ml-[26px] xl:flex  justify-center my-[30px] sm:text-[53px] xl:w-full lg:w-[50%] text-[36px] ">
                      {clubInfo?.data?.avaliableClass[0]?.title}
                    </p>
                  </div>
                  <ul className="xl:flex block  ">
                    {clubInfo?.data?.avaliableClass.map(
                      (i, idx) =>
                        idx > 0 && (
                          <li
                            className={` border-[1px] xl:border-x-[1px] border-x-[0px] relative border-brand-orange transition-all duration-300  ${i.title === classActive
                              ? "xl:w-[572px] w-full"
                              : "xl:w-[100px] w-full "
                              }`}
                            onClick={() => setClassActive(i.title)}
                          >
                            <p
                              className={`text-2xl font-bold xl:absolute  w-max bottom-[43px] ml-[26px] xl:ml-0 left-[50%] h-[57px] flex items-center origin-left xl:-rotate-90 ${i.title === classActive ? "hidden" : ""
                                }`}
                            >
                              {i.title}
                            </p>
                            <div
                              className={`relative overflow-hidden coacher-content transition-all duration-300  ${i.title === classActive
                                ? "h-[415px] xl:h-full w-full"
                                : "w-0"
                                }`}
                            >
                              <img
                                className="w-full h-full object-cover opacity-80"
                                src={
                                  clubInfo?.data?.avaliableClass[6]?.images
                                    ?.data[0]?.attributes?.url
                                }
                                alt="gymer"
                              />
                              <p className="text-[40px] xl:text-[58px]  font-bold absolute w-max bottom-[14px] left-10   origin-left -rotate-90 ">
                                {i?.title}
                              </p>
                              <div
                                id="studio"
                                className="absolute top-0 right-5 flex items- justify-between items-end"
                              >
                                <div className="flex flex-col items-center relative w-6/12 ml-64 top-[167px] xl:top-[350px]">
                                  <p className="w-full tracking-wide xl:text-base font-normal graphik-regular text-sm">
                                    {i?.description}
                                  </p>
                                  <div className="flex items-center justify-between w-full">
                                    <p className="text-brand-orange text-sm my-[23px] font-bold  xl:text-[19px]">
                                      sweat rating
                                    </p>
                                    <span className="flex items-center ">
                                      {calculateRating(i?.customMetadata?.rate)}
                                    </span>
                                  </div>
                                  <Link to="/book-a-class">
                                    <ButtonNavigate
                                      style="border-b xl:w-[262px] h-[50px] w-[200px] xl:h-[56px] border-[#777777] text-[#777777]"
                                      text="book a class"
                                    ></ButtonNavigate>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </>
            )}

          <div id="classes">
            <p className="mx-[4.9%] mt-[50px]">
              <span className="text-6xl outline-text-1">05</span>
              <span className="text-6xl text-white"> classes today</span>
            </p>
          </div>
          <div className="text-white mx-[4.9%]  mt-[28px] mb-[11px] xl:block hidden">
            <div className="w-full flex  py-5 pr-[63px] pl-4 ">
              <div className="w-1/4 text-brand-orange text-xl font-bold">
                time
              </div>
              <div className="w-1/4 text-brand-orange text-xl font-bold">
                class
              </div>
              <div className="w-1/4 text-brand-orange text-xl font-bold">
                coach
              </div>
              <div className="w-1/4 text-brand-orange text-xl font-bold">
                club
              </div>
            </div>
            {timeTable.map((item: object, index: number) => (
              <div
                onMouseEnter={() => {
                  setToggle(true);
                  setSelected(index);
                }}
                onMouseLeave={() => setToggle(false)}
                key={index}
              >
                <div
                  style={{
                    borderTop:
                      selected === index && toggle
                        ? "1px solid #FF6900"
                        : "1px solid white",
                    background: selected === index && toggle ? "#1C1C1C" : "",
                    borderBottom:
                      index === 4 && !toggle ? "1px solid #C6C6C6" : "",
                  }}
                  className="flex cursor-pointer items-center py-3 pr-[63px] pl-4 "
                >
                  <div className="w-1/4 flex justify-between px-1">
                    <div
                      style={{
                        color: selected === index && toggle ? "white" : "white",
                      }}
                      className="text-xl font-bold"
                    >
                      {item.time}
                    </div>
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                        setSelected(index);
                      }}
                    >
                      <img
                        src={selected === index && toggle ? plus : plus}
                        alt="plus icon"
                        className={
                          selected === index && toggle
                            ? "rotate-45 text-brand-orange"
                            : ""
                        }
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      color: selected === index && toggle ? "#FF6900" : "white",
                    }}
                    className="w-1/4"
                  >
                    {item.class}
                  </div>
                  <div
                    style={{
                      color: selected === index && toggle ? "#FF6900" : "white",
                    }}
                    className="w-1/4"
                  >
                    {item.coach}
                  </div>
                  <div
                    style={{
                      color: selected === index && toggle ? "#FF6900" : "white",
                    }}
                    className="w-1/4 flex"
                  >
                    <div className="w-1/2">{item.club}</div>
                    <div className="w-1/2 flex ">
                      <button
                        className={`w-[125px] h-[40px] border-b font-bold border-t border-l border-r
                        ${item.club.length % 2 == 0
                            ? "border-[#777777]"
                            : item.club.length % 3 == 0
                              ? "border-brand-orange cursor-default"
                              : "border-[#777777]"
                          } ${item.club.length % 2 == 0
                            ? "text-[#777777]"
                            : item.club.length % 3 == 0
                              ? "text-brand-orange"
                              : "text-[#777777]"
                          }
                      ${item.club.length % 2 == 0
                            ? "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-brand-orange focus:text-brand-orange hover:text-white"
                            : item.club.length % 3 == 0
                              ? ""
                              : "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-white focus:text-white hover:text-white"
                          }
                      `}
                      >
                        {item.club.length % 2 == 0
                          ? "book me"
                          : item.club.length % 3 == 0
                            ? "booked"
                            : "waitlist"}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  id={selected === index && toggle ? "showlist" : "hideList"}
                  style={{
                    paddingBottom: selected == index && toggle ? "25px" : "",
                    padding:
                      selected === index && toggle
                        ? "1.25rem 63px 5rem 1rem"
                        : "",
                    background: selected === index && toggle ? "#1C1C1C" : "",
                  }}
                  className="overflow-hidden flex transition-all duration-300 "
                >
                  <div className="w-2/4 flex gap-[16px]">
                    <img src={item.image} />
                    <div>
                      <p className="my-[27px] font-bold text-2xl">
                        {item.descTitle}
                      </p>
                      <p className="w-[395px] font-light text-sm graphik-regular">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                  <div className="w-2/4 flex pt-[70px]">
                    <div className="w-1/3 flex flex-col gap-[28px]">
                      <div className="flex">
                        <p className="w-1/2">studio</p>
                        <p className="w-1/2 text-brand-orange">{item.studio}</p>
                      </div>
                      <div className="flex">
                        <p className="w-1/2">almost full</p>
                        <p className="w-1/2 text-brand-orange">
                          {item.almostFull}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/3 flex flex-col gap-[28px]">
                      <div className="flex">
                        <p className="w-1/2">sweat rating</p>
                        <p className="ml-3">
                          <span className="flex items-center ">
                            {calculateRating(item.sweatRating)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="xl:hidden block mx-[4.9%]">
            <div className="border-b-[1px] flex justify-between border-b-white">
              <div className="text-brand-orange">
                today {new Date().getDay() + "/" + new Date().getMonth()}
              </div>
              <div className="text-brand-orange">5 classes</div>
            </div>
            <div className="border-b-white border-b-[1px]">
              <div className="flex justify-between p-[10px]">
                <div>07:30-08:15</div>
                <div>
                  <img src={plus} />
                </div>
              </div>
              <div className="flex justify-between items-center p-[10px]">
                <div className="text-white text-lg ">
                  <div>republic throwdown</div>
                  <div className="text-[14px] graphik-regular">
                    laura b | anu
                  </div>
                </div>
                <button
                  className={`w-[125px] h-[40px] border-b font-bold border-t border-l border-r
                        ${2 % 2 == 0
                      ? "border-[#777777]"
                      : 2 % 3 == 0
                        ? "border-brand-orange cursor-default"
                        : "border-[#777777]"
                    } ${2 % 2 == 0
                      ? "text-[#777777]"
                      : 2 % 3 == 0
                        ? "text-brand-orange"
                        : "text-[#777777]"
                    }
                      ${2 % 2 == 0
                      ? "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-brand-orange focus:text-brand-orange hover:text-white"
                      : 2 % 3 == 0
                        ? ""
                        : "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-white focus:text-white hover:text-white"
                    }
                      `}
                >
                  {2 % 2 == 0 ? "book me" : 2 % 3 == 0 ? "booked" : "waitlist"}
                </button>
              </div>
            </div>
            <div className="border-b-white border-b-[1px]">
              <div className="flex justify-between p-[10px]">
                <div>07:30-08:15</div>
                <div>
                  <img src={plus} />
                </div>
              </div>
              <div className="flex justify-between items-center p-[10px]">
                <div className="text-white text-lg ">
                  <div>republic throwdown</div>
                  <div className="text-[14px] graphik-regular">
                    laura b | anu
                  </div>
                </div>
                <button
                  className={`w-[125px] h-[40px] border-b font-bold border-t border-l border-r
                        ${2 % 2 == 0
                      ? "border-[#777777]"
                      : 2 % 3 == 0
                        ? "border-brand-orange cursor-default"
                        : "border-[#777777]"
                    } ${2 % 2 == 0
                      ? "text-[#777777]"
                      : 2 % 3 == 0
                        ? "text-brand-orange"
                        : "text-[#777777]"
                    }
                      ${2 % 2 == 0
                      ? "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-brand-orange focus:text-brand-orange hover:text-white"
                      : 2 % 3 == 0
                        ? ""
                        : "hover:border-white hover:border-b-[5px] hover:border-b-brand-orange focus:border-b-[1px] focus:border-white focus:text-white hover:text-white"
                    }
                      `}
                >
                  {2 % 2 == 0 ? "book me" : 2 % 3 == 0 ? "booked" : "waitlist"}
                </button>
              </div>
            </div>
          </div>
          <div id="freeTrial">
            <GetYourFreePass></GetYourFreePass>
          </div>
          {slickCarousel(clubInfo?.data?.coachers)}
          <StudiosNearYou text="all studios" isReverse={true}></StudiosNearYou>
        </>
      )}
    </>
  );
}

function StudioDetail(props: any) {
  return <div className="text-white relative ">{studioInformation(props)}</div>;
}
export default StudioDetail;
