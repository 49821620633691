import React from 'react'
interface Iprops {
    text: string
    plan: object
}

function ButtonSelected(props: Iprops) {
    const { text, plan } = props

    return (
        <button className='w-full h-[40px] rounded-[4px] border-[0.8px] focus:border-black focus:bg-brand-clime focus:text-black border-white uppercase bg-transparent text-white flex items-center justify-center font-medium transition-colors text-[11.2px] gotham hover:border-brand-clime hover:text-brand-clime'>
            {text}
        </button>
    )
}

export default ButtonSelected