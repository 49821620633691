import ladies from '../src/assets/images/vivaBrands/ladies.png';
import calendarIcon from '../src/assets/images/icons/calendarIconWhite.svg';
import progressIconWhite from '../src/assets/images/icons/progressIconWhite.svg';
import faqIconWhite from '../src/assets/images/icons/faq-iconWhite.svg';
import email from '../src/assets/images/icons/email.svg';
import circlePlus from '../src/assets/images/icons/plusIcon.svg';
import noNameAvatar from '../src/assets/images/icons/noNameAvatar.png';
import threeDot from '../src/assets/images/icons/threeDot.svg';
import benefit1 from '../src/assets/images/dashboard/benefit1.png';
import benefit2 from '../src/assets/images/dashboard/benefit2.png';
import benefit3 from '../src/assets/images/dashboard/benefit3.png';
import benefit4 from '../src/assets/images/dashboard/benefit4.png';
import benefit5 from '../src/assets/images/dashboard/benefit5.png';
export const dashboardUserBasicInfo = {
  level: 'multi club +',
  status: 'active',
  id: 391847,
  name: 'angelique',
  brandIncludes: [
    { text: 'club lime', color: '#B5FF00' },
    { text: 'hiit republic', color: '#FF6900' },
    { text: 'ground up', color: '#A4ACD7' },
  ],
};
export const sidebarMenu = [
  { name: 'dashboard', link: 'dashboard', id: '' },
  { name: 'my bookings', link: 'bookings', id: 'bookings' },
  { name: 'timetable', link: 'timetable', id: 'timetable' },
  {
    name: 'account',
    link: 'account',
    id: 'account',
    tabs: [
      { name: 'General', link: 'dashboard/account' },
      { name: 'Password', link: 'dashboard/account/password' },
      { name: 'Biling', link: 'dashboard/account/biling' },
      { name: 'Notifications', link: 'dashboard/account/notifications' },
    ],
  },
  { name: 'upgrade', link: 'upgrade', id: 'upgrade' },
  { name: 'member benefits', link: 'benefits', id: 'benefits' },
  // { name: 'log out', link: '', id: 'logout' },
];

export const dataCollection = [
  {
    text: 'book a class',
    img: ladies,
    icon: calendarIcon,
  },
  {
    text: 'My Bookings',
    img: ladies,
    icon: faqIconWhite,
  },
  {
    text: 'class buddies',
    img: ladies,
    icon: circlePlus,
  },
  {
    text: 'My account',
    img: ladies,
    icon: noNameAvatar,
  },
  {
    text: 'Personal training',
    img: '',
    icon: progressIconWhite,
  },
  {
    text: 'Invite a friend',
    img: '',
    icon: email,
  },
  {
    text: 'Upgrade',
    img: '',
    icon: threeDot,
  },
  {
    text: 'Member benefits',
    img: '',
    icon: calendarIcon,
  },
];
export const benefits = [
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store or online',
    img: benefit1,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store only.',
    img: benefit1,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store or online',
    img: benefit2,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store only.',
    img: benefit3,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store or online',
    img: benefit4,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
  {
    title: '40% off Storewide',
    description: 'Viva members save up to 40% off storewide between 5 to 18 July.',
    note: 'Offer available in-store only.',
    img: benefit5,
    descriptionFull:
      'Viva members save up to 40% off storewide. This offer is available in-store and online. Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.',
  },
];
