export const dropdown = {
  location: {
    label: 'location',
    value: [
      // { 'country': ['australia'] },
      { state: ['act', 'nsw', 'qld', 'vic'] },
    ],
  },
  sort: {
    label: 'sort by',
    value: [{ 'sort by': ['a-z', 'z-a', 'closest to me'] }],
  },
};
