import React, { useState } from "react";
import InputBase from "../../common/inputs/InputBase.tsx";
import informationImage4 from "../../assets/images/StudioDetailInfo/informationImage4.jpg";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import Checkbox from "../../common/buttons/Checkbox";
import plus from "../../assets/images/memberships/plus.png";
// interface bannerType {
//   onClick: () => void;
// }
function TwoWeekFree({ setShow }: any) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  return (
    <div
      className={`"w-[360px] border-[1px] z-50 border-brand-orange h-[761px] bg-black relative"`}
    >
      <button
        className="w-fit absolute rotate-45 right-1 top-1 rounded-full border-brand-orange border-[1px]"
        onClick={() => setShow(false)}
      >
        <img src={plus} />
      </button>
      <img src={informationImage4} className="w-[360px] h-[175px]" />
      <div className=" absolute top-[115px] m-[28px]">
        <p className="outline-text-1 text-[40px] ">two weeks free</p>
        <p className="text-white mt-6 graphik-regular text-[14px] mb-6">
          This form has multiple uses, if no offers are available this form will
          be an opportunity to sign up to receive future offers.
        </p>
        <div className="flex flex-col gap-1">
          <InputBase
            width="100%"
            type="string"
            holder="First name*"
            isRequired={true}
            isShowRequired={formControlled.firstName}
            onchange={(e) => setForm({ ...form, firstName: e.target.value })}
          />
          <InputBase
            width="100%"
            type="string"
            holder="Last name*"
            isRequired={true}
            isShowRequired={formControlled.lastName}
            onchange={(e) => setForm({ ...form, lastName: e.target.value })}
          />
          <InputBase
            width="100%"
            type="email"
            holder="Email address*"
            errorText={errText.email}
            isRequired={true}
            isShowRequired={formControlled.email}
            onchange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <InputBase
            width="100%"
            type="string"
            holder="Phone number*"
            isRequired={true}
            isShowRequired={formControlled.phoneNumber}
            onchange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
          />
        </div>
        <div className="flex gap-4 my-2 items-center">
          <Checkbox></Checkbox>
          <p className="text-sm text-white">
            I aggree terms & conditions and the privacy policy
          </p>
        </div>
        <ButtonNavigate
          text="unlock promo"
          onClick={() => submit()}
          style="border-b border-[#777777] text-[#777777] w-[188px] h-[56px]"
        ></ButtonNavigate>
      </div>
    </div>
  );
}
export default TwoWeekFree;
